import React, {useEffect, useState} from 'react';
import {Button, Image} from "@lguuxe/2024_designsystem_uni";
import {useNavigate, useParams} from "react-router-dom";
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import CommonDivider from "../../components/Common/CommonDivider";
import SectionList from "../../components/UPTP/SectionList";
import BoxHeaderInfo from "../../components/UPTP/BoxHeaderInfo";
import CommonCTAColumn from "../../components/Common/CommonCTAColumn";
import membershipsServiceApi from "../../api/memberships-service";
import {initialProInfo} from "./types";
import MustCheckAccordion from "../../components/Setting/SectionAccordion";
import BS_Barcode from "../../components/Barcode/BS_Barcode";
import {sendEvent} from "../../api/bridgeApi";
import ExImg from "../../images/default/default_Upltwopl_full.svg";

const UPTPDetail: React.FC = () => {
    const navigate = useNavigate();
    const {eventId} = useParams(); // useParams로 eventId를 가져옴
    const [proInfo, setProInfo] = useState(initialProInfo);
    const [timestamp, setTimestamp] = useState<string>("");
    const [isBarcodeOpen, setBarcodeOpen] = useState<boolean>(false);
    useEffect(() => {
        const fetchEventById = async () => {
            try {
                if (eventId) {
                    const getUPTPEvent = await membershipsServiceApi.getUPTPEvent(eventId, 'v1');
                    setProInfo(getUPTPEvent.body.proInfo);
                    setTimestamp(getUPTPEvent.header.timeStamp)
                }
            } catch (error) {
                console.error('Failed to fetch event by id:', error);
            }
            sendEvent("KAKAO_PIXEL", {param: "ParticipationEvent",tag:"Uplay"})
        };

        fetchEventById();
    }, [eventId]);

    const goEntry = () => {
        navigate("/uptp-entry");
    };


    const renderBarcodeButton = () => {
        let handleBarcodeClick = () => {
            setBarcodeOpen(true)
        }

        const currentDate = new Date(timestamp);
        const startDate = new Date(proInfo.proStartDate);
        const endDate = new Date(proInfo.proEndDate);

        let buttonText = "U+멤버쉽 바코드 보기";
        let disabled = false;
        let color = "secondary"
        if (currentDate < startDate) {
            const dayDifference = Math.ceil((startDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24)); // 일 단위로 계산
            const hours = startDate.getHours().toString().padStart(2, '0');

            buttonText = `이벤트 오픈 D-${dayDifference}, ${hours}부터 확인 가능`;
            color = "neutral";
            disabled = false;
            handleBarcodeClick = () => {
            }
        } else if (currentDate > endDate) {
            buttonText = "종료된 이벤트";
            disabled = true;
        }

        return (
            <>
                <div style={{padding: '12px 24px 24px 24px'}}>
                    <Button
                        color={color}
                        fill='solid'
                        size='medium'
                        state='default'
                        style={{width: '100%'}}
                        onClick={handleBarcodeClick}
                        disabled={disabled}
                    > {buttonText}</Button>
                </div>
            </>
        )
    }
    const renderContentByProType = () => {
        switch (proInfo.proType) {
            case "138001": // 쿠폰형
                return (
                    <>
                        {proInfo.cpnInfoList && <SectionList cpnInfoList={proInfo.cpnInfoList}/>}
                        <BoxHeaderInfo title="이용 조건" content={proInfo.cpnUseTerms}/>
                        <BoxHeaderInfo title="이용 방법" content={proInfo.cpnUseMethod}/>
                        <BoxHeaderInfo title="쿠폰 이용 기간" content={proInfo.cpnUseMethod}/>
                        <div style={{margin: "8px 0"}}>
                            <CommonDivider/>
                        </div>
                        {proInfo.cpnPrecautions && <MustCheckAccordion items={[proInfo.cpnPrecautions]}/>}
                    </>
                );
            case "138002": // 응모형
                return (
                    <>
                        <BoxHeaderInfo title="혜택" content={proInfo.entBenefits}/>
                        <BoxHeaderInfo title="대상" content={proInfo.entUseTerms}/>
                        <BoxHeaderInfo title="이용 방법" content={proInfo.entUseMethod}/>
                        <BoxHeaderInfo title="당첨 확인" content={proInfo.entUseTerms}/>
                        <div style={{margin: "8px 0"}}>
                            <CommonDivider/>
                        </div>
                        {proInfo.entPrecautions && <MustCheckAccordion items={[proInfo.entPrecautions]}/>}
                    </>
                );
            case "138003": // 바코드형
                return (
                    <>
                        {renderBarcodeButton()}
                        <div style={{marginBottom: "8px"}}>
                            <CommonDivider/>
                        </div>
                        <BoxHeaderInfo title="혜택" content={proInfo.entBenefits}/>
                        <BoxHeaderInfo title="대상" content={proInfo.entUseMethod}/>
                        <BoxHeaderInfo title="이용 방법" content={proInfo.entUseMethod}/>
                        <div style={{margin: "8px 0"}}>
                            <CommonDivider/>
                        </div>
                        {proInfo.entPrecautions && <MustCheckAccordion items={[proInfo.entPrecautions]}/>}
                    </>
                );
            default:
                return <div>알 수 없는 프로모션 타입입니다.</div>;
        }
    };

    return (
        <>
            <CommonLeftIconHeader title={proInfo.proTitle}/>

            <div>
                <Image
                    customClass="customClass"
                    ratio="1:1"
                    src={proInfo.introImgUrl || "https://picsum.photos/seed/1/200/300"}
                    width="100%"
                    srcFallback={ExImg}
                />
            </div>

            <div
                style={{
                    padding: "12px 0 24px"
                }}
            >
                {renderContentByProType()}
            </div>
            <BS_Barcode isOpen={isBarcodeOpen} onClose={() => (setBarcodeOpen(false))}/>
            {proInfo.proType === "138002" && (
                <CommonCTAColumn buttonText={"응모하기"} handleSummit={goEntry}/>
            )}
        </>
    );
}

export default UPTPDetail;
