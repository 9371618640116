import React, {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import HomeBenefit from './HomeBenefit';
import commonApi from '../../api/common';
import {defaultMembershipInfo, MembershipInfo, session} from './types';
import CommonPopup from "../../components/Common/CommonPopup";
import {addItem, getItemByKey} from "../../utils/indexedDB";
import {sendEvent, sendEventReturn, sendEventReturnToAndroid, sendEventReturnToIOS} from "../../api/bridgeApi";

import useDevicePlatform from "../../hooks/useDevicePlatform";
import useLoading from "../../hooks/useLoading";
import {ApiResponse} from "../../api/types";
import {getMemberStatus, goAppStore, goMovie} from "../../utils/helper";
import MembershipsService from "../../api/memberships-service";
import {useNavigate} from "react-router-dom";
import CommonPopupWithImage from "../../components/Common/CommonImagePopup";
import {adSSP} from "../../api/adSSP";
import common from "../../api/common";
import payment from "../../api/payment";
import paymentApi from "../../api/payment";

export interface HomeBenefitPopupHandles {
    triggerShowAddPopup: () => void;
    triggerFetchSession: () => void;
}

const HomeBenefitPopup = forwardRef<HomeBenefitPopupHandles>((props, ref) => {
    const navigate = useNavigate();
    const [popupContent, setPopupContent] = useState<{
        type: string;
        onSubmit?: () => void;
        title?: string;
        imageUrl?: string;
        description?: string;
        submitText: string;
        cancelText?: string;
        showCheckbox?: boolean;
        onCheckbox?: () => void;
    } | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showAdPopup, setShowAdPopup] = useState(false); // 팝업 표시 여부를 관리하는 상태
    const [userStatus, setUserStatus] = useState<string>('0');
    const [popupQueue, setPopupQueue] = useState<
        { type: string, title?: string; description?: string; submitText: string; cancelText?: string; showCheckbox?: boolean; }[]
    >([]);
    const [landingUrl, setLandingUrl] = useState<string | null>(null);// 클릭 시 이동할 랜딩 URL
    const [popupImageUrl, setPopupImageUrl] = useState<string | null>(null);
    const [clickUrls, setClickUrls] = useState<string[]>([]); // 클릭 시 호출할 URL 리스트
    const [openErrorPopup, setOpenErrorPopup] = useState(false);

    useImperativeHandle(ref, () => ({
        async triggerShowAddPopup() {
            await showPopup()
        },
        async triggerFetchSession() {
            await fetchSession()
        }
    }));

    const finishApp = () => {
        sendEvent('APP_FINISH', {})
    }

    useEffect(() => {
        const requestSession = async () => {
            const device = await getItemByKey('model');
            if(device) {
                await fetchSession();
            }
        }
        requestSession()
    }, [])

    const handlePopupSubmit = () => {
        // 팝업 확인 버튼 클릭 시 수행할 동작
        setShowAdPopup(false);
        sendEvent("APP_FINISH", {})
        console.log("Popup submit action performed");
    };

    const handleImageClick = () => {
        console.log('img click!!!')
        console.log(landingUrl)
        // click_urls 호출
        clickUrls.forEach(url => {
            fetch(url)
                .then(response => console.log(`Click URL called: ${url}`, response))
                .catch(error => console.error(`Error calling click URL: ${url}`, error));
        });

        // 외부 브라우저로 이동
        if (landingUrl) {
            sendEventReturn('EXTERNAL_CALL', {type: '1', url: landingUrl});
        }
    };

    const showPopup = async () => {
        console.log('No more history, perform specific action');
        let adResponse
        // SSP API 호출
        try {
            adResponse = await adSSP('비식별');
            // 받은 데이터에서 이미지 URL을 추출하고 impression_urls 호출
            if (adResponse.exist && adResponse.creatives.length > 0) {
                const firstCreative = adResponse.creatives[0]; // 첫 번째 크리에이티브를 사용

                // impression_urls 호출
                firstCreative.impression_urls?.forEach((url: URL | RequestInfo) => {
                    fetch(url)
                        .then(response => console.log(`Impression URL called: ${url}`, response))
                        .catch(error => console.error(`Error calling impression URL: ${url}`, error));
                });

                setPopupImageUrl(firstCreative.image_url || null); // 이미지 URL 설정
                setClickUrls(firstCreative.click_urls || []); // 클릭 시 호출할 URL 설정
                setLandingUrl(firstCreative.landing_url || null); // 클릭 시 이동할 랜딩 URL 설정
                setShowAdPopup(true); // 팝업을 표시하도록 상태를 변경
            } else {
                setPopupImageUrl(null);
                setShowAdPopup(false);
            }
        } catch (e) {
            console.log(e)
        }
    }


    const fetchSession = async () => {
        console.log('@@@@call fetchSession')
        try {
            const response: ApiResponse<session> = await commonApi.getSession({}, 'v1');
            const data: session = response.body;
            // await handlePopups(data);
            if (data === undefined || data === null) {
                setIsLoading(false)
                setOpenErrorPopup(true)
            } else {
                await addSessionData(data);
                let cardInfo: MembershipInfo = defaultMembershipInfo
                if (data.memberId) {
                    if (['45', '46', '47'].includes(data.memberStatus)) {
                        await addItem('memberStatus', "5")
                        setUserStatus("5")
                    } else {
                        try {
                            const response2: ApiResponse<MembershipInfo> = await MembershipsService.getCardInfo({}, 'v1');
                            cardInfo = response2.body;
                        } catch {
                            console.log("cardInfo error")
                        }
                        const memberStatus = getMemberStatus(data.memberId, data.memberStatus, cardInfo.cardLevel)
                        await addItem('memberStatus', memberStatus)
                        setUserStatus(memberStatus)
                    }
                    await addItem('cardLevel', cardInfo.cardLevel);
                    await addItem('token', cardInfo.token);
                    await addItem('encKey', cardInfo.encKey);
                    await addItem('youthYn', cardInfo.youthYn);
                    await addItem('longTermName', cardInfo.longTermName);
                    try {
                        const response2 = await MembershipsService.getVipBenefitThisMonth('v1');
                        await addItem('isVIP', response2.body.isVIP);
                        if (response2.body.useVipkok && response2.body.useVipkok.length > 0) {
                            await addItem('useVipkokYn', response2.body.useVipkok[0].isUsed);
                        } else {
                            await addItem('useVipkokYn', 'N');
                        }
                    } catch (error) {
                        console.log('Failed to fetch useVipkokYn :', error);
                    }
                } else { // 비로그인
                    await addItem('memberStatus', "0")
                    setUserStatus("0")
                }
            }
        } catch (error) {
            console.error('Error fetching session detail:', error);
        } finally {
            setIsLoading(false)
        }
    };

    const addSessionData = async (data: any) => {
        const sessionData = {
            memberId: data.memberId === null ? '' : data.memberId,
            subNo: data.subNo === null ? '' : data.subNo,
            subCustNo: data.subCustNo === null ? '' : data.subCustNo,
            customerName: data.customerName === null ? '' : data.customerName,
            birthday: data.birthday === null ? '' : data.birthday,
            gender: data.sex === null ? '' : data.sex,
            serviceYackReqStatus: data.serviceYackReqStatus === null ? '' : data.serviceYackReqStatus,
            serviceNotiYn: data.serviceNotiYn === null ? '' : data.serviceNotiYn,
            maketingInfoYn: data.maketingInfoYn === null ? '' : data.maketingInfoYn,
            sessionKey: data.sessionKey === null ? '' : data.sessionKey,
            telcoCode: data.telcoCode === null ? '' : data.telcoCode,
            userStatus: data.userStatus === null ? '' : data.userStatus,
            payExistYn: data.payExistYn === null ? '' : data.payExistYn,
            updateStatus: data.updateStatus === null ? '' : data.updateStatus,
            lossPhone: data.lossPhone === null ? '' : data.lossPhone,
            emulUse: data.emulUse === null ? '' : data.emulUse,
            moneyMeUse: data.moneyMeUse === null ? '' : data.moneyMeUse,
            ci: data.ci === null ? '' : data.ci,
            dataDogSessionSampleRate: data.dataDogSessionSampleRate === null ? '' : data.dataDogSessionSampleRate,
        };

        for (const [key, value] of Object.entries(sessionData)) {
            if (value !== undefined) {
                await addItem(key, value);
            }
        }
        await sendEvent('SET_AUTHORIZATION', {
            authorization: `${data.sessionKey}`
        })
        await sendEvent('SET_MEMBER_ID', {memberId: sessionData.memberId})
        await sendEvent('SET_USER_BIRTH', {userBirth: sessionData.birthday})
        await sendEvent('SET_USER_NAME', {userName: sessionData.customerName})
    };

    const handlePopups = async (data: session) => {
        const refuseMarketing= await getItemByKey("refuseMarketing")
        const refuseMinorUpdate= await getItemByKey("refuseMinorUpdate")
        const queue = [];
        if (data.lossPhone) {
            if (data.lossPhone === "Y") {
                queue.push({
                    type: 'common',
                    description: '휴대폰 분실 접수 상태로\n앱을 실행할 수 없습니다.\n분실 해제 후 이용해주세요.',
                    submitText: '앱 종료',
                });
            }
        }
        if (data.updateStatus) {
            if (data.updateStatus === '115001') {
                queue.push({
                    type: 'common',
                    title: '필수 업데이트 안내',
                    description: 'U+멤버십 앱을 계속 이용하려면 \n업데이트를 해야 해요.\n업데이트를 하시겠어요?',
                    submitText: '업데이트',
                    onSubmit: () => goAppStore(),
                    cancelText: '닫기',
                });
            } else if (data.updateStatus === '115002') {
                queue.push({
                    type: 'common',
                    title: '필수 업데이트 안내',
                    description: 'U+멤버십 앱을 계속 이용하려면 \n업데이트를 해야 해요.\n업데이트를 하시겠어요?',
                    submitText: '업데이트',
                    onSubmit: () => goAppStore(),
                    cancelText: '닫기',
                });
            } else if (data.updateStatus === '115003') {
                if(!refuseMinorUpdate) {
                    queue.push({
                        type: 'common',
                        title: '업데이트 안내',
                        description: 'U+멤버십 앱을 업데이트 할 수 있어요.\n업데이트하고 더 편리하게 이용해 보세요.',
                        submitText: '업데이트',
                        onSubmit: () => goAppStore(),
                        cancelText: '닫기',
                        showCheckbox: true,
                        onCheckbox: async () => {await addItem("refuseMinorUpdate", true)},
                    });
                }
            }
        }
        if (data.serviceYackReqStatus) {
            const response = await commonApi.terms.getAgreements({},'v1')
            //TODO 추후 필요 데이터 확인
            console.log("serviceYackReqStatus", response.body)
        }
        if (data.serviceNotiYn) {
            const response = await commonApi.getServiceNoti({},'v1')
            //TODO 뭐먼저 보여줄까
            // const data = response.body.svcPopList[0]
            // queue.push({
            //     type: 'common',
            //     title: data.svcTitle,
            //     description: data.svcContent,
            //     submitText: "확인",
            //     onSubmit: () => closePopup()
            // })
            console.log("serviceNotiYn", response.body)
        }
        if(!refuseMarketing) {
            if (data.maketingInfoYn) {
                const response = await commonApi.getMarketingNoti({},'v1')
                //TODO 뭐먼저 보여줄까
                // const data = response.body.popupList[0]
                // queue.push({
                //     type: 'image',
                //     title: data.popupTitle,
                //     imageUrl: data.imgUrl,
                //     submitText: "확인",
                //     showCheckbox: true,
                //     onCheckbox: async () => {await addItem("refuseMarketing", true)},
                //     onSubmit: () => closePopup()
                // })
                console.log("maketingInfoYn", response.body)
            }
        }
        if (data.payExistYn) {
            if (data.payExistYn === 'Y') {
                const response = await paymentApi.getPayRequest({},'v1')
                if(response.header.resultCode === "0000") {
                    const data = response.body
                    queue.push({
                        type: 'common',
                        title: '휴대폰 결제 요청',
                        description: `${data.payShopName}에서 ${data.payMoney}를 결제할까요?`,
                        submitText: '확인',
                        onSubmit: () => {sendEventReturn("CHECK_PASSWORD", {page: `payment-${data.otpId}`})},
                        cancelText: '닫기',
                    });
                } else {
                    queue.push({
                        type: 'common',
                        title: '휴대폰 결제 요청 실패',
                        description: response.header.resultCode + response.header.message,
                        submitText: '확인',
                        onSubmit: closePopup
                    });
                }
            }
        }
        setPopupQueue(queue);
        if (queue.length > 0) {
            setPopupContent(queue[0]);
        }
    };

    const closePopup = () => {
        setPopupQueue((prevQueue) => {
            const newQueue = prevQueue.slice(1);
            if (newQueue.length > 0) {
                setPopupContent(newQueue[0]);
            } else {
                setPopupContent(null);
            }
            return newQueue;
        });
    };

    return (
        <>
            {useLoading(isLoading)}
            {popupContent && popupContent.type === 'common' && (
                <CommonPopup
                    onSubmit={popupContent.onSubmit}
                    onClose={closePopup}
                    title={popupContent.title}
                    description={popupContent.description}
                    submitText={popupContent.submitText}
                    cancelText={popupContent.cancelText}
                    showCheckbox={popupContent.showCheckbox}
                    onCheckbox={popupContent.onCheckbox}
                />
            )}

            {popupContent && popupContent.type === 'image' && (
                <CommonPopupWithImage
                    onSubmit={closePopup}
                    submitText={popupContent.submitText}
                    cancelText={popupContent.cancelText}
                    imageUrl={popupContent.imageUrl || "https://example.com/default-ad-image.jpg"}
                    showCheckbox={popupContent.showCheckbox}
                    onImageClick={handleImageClick}
                    onCheckbox={popupContent.onCheckbox}
                />
            )}
            {!isLoading && <HomeBenefit/>}
            {openErrorPopup &&
                <CommonPopup description={"일시적인 서비스 오류로 이용이 어려워요.\n다음에 이용부탁드립니다."} submitText={"확인"}
                             onSubmit={finishApp}/>
            }
            {showAdPopup && (
                <CommonPopupWithImage
                    onClose={() => {setShowAdPopup(false)}}
                    onSubmit={handlePopupSubmit}
                    submitText="앱 종료"
                    cancelText="취소"
                    imageUrl={popupImageUrl || "https://example.com/default-ad-image.jpg"}
                    onImageClick={handleImageClick} // 이미지 클릭 핸들러 추가
                />
            )}
            {/*0.미로그인 / 1.14이상 VIP / 2.14이상 우수 / 3.14미만 vip / 4.14미만 우수 / 5.타사*/}
        </>
    );
});

export default HomeBenefitPopup;
