import React, {useEffect, useRef, useState} from 'react';
import './App.css';

import PartnerDetail from './pages/Partner/PartnerDetail';
import Search from './pages/Search/Search';
import {Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import ResultDetail from './pages/Search/ResultDetail';
import Notice from './pages/Notice/Notice';
import Vipkok from './pages/Vipkok/Vipkok';
import VipkokDetail from './pages/Vipkok/VipkokDetail';
import BS_CouponBarcode from './components/Barcode/BS_CouponBarcode';
import HomeBenefitPopup, {HomeBenefitPopupHandles} from './pages/Home/HomeBenefitPopup';
import NativeInterfaceTest from './pages/Home/NativeInterfaceTest';
import IdentityVerification from './pages/IdentityVerification/IdentityVerification';
import PhoneVerification from './pages/IdentityVerification/PhoneVerification';
import Mission from './pages/Mission/Mission';
import MissionAttendance from './pages/Mission/MissionAttendance';
import UPTP from './pages/UPTP/UPTP';
import UPTPDetail from './pages/UPTP/UPTPDetail';
import My from './pages/My/My';
import MyGrade from './pages/My/MyGrade';
import MyBenefit from './pages/My/MyBenefit';
import Promotion from "./pages/Badge/Promotion";
import AuthTest from './pages/Home/AuthTest';
import Entire from './pages/Entire/Entire';
import MyBadge from "./pages/Badge/MyBadge";
import GiftBadge from "./pages/Badge/GiftBadge";
import Test from './pages/Entire/Test';
import MyCoupon from './pages/My/MyCoupon';
import MyCouponDetail from './pages/My/MyCouponDetail';
import Partners, {PartnersHandles} from "./pages/Partner/Partners";
import MyLikedPartners from "./pages/My/MyLikedPartners";
import {addItem, getItemByKey} from "./utils/indexedDB";
import Agreements, {AgreementHandles} from "./pages/Agreements/Agreements";
import TagManager from 'react-gtm-module';
import {gtmUserInfo} from "./api/GaApi";

import {sendEventReturn, sendEventReturnToAndroid, sendEventReturnToIOS} from "./api/bridgeApi";
import ResultMore from "./pages/Search/ResultMore";
import UPTPEntry from "./pages/UPTP/UPTPEntry";
import RepresentativeTerm from "./pages/IdentityVerification/RepresentativeTerm";
import PartnerMap from "./pages/Partner/PartnerMap";
import Pop from "./pages/Entire/pop/Pop";
import PopTerms from "./pages/Entire/pop/PopTerms";
import PopInput from "./pages/Entire/pop/PopInput";
import Setting, {SettingHandles} from "./pages/Setting/Setting";
import Quit, {QuitHandles} from "./pages/Setting/Quit";
import ModifyUserInfo from "./pages/Setting/ModifyUserInfo";
import AppVersion from "./pages/Setting/AppVersion";
import BenefitGuide from "./pages/BenefitGuide/BenefitGuide";
import Announcement from "./pages/Entire/Help/Announcement";
import AnnouncementDetail from "./pages/Entire/Help/AnnouncementDetail";
import Faq from "./pages/Entire/Help/Faq";
import FaqDetail from "./pages/Entire/Help/FaqDetail";
import CustomerAsk from "./pages/Entire/Help/CustomerAsk";
import CustomerAskWrite from "./pages/Entire/Help/CustomerAskWrite";
import RewardAsk from "./pages/Entire/Help/RewardAsk";
import UseTerms from "./pages/Entire/Help/UseTerms";
import PrivacyPolicy from "./pages/Entire/Help/PrivacyPolicy";
import Opensource from "./pages/Setting/OpenSource";

import otherApi from "./api/other";
import {decrypt, generateMCP_TR_KEY} from "./api/api";
import CommonPopup from "./components/Common/CommonPopup";
import Chatbot from "./pages/External/Chatbot";
import Event from "./pages/Entire/Event/Event";
import MobilePayment from "./pages/MobilePayment/MobilePayment";
import MobilePaymentSetting from "./pages/MobilePayment/MobilePaymentSetting";
import MobilePaymentLimit from "./pages/MobilePayment/MobilePaymentLimit";
import EventResult from "./pages/Entire/Event/EventResult";
import paymentApi from "./api/payment";
import {settingApi} from "./api/settingAndTerms";
import useLandingPage from "./hooks/useLandingPage";
import useDevicePlatform from "./hooks/useDevicePlatform";
import setting from "./pages/Setting/Setting";

TagManager.initialize({
    gtmId: 'GTM-MQRHVPWZ'
})

const App: React.FC = () => {
    const navigate = useNavigate();
    const {landingPage} = useLandingPage();
    const homeBenefitPopupRef = useRef<HomeBenefitPopupHandles>(null);
    const SettingRef = useRef<SettingHandles>(null);
    const AgreementRef = useRef<AgreementHandles>(null);
    const partnersRef = useRef<PartnersHandles>(null);
    const quitRef = useRef<QuitHandles>(null);
    const location = useLocation();

    const [myLgIdPopup, setMyLgIdPopup] = useState(false);
    const [passwordErrorPopup, setPasswordErrorPopup] = useState({show: false, message: ''});
    const webPlatformInfo = useDevicePlatform();
    const fetchUserInfo = async () => {
        if (process.env.REACT_APP_ENV_MODE === 'local') {
            const ctn = await getItemByKey('ctn')
            if (ctn !== undefined && ctn !== '00000000000') {
                console.log('@@@@@@@@@@@@@@login user')
                if (homeBenefitPopupRef.current) {
                    console.log("triggerFetchSession in App.tsx")
                    homeBenefitPopupRef.current.triggerFetchSession();
                }
            } else {
                console.log('@@@@@@@@@@@@@@Default unLogin user')
                await addItem("platform", webPlatformInfo)
                await addItem('appVersion', '10.01.01')
                await addItem('telcoCode', 'L')
                await addItem('osVersion', '14')
                await addItem('ctn', '00000000000')
                await addItem('model', 'SM-F731N')
                if (homeBenefitPopupRef.current) {
                    console.log("triggerFetchSession in App.tsx")
                    homeBenefitPopupRef.current.triggerFetchSession();
                }
                await addItem('memberId', '')
            }
        } else {
            try {
                console.log('@@@@call DEVICE_SYSTEM_INFO && GET_MEMBER_ID')
                try {
                    sendEventReturnToIOS('GET_MEMBER_ID', {})
                    sendEventReturnToIOS('DEVICE_SYSTEM_INFO', {});
                    sendEventReturnToIOS('GET_AD_AGREE', {})
                } catch (error) {
                    console.error('error in sendEventReturnToIOS DEVICE_SYSTEM_INFO', error);
                }
                try {
                    sendEventReturnToAndroid('GET_MEMBER_ID', {})
                    sendEventReturnToAndroid('DEVICE_SYSTEM_INFO', {});
                    sendEventReturnToAndroid('GET_AD_AGREE', {})
                } catch (error) {
                    console.error('error in sendEventReturnToAndroid DEVICE_SYSTEM_INFO', error);
                }
            } catch (error) {
                console.error('Error fetching user info:', error);
            }
        }
    };

    useEffect(() => {
        const startApp = async () => {
            try {
                if (process.env.REACT_APP_ENV_MODE === 'local') {
                    await fetchUserInfo()
                }
            } catch (error) {
                console.error(error);
            } finally {
                console.log('@@@@call INITIAL_LOADING_FINISHED')
                try {
                    try {
                        sendEventReturnToIOS('INITIAL_LOADING_FINISHED', {});
                    } catch (error) {
                        console.error('error in sendEventReturnToIOS DEVICE_SYSTEM_INFO', error);
                    }
                    try {
                        sendEventReturnToAndroid('INITIAL_LOADING_FINISHED', {});
                    } catch (error) {
                        console.error('error in sendEventReturnToAndroid DEVICE_SYSTEM_INFO', error);
                    }
                } catch (error) {
                    console.error('Error fetching user info:', error);
                }
            }
        }
        startApp()

    }, []);

    console.log(`api start in ${process.env.NODE_ENV} env and ${process.env.REACT_APP_ENV_MODE} MODE`)
    useEffect(() => {
        (window as any).onExecuteCallback = async (jsonString: string) => {
            try {
                console.log(jsonString)
                const jsonObject = JSON.parse(jsonString);
                console.log('web : Received object from native:', jsonObject);

                if (jsonObject.type === 'DEVICE_SYSTEM_INFO') {
                    const deviceInfo = jsonObject.param
                    console.log(deviceInfo)
                    await addItem('ctn', deviceInfo.ctn_info)
                    await addItem('first_loading', deviceInfo.first_loading)
                    await addItem('platform', deviceInfo.os_info === 'AND' ? 'AOS' : 'IOS')
                    await addItem('osVersion', deviceInfo.os_version)
                    await addItem('appVersion', deviceInfo.version_info)
                    await addItem('model', deviceInfo.dev_model);
                    await addItem('telcoCode', deviceInfo.tel_info === "LG U+" ? "L" : "O")
                    await addItem('adid', deviceInfo.adid)
                    if (homeBenefitPopupRef.current) {
                        console.log("triggerFetchSession in App.tsx")
                        homeBenefitPopupRef.current.triggerFetchSession();
                    }
                }

                if (jsonObject.type === 'GET_MEMBER_ID') {
                    const data = jsonObject.param
                    console.log('web : memberId:', data.memberId);
                    if (data.memberId) await addItem('memberId', data.memberId)
                    else await addItem('memberId', '')
                }

                if (jsonObject.type === 'SET_APP_LOCK') {
                    const data = jsonObject.param
                    if (SettingRef.current) {
                        SettingRef.current.setAppLock(data.enable)
                    }
                }

                if (jsonObject.type === 'SET_BIO_AUTH') {
                    const data = jsonObject.param
                    if (SettingRef.current) {
                        SettingRef.current.setBIO(data.enable)
                    }
                }

                if (jsonObject.type === 'GET_BIO_AUTH') {
                    const data = jsonObject.param
                    if (SettingRef.current) {
                        SettingRef.current.getBIO(data.enable)
                    }
                }

                if (jsonObject.type === 'GET_APP_LOCK') {
                    const data = jsonObject.param
                    if (SettingRef.current) {
                        SettingRef.current.getAppLock(data.enable)
                    }
                }

                if (jsonObject.type === 'INITIAL_LOADING_FINISHED') {
                    const data = jsonObject.param
                    console.log('web : LOADING_FINISHED:', data);
                    await fetchUserInfo()
                }

                if (jsonObject.param.action === 'keypress' && jsonObject.param.detail === 'back') {
                    console.log('web : back key pressed');
                    if (homeBenefitPopupRef.current) {
                        console.log("triggerShowAddPopup in App.tsx")
                        homeBenefitPopupRef.current.triggerShowAddPopup();
                    } else {
                        navigate(-1)
                    }
                }

                if (jsonObject.type === 'GET_AD_AGREE') {
                    const data = jsonObject.param
                    if (data.agree) await addItem('adAgreeYn', data.ad_agree === 'true' ? 'Y' : 'N');
                }

                if (jsonObject.type === 'SET_PASSWORD') {
                    const data = jsonObject.param
                    const resultType = data.result
                    if (resultType === "00") {
                        const telcoCode = await getItemByKey('telcoCode');
                        if (telcoCode === 'L') {
                            try {
                                const lockDisable = await paymentApi.setLockDisable({}, 'v1');
                                if (lockDisable.header.resultCode === "0000") {
                                    const paySeq = await paymentApi.getPaySeq('v1')
                                    if (paySeq.header.resultCode === "0000") {
                                        const encSeq = paySeq.body.encSeq
                                        const postPassword = await paymentApi.postPassword({
                                            encSeq: encSeq,
                                            passwordSha: '',
                                            newPasswordSha: data.password
                                        }, 'v1')
                                        if (postPassword.header.resultCode === "0000") {
                                            await sendEventReturn("SAVE_PASSWORD", {password: data.password})
                                        } else {
                                            setPasswordErrorPopup({
                                                message: postPassword.header.resultCode + postPassword.header.message,
                                                show: true
                                            })
                                            await sendEventReturn("SAVE_PASSWORD", {password: ''})
                                        }
                                    } else {
                                        setPasswordErrorPopup({
                                            message: paySeq.header.resultCode + paySeq.header.message,
                                            show: true
                                        })
                                        await sendEventReturn("SAVE_PASSWORD", {password: ''})
                                    }
                                } else {
                                    setPasswordErrorPopup({
                                        message: lockDisable.header.resultCode + lockDisable.header.message,
                                        show: true
                                    })
                                    await sendEventReturn("SAVE_PASSWORD", {password: ''})
                                }
                            } catch (error) {
                                await sendEventReturn("SAVE_PASSWORD", {password: ''})
                                console.error("Error SET_PASSWORD detail:", error);
                            }
                        } else {
                            //타사
                            await sendEventReturn("SAVE_PASSWORD", {password: data.password})
                        }
                    }
                }

                if (jsonObject.type === 'CHANGE_PASSWORD') {
                    const data = jsonObject.param
                    const resultType = data.result
                    if (resultType === "00") {
                        const telcoCode = await getItemByKey('telcoCode');
                        if (telcoCode === 'L') {
                            try {
                                const paySeq = await paymentApi.getPaySeq('v1')
                                if (paySeq.header.resultCode === "0000") {
                                    const encSeq = paySeq.body.encSeq
                                    const postPassword = await paymentApi.postPassword({
                                        encSeq: encSeq,
                                        passwordSha: '',
                                        newPasswordSha: data.password
                                    }, 'v1')
                                    if (postPassword.header.resultCode === "0000") {
                                        await sendEventReturn("SAVE_PASSWORD", {password: data.password})
                                    } else {
                                        setPasswordErrorPopup({
                                            message: postPassword.header.resultCode + postPassword.header.message,
                                            show: true
                                        })
                                        await sendEventReturn("SAVE_PASSWORD", {password: ""})
                                    }
                                } else {
                                    setPasswordErrorPopup({
                                        message: paySeq.header.resultCode + paySeq.header.message,
                                        show: true
                                    })
                                    await sendEventReturn("SAVE_PASSWORD", {password: ""})
                                }
                            } catch (error) {
                                console.log("SAVE_PASSWORD error", error)
                                await sendEventReturn("SAVE_PASSWORD", {password: ""})
                            }
                        } else {
                            await sendEventReturn("SAVE_PASSWORD", {password: data.password})
                        }
                        if (SettingRef.current) {
                            SettingRef.current.handleChangePassword()
                        }
                    }
                }


                if (jsonObject.type === 'CHECK_PASSWORD') {
                    const data = jsonObject.param
                    const resultType = data.result
                    const auth = data.authType
                    const page = data.page

                    if (page === "barcode") {
                        if (resultType === '00') sendEventReturn("PASSWORD", {});
                        else {
                            const event = new CustomEvent('failPassword', {detail: {resultType: resultType}});
                            window.dispatchEvent(event)
                        }
                    }
                    if (page.startsWith("payment")) {
                        const otpId = page.split("-")[1]
                        const password = data.password
                        const paySeq = await paymentApi.getPaySeq('v1')
                        if (paySeq.header.resultCode === "0000") {
                            const payConfirm = await paymentApi.postPayConfirm({
                                otpId: otpId,
                                encSeq: paySeq.body.encSeq,
                                passwordSha: password
                            }, 'v1')
                            if (paySeq.header.resultCode === "0000") {
                                setPasswordErrorPopup({message: "거래완료", show: true})
                            }
                        } else {
                            setPasswordErrorPopup({
                                message: paySeq.header.resultCode + paySeq.header.message,
                                show: true
                            })
                        }
                    }
                    if (page === "modifyInfo") {
                        if (resultType === '00') {
                            if(SettingRef.current) {
                                SettingRef.current.handleModifyInfo()
                            }
                        }
                    }
                }

                if (jsonObject.type === 'PASSWORD') {
                    const data = jsonObject.param
                    const password = data.password
                    const event = new CustomEvent('password', {detail: {password: password}});
                    window.dispatchEvent(event)
                }

                if (jsonObject.type === 'CALL_LOGIN') {
                    const data = jsonObject.param
                    switch (data.rt) {
                        case '00000':
                            const fetchDas = async () => {
                                try {
                                    const tcpTrKey = await generateMCP_TR_KEY();
                                    const res = await otherApi.getDas({
                                        ssoKey: data.sso,
                                        lgId: data.userId
                                    }, 'v1', tcpTrKey);
                                    if (res.header.resultCode === '0000') {
                                        const name = await decrypt(tcpTrKey, res.body.name);
                                        const ctn = res.body.ctn;
                                        const info = {
                                            name: name,
                                            birthday: res.body.birthday,
                                            gender: res.body.gender,
                                            nation: res.body.nation,
                                            telcoCode: res.body.carrier,
                                            phoneNo: ctn.length === 12 ? ctn.slice(0, 3) + ctn.slice(4) : ctn,
                                            userCI: res.body.ci,
                                            dasKey: res.body.dasKey,
                                            dasSsoKey: res.body.dasSsoKey,
                                        }
                                        navigate('/agreements/join', {state: info});
                                    } else {
                                        setMyLgIdPopup(true);
                                    }
                                } catch (error) {
                                    console.log('Error fetching DAS API:', error);
                                    setMyLgIdPopup(true);
                                }
                            }
                            fetchDas();
                            break;
                        case '00014': // 14세 미만
                        default:
                            setMyLgIdPopup(true);
                            break;
                    }
                }

                if (jsonObject.type === 'CALL_CERTIFICATION') {
                    const data = jsonObject.param
                    if (data && data.target === 'certification' && data.isMember) {
                        navigate('/phone-certification?target=app')
                    }
                    if (data && data.target === 'certification' && !data.isMember) {
                        navigate('/phone-certification?target=web')
                    }
                }

                if (jsonObject.type === 'LANDING_PAGE') {
                    const data = jsonObject.param
                    console.log('targetUrl : ', data.targetUrl)
                    let urlParam = '?';
                    if (data.utm_medium !== '') {
                        urlParam += 'utm_medium=' + data.utm_medium + '&';
                    }
                    if (data.utm_source !== '') {
                        urlParam += 'utm_source=' + data.utm_source + '&';
                    }
                    if (data.utm_campaign !== '') {
                        urlParam += 'utm_campaign=' + data.utm_campaign + '&';
                    }
                    if (data.utm_term !== '') {
                        urlParam += 'utm_term=' + data.utm_term + '&';
                    }
                    if (data.utm_content !== '') {
                        urlParam += 'utm_content=' + data.utm_content + '&';
                    }
                    urlParam = urlParam.slice(0, -1);
                    landingPage(data.targetType, data.targetUrl, undefined, urlParam);
                }

                if (jsonObject.type === 'FCM_TOKEN') {
                    const data = jsonObject.param
                    if (AgreementRef.current) {
                        AgreementRef.current.triggerHandleJoin(data.fcmToken)
                    }
                }
                if (jsonObject.type === 'REFRESH_TOKEN') {
                    const data = jsonObject.param
                    await addItem('pushRegId', data.newToken)
                    const response = await settingApi.putPushReg({pushRegId: data.newToken}, 'v1');
                    if (response.header.resultCode === '0000') {
                        await addItem('sendTokenYn', 'Y')
                    } else {
                        await addItem('sendTokenYn', 'N')
                    }
                }
                if (jsonObject.type === 'ACTION_EVENT') {
                    const data = jsonObject.param
                    if (data.action === 'resume') {
                        navigate(window.location.pathname);
                    }
                }

                if (jsonObject.type === 'WAITING_LINE_POPUP') {
                    const data = jsonObject.param
                    if (data.result === '00') {

                    } else if (data.result === '01') {

                    }
                }

                if (jsonObject.type === 'GET_GRANT_LOCATION') {
                    const data = jsonObject.param
                    if (data.agree) {
                        navigate('/partners/map');
                    } else {
                        if (partnersRef.current) {
                            partnersRef.current.triggerLocationBS();
                        }
                    }
                }
                if (jsonObject.type === 'RESET_LOGIN_INFO') {
                    if (SettingRef.current) {
                        SettingRef.current.handleResetLoginInfo();
                    }
                    if (quitRef.current) {
                        quitRef.current.handleResetLoginInfo();
                    }
                }

            } catch (error) {
                console.log('web : JSON parsing error:', error);
            }
        };

        gtmUserInfo();
        // (window as any).onExecuteCallback(JSON.stringify({ action: 'keypress', detail: 'back' }));

        return () => {
            delete (window as any).onExecuteCallback;
        };

    }, []);

    useEffect(() => {
    }, [location]);

    return (
        <div className='app-container'>
            <div className='main-content'>
                <Routes>
                    //TODO index.html 추후 삭제
                    <Route path='/index.html' element={<HomeBenefitPopup ref={homeBenefitPopupRef}/>}/>
                    <Route path='/' element={<HomeBenefitPopup ref={homeBenefitPopupRef}/>}/>
                    <Route path='/partners' element={<Partners/>}/>
                    <Route path='/partners/:ptnId' element={<PartnerDetail/>}/>
                    <Route path='/vip-kok' element={<Vipkok/>}/>
                    <Route path='/vip-kok-detail/:ptnId' element={<VipkokDetail/>}/>
                    <Route path='/identity-verification' element={<IdentityVerification/>}/>
                    <Route path='/phone-certification' element={<PhoneVerification/>}/>
                    <Route path='/representative' element={<RepresentativeTerm/>}/>
                    <Route path='/search' element={<Search/>}/>
                    <Route path='/result-detail' element={<ResultDetail/>}/>
                    <Route path='/result-more' element={<ResultMore/>}/>
                    <Route path='/notice' element={<Notice/>}/>
                    <Route path='/Barcode/BS_CouponBarcode' element={<BS_CouponBarcode/>}/>
                    <Route path='/agreements/:type' element={<Agreements ref={AgreementRef}/>}/>
                    <Route path='/uptp' element={<UPTP/>}/>
                    <Route path='/uptp-detail/:eventId' element={<UPTPDetail/>}/>
                    <Route path='/uptp-entry' element={<UPTPEntry/>}/>
                    <Route path='/promotion' element={<Promotion/>}/>
                    <Route path='/my' element={<My/>}/>
                    <Route path='/my/grade' element={<MyGrade/>}/>
                    <Route path='/my/benefit' element={<MyBenefit/>}/>
                    <Route path='/mission' element={<Mission/>}/>
                    <Route path='/mission/attendance' element={<MissionAttendance/>}/>
                    <Route path='/entire' element={<Entire/>}/>
                    <Route path='/my/badge' element={<MyBadge/>}/>
                    <Route path='/my/badge/gift' element={<GiftBadge/>}/>
                    <Route path='/my/coupon' element={<MyCoupon/>}/>
                    <Route path='/my/coupon/:cpnId' element={<MyCouponDetail/>}/>
                    <Route path='/my/partners' element={<MyLikedPartners/>}/>
                    <Route path='/map' element={<PartnerMap/>}/>
                    <Route path='/setting' element={<Setting ref={SettingRef}/>}/>
                    <Route path='/setting/quit' element={<Quit ref={quitRef}/>}/>
                    <Route path='/setting/modify-user-info' element={<ModifyUserInfo/>}/>
                    <Route path='/setting/app-version' element={<AppVersion/>}/>
                    <Route path='/setting/license' element={<Opensource/>}/>
                    <Route path='/pop' element={<Pop/>}/>
                    <Route path='/pop/terms' element={<PopTerms/>}/>
                    <Route path='/pop/input' element={<PopInput/>}/>
                    <Route path='/benefit-guide' element={<BenefitGuide/>}/>
                    <Route path='/help/announcement' element={<Announcement/>}/>
                    <Route path='/help/announcement/:id' element={<AnnouncementDetail/>}/>
                    <Route path='/help/faq' element={<Faq/>}/>
                    <Route path='/help/faq/:id' element={<FaqDetail/>}/>
                    <Route path='/help/ask' element={<CustomerAsk/>}/>
                    <Route path='/help/ask/reward' element={<RewardAsk/>}/>
                    <Route path='/help/ask/write' element={<CustomerAskWrite/>}/>
                    <Route path='/help/terms' element={<UseTerms/>}/>
                    <Route path='/help/private-policy' element={<PrivacyPolicy/>}/>
                    <Route path='/event' element={<Event/>}/>
                    <Route path='/mobile-pay' element={<MobilePayment/>}/>
                    <Route path='/mobile-pay/setting' element={<MobilePaymentSetting/>}/>
                    <Route path='/mobile-pay/limit' element={<MobilePaymentLimit/>}/>
                    <Route path='/event/result/:eventId' element={<EventResult/>}/>
                    <Route path='/benefit-guide/:id' element={<BenefitGuide/>}/>
                    {/* test page*/}
                    <Route path='/externalApi' element={<Chatbot/>}/>
                    <Route path='/auth' element={<AuthTest/>}/>
                    <Route path='/native' element={<NativeInterfaceTest/>}/>
                    <Route path='/test' element={<Test/>}/>
                </Routes>
            </div>

            <CommonPopup isOpen={passwordErrorPopup.show} description={passwordErrorPopup.message} submitText={'확인'}
                         onSubmit={() => setPasswordErrorPopup({...passwordErrorPopup, show: false})}
            />
            <CommonPopup isOpen={myLgIdPopup} description={'일시적으로 MY LG ID 인증을 할 수 없어요. \n휴대폰번호로 인증해 주세요.'}
                         submitText={'휴대폰번호로 인증'}
                         onSubmit={() => {
                             setMyLgIdPopup(false)
                             navigate('/phone-certification')
                         }} cancelText={'취소'} onClose={() => {
                setMyLgIdPopup(false)
            }}/>
        </div>
    );
};

export default App;
