import React from 'react';
import { TextSet, List, Image, Tag, Text } from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";
import {getColorTokenForLabel} from "../../utils/helper";

interface UPTPItemProps {
    itemList: UPTPItem[];
    threshold?: number;
}

interface UPTPItem {
    itemType: string;
    itemName: string;
    itemId: string;
    itemImgUrl: string;
    itemSummary: string;
    tagName: string;
}
const UPTPItem: React.FC<UPTPItemProps> = ({ itemList, threshold }) => {
    console.log(itemList)
    const navigate = useNavigate()
    const itemsToRender = itemList.slice(0, threshold ?? itemList.length);

    const onClickUPTP = (item : UPTPItem) => {
        navigate(`/uptp-detail/${item.itemId}`)
    }

    return (
        <div>
            <List
                type="view"
                fill="none"
                items={itemsToRender.map(item => ({
                    onClick: () => onClickUPTP(item),
                    leftSlot: [
                        <div
                            key={item.itemId}
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "0.5rem",
                            }}>
                            <TextSet
                                subComponent="Body_1"
                                text={{
                                    eyebrow: (
                                        <div
                                            style={{
                                                paddingBottom: "2px"
                                            }}
                                        >
                                            <span>{item.itemName.split('레저/테마파크')[0]}</span>
                                            <span className="bar"></span>
                                            <span>{item.itemName.split('레저/테마파크')[1]}</span>
                                        </div>
                                    ),
                                    title: (
                                        <Text color="color_text_neutral_6">
                                            {item.itemSummary.split('\n').map((line, index) => (
                                                <React.Fragment key={index}>
                                                    {line}<br />
                                                </React.Fragment>
                                            ))}
                                        </Text>
                                    )
                                }}
                            />
                            <Tag
                                item={item.tagName.split('|').map((tag, index) => ({
                                    label: tag.trim(), // 공백을 제거한 태그 이름
                                    size: "xSmall",
                                    style: "solid",
                                    colorToken: getColorTokenForLabel(tag),
                                }))}
                            />
                        </div>
                    ],
                    rightSlot: [
                        <Image
                            key={item.itemId + '_image'}
                            src={item.itemImgUrl}
                            ratio="1:1"
                            width="72px"
                        />
                    ],
                }))}
            />
        </div>
    );
}

export default UPTPItem;