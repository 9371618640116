import React from 'react';
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";

// 패키지 데이터를 리스트로 정의
const packageList = [
    { name: 'npm', url: 'https://docs.npmjs.com/policies/npm-license' },
    { name: 'TypeScript', url: 'https://github.com/microsoft/TypeScript/blob/main/LICENSE.txt' },
    { name: 'lottie-react', url: 'https://github.com/Gamote/lottie-react/blob/main/LICENSE' },
    { name: 'React', url: 'https://github.com/facebook/react/blob/main/LICENSE' },
    { name: 'react-barcode', url: 'https://github.com/TheWirv/react-barcode-scanner/blob/master/LICENSE' },
    { name: 'react-daum-postcode', url: 'https://github.com/actbase/react-daum-postcode/blob/main/LICENSE' },
    { name: 'react-gtm-module', url: 'https://github.com/sooro-io/react-gtm-module/blob/main/LICENSE' },
    { name: 'react-load-script', url: 'https://www.npmjs.com/package/react-load-script' },
    { name: 'react-router-dom', url: 'https://github.com/Doist/react-router-dom/blob/main/LICENSE' },
    { name: 'idb', url: 'https://github.com/jakearchibald/idb/blob/master/LICENSE' },
    { name: 'react-dom', url: 'https://github.com/facebook/react/blob/main/LICENSE' },
    { name: 'react-scripts', url: 'https://github.com/facebook/create-react-app/blob/main/packages/react-scripts/LICENSE' },
    { name: 'react-slick', url: 'https://github.com/akiran/react-slick/blob/master/LICENSE' },
    { name: 'react-swipeable', url: 'https://github.com/FormidableLabs/react-swipeable/blob/master/LICENSE' },
    { name: 'web-vitals', url: 'https://github.com/GoogleChrome/web-vitals/blob/main/LICENSE' },
    { name: 'react-helmet-async', url: 'https://github.com/staylor/react-helmet-async/blob/main/LICENSE' },
    { name: '@testing-library/user-event', url: 'https://github.com/testing-library/user-event/blob/main/LICENSE' },
    { name: '@testing-library/jest-dom', url: 'https://github.com/testing-library/jest-dom/blob/main/LICENSE' }
];

// 패키지 항목을 렌더링하는 함수
const renderPackageItem = (packageName: string, url: string) => {
    return (
        <li key={packageName} style={{ fontSize: '12px', fontWeight: 'bold' }}>
            {packageName} <br />
            <a href={url} target="_blank" style={{ fontSize: '10px' }}>
                {url}
            </a>
        </li>
    );
}

const Opensource = () => {
    return (
        <>
            <CommonLeftIconHeader title={"오픈소스 라이센스"} />
            <ul style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}>
                {packageList.map(pkg => renderPackageItem(pkg.name, pkg.url))}
            </ul>
        </>
    );
}

export default Opensource;
