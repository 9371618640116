import React from 'react';
import {BenefitInfo} from '../../pages/Partner/types';
import {TextSet} from "@lguuxe/2024_designsystem_uni";
import {getTagInfoByBenefitCode} from "../../utils/helper";

export interface BoxTableGradeProps {
    benefitContent: string;
    benefitInfoList?: BenefitInfo[];
}

const BenefitInfoSection: React.FC<BoxTableGradeProps> = ({benefitContent, benefitInfoList}) => {
    return (
        <>
            <div
                style={{
                    width: '360px',
                    backgroundColor: '#FFFFFF',
                }}
            >
                <TextSet
                    subComponent='Heading_4'
                    text={{
                        title: '혜택 안내',
                        description: benefitContent,
                    }}
                    blockSpacing={{top: '24px', bottom: '12px'}}
                    isIndent={true}
                />
            </div>
            <div className='tbl_box'>
                <div className='tbl'>
                    <table>
                        <caption>table</caption>
                        <colgroup>
                            <col width='85px'/>
                            <col/>
                        </colgroup>
                        <tbody>
                        <tr>
                            <th>등급</th>
                            <th>혜택 정보</th>
                        </tr>
                        {benefitInfoList?.map((benefit) => (
                            <tr>
                                <td>{getTagInfoByBenefitCode(benefit.benefitCode).label}</td>
                                <td>{benefit.benefitSummary}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>

    );
};

export default BenefitInfoSection;
