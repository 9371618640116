import React from 'react';
import { TextSet, List, Image, Badge, Text } from "@lguuxe/2024_designsystem_uni";
import useLandingPage from "../../hooks/useLandingPage";

interface EventItemProps {
    itemList: EventItem[];
    threshold?: number;
}

interface EventItem {
    itemType: string;
    itemName: string;
    itemId: string;
    itemImgUrl: string;
    itemSummary: string;
    itemStartDate: string;
    itemEndDate: string;
    isNew: string;
    tagName: string;
    linkType: string;
    linkUrl: string;
}

const EventItem: React.FC<EventItemProps> = ({ itemList, threshold }) => {
    const itemsToRender = itemList.slice(0, threshold ?? itemList.length);
    const {landingPage} = useLandingPage();
    const onEventClick = (event: EventItem) => () => {
        landingPage(event.linkType, event.linkUrl, event.tagName);
    }
    return (
        <div>
            <List
                fill="none"
                name="list_name"
                size="large"
                type="view"
                weight="default"
                isSlotClickable={true}
                items={itemsToRender.map(item => ({
                    onClick: onEventClick(item),
                    leftSlot: [
                        <Image
                            key={item.itemId}
                            ratio="1:1"
                            rounded="55"
                            src={item.itemImgUrl}
                            width="48"
                        />,
                        <div key={item.itemId + '_details'}>
                            <TextSet
                                subComponent="Body_1"
                                text={{
                                    title: (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                gap: "4px"
                                            }}
                                        >
                                            <Text typo="font_body_1B">{item.itemName}</Text>
                                            {item.isNew === 'Y' && (
                                                <Badge
                                                    fill="circle"
                                                    text="N"
                                                />
                                            )}
                                        </div>
                                    ),
                                    description: (
                                        <Text typo="font_detail_2M">
                                            {item.itemStartDate.slice(0, 4)}.{item.itemStartDate.slice(4, 6)}.{item.itemStartDate.slice(6, 8)} ~ {item.itemEndDate.slice(0, 4)}.{item.itemEndDate.slice(4, 6)}.{item.itemEndDate.slice(6, 8)}
                                        </Text>
                                    )
                                }}
                            />
                        </div>
                    ],
                    linkIconOnlyId: "empty"
                }))}
            />
        </div>
    );
}

export default EventItem;