import React from 'react';
import { Button } from '@lguuxe/2024_designsystem_uni';

interface AmountButtonProps {
    label: string;
    increment: number;
    onClick: (increment: number) => void;
}

const AmountButton: React.FC<AmountButtonProps> = ({ label, increment, onClick }) => {
    return (
        <Button
            size="small"
            color="neutral"
            fill="solid"
            style={{ minWidth: 'calc(33.3% - 6px)' }}
            onClick={() => onClick(increment)}
        >
            {label}
        </Button>
    );
};

export default AmountButton;
