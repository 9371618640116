import {Icon, Text} from "@lguuxe/2024_designsystem_uni";

interface CouponMessageProps {
    couponState: 'used' | 'expired';
}

const CouponMessage: React.FC<CouponMessageProps> = ({couponState}) => {
    return (
        <div
            style={{
                display: "flex",
                gap: "12px",
                flexDirection: "column",
                padding: "0",
                width: "100%"
            }}
        >
            <div
                style={{
                    display: "flex",
                    gap: "12px",
                    justifyContent: "center",
                    padding: "4px 20px 0",
                    alignSelf: "stretch"
                }}
            >
                <div
                    style={{
                        alignItems: "flex-start",
                        alignSelf: "stretch",
                        backgroundColor: "#F9FAFB",
                        borderRadius: "12px",
                        display: "flex",
                        gap: "6px",
                        padding: "16px 20px",
                        width: "100%"
                    }}
                >
                    <Icon color="#7f8a94" name="error_circle_filled" size="24px"/>
                    <Text typo="font_body_2M" color="color_text_neutral_3">
                        {couponState === 'used' && '쿠폰을 사용했어요.'}
                        {couponState === 'expired' && '기간이 만료되었어요.'}
                    </Text>
                </div>
            </div>
        </div>
    )
}

export default CouponMessage;