import React, {useEffect, useState} from 'react';
import SectionHeader from '../../components/My/SectionHeader';
import DiscountSection from '../../components/My/DiscountSection';
import MembershipGrade from '../../components/My/MembershipGrade';
import VipkokBenefit from '../../components/My/VipkokBenefit';
import UseHistory from '../../components/My/UseHistory';
import MyBadges from '../../components/My/MyBadges';
import MyMenu from '../../components/My/MyMenu';
import MissionReword from '../../components/My/MissionReword';
import CommonDivider from '../../components/Common/CommonDivider';
import membershipsServiceApi from '../../api/memberships-service';
import {partnerApi} from '../../api/partnerAndAffiliate-service';
import nftApi from '../../api/nft';
import {
    BenefitSummery,
    BenefitThisMonth,
    initialBenefitSummary,
    MissionReward,
    MoneyBenefit,
    MyBadge,
    UsedHistory
} from './types';
import PartnerCarousel from '../../components/Partner/PartnerCarousel';
import NavigationBar from '../../components/Common/NavigationBar';
import {brazeEventApi} from '../../api/brazeBridge';
import {LikedPartners} from '../Partner/types';
import {handlePromiseResult} from "../../api/api";
import {getItemByKey} from "../../utils/indexedDB";
import {useNavigate} from 'react-router-dom';
import otherApi from "../../api/other";
import TitleHeader from "../../components/Common/TitleHeader";

const initialBenefitThisMonth: BenefitThisMonth = {
    isVIP: '',
    currentServerDate: '',
    useVipkok: [],
};

const initialMyBadge: MyBadge = {
    sendableCount: '0',
    badgeCount: '0',
    categoryList: [],
};

const initialUseHistory: UsedHistory = {
    totalCnt: 0,
    pointUseList: [],
};

const initialLikedPartners: LikedPartners = {
    ptnList: [],
};

const initialMissionReward: MissionReward = {
    rewardPoint: '0',
};

const initialMoneyBenefit: MoneyBenefit = {
    moneyMePoint: '0',
};

const My: React.FC = () => {
    const navigate = useNavigate();
    const [benefitSummary, setBenefitSummary] = useState<BenefitSummery>(initialBenefitSummary);
    const [benefitThisMonth, setBenefitThisMonth] = useState<BenefitThisMonth>(initialBenefitThisMonth);
    const [myBadge, setMyBadge] = useState<MyBadge>(initialMyBadge);
    const [useHistory, setUseHistory] = useState<UsedHistory>(initialUseHistory);
    const [likedPartners, setLikedPartners] = useState<LikedPartners>(initialLikedPartners);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [memberStatus, setMemberStatus] = useState<string>('');
    const [missionReward, setMissionReward] = useState<MissionReward>(initialMissionReward);
    const [moneyBenefit, setMoneyBenefit] = useState<MoneyBenefit>(initialMoneyBenefit);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const birthday = await getItemByKey('birthday');
                const [benefitSummary, benefitThisMonth, myBadge, useHistory, likedPartners, missionReward,] = await Promise.allSettled(
                    [
                        membershipsServiceApi.getVipBenefitSummary({userBirth: birthday}, 'v1'),
                        membershipsServiceApi.getVipBenefitThisMonth('v1'),
                        nftApi.getMyBadge('v1'),
                        membershipsServiceApi.vipKok.getUseHistory({memberId: '1', year: '2024'}, 'v1'),
                        partnerApi.getPartnersLike({}, 'v1'),
                        otherApi.getMissionReward('v1'),
                    ]
                );
                setBenefitSummary(handlePromiseResult(benefitSummary, initialBenefitSummary));
                setBenefitThisMonth(handlePromiseResult(benefitThisMonth, initialBenefitThisMonth));
                setMyBadge(handlePromiseResult(myBadge, initialMyBadge));
                setUseHistory(handlePromiseResult(useHistory, initialUseHistory));
                setLikedPartners(handlePromiseResult(likedPartners, initialLikedPartners));
                otherApi.getMoneyBenefit('v1').then((res) => {
                    if (res.header.resultCode === '0000') {
                        setMoneyBenefit(res.body);
                    }
                });
            } catch (error) {
                console.log('Failed to fetch my:', error);
            } finally {
                setIsLoading(false);
                const moneyMeUse = await getItemByKey('moneyMeUse');
                brazeEventApi.my_tab_enter(moneyMeUse === 'Y', Number(moneyBenefit.moneyMePoint));
            }
        };

        const fetchIndexedDb = async () => {
            setMemberStatus(await getItemByKey('memberStatus'));
        };

        fetchIndexedDb();
        fetchData();
    }, []);


    useEffect(() => {
        const handleBeforeUnload = async () => {
            const moneyMeUse = await getItemByKey('moneyMeUse');
            brazeEventApi.my_tab_exit(moneyMeUse === 'Y', Number(moneyBenefit.moneyMePoint));
        };
        return () => {
            handleBeforeUnload();
        }
    }, [navigate]);

    return (
        <>
            {/*{useLoading(isLoading)}*/}
            <div style={{paddingBottom: '96px'}}>
                {memberStatus === '5' ? (
                    <TitleHeader title='마이' isSetting={false}/>
                ) : (
                    <>
                        <SectionHeader/>
                        <DiscountSection summary={benefitSummary} date={benefitThisMonth.currentServerDate}/>
                    </>
                )}


                {/*----------------------- 멤버십 등급 --------------------*/}
                <MembershipGrade summary={benefitSummary}/>
                <CommonDivider/>

                {/*----------------------- VIP콕 -------------------------*/}
                <VipkokBenefit vipkok={benefitThisMonth}/>
                <CommonDivider/>

                {/*----------------------- 내가 받은 혜택 --------------------*/}
                {/* TODO: Vipkok 사용 내역 API 추가 필요 */}
                {memberStatus !== '5' && (
                    <>
                        <UseHistory history={useHistory}/>
                        <CommonDivider/>
                    </>
                )}

                {/*-------------------------- 내 배지 -----------------------*/}
                {(memberStatus === '1' || memberStatus === '2') && (
                    <>
                        <MyBadges myBadge={myBadge}/>
                        <CommonDivider/>
                    </>
                )}

                {/*----------------------- 나의 메뉴 --------------------*/}
                <MyMenu memberStatus={memberStatus}/>
                <CommonDivider/>

                {/*----------------------- 좋아하는 제휴사 --------------------*/}
                <PartnerCarousel partnerList={likedPartners.ptnList}/>
                <CommonDivider/>

                {/*----------------------- 미션 리워드 & 머니 --------------------*/}
                {(memberStatus !== '3' && memberStatus !== '4') && (
                    <MissionReword missionReward={missionReward} moneyBenefit={moneyBenefit}/>)}

            </div>
            <NavigationBar selectedItem='my'/>
        </>
    );
};

export default My;
