import React, {useEffect, useState} from 'react';
import {Button, Icon, IconButton, Tag, Text, Toggle} from '@lguuxe/2024_designsystem_uni';
import CommonDividerVertical from '../Common/CommonDividerVertical';
import BarcodeFailed from './BarcodeFailed';
import BarcodeTimeOut from './BarcodeTimeOut';
import BarcodeBlock from './BarcodeBlock';
import {initialPaymentBarcode, PaymentBarcode, RequestPaymentInfo} from './types';
import Barcode from 'react-barcode';
import paymentApi from "../../api/payment";
import {sendEventReturn} from "../../api/bridgeApi";
import {getBarcodeWidth} from "./utils";

const BARCODE_TIMER = 60; // 15분 = 900초

const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes < 10 ? `0${minutes}` : minutes}:${
        remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds
    }`;
};

export interface BarcodeHandlers {
    handleCheckPassword: (result: string, auth: string) => void;
}

interface PaymentBarcodeSectionProps {
    showSnackbar: (message: string) => void;
    blocked: boolean;
}

const PaymentBarcodeSection: React.FC<PaymentBarcodeSectionProps> = ({
                                                                         showSnackbar,
                                                                         blocked,
                                                                     }) => {
    const [time, setTime] = useState(BARCODE_TIMER);
    const [isMembershipPopupVisible, setIsMembershipPopupVisible] = useState(false);
    const [isMembershipDiscount, setIsMembershipDiscount] = useState<boolean>(false);
    const [paymentBarcode, setPaymentBarcode] = useState<PaymentBarcode>(initialPaymentBarcode);
    const [showPaymentUseTermBS, setShowPaymentUseTermBS] = useState(false);
    const [requestInfo, setRequestInfo] = useState<RequestPaymentInfo>({encSeq: '', otpId: '', passwordSha: ''});
    const [barcodeWidth, setBarcodeWidth] = useState(2);
    const [barcodeState, setBarcodeState] = useState('timeout');

    // TODO: 보안 프로그램 설치 팝업

    useEffect(() => {
        const handleCheckPasswordEvent = (event: CustomEvent) => {
            const password = event.detail.password;
            // fetchPaymentUseTerm();
            fetchPaymentBarcode(password);
        };

        const handleFailPasswordEvent = (event: CustomEvent) => {
            console.log('handleFailPasswordEvent', event);
            showSnackbar('비밀번호가 일치하지 않습니다. 다시 입력해주세요. (임시 메시지)');
        }

        window.addEventListener('password', handleCheckPasswordEvent as EventListener);
        window.addEventListener('failPassword', handleFailPasswordEvent as EventListener);

        // 바코드 크기 조정
        const updateBarcodeWidth = () => {
            setBarcodeWidth(getBarcodeWidth());
        };
        updateBarcodeWidth();
        window.addEventListener('resize', updateBarcodeWidth);

        return () => {
            window.removeEventListener('password', handleCheckPasswordEvent as EventListener);
            window.removeEventListener('failPassword', handleFailPasswordEvent as EventListener);
            window.removeEventListener('resize', updateBarcodeWidth);
        };
    }, []);

    useEffect(() => {
        if (blocked) {
            setBarcodeState('blocked');
            return;
        }

        if (barcodeState === 'failed') {
            return;
        }

        if (paymentBarcode.barcode !== '' && barcodeState === 'ready') {
            setBarcodeState('running');
            const timer = setInterval(() => {
                setTime((prevTime) => {
                    if (prevTime > 1) {
                        return prevTime - 1;
                    } else {
                        clearInterval(timer);
                        setBarcodeState('timeout');
                        return 0;
                    }
                });
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [barcodeState, paymentBarcode]);

    const fetchPaymentBarcode = async (passwordSha: string) => {
        try {
            console.log('fetchPaymentBarcode in : ', requestInfo);
            const otpRes = await paymentApi.postPayOtp({certType: '20', uicc: ''}, 'v1');
            let otpId = otpRes.body.otpId;

            const seqRes = await paymentApi.getPaySeq('v1');
            const encSeq = seqRes.body.encSeq;

            const confirmParams = {
                otpId: otpId,
                encSeq: encSeq,
                appResult: '00',
                authType: '01',
                passwordSha: passwordSha,
            }
            const confirmRes = await paymentApi.postPayConfirm(confirmParams, 'v1');
            otpId = confirmRes.body.otpId;

            paymentApi.getCardBarcode({otpId: otpId}, 'v1').then((res) => {
                if (res.header.resultCode === '0000' && res.body) {
                    setPaymentBarcode(res.body);
                    setBarcodeState('ready');
                }
            });
        } catch (error) {
            console.log('An unexpected error occurred:', error);
            setBarcodeState('failed');
        }
    }

    const fetchPaymentReady = async () => {
        try {
            paymentApi.getPaySeq('v1').then((seqRes) => {
                console.log('seqRes', seqRes);
                if (seqRes.header.resultCode === '0000' && seqRes.body) {
                    setRequestInfo(prev => ({...prev, encSeq: seqRes.body.encSeq}));
                }
            });
            const otpRes = await paymentApi.postPayOtp({certType: '20', uicc: ''}, 'v1');
            if (otpRes.header.resultCode === '0000' && otpRes.body) {
                setRequestInfo(prev => ({...prev, otpId: otpRes.body.otpId}));
                return otpRes.body.otpId;
            }
            return '';
        } catch (error) {
            console.log('An unexpected error occurred:', error);
        }
    }

    const fetchPaymentUseTerm = async () => {
        try {
            paymentApi.getServiceUseTerm('v1').then((res) => {
                console.log('getServiceUseTerm:', res);
                if (res.header.resultCode === '0000' && res.body) {
                    if (res.body.useTermYn === 'Y') {
                        // fetchPaymentBarcode();
                    } else {
                        // TODO: 수정 필요
                        setShowPaymentUseTermBS(true);
                    }
                }
            });
        } catch (error) {
            setShowPaymentUseTermBS(true);
            console.log('An unexpected error occurred:', error);
        }
    }


    const handleCopy = () => {
        navigator.clipboard
            .writeText(paymentBarcode.barcode)
            // .then(() => { // 1.3v 이후 삭제
            //     showSnackbar('바코드 번호가 복사되었습니다.');
            // })
            .catch((err) => {
                console.error('복사 실패', err);
            });
    };

    const formatBarcode = (barcode: string) => {
        return barcode.match(/.{1,4}/g) || [];
    };

    const handleGenerateBarcode = async () => {
        // TODO: 1.  보안프로그램 설치 여부
        sendEventReturn('CHECK_PASSWORD', {reqAuthType: '01', page: 'barcode'});
        // fetchPaymentBarcode();
        // TODO: 밑에 제거
        // setBarcodeTimeOut(false);
        // setTime(BARCODE_TIMER);
    };

    const handleMembershipDiscount = (event: any) => {
        console.log('handleMembershipDiscount', event);
        if (event.value == 'value') {
            setIsMembershipDiscount(!isMembershipDiscount);
            showSnackbar('제휴사에서 휴대폰결제할 때 U+멥버십 할인이 \\n자동 적용돼요.');
        } else {
            setIsMembershipPopupVisible(true);
            showSnackbar('휴대폰결제할 때 U+멤버십 할인이 \\n적용되지않아요.');
        }
    };

    // 조건에 따른 화면 분기
    if (barcodeState === 'failed') {
        return <BarcodeFailed onGenerateBarcode={handleGenerateBarcode}/>;
    }

    if (barcodeState === 'timeout') {
        return <BarcodeTimeOut onGenerateBarcode={handleGenerateBarcode} tabType='payment'/>;
    }

    if (barcodeState === 'blocked') {
        return <BarcodeBlock/>;
    }

    return (
        <>
            <div
                style={{
                    border: '2px solid transparent',
                    borderRadius: '12px',
                    backgroundImage: 'linear-gradient(#fff, #fff), linear-gradient(90deg, #F6257F 0%, #AE0ABC 100%)',
                    borderImageSlice: '1',
                    backgroundOrigin: 'border-box',
                    backgroundClip: 'content-box, border-box',
                    width: '100%',
                    height: '155px',
                    boxSizing: 'border-box',
                }}
            >
                <div
                    style={{
                        padding: '16px 20px',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Tag
                            item={[
                                {
                                    colorToken: 'gradient_secondary',
                                    label: '결제 바코드',
                                    size: 'small',
                                    style: 'solid',
                                },
                            ]}
                        />
                        <Toggle size='small' onChange={handleMembershipDiscount}>
                            멤버십 할인 함께 받기
                        </Toggle>
                    </div>
                    <div
                        style={{
                            width: 'auto',
                            height: '56px',
                            // display: "flex",
                            margin: '12px auto 0',
                            justifyContent: 'center',
                        }}
                        data-gtm-event-name={'all_click'}
                        data-gtm-event-category={'클릭'}
                        data-gtm-event-action={'바코드|휴대폰 결제 - 링크 클릭'}
                        data-gtm-event-label={'컨텐츠 : 소액결제 바코드'}
                        data-gtm-click-url={''}
                        data-gtm-click-location={'바코드|휴대폰 결제'}
                        data-gtm-click-direction={'내부'}
                        data-gtm-click-text={'소액결제 바코드'}
                    >
                        <Barcode value={paymentBarcode.barcode} displayValue={false} height={56} width={barcodeWidth}
                                 margin={0}/>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '12px',
                        }}
                    >
                        <div style={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                            {formatBarcode(paymentBarcode.barcode).map((chunk, index) => (
                                <Text key={index} typo='font_body_2M' color='color_text_neutral_4'>
                                    {chunk}
                                </Text>
                            ))}
                            <IconButton icon='copy_line' size='small' fill='icon' onClick={handleCopy}/>
                        </div>

                        <CommonDividerVertical/>
                        <Button color='primary' fill='text' onClick={handleGenerateBarcode}>
                            {formatTime(time)}
                            <Icon
                                name='refresh_line'
                                size='16px'
                                color='#E6007E'
                                style={{
                                    marginLeft: '2px',
                                }}
                            />
                        </Button>

                        {/* {isMembershipPopupVisible && (  )} */}
                        {/* TODO: 팝업 띄우기... 팝업 공용화. 차단 헤제하기 누르면 휴대폰결제 설정 화면으로 이동     */}
                    </div>
                </div>
            </div>
            {/*{showPaymentUseTermBS && (<BS_PaymentTerm isOpen={showPaymentUseTermBS} onClose={() => {*/}
            {/*    setShowPaymentUseTermBS(false)*/}
            {/*}} onAgree={() => {*/}
            {/*    fetchPaymentBarcode()*/}
            {/*}} reqInfo={requestInfo}/>)}*/}

        </>
    );
};

export default PaymentBarcodeSection;
