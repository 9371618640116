import React from 'react';

const CommonDividerThin: React.FC = () => {
    return (
        <div
            style={{
                height: "1px",
                width: "100%",
                backgroundColor: "#F3F5F6",
            }}
        />
    );
}

export default CommonDividerThin;