import React, {useState} from 'react';
import {Box, Button, CTA, TextSet} from "@lguuxe/2024_designsystem_uni";
import CommonText2row from "../Common/CommonText2row";
import BS_Barcode from "../Barcode/BS_Barcode";
import {brazeEventApi} from "../../api/brazeBridge";
import {useNavigate} from "react-router-dom";
import {formatCurrency} from "../../utils/currencyUtils";

interface SectionPaymentInfoProps {
    setting: {
        payBlckYn: string;
        autoPayBlckYn: string;
    } | null;
    limit: {
        maxLimit: string;
        monthUseAmnt: string;
    } | null;
}

const SectionPaymentInfo: React.FC<SectionPaymentInfoProps> = ({setting, limit}) => {
    const [isBarcodeOpen, setIsBarcodeOpen] = useState(false);
    const navigate = useNavigate()
    const goPaymentSetting = () => {
        navigate('/mobile-pay/setting')
    }
    const handleBarcodeClose = () => {
        setIsBarcodeOpen(false);
        brazeEventApi.home_barcode_floating_close();
    };

    // 차단 여부에 따른 title 처리
    const getTitle = () => {
        if (setting?.payBlckYn === 'Y') {
            return '휴대폰 결제 차단 중';
        }
        return formatCurrency(limit?.monthUseAmnt) + '원';
    };

    return (
        <>
            <div>
                <Box type="2_trbl">
                    <TextSet
                        subComponent="Heading_1"
                        text={{
                            eyebrow: '현재 사용 가능 금액',
                            title: getTitle(),
                        }}
                        weight="bolder"
                    />
                </Box>

                <div
                    style={{
                        padding: "16px 20px 0",
                    }}
                >
                    <CommonText2row label={"사용한 금액"} value={`${formatCurrency(limit?.monthUseAmnt)}원`}/>
                    <CommonText2row label={"월 결제 한도"} value={`${formatCurrency(limit?.monthUseAmnt)}원`}/>
                    <CommonText2row label={"최대 한도"} value={`${formatCurrency(limit?.maxLimit)}원`}/>
                </div>

                <div
                    style={{
                        paddingTop: "12px",
                    }}
                >
                    <CTA layout="column">

                        {setting?.payBlckYn === 'Y' ? (
                            <Button color="secondary" onClick={goPaymentSetting}>
                                차단 해제
                            </Button>
                        ) : <Button color="primary" onClick={() => setIsBarcodeOpen(true)}>
                            휴대폰 결제 바코드 보기
                        </Button>}
                    </CTA>
                </div>
            </div>

            <BS_Barcode isOpen={isBarcodeOpen} onClose={handleBarcodeClose} initialTab={"payment"}/>
        </>
    );
};

export default SectionPaymentInfo;
