// ****************************************************
// [배포] 4.마이 > 내 등급, 혜택 이력 > 내 등급(등급안내) - (UMembershipReceivedBenefit)
// ****************************************************
import React from 'react';
import {Accordion, List, Tag, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import {Grade} from '../../pages/My/types';
import {getGradeTag} from '../../utils/tagUtils';
import {formatCurrency} from '../../utils/currencyUtils';

interface NextYearGradeProps {
    grade: Grade;
}

const formatDateRange = (dateRange: string): string => {
    const startDate = `${dateRange.slice(0, 4)}.${dateRange.slice(4, 6)}.${dateRange.slice(6, 8)}`;
    const endDate = `${dateRange.slice(8, 12)}.${dateRange.slice(12, 14)}.${dateRange.slice(14, 16)}`;
    return `${startDate}~${endDate}`;
};

const NextYearGrade: React.FC<NextYearGradeProps> = ({grade}) => {
    const tags: any[] = [];

    if (grade.nxtYrLevelName) {
        tags.push(getGradeTag(grade.nxtYrLevelName));
    }

    // if (grade.longTermName) {
    //     tags.push(getLongTermTag(grade.longTermName));
    // }

    return (
        <>
            {/*---------------------일반 등급---------------- */}
            {!(['6', '7', '8'].includes(grade.cardlevel)) && (
                <List
                    fill='underline'
                    items={[
                        {
                            leftSlot: [
                                <>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <TextSet
                                            subComponent='Body_1'
                                            text={{
                                                title: `${grade.levelUpDate.slice(0, 4)} 등급`,
                                            }}
                                            weight='bolder'
                                        />
                                        <div style={{marginTop: '8px'}}>
                                            <Tag item={tags}/>
                                        </div>
                                    </div>
                                </>,
                            ],
                        },
                    ]}
                    type='view'
                />
            )}

            {/* -------------그 외 등급 (vip, vvip. 우수)--------------*/}
            {(['6', '7', '8'].includes(grade.cardlevel)) && (
                <Accordion
                    title={
                        <TextSet
                            subComponent='Body_1'
                            text={{
                                title: '내년 예상 혜택'
                            }}
                            weight='bolder'
                        />
                    }
                    description={
                        <div style={{marginTop: '8px'}}>
                            <Tag item={tags}/>
                        </div>
                    }
                >
                    <Accordion.Body>
                        <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                            <div style={{display: 'flex', flexDirection: 'row', padding: '4px 0'}}>
                                <Text style={{flex: '1'}} typo='font_body_1M' color='color_text_neutral_4'>
                                    산정 기간
                                </Text>
                                <Text typo='font_heading_5Sb' color='color_text_neutral_6'>
                                    {formatDateRange(grade.nxtYrLevelAplPrd)}
                                </Text>
                            </div>

                            <div style={{display: 'flex', flexDirection: 'row', padding: '4px 0'}}>
                                <Text style={{flex: '1'}} typo='font_body_1M' color='color_text_neutral_4'>
                                    기간 내 총 납부금액
                                </Text>
                                <Text typo='font_heading_5Sb' color='color_text_neutral_6'>
                                    {formatCurrency(grade.nxtYrTotalPayAmt)}원
                                </Text>
                            </div>
                        </div>
                    </Accordion.Body>
                </Accordion>
            )}
        </>
    );
};

export default NextYearGrade;
