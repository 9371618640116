// ****************************************************
// [배포] 4.마이 - 내 등급, 혜택 이력 - 내 등급(등급안내)
// ****************************************************
import React, {useEffect, useState} from 'react';
import {Button, CTA} from '@lguuxe/2024_designsystem_uni';

import CommonLeftIconHeader from '../../components/Common/CommonLeftIconHeader';
import CommonDividerThin from '../../components/Common/CommonDividerThin';
import CommonDivider from '../../components/Common/CommonDivider';
import UserGradeInfo from '../../components/My/UserGradeInfo';
import CurrentYearGrade from '../../components/My/CurrentYearGrade';
import NextYearGrade from '../../components/My/NextYearGrade';
import LevelUpTips from '../../components/My/LevelUpTips';
import membershipsServiceApi from '../../api/memberships-service';
import {Grade} from './types';
import useLoading from '../../hooks/useLoading';
import {getItemByKey} from "../../utils/indexedDB";
import {sendEvent} from "../../api/bridgeApi";

const initialGrade: Grade = {
    cardlevel: '', // 등급 코드
    cardLevelName: '', // "등급 명 (VVIP, VIP, 우수, 일반) * 타사 또는 U+멤버십 서비스 이용불가자(선불, 법인)은 일반"
    longTermCode: '', // 장기고객코드
    longTermName: '', // 장기고객 명칭
    youthYn: '',
    youthName: '',
    levelAplPrd: '',
    levelPayAmt: '',
    subsPrd: '',
    levelUpDate: '',
    nxtYrLevel: '',
    nxtYrLevelName: '',
    nxtYrLevelAplPrd: '',
    nxtYrTotalPayAmt: '',
};

const MyGrade: React.FC = () => {
    const [grade, setGrade] = useState<Grade>(initialGrade);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [memberStatus, setMemberStatus] = useState<string>('0');
    const [memberName, setMemberName] = useState<string>('');

    useEffect(() => {
        const fetchIndexedDB = async () => {
            setMemberStatus(await getItemByKey('memberStatus'));
            setMemberName(await getItemByKey('customerName'));
        }

        const fetchData = async () => {
            try {
                const memberId = await getItemByKey('memberId');
                membershipsServiceApi.getRankInfo({memberId: memberId}, 'v1').then((res) => {
                    if (res.header.resultCode === '0000') {
                        setGrade(res.body);
                    }
                });
            } catch (error) {
                console.error('Failed to fetch :', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchIndexedDB();
        fetchData();
    }, []);
    const handleClick = () => {
        sendEvent('CALL_WEBVIEW', {
            url: 'https://www.lguplus.com/mypage/price-plan/mobile',
            title: '모바일 요금제',
            showTitlebar: true
        });
    };
    return (
        <>
            {useLoading(isLoading)}
            <div style={{paddingBottom: '114px'}}>

                <CommonLeftIconHeader title='내 등급'/>

                <UserGradeInfo cardLevel={grade.cardlevel} gradeName={grade.cardLevelName} name={memberName}/>

                <div
                    style={{
                        padding: '0 20px 8px',
                    }}
                >
                    <CommonDividerThin/>
                </div>

                <CurrentYearGrade grade={grade}/>
                <NextYearGrade grade={grade}/>

                <div style={{marginBottom: '8px'}}>
                    <CommonDivider/>
                </div>

                <LevelUpTips/>
            </div>

            {grade.cardLevelName == '일반' ? null : (
                <div
                    style={{
                        position: 'fixed',
                        bottom: '0',
                        width: '100%',
                    }}
                >
                    <CTA layout='column'>
                        <Button color='secondary' onClick={handleClick}>
                            모바일요금제 변경하러 가기
                        </Button>
                    </CTA>
                </div>
            )}
        </>
    );
};

export default MyGrade;
