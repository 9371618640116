import React, {useEffect, useState} from 'react';
import {Box, Image, Text} from '@lguuxe/2024_designsystem_uni';
import Lottie from 'lottie-react';
import to19Coin from '../../images/json/Coin/Coin_Gold_01.json';
import to39Coin from '../../images/json/Coin/Coin_Gold_02.json';
import to59Coin from '../../images/json/Coin/Coin_Gold_03.json';
import to79Coin from '../../images/json/Coin/Coin_Gold_05.json';
import to99Coin from '../../images/json/Coin/Coin_Gold_06.json';
// import zeroCoin from '../../images/json/Coin/Coin_Gold_06.json';
import zeroCoin from '../../images/icon/4_my/benefit_none.svg';
import sliverCoin from '../../images/json/Coin/Coin_Silver.json';
import loveIcon from '../../images/icon/11_emoji/emoji_01.svg';
import happyIcon from '../../images/icon/11_emoji/emoji_02.svg';
import sosoIcon from '../../images/icon/11_emoji/emoji_03.svg';
import sadIcon from '../../images/icon/11_emoji/emoji_04.svg';
import {BenefitSummery} from '../../pages/My/types';
import {formatCurrency} from '../../utils/currencyUtils';
import {getItemByKey} from "../../utils/indexedDB";

interface DiscountSectionProps {
    summary: BenefitSummery;
    date: string;
}

const getIcon = (ranking: string): string => {
    const numberRanking = parseInt(ranking);
    if (numberRanking >= 0 && numberRanking <= 39) return loveIcon;
    if (numberRanking >= 40 && numberRanking <= 59) return happyIcon;
    if (numberRanking >= 60 && numberRanking <= 99) return sosoIcon;
    return sadIcon;
};

const getCoinAnimation = (ranking: string) => {
    const numberRanking = parseInt(ranking);
    if (numberRanking >= 0 && numberRanking <= 19) return to19Coin;
    if (numberRanking >= 20 && numberRanking <= 39) return to39Coin;
    if (numberRanking >= 40 && numberRanking <= 59) return to59Coin;
    if (numberRanking >= 60 && numberRanking <= 79) return to79Coin;
    if (numberRanking >= 80 && numberRanking <= 99) return to99Coin;
    return zeroCoin;
};

const DiscountSection: React.FC<DiscountSectionProps> = ({summary, date}) => {
    const [name, setName] = useState('');
    const [faceIcon, setFaceIcon] = useState('');
    const [coinAnimation, setCoinAnimation] = useState<any>('');
    const [isZero, setIsZero] = useState(false);

    useEffect(() => {
        const fetchIndexedDB = async () => {
            const name = await getItemByKey('customerName');
            setName(name);
        }
        fetchIndexedDB();
    }, []);


    useEffect(() => {
        const faceIcon = getIcon(summary.ranking);
        setFaceIcon(faceIcon);
        const coinAnimation = getCoinAnimation(summary.ranking);
        setCoinAnimation(coinAnimation);
        if (coinAnimation === zeroCoin) {
            setIsZero(true);
        }
    }, [summary]);

    return (
        <Box style={{backgroundColor: '#262A3B', padding: '0'}}>
            <Box style={{padding: '0 20px 0'}}>
                <Text typo='font_heading_3B' color='color_text_neutral' style={{color: '#fff'}}>
                    {Number(date.substring(4, 6))}월에 쓴 혜택은&nbsp;
                </Text>
                <br/>
                <Text typo='font_heading_3B' color='color_text_neutral' style={{color: '#fff'}}>
                    <span style={{color: '#E6007E'}}>상위 {summary.ranking}%</span>&nbsp;이네요
                    <div style={{marginLeft: '8px'}}>
                        <Image src={faceIcon} alt='placeholder' width='22' ratio='1:1'/>
                    </div>
                </Text>
            </Box>
            <Box style={{padding: '0 20px 20px', display: 'flex', justifyContent: 'center'}}>
                <div>
                    {/* <Image
            customClass="customClass"
            ratio="1:1"
            src={my1Icon}
            width= {160}
          /> */}
                    <Lottie loop={false} animationData={sliverCoin}/>
                    <div
                        style={{
                            padding: '12px 0 0 0',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Text typo='font_label_4Sb' color='#fff'>
                            {Number(date.substring(4, 6))}월 전체 평균
                        </Text>
                        <Text typo='font_body_1Sb' color='#fff'>
                            {formatCurrency(summary.userMonthAverageDiscount)}원
                        </Text>
                    </div>
                </div>
                <div>
                    {isZero ? (
                        <Image customClass='customClass' ratio='1:1' src={zeroCoin} width={160}/>
                    ) : (
                        <Lottie loop={false} animationData={coinAnimation}/>
                    )}

                    <div
                        style={{
                            padding: '12px 0 0 0',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Text typo='font_label_4Sb' color='#FFBC27'>
                            {name}
                        </Text>
                        <Text typo='font_body_1Sb' color='#FFBC27'>
                            {formatCurrency(summary.thisMonthDiscount)}원
                        </Text>
                    </div>
                </div>
            </Box>
        </Box>
    );
};

export default DiscountSection;
