import {Button, Icon, Image, Text} from '@lguuxe/2024_designsystem_uni';
import React, {useEffect, useState} from 'react';
import Snackbar from '../Common/Snackbar';
import {partnerApi} from "../../api/partnerAndAffiliate-service";
import {ProfileInfo} from "../../pages/Partner/types";
import CommonPopup from "../Common/CommonPopup";
import BS_Barcode from "../Barcode/BS_Barcode";

export interface PartnerProfileProps {
    profileInfo: ProfileInfo;
    // onLikeClick: (profileInfo: ProfileInfo) => void;
}

const PartnerProfile: React.FC<PartnerProfileProps> = ({profileInfo}) => {
    const [isLike, setIsLike] = useState(profileInfo.isLike);
    const [likeNum, setLikeNum] = useState(Number(profileInfo.likeNum));
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [showBarcode, setShowBarcode] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);

    useEffect(() => {
        setIsLike(profileInfo.isLike);
        setLikeNum(Number(profileInfo.likeNum));
        console.log('TODO: 바코드 탭 설정 안되는거 수정')
    }, [profileInfo.isLike, profileInfo.likeNum]);

    const fetchLike = async () => {
        try {
            const requestParam = {
                regYn: isLike === 'Y' ? 'N' : 'Y',
            }
            return await partnerApi.putPartnersLikeById(profileInfo.ptnId, requestParam, 'v1').then((res) => {
                console.log('fetchLike res:', res);
                if (res.header.resultCode === '0000') {
                    return 'success';
                } else {
                    return 'fail';
                }
            });

        } catch (error) {
            return 'fail';
            console.log('Failed to fetch :', error);
        }
    }

    const handleClickLike = async () => {
        const res = await fetchLike();
        if (res === 'success') {
            if (isLike === 'Y') {
                setLikeNum(likeNum - 1 < 0 ? 0 : likeNum - 1);
            } else {
                setLikeNum(likeNum + 1);
            }
            setIsLike(isLike === 'Y' ? 'N' : 'Y');

            setShowSnackbar(true);
            setTimeout(() => {
                setShowSnackbar(false);
            }, 1000);
        } else {
            setShowErrorPopup(true);
        }
    };

    const handleClickBarcode = () => {
        setShowBarcode(!showBarcode);
    };

    const renderButtonGroup = (showBarcodeButton: boolean) => (
        <div style={{
            display: 'flex',
            gap: '8px',
            justifyContent: 'center',
            width: '100%',
        }}>
            <Button fill='outline' color="secondary" style={{
                display: 'flex',
                justifyContent: 'center',
                flex: showBarcodeButton ? undefined : '1',
                width: showBarcodeButton ? '75px' : '100%',
            }} onClick={handleClickLike}>
                {isLike === 'Y' ? (
                    <Icon name='favorite_filled' color='#E6007E'></Icon>
                ) : (
                    <Icon name='favorite_line'></Icon>
                )}
                {likeNum}
            </Button>
            {showBarcodeButton && (
                <Button color='secondary' fill='solid' style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '237px',
                    flex: '1',
                }} onClick={handleClickBarcode}>
                    U+멤버십 바코드
                </Button>
            )}
        </div>
    );

    return (
        <div style={{
            display: 'flex',
            gap: '32px',
            alignItems: 'center',
            flexDirection: 'column',
            padding: '24px 20px',
            borderRadius: '12px',
            backgroundColor: '#fff',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '12px',
                alignSelf: 'stretch',
            }}>
                <Image
                    customClass='customClass'
                    ratio='1:1'
                    rounded='55'
                    src={profileInfo.ptnImgUrl}
                    width='72'
                />
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '4px',
                }}>
                    <Text as='span' textAlign='center' typo='font_detail_2M' color='color_text_neutral_3'>
                        {profileInfo.metaTag}
                    </Text>
                    <Text as='span' textAlign='center' typo='font_heading_4B' color='color_text_neutral_6'>
                        {profileInfo.ptnName}
                    </Text>
                </div>
            </div>

            {profileInfo.isMembershipAliance === 'N' ? renderButtonGroup(false) : renderButtonGroup(true)}

            {showSnackbar && (
                <Snackbar text={isLike === 'Y' ? '좋아하는 제휴처에 추가했어요' : '좋아하는 제휴처가 해제 되었습니다.'}/>
            )}
            <BS_Barcode isOpen={showBarcode} onClose={() => {
                setShowBarcode(false)
            }} bottomSpace={'0'}/>
            <CommonPopup submitText={'확인'} description={'잠시 후 다시 시도해주세요.'} isOpen={showErrorPopup} onSubmit={() => {
                setShowErrorPopup(false)
            }}/>
        </div>
    );
};

export default PartnerProfile;