import React, { useState, useEffect } from "react";
import Lottie from "lottie-react";
import { Text, Image } from "@lguuxe/2024_designsystem_uni";

import chevron_right_small_24_line from "../../images/main/benefit/chevron_right_line.svg";
import earlyMonthIcon from "../../images/lottie/json/Icon/Icon_New.json";
import midMonthIcon from "../../images/lottie/json/Icon/Icon_Bell.json";
import endMonthIcon from "../../images/lottie/json/Icon/Icon_Time.json";
import notificationIcon from "../../images/lottie/json/Icon/Icon_Notification.json";
import guestIcon from "../../images/lottie/json/Icon/Icon_Gift.json";
import MidMonth from "./UserMessageCard/MidMonth";
import EndMonth from "./UserMessageCard/EndMonth";
import EarlyMonth from "./UserMessageCard/EarlyMonth";
import NotLogin from "./UserMessageCard/NotLogin";
import Notification from "./UserMessageCard/Notification";

import {initialMsg, Msg} from "../../pages/Home/types";
import { useNavigate } from "react-router-dom";
import { CheckVip } from "./types";
import useLandingPage from "../../hooks/useLandingPage";
interface UserMessageProps {
    prsMsg: Msg[];
    type?: string;
    userStatus?: string;
    checkVip?: CheckVip;
}

const style = {
    width: 48
};

const UserMessage: React.FC<UserMessageProps> = ({ prsMsg = [initialMsg], userStatus, checkVip }) => {
    const [currentPeriod, setCurrentPeriod] = useState<"early" | "mid" | "end">("early");
    const [messageType, setMessageType] = useState<string>("VIP");
    const navigate = useNavigate();
    const {landingPage} = useLandingPage();
    useEffect(() => {
        const now = new Date();
        const day = now.getDate();

        if (day >= 1 && day <= 5) {
            setCurrentPeriod("early");
        } else if (day >= 6 && day <= 19) {
            setCurrentPeriod("mid");
        } else {
            setCurrentPeriod("end");
        }

        // Determine message type based on userStatus and checkVip
        if (userStatus === "0") {
            setMessageType("NotLogin");
        } else if ((userStatus === "1" || userStatus === "3") && checkVip?.useVipkok?.isUsed) {
            setMessageType("Notification");
        } else if (userStatus === "1" || userStatus === "3") {
            setMessageType("VIP");
        } else {
            setMessageType("Notification");
        }
    }, [userStatus, checkVip]);

    const getAnimationData = () => {
        switch (messageType) {
            case "VIP":
                switch (currentPeriod) {
                    case "early":
                        return earlyMonthIcon;
                    case "mid":
                        return midMonthIcon;
                    case "end":
                        return endMonthIcon;
                    default:
                        return earlyMonthIcon;
                }
            case "NotLogin":
                return guestIcon;
            case "Notification":
                return notificationIcon;
            default:
                return earlyMonthIcon;
        }
    };

    const onClick = () => {
        if (messageType === "NotLogin") {
            navigate("/identity-verification");
        } else if (messageType === "Notification"){
            if(prsMsg[0].linkUrl && prsMsg[0].linkType) {
                landingPage(prsMsg[0].linkType, prsMsg[0].linkUrl)
                navigate(prsMsg[0].linkUrl);
            } else {
                console.log("편성 에러 linkType과 linkUrl이 Null입니다.", prsMsg[0])
            }
        } else {
            navigate("/vip-kok")
        }
    };

    const getText = () => {
        switch (messageType) {
            case "VIP":
                switch (currentPeriod) {
                    case "early":
                        return <EarlyMonth />;
                    case "mid":
                        return <MidMonth />;
                    case "end":
                        return <EndMonth />;
                    default:
                        return <EarlyMonth />;
                }
            case "NotLogin":
                return <NotLogin />;
            case "Notification":
                return <Notification msgData={prsMsg[0]}/>;
            default:
                return <EarlyMonth />;
        }
    };

    return (
        <div style={{ padding: "0 20px" }} onClick={onClick}>
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "16px 24px 16px 16px",
                    border: "1px solid #E7EBEE",
                    borderRadius: "16px",
                    gap: "8px",
                    alignItems: "center",
                    background: "#fff"
                }}
            >
                <Lottie animationData={getAnimationData()} style={style} />
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: "1"
                    }}
                >
                    {getText()}
                </div>
                <Image src={chevron_right_small_24_line} alt="placeholder" width="12" ratio="1:2" />
            </div>
        </div>
    );
};

export default UserMessage;
