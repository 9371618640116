import React from 'react';
import { Text } from "@lguuxe/2024_designsystem_uni";

interface CommonText2rowProps {
    label: string;
    value: string;
}

const CommonText2row: React.FC<CommonText2rowProps> = ({ label, value }) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                padding: "4px 0"
            }}
        >
            <Text
                style={{ flex: "1" }}
                typo="font_body_1M"
                color="color_text_neutral_4"
            >
                {label}
            </Text>
            <Text typo="font_heading_5Sb" color="color_text_neutral_6">
                {value}
            </Text>
        </div>
    );
}

export default CommonText2row;
