import { Text } from "@lguuxe/2024_designsystem_uni";
import React from "react";

const MidMonth : React.FC = () => {
    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: "1"
                }}
            >
                <Text
                    typo="font_body_2Sb"
                    color="color_text_neutral_5"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "2px",
                        alignSelf: "stretch",
                        width: "100%"
                    }}
                >
                    VIP콕 혜택을 사용하지 않았어요!
                </Text>
                <Text
                    typo="font_body_2Sb"
                    color="color_text_primary"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "2px",
                        alignSelf: "stretch",
                        width: "100%"
                    }}
                >
                    이번 달 VIP콕 혜택 사용하러가기
                </Text>
            </div>
        </>
    )
}

export default MidMonth