import React from 'react';
import { Button } from "@lguuxe/2024_designsystem_uni";
import CommonDividerVertical from "../../components/Common/CommonDividerVertical";

interface LogoutQuitButtonsProps {
    onLogoutClick: () => void;
    onQuitClick: () => void;
}

const LogoutQuitButtons: React.FC<LogoutQuitButtonsProps> = ({ onLogoutClick, onQuitClick }) => {
    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: "40px 0 24px",
            }}
        >
            <Button
                as="button"
                color="neutral"
                fill="text"
                size="medium"
                state="default"
                onClick={onLogoutClick}
            >
                로그아웃
            </Button>
            <CommonDividerVertical />
            <Button
                as="button"
                color="neutral"
                fill="text"
                size="medium"
                state="default"
                onClick={onQuitClick}
            >
                <span style={{ textDecoration: "underline", textUnderlineOffset: "5px" }}>
                    회원탈퇴
                </span>
            </Button>
        </div>
    );
};

export default LogoutQuitButtons;
