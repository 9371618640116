import React from 'react';
import { TextSet, Tab } from "@lguuxe/2024_designsystem_uni";

interface CommonHeadingTabProps {
    selectedTab: string;
    setSelectedTab: (tabId: string) => void;
}

const CommonHeadingTab: React.FC<CommonHeadingTabProps> = ({ selectedTab, setSelectedTab }) => {
    const tabItems = [
        {
            id: '1',
            label: '기본 혜택'
        },
        {
            id: '2',
            label: 'VIP콕'
        },
        {
            id: '3',
            label: '장기고객 혜택'
        }
    ];

    return (
        <>
            <TextSet
                subComponent="Heading_4"
                text={{
                    title: "타이틀",
                }}
                blockSpacing={{ top: "24px", bottom: "8px" }}
                isIndent={true}
            />
            <Tab
                fill="underline"
                items={tabItems}
                layout="fitContent"
                selectedItemId={selectedTab}
                size="small"
                onItemSelected={(id: string) => setSelectedTab(id)}
            />
        </>
    );
}

export default CommonHeadingTab;
