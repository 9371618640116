import React, { useState, CSSProperties } from "react";
import { Text, Button, List, TextSet, Icon, Box, Image } from "@lguuxe/2024_designsystem_uni";
import membershipVvipIcon from "../../images/icon/1_grade/membership_24=vvip.svg";
import membershipExcellentIcon from "../../images/icon/1_grade/membership_24=excellent.svg";
import membershipGeneralIcon from "../../images/icon/1_grade/membership_24=general.svg";
import { VipStatus } from "../../pages/Vipkok/types";
import InfoBox2 from "./InfoBox2";
import {useNavigate} from "react-router-dom";

const commonBoxStyle: CSSProperties = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    alignSelf: "stretch",
    border: "2px solid transparent",
    borderRadius: "12px",
    backgroundImage: "linear-gradient(#fff, #fff), linear-gradient(90deg, #F6257F 0%, #AE0ABC 100%)",
    borderImageSlice: "1",
    backgroundOrigin: "border-box",
    backgroundClip: "content-box, border-box",
    padding: "0"
};

const bottomButtonStyle: CSSProperties = {
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
    backgroundImage: "linear-gradient(90deg, #F6257F 0%, #AE0ABC 100%)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "stretch",
    padding: "8px 0px"
};

const renderListItem = (eyebrowText: string, titleText: string, imgSrc: string) => (
    <List
        fill="none"
        items={[
            {
                leftSlot: (
                    <div style={{display: "flex", gap: "0.5rem"}}>
                        <TextSet
                            subComponent="Body_1"
                            text={{
                                eyebrow: (
                                    <Text typo="font_label_2Sb" color="color_text_neutral_2">
                                        {eyebrowText}
                                    </Text>
                                ),
                                title: (
                                    <Text typo="font_heading_3B" color="color_text_primary">
                                        {titleText}
                                    </Text>
                                )
                            }}
                        />
                    </div>
                ),
                rightSlot: (
                    <Image src={imgSrc} alt="placeholder" width="48" ratio="1:1" />
                )
            }
        ]}
        type="view"
    />
);

const renderBottomButton = (buttonText: string, showIcon = false, onClick: () => void) => (
    <Box type="1_b" style={bottomButtonStyle}>
        <Button color="neutral" fill="ghost" style={{ width: "100%" }} onClick={onClick}>
            <Text
                typo="font_label_2Sb"
                color="color_text_neutral"
                style={{
                    color: "#fff"
                }}
            >
                {buttonText}
            </Text>
            {showIcon && <Icon color="#fff" name="chevron_right_small_line" size="24px" />}
        </Button>
    </Box>
);

interface BoxHeadingUserGradeProps {
    status: VipStatus;
}

const BoxHeadingUserGrade: React.FC<BoxHeadingUserGradeProps> = ({ status }) => {
    const navigate = useNavigate()
    const goIdentityVerification = () => {
        navigate("/identity-verification")
    }
    const goBenefitGuide = () => {
        navigate("/benefit-guide/21");
    };
    const renderBoxContent = () => {
        switch (status) {
            case VipStatus.VIP_NOT_USED:
                return (
                    <Box type="1_b" style={commonBoxStyle}>
                        <Box type="1_b" style={{ width: "100%", display: "flex", flexDirection: "column", gap: "0", padding: "0" }}>
                            {renderListItem("이번 달 VIP콕", "사용 전", membershipVvipIcon)}
                        </Box>
                    </Box>
                );
            case VipStatus.VIP_USED:
                return (
                    <Box type="1_b" style={{ ...commonBoxStyle, gap: "12px" }}>
                        <Box type="1_b" style={{ width: "100%", display: "flex", flexDirection: "column", gap: "12px", padding: "8px 0" }}>
                            {renderListItem("이번 달 VIP콕 사용", "사용 완료", membershipVvipIcon)}
                        </Box>
                    </Box>
                );
            case VipStatus.EXCELLENT:
                return (
                    <Box type="1_b" style={commonBoxStyle}>
                        <Box type="1_b" style={{ width: "100%", display: "flex", flexDirection: "column", gap: "0", padding: "0" }}>
                            {renderListItem("VVIP/VIP 전용 혜택", "조건 안됨", membershipExcellentIcon)}
                            {renderBottomButton("등급 및 혜택 안내", true, goBenefitGuide)}
                        </Box>
                    </Box>
                );
            case VipStatus.GENERAL:
                return (
                    <Box type="1_b" style={commonBoxStyle}>
                        <Box type="1_b" style={{ width: "100%", display: "flex", flexDirection: "column", gap: "0", padding: "0" }}>
                            {renderListItem("VVIP/VIP 전용 혜택", "조건 안됨", membershipGeneralIcon)}
                            {renderBottomButton("등급 및 혜택 안내", true, goBenefitGuide)}
                        </Box>
                    </Box>
                );
            case VipStatus.NOT_LOGGED_IN:
                return (
                    <>
                        <Box type="1_b" style={commonBoxStyle}>
                            <Box type="1_b" style={{ width: "100%", display: "flex", flexDirection: "column", gap: "0", padding: "0" }}>
                                <Box type="4_trbl" style={{ display: "flex", flexDirection: "column", alignItems: "center", alignSelf: "stretch", paddingTop: "20px" }}>
                                    <Text style={{ textAlign: "center" }} typo="font_body_1M" color="color_text_neutral_3">
                                        본인 인증 후 U+ 멤버십만의<br/>다양한 혜택을 즐겨보세요.
                                    </Text>
                                </Box>
                                {renderBottomButton("본인인증하기", false ,goIdentityVerification)}
                            </Box>
                        </Box>
                        <InfoBox2 text={"U+ 멤버십 VVIP/VIP 회원만 사용 가능합니다."}/>
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Box
            type="3_trbl"
            style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "0",
                gap: "10px",
                marginBottom: "12px"
            }}
        >
            {renderBoxContent()}
        </Box>
    );
};

export default BoxHeadingUserGrade;
