import React, {useEffect, useState} from 'react';
import BadgeComponent from "./BadgeComponent";
import {Box} from '@lguuxe/2024_designsystem_uni';
import {addItem, getItemByKey} from '../../utils/indexedDB';
import {CLICK_LOCATION, EVENT_ACTION} from "../../utils/const";

// Badge 카테고리 데이터 타입 정의
interface BadgeCategory {
    count: string;
    categoryName: string;
}

interface SectionBadgeProps {
    badgeCategories: BadgeCategory[];
}

const SectionBadge: React.FC<SectionBadgeProps> = ({badgeCategories}) => {
    const [newBadges, setNewBadges] = useState<string[]>([]);
    const [selectedBadge, setSelectedBadge] = useState<BadgeCategory | null>(null);

    // 배지 카테고리의 기본 순서 유지
    const badgeOrder = ["나들이킹", "쇼핑킹", "스터디킹", "뷰티킹", "컨텐츠킹", "일상킹", "푸드킹"];

    useEffect(() => {
        const checkAndStoreBadgeData = async () => {
            const newBadgeNames: string[] = [];

            for (const badge of badgeCategories) {
                const storedBadge = await getItemByKey(badge.categoryName);
                if (storedBadge) {
                    if (parseInt(badge.count) > parseInt(storedBadge.count)) {
                        newBadgeNames.push(badge.categoryName);
                    }
                }
                await addItem(badge.categoryName, badge);
            }

            setNewBadges(newBadgeNames);
        };

        checkAndStoreBadgeData();
    }, [badgeCategories]);

    const handleBadgeClick = (badge: BadgeCategory) => {
        setSelectedBadge(badge);
    };

    const handleClose = () => {
        setSelectedBadge(null);
    };

    // 배지 카테고리를 순서에 맞게 정렬
    const sortedCategories = badgeOrder.map(order =>
        badgeCategories.find(category => category.categoryName === order) || {count: '0', categoryName: order}
    );

    return (
        <>
            <Box
                style={{
                    padding: "24px 20px",
                    display: "grid",
                    gap: "12px 0",
                    gridTemplateColumns: "1fr 1fr 1fr"
                }}
            >
                {sortedCategories.map((badge) => (
                    <BadgeComponent
                        key={badge.categoryName}
                        name={badge.categoryName}
                        count={parseInt(badge.count)}
                        isNew={newBadges.includes(badge.categoryName)}
                        data-gtm-event-name={'all_click'}
                        data-gtm-event-category={'클릭'}
                        data-gtm-event-action={`${CLICK_LOCATION.BADGE_LIST} - ${EVENT_ACTION.LINK_CLICK}`}
                        data-gtm-event-label={`컨텐츠 : ${badge.categoryName}`}
                        data-gtm-click-url={''}
                        data-gtm-click-location={`${CLICK_LOCATION.BADGE_LIST}`}
                        data-gtm-click-direction={'내부'}
                        data-gtm-click-text={`${badge.categoryName}`}
                    />
                ))}
            </Box>
        </>
    );
};

export default SectionBadge;
