import React from 'react';
import { TextSet, List, Image, Text } from "@lguuxe/2024_designsystem_uni";

interface StoreItemProps {
    itemList: {
        itemType: string;
        itemName: string;
        itemId: string;
        itemImgUrl: string;
        itemSummary: string;
    }[];
    threshold?: number;
}

const StoreItem: React.FC<StoreItemProps> = ({ itemList, threshold }) => {
    const itemsToRender = itemList.slice(0, threshold ?? itemList.length);

    return (
        <div style={{ padding: "0 20px" }}>
            <List
                fill="none"
                name="list_name"
                size="large"
                type="view"
                weight="default"
                isSlotClickable={true}
                items={[
                    {
                        leftSlot: [
                            <div
                                key="store_items"
                                style={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: "24px",
                                    justifyContent: "space-between",
                                }}
                            >
                                {itemsToRender.map((item, index) => (
                                    <div
                                        key={item.itemId}
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "6px",
                                            padding: "0 8px",
                                        }}
                                    >
                                        <Image
                                            src={item.itemImgUrl}
                                            alt="제휴이미지"
                                            width="56"
                                            ratio="1:1"
                                            rounded={54.86}
                                        />
                                        <div style={{ gap: "2px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                            <Text typo="font_body_1B" color="color_text_neutral_6">
                                                {item.itemName}
                                            </Text>
                                            <Text typo="font_body_3M" color="color_text_neutral_3">
                                                {item.itemSummary}
                                            </Text>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ]
                    }
                ]}
            />
        </div>
    );
}

export default StoreItem;