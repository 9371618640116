// ExceedPopup.tsx
import React from 'react';
import { Popup, Box, TextSet, CTA, Button } from '@lguuxe/2024_designsystem_uni';

interface AuthPopupProps {
    isOpen: boolean;
    onClose: () => void;
}

const AuthPopup: React.FC<AuthPopupProps> = ({ isOpen, onClose }) => {
    return (
        <Popup isOpen={isOpen}>
            <Popup.CenterSlot>
                <Box style={{ padding: '0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <TextSet
                        subComponent='Body_1'
                        text={{
                            title: (
                                <>
                                    <span style={{ paddingTop: '12px', textAlign: 'center' }}>
                                        인증번호 입력 횟수 3회를 초과했습니다.
                                        <br />
                                        개인정보 보호를 위해
                                        <br />
                                        모든 정보를 다시 입력해주세요.
                                    </span>
                                </>
                            ),
                        }}
                        weight='default'
                    />
                </Box>
            </Popup.CenterSlot>
            <Popup.BottomSlot>
                <CTA layout='column'>
                    <Button color='primary' onClick={onClose}>
                        확인
                    </Button>
                </CTA>
            </Popup.BottomSlot>
        </Popup>
    );
};

export default AuthPopup;
