import React from 'react';
import DaumPostcodeEmbed from "react-daum-postcode";

interface DaumPostcodeProps<T> {
    isOpen: boolean;
    onClose: () => void;
    setAddress: React.Dispatch<React.SetStateAction<T>>;
}

const AddressSection = <T, >({isOpen, onClose, setAddress}: DaumPostcodeProps<T>) => {
    const handleComplete = (data: any) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        console.log(fullAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
        setAddress(prevState => ({
            ...prevState,
            address1: fullAddress,
            zipCode: data.zonecode
        }));
        // onClose();
    };

    return (
        <div>
            <DaumPostcodeEmbed onComplete={handleComplete} onClose={onClose}/>
        </div>
    )
};

export default AddressSection;