import React from "react";
import { Box, Image, Text } from "@lguuxe/2024_designsystem_uni";

import customer10yIcon from "../../images/icon/1_grade/customer_80=10y.svg";
import customer5yIcon from "../../images/icon/1_grade/customer_80=5y.svg";
import customer2yIcon from "../../images/icon/1_grade/customer_80=2y.svg";

const BoxTableBenefit2: React.FC = () => {
    return (
        <Box type="3_trbl">
            <div className="tbl" style={{ marginBottom: "0" }}>
                <table>
                    <caption>장기고객 등급 안내</caption>
                    <colgroup>
                        <col width="85px" />
                        <col />
                    </colgroup>
                    <tbody>
                    <tr>
                        <th>등급</th>
                        <th>등급 기준 안내</th>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Image src={customer10yIcon} alt="10년 이상 고객 아이콘" width="32" ratio="1:1" />
                                <Text typo="font_body_1B" color="color_text_neutral_5" style={{ fontSize: "12px" }}>
                                    10년 이상
                                </Text>
                            </div>
                        </td>
                        <td className="left">U+ 모바일 이용 기간 10년 이상</td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Image src={customer5yIcon} alt="5년 이상 고객 아이콘" width="32" ratio="1:1" />
                                <Text typo="font_body_1B" color="color_text_neutral_5" style={{ fontSize: "12px" }}>
                                    5년 이상
                                </Text>
                            </div>
                        </td>
                        <td className="left">U+ 모바일 이용 기간 5년 이상</td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Image src={customer2yIcon} alt="2년 이상 고객 아이콘" width="32" ratio="1:1" />
                                <Text typo="font_body_1B" color="color_text_neutral_5" style={{ fontSize: "12px" }}>
                                    2년 이상
                                </Text>
                            </div>
                        </td>
                        <td className="left">U+ 모바일 이용 기간 2년 이상</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </Box>
    );
};

export default BoxTableBenefit2;
