import React, {useEffect, useState} from 'react';
import CommonDivider from '../../components/Common/CommonDivider';
import NavigationBar from '../../components/Common/NavigationBar';
import TitleHeader from '../../components/Common/TitleHeader';
import Calender from '../../components/Mission/Calender';
import MissionList from '../../components/Mission/MissionList';
import {AttendanceInfo, initialAttendance} from './types';
import {attendanceApi} from '../../api/attendanceAndEvent';
import RewordBlock from '../../components/Mission/RewordBlock';
import OtherApi from '../../api/other';
import {MissionReward} from "../My/types";
import {handlePromiseResult} from '../../api/api'
import {getItemByKey} from "../../utils/indexedDB";
import {brazeEventApi} from "../../api/brazeBridge";
import {useNavigate} from "react-router-dom";

const Mission: React.FC = () => {
    const [attendance, setAttendance] = useState<AttendanceInfo>(initialAttendance);
    const [missionReward, setMissionReward] = useState<MissionReward>({rewardPoint: '0'});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [memberState, setMemberState] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchIndexedDb = async () => {
            setMemberState(await getItemByKey('memberState'));
        }

        const fetchData = async () => {
            try {
                const [attendance, reward] = await Promise.allSettled([
                    await attendanceApi.get('v1'),
                    await OtherApi.getMissionReward('v1'),
                ]);
                const attendanceData = handlePromiseResult(attendance, initialAttendance);
                setAttendance(attendanceData);
                setMissionReward(handlePromiseResult(reward, {rewardPoint: '0'}));
            } catch (error) {
                console.log('Failed to fetch Mission :', error);
            } finally {
                setIsLoading(false);
            }
        };
        brazeEventApi.mission_tab_enter();
        fetchIndexedDb();
        fetchData();
    }, []);

    useEffect(() => {
        const handleBeforeUnload = () => {
            // 이탈 시 API 호출
            brazeEventApi.mission_tab_exit();
        };

        return () => {
            handleBeforeUnload();
        };
    }, [navigate]);
    return (
        <>
            {/*{useLoading(isLoading)}*/}
            <div style={{paddingBottom: '96px'}}>
                <TitleHeader title='미션' isSetting={false}/>

                <Calender attendance={attendance} weekOrMonth={'week'}/>
                <CommonDivider/>

                {/* 일반 사용자(타사/앱전용)에게는 비노출 */}
                <MissionList/>
                <CommonDivider/>

                {memberState !== '9999' && <RewordBlock missionReward={missionReward}/>}

                <NavigationBar selectedItem='mission'/>
            </div>
        </>
    );
};

export default Mission;
