import React from 'react'
import {Badge, List, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";
import CommonDivider from "../Common/CommonDivider";

interface MenuListProps {
    title: string;
    menuItems: { title: string, link: string, onAction?: () => void, isBold?: boolean, isNew?: boolean }[];
    noDivider?: boolean;
}

const MenuList: React.FC<MenuListProps> = ({title, menuItems, noDivider = false}) => {
    const navigate = useNavigate();

    const handleMenuClick = (link?: string, onAction?: () => void) => {
        if (onAction) {
            onAction();
            return;
        }
        if (link) {
            navigate(link);
        }
    }

    return (
        <>
            {menuItems.length !== 0 && <TextSet
                subComponent="Heading_4"
                text={{
                    title: <><span style={{color: "#181A1B"}}>{title}</span></>,
                }}
                blockSpacing={{top: "24px", bottom: "12px"}}
                isIndent={true}
            />}


            <div style={{paddingBottom: "12px"}}>
                <List
                    fill="none"
                    name="list_name"
                    size="medium"
                    type="view"
                    weight="default"
                    items={menuItems.map(item => ({
                        id: item.link,
                        leftSlot: [
                            <TextSet
                                subComponent="Body_1"
                                text={{
                                    title: <>
                                        <Text typo="font_body_1Sb"
                                              color={item.isBold ? 'color_status_onInfo_high' : undefined}>{item.title}</Text>
                                        {item.isNew && <Badge text='N' style={{marginLeft: '8px'}}></Badge>}
                                    </>,

                                }}
                                weight="bolder"
                            />],
                        linkIconOnlyId: item.link,
                        onClick: () => handleMenuClick(item.link, item.onAction),
                    }))}
                />
            </div>
            {menuItems.length !== 0 && !noDivider && <CommonDivider/>}
        </>
    )
}

export default MenuList
