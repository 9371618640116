export const formatDate = (date: string) => {
    const [datePart] = date.split(' ');
    const [year, month, day] = datePart.split('-');
    return `${year}.${month}.${day}`;
}

// yyyyMMddH HHmmsss 형식의 문자열을 Date 객체로 변환 (시간 부분은 무시)
export const toDate = (date: string) => {
    if (date === null || date === undefined) {
        console.log("date 값이 null 또는 undefined 입니다.");
        return new Date();
    }
    const [datePart, timePart] = date.split(' ');
    // 날짜 부분 처리 (YYYY-MM-DD 또는 YYYYMMDD)
    let year, month, day;
    if (datePart.includes('-')) {
        [year, month, day] = datePart.split('-');
    } else {
        year = datePart.slice(0, 4);
        month = datePart.slice(4, 6);
        day = datePart.slice(6, 8);
    }

    // 시간 부분 처리 (HH:mm:ss 또는 HHmmss)
    // let hours = 0, minutes = 0, seconds = 0;
    // if (timePart) {
    //     if (timePart.includes(':')) {
    //         [hours, minutes, seconds] = timePart.split(':').map(Number);
    //     } else {
    //         hours = Number(timePart.slice(0, 2));
    //         minutes = Number(timePart.slice(2, 4));
    //         seconds = Number(timePart.slice(4, 6));
    //     }
    // }

    // Date 객체 생성
    return new Date(Number(year), Number(month) - 1, Number(day));
}