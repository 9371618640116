import React, {useEffect, useState} from 'react';
import {Button, Image, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import bugapan4 from '../../images/icon/11_emoji/emoji_01.svg';
import attendanceIcon from '../../images/main/misson/attendance.svg';
import CommonDividerThin from '../Common/CommonDividerThin';
import {AttendanceInfo} from '../../pages/Mission/types';
import {useNavigate} from 'react-router-dom';
import {attendanceApi} from '../../api/attendanceAndEvent';
import CommonPopupWithImage from '../Common/CommonImagePopup';
import attendanceComplete from '../../images/img/popup/attendance_completed.svg';
import entranceComplete from '../../images/img/popup/entrance_completed.svg';
import {getItemByKey} from "../../utils/indexedDB";
import {brazeEventApi} from "../../api/brazeBridge";

interface CalenderThisWeekProps {
    attendance: AttendanceInfo;
    weekOrMonth: 'week' | 'month';
}

interface Popup {
    specialDayEventPopup: boolean;
    attendancePopup: boolean;
    continueAttendancePopup: boolean;
    giftName: string;
    attendCntLimit: number;
}

const initialPopup: Popup = {
    specialDayEventPopup: false,
    attendancePopup: false,
    continueAttendancePopup: false,
    giftName: '',
    attendCntLimit: 0,
}


const getWeekStartAndEnd = (today: Date) => {
    // 오늘 날짜의 요일을 가져옵니다. (0: 일요일, 1: 월요일, ..., 6: 토요일)
    const dayOfWeek = today.getDay();

    // 주의 시작일 (일요일)
    const startOfWeek = new Date(today);
    startOfWeek.setDate(today.getDate() - dayOfWeek);

    // 주의 종료일 (토요일)
    const endOfWeek = new Date(today);
    endOfWeek.setDate(today.getDate() + (6 - dayOfWeek));

    return {
        startOfWeek,
        endOfWeek,
    };
}

const Calender: React.FC<CalenderThisWeekProps> = ({attendance, weekOrMonth}) => {
    const [today, setToday] = useState<Date>(new Date());
    const [todayAttendance, setTodayAttendance] = useState<boolean>(false);
    // const [attendancePopup, setAttendancePopup] = useState<boolean>(false);
    // const [specialDayEventPopup, setSpecialDayEventPopup] = useState<boolean>(false);
    const [alertSnackbar, setAlertSnackbar] = useState<boolean>(false);
    const [attendDate, setAttendDate] = useState<number[]>([]);
    const [specialDate, setSpecialDate] = useState<number[]>([]);
    const [name, setName] = useState<string>('');
    const navigate = useNavigate();
    const [popup, setPopup] = useState<Popup>(initialPopup);

    useEffect(() => {
        let today = new Date();
        if (attendance && attendance.yearMonth) {
            const year = parseInt(attendance.yearMonth.substring(0, 4), 10);
            const month = parseInt(attendance.yearMonth.substring(4, 6), 10) - 1; // 월은 0부터 시작하므로 1을 빼야 함
            const day = parseInt(attendance.yearMonth.substring(6, 8), 10);
            today = new Date(year, month, day);
            setToday(today);
        }
        const dates = attendance.attendCheckDateList.map(item => parseInt(item.attendCheckDate, 10));
        setAttendDate(dates);
        console.log('dates : ', dates);
        console.log('today.getDate() : ', today.getDate());
        if (dates.includes(today.getDate())) {
            setTodayAttendance(true);
        } else {
            setTodayAttendance(false);
        }

        const specialDates = attendance.specialDateList.map(item => parseInt(item.specialDate, 10));
        setSpecialDate(specialDates);

        const fetchIndexedDb = async () => {
            setName(await getItemByKey('customerName'));
        }
        fetchIndexedDb();
    }, [attendance]);

    const handleAttendance = () => {
        const fetch = async () => {
            const memberId = await getItemByKey('memberId');
            try {
                const request = {
                    memberId: memberId,
                    attendId: attendance.attendId,
                    attendName: attendance.attendName,
                }
                attendanceApi.postCheck(request, 'v1').then((response) => {
                    console.log('attendanceApi.postCheck response : ', response);
                    switch (response.header.resultCode) {
                        case '0000':

                            let isPopup = false;
                            attendance.giftList.map(item => {
                                if (item.giftType === 'A' && item.attendCntLimit === attendance.attendCheckDateList.length + 1) {
                                    setPopup({
                                        ...popup,
                                        continueAttendancePopup: true,
                                        giftName: item.giftName,
                                        attendCntLimit: item.attendCntLimit,
                                    });
                                    isPopup = true;
                                }
                                if (item.giftType === 'S' && specialDate.includes(today.getDate())) {
                                    setPopup({
                                        ...popup,
                                        specialDayEventPopup: true,
                                        giftName: item.giftName,
                                    });
                                    isPopup = true;
                                }
                            });

                            if (!isPopup) {
                                setPopup({
                                    ...popup,
                                    attendancePopup: true,
                                });
                            }
                            setTodayAttendance(true);
                            setAttendDate([...attendDate, today.getDate()]);
                            brazeEventApi.attendance_check_complete(true, attendance.attendCheckDateList.length + 1);
                            break;
                        default:
                            break;
                    }
                });

            } catch (error) {
                brazeEventApi.attendance_check_complete(false, attendance.attendCheckDateList.length);
                console.log('attendanceApi.postCheck error : ', error);
            }
        };
        fetch();

    };

    const generateWeekCalendar = () => {
        const days = [];
        const {startOfWeek, endOfWeek} = getWeekStartAndEnd(today);

        for (let i = 0; i < 7; i++) {
            const current = new Date(startOfWeek);
            current.setDate(startOfWeek.getDate() + i);
            const dNum = current.getDate();

            const isToday = current.toDateString() === today.toDateString();
            const isAttendance = attendDate.includes(dNum);
            const isPast = current < today;
            const isSpecial = specialDate.includes(dNum);

            let dayClass = isToday
                ? isAttendance
                    ? 'attendance'
                    : 'd_day'
                : isAttendance
                    ? 'attendance'
                    : isPast
                        ? 'back'
                        : '';

            if (isSpecial && !isAttendance && !isPast) {
                dayClass = 'special';
            }

            days.push(
                <span key={i} className={dayClass}
                      style={{visibility: current.getMonth() === today.getMonth() ? 'visible' : 'hidden'}}>
                    {isToday && !isAttendance ? (
                        <span className='d_day'>
                            <span>{dNum}</span>
                        </span>
                    ) : (
                        dNum
                    )}
                </span>
            );
        }
        return days;
    };
    const generateMonthCalendar = () => {
        const weeks = [];
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 0);

        const calendar = [];
        let dNum = 1;
        const firstDay = firstDayOfMonth.getDay();
        const lastDate = lastDayOfMonth.getDate();
        const rows = Math.ceil((firstDay + lastDate) / 7);

        for (let i = 0; i < rows; i++) {
            const week = [];
            for (let k = 0; k < 7; k++) {
                if ((i === 0 && k < firstDay) || dNum > lastDate) {
                    week.push(<span key={k}></span>);
                } else {
                    const isToday = dNum === today.getDate();
                    const isPast = dNum < today.getDate();
                    const isAttendance = attendDate.includes(dNum);
                    const isSpecial = specialDate.includes(dNum);
                    let dayClass = isToday
                        ? isAttendance
                            ? 'attendance'
                            : 'd_day'
                        : isAttendance
                            ? 'attendance'
                            : isPast
                                ? 'back'
                                : '';

                    if (isSpecial && !isAttendance && !isPast) {
                        dayClass = 'special';
                    }

                    week.push(
                        <span key={k} className={dayClass}>
                        {isToday && !isAttendance ? (
                            <span className='d_day'>
                                <span>{dNum}</span>
                            </span>
                        ) : (
                            dNum
                        )}
                    </span>
                    );
                    dNum++;
                }
            }
            calendar.push(
                <div key={i} className='data_num'>
                    {week}
                </div>
            );
        }
        return calendar;
    };

    const getContinueAttendNum = (attend: number[]): number => {
        // const attendNumbers = attend.map(Number);
        let todayNumber = Number(today.getDate());

        attend.sort((a, b) => a - b);

        let count = 1;
        for (let i = attend.length - 1; i >= 0; i--) {
            if (attend[i] === todayNumber) {
                todayNumber--;
            } else if (attend[i] === todayNumber) {
                count++;
                todayNumber--;
            } else {
                break;
            }
        }

        return count;
    }

    const handleMoreClick = () => {
        navigate('/mission/attendance');
    };

    const handleReceivedAlert = () => {
        navigate('/setting');
    };
    return (
        <>
            <div style={{padding: '24px 20px 12px'}}>
                <TextSet
                    subComponent='Heading_2'
                    text={{
                        title: (
                            <div style={{display: 'flex', alignItems: 'flex-end', gap: '8px'}}>
                                {weekOrMonth === 'week' ? (
                                    <span>
                                        잊지말고
                                        <br/>
                                        오늘도 출석출석하세요
                                        {/*<img*/}
                                        {/*    src={bugapan4}*/}
                                        {/*    alt="버튼 이미지"*/}
                                        {/*    style={{marginLeft: '5px', verticalAlign: 'middle', width: '22px'}}*/}
                                        {/*/>*/}
                                    </span>) : (<span>
                                {name}님
                                <br/>
                                    {/*<span style={{color: '#E6007E'}}>{attendDate.length}번</span>{' '}*/}
                                    <span
                                        style={{
                                            marginBottom: '5px',
                                            display: 'inline-flex',
                                            gap: '5px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        이번 달은 {attendDate.length}번 출석했어요
                                        <Image customClass='customClass' ratio='1:1' src={bugapan4} width='22'/>
                                    </span>
                            </span>)}
                            </div>
                        ),
                    }}
                    weight='default'
                />
            </div>

            <div className='Marketing_calendar'>
                <div className='data'>
                    {['일', '월', '화', '수', '목', '금', '토'].map((day) => (
                        <Text key={day} typo='font_body_3Sb' color='color_text_neutral_5'>
                            {day}
                        </Text>
                    ))}
                </div>

                <div style={{padding: '0 20px'}}>
                    <CommonDividerThin/>
                </div>

                {weekOrMonth === 'week' ? (
                    <>
                        <div className='data_num'>{generateWeekCalendar()}</div>

                        <div style={{padding: '0 20px'}}>
                            <CommonDividerThin/>
                        </div>
                    </>
                ) : (
                    <>
                        {generateMonthCalendar().map((week, index) => (
                            <React.Fragment key={index}>
                                {week}
                                <div style={{padding: '0 20px'}}>
                                    <CommonDividerThin/>
                                </div>
                            </React.Fragment>
                        ))}
                    </>
                )}

                <div className='data_list'>
                    <div className='attendance_day'>
                        <Image ratio='1:1' src={attendanceIcon} width='15'/>
                        <Text typo='font_detail_3M'>출석한 날</Text>
                    </div>
                    <div className='day'>
                        <span className='dot'></span>
                        <Text typo='font_detail_3M'>오늘</Text>
                    </div>
                    <div className='special_day'>
                        <span className='dot orange'></span>
                        <Text typo='font_detail_3M'>특별 지급일</Text>
                    </div>
                </div>
            </div>

            <div style={{padding: '24px 20px'}}>
                <Button
                    as='button'
                    color='secondary'
                    fill='solid'
                    size='medium'
                    state='default'
                    style={{width: '100%'}}
                    onClick={handleAttendance}
                    disabled={todayAttendance}
                >
                    {todayAttendance ? '출석 체크 완료' : '출석 체크하기'}
                </Button>
                {weekOrMonth === 'week' && (<Button
                    as='button'
                    color='secondary'
                    fill='text'
                    size='medium'
                    state='default'
                    rightIcon='chevron_right_small_line'
                    style={{width: '100%', padding: '13px 0'}}
                    onClick={handleMoreClick}
                >
                    <Text typo='font_label_2Sb' color='color_text_neutral_3'>
                        더보기
                    </Text>
                </Button>)}
            </div>

            <CommonPopupWithImage
                title='출석 완료!'
                description={getContinueAttendNum(attendDate) + '일째 연속 출석했어요.'}
                imageUrl={attendanceComplete}
                onSubmit={() => setPopup({...popup, attendancePopup: false})}
                submitText='확인'
                isOpen={popup.attendancePopup}
            />

            <CommonPopupWithImage
                title={`${popup.giftName} 응모 완료!`}
                description={`${today.getDate()}일에 출석하여 이벤트에 자동 응모됐\n어요.\\n\\n*당첨 안내를 받으려면 알림을 켜주세요.`}
                imageUrl={entranceComplete}
                onSubmit={() => setPopup({...popup, specialDayEventPopup: false})}
                submitText='확인'
                cancelText='알림 받기'
                onClose={handleReceivedAlert}
                isOpen={popup.specialDayEventPopup}
            />

            <CommonPopupWithImage
                title={`${popup.giftName} 응모 완료!`}
                description={`${popup.attendCntLimit}회 출석하여 이벤트에 자동 응모됐\n어요.\\n\\n*당첨 안내를 받으려면 알림을 켜주세요.`}
                imageUrl={attendanceComplete}
                onSubmit={() => setPopup({...popup, continueAttendancePopup: false})}
                submitText='확인'
                cancelText='알림 받기'
                onClose={handleReceivedAlert}
                isOpen={popup.continueAttendancePopup}
            />
        </>
    );
};

export default Calender;