import { Text } from "@lguuxe/2024_designsystem_uni";
import React from "react";

const EndMonth : React.FC = () => {
    return (
        <div
            style={{
                display: "inline-flex",
                flexDirection: "column",
                flex: "1"
            }}
        >
            <Text
                typo="font_body_2Sb"
                color="color_text_neutral_5"
                style={{
                    display: "inline"
                }}
            >
                <span style={{ display: "inline-block", color: "#E6007E" }}>VIP콕 혜택</span>을 사용 할수 있는 일이 몇일 남지
                않았어요!
            </Text>
        </div>
    )
}

export default EndMonth