import {Accordion, Text} from '@lguuxe/2024_designsystem_uni';
import React from 'react';
import {UsageNotice} from "../../pages/Partner/types";

interface UsageAccordionProps {
    usageNoticeList: UsageNotice[];
}

const UsageAccordion: React.FC<UsageAccordionProps> = ({usageNoticeList}) => {

    // content를 줄바꿈 기준으로 분리하고, Text 컴포넌트로 감싸서 JSX로 변환
    const formatContent = (content: string) => {
        return content.split('\r\n').map((line, index) => (
            <Text key={index} typo='font_body_2M'>
                {line}
                <br/> {/* 줄바꿈 처리 */}
            </Text>
        ));
    };

    return (
        <div>
            <Accordion
                fill='underline'
                size='medium'
                weight='bolder'
                title={<Text>이용방법 및 유의사항</Text>}
            >
                <Accordion.Body>
                    <div>
                        {usageNoticeList.map((item, index) => {
                            if (item.code === '152001') {
                                return (
                                    <div key={index}>
                                        {/*<Text typo='font_body_2M'>이용방법 및 유의사항</Text>*/}
                                        {/* 파싱된 content를 Text 컴포넌트로 출력 */}
                                        {formatContent(item.content)}
                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>
                </Accordion.Body>
            </Accordion>
        </div>
    );
};

export default UsageAccordion;