import React, { useRef, useEffect } from 'react';
import { Text } from "@lguuxe/2024_designsystem_uni";
import '../../css/style.css';
import { MonthEvent } from "../../pages/UPTP/types";
import { CLICK_LOCATION, EVENT_ACTION } from "../../utils/const";

interface SectionCalendarProps {
    monthEvent: MonthEvent;
    currentDay: string;
    selectedDay: string; // selectedDay를 number 타입으로 변경
    setSelectedDay: (day: string) => void; // selectedDay 업데이트 함수의 타입 정의
}

const SectionCalendar: React.FC<SectionCalendarProps> = ({ monthEvent, currentDay, selectedDay, setSelectedDay }) => {
    const year = parseInt(currentDay.slice(0, 4));
    const month = parseInt(currentDay.slice(4, 6));

    const getDaysInMonth = (year: number, month: number) => {
        return new Array(new Date(year, month, 0).getDate()).fill(null).map((_, i) => i + 1);
    };

    const daysInMonth = getDaysInMonth(year, month); // 해당 월의 모든 날짜를 가져옵니다.

    // 스크롤 이동을 위한 ref 생성
    const selectedDayRef = useRef<HTMLDivElement | null>(null);

    const handleItemClick = (day: string) => {
        console.log("selectedDay", day);
        setSelectedDay(day); // 클릭된 아이템의 날짜를 상태에 저장
    };

    // selectedDay에 맞춰 해당 아이템으로 스크롤 이동
    useEffect(() => {
        if (selectedDayRef.current) {
            const offset = 10;
            const calendarElement = selectedDayRef.current.parentElement;

            if (calendarElement) {
                const selectedDayLeft = selectedDayRef.current.offsetLeft;
                calendarElement.scrollLeft = selectedDayLeft - offset;
            }
        }
    }, [selectedDay]);

    const eventDates = monthEvent.eventDateList?.map(event => event.evtDate.slice(0, 8));

    return (
        <div className="calendar" style={{ display: 'flex', overflowX: 'auto' }}>
            {daysInMonth.map((day) => {
                const formattedDay = `${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`;
                const dDay = day - parseInt(currentDay.slice(6, 8));

                let className = 'data_box'; // 기본 스타일
                if (formattedDay === selectedDay) {
                    className = 'data_box event'; // 클릭된 날짜
                } else if (formattedDay < currentDay) {
                    className = 'data_box end'; // 지나간 날짜
                }

                return (
                    <div
                        key={day}
                        className={className}
                        onClick={() => handleItemClick(formattedDay)}
                        ref={formattedDay === selectedDay ? selectedDayRef : null} // 선택된 날짜에 ref 연결
                        data-gtm-event-name={'all_click'}
                        data-gtm-event-category={'클릭'}
                        data-gtm-event-action={`${CLICK_LOCATION.UPTP_DATE} - ${EVENT_ACTION.LINK_CLICK}`}
                        data-gtm-event-label={`컨텐츠 : ${formattedDay}`}
                        data-gtm-click-url={''}
                        data-gtm-click-location={`${CLICK_LOCATION.UPTP_DATE}`}
                        data-gtm-click-direction={'내부'}
                        data-gtm-click-text={`${formattedDay}`}
                    >
                        <Text typo="font_heading_4B" className="data">
                            {day.toString()} {/* 해당 월의 날짜 표시 */}
                        </Text>
                        <Text typo="font_body_3M">
                            {dDay === 0 ? "Today" : dDay < 0 ? `D+${-dDay}` : `D-${dDay}`}
                        </Text>
                    </div>
                );
            })}
        </div>
    );
}

export default SectionCalendar;
