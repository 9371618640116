import React, { useState, useEffect } from "react";
import { Chip } from "@lguuxe/2024_designsystem_uni";
import { Tag } from "../../pages/Home/types";


interface SectionChipProps {
    Tags: Tag[];
    handleChipClick?: (tagId: string) => void;
}

const SectionChip: React.FC<SectionChipProps> = ({ Tags, handleChipClick}) => {
    const [selectedChip, setSelectedChip] = useState<string | null>(null);
    console.log(Tags)
    // Set the initial selected chip to the first item in the Tags array
    useEffect(() => {
        if (Tags.length > 0) {
            setSelectedChip(Tags[0].tagId);
        }
    }, [Tags]);

    const handleClick = (id:string) => {
        if(handleChipClick) {
            handleChipClick(id)
        }
        setSelectedChip(id)
    }
    const selectedStyle = {
        minWidth: "auto",
        backgroundColor: "#414762",
        color: "#fff",
    };

    return (
        <div
            style={{
                padding: "0",
                display: "flex",
                gap: "6px",
                overflow: "auto"
            }}
        >
            {Tags.map((tag) => (
                <Chip.Button
                    key={tag.tagId}
                    color="neutral"
                    fill="outline"
                    label={tag.tagName}
                    onClick={() => handleClick(tag.tagId)}
                    size="medium"
                    style={selectedChip === tag.tagId ? selectedStyle : { minWidth: "auto" }}
                />
            ))}
        </div>
    );
};

export default SectionChip;
