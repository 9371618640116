import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import CommonDivider from '../../components/Common/CommonDivider';
import AllIconHeader from '../../components/Common/AllIconHeader';
import PartnerCarousel from '../../components/Partner/PartnerCarousel';
import {partnerApi} from '../../api/partnerAndAffiliate-service';
import {CategoryItem, LikedPartners, SortItem, TotalPartners} from './types';

import {handlePromiseResult} from "../../api/api";
import '../css/style.css';
import CategoryTabs from "../../components/Partner/CategoryTabs";
import PartnerList from "../../components/Partner/PartnerList";
import SortFilter from "../../components/Partner/SortFilter";
import membershipsServiceApi from "../../api/memberships-service";
import CommonPopup from "../../components/Common/CommonPopup";

const initialLikedPartners: LikedPartners = {
    ptnList: [],
};

interface CardInfo {
    longTermCode: string; // NG1:2년이상, NG2:5년이상, NG3:10년이상
    longTermName: string;
    youthYn: string;
    youthName: string;
}

const initCardInfo: CardInfo = {
    longTermCode: '',
    longTermName: '',
    youthYn: '',
    youthName: '',
};

const initCategoryList = {
    categoryList: [],
};

const initPartners: TotalPartners = {
    totalCount: '0',
    partnerList: [],
};

interface SortState {
    sortType: string;
    isUsable: boolean;

}

const sortList: SortItem[] = [
    {
        id: '1',
        label: '인기순',
    },
    {
        id: '2',
        label: '최신순',
    },
    {
        id: '3',
        label: '가나다순',
    },
];

export interface PartnersHandles {
    triggerLocationBS: () => void;
}

const Partners: React.FC = forwardRef<PartnersHandles>((props, ref) => {
    const navigate = useNavigate();
    const [likedPartners, setLikedPartners] = useState<LikedPartners>(initialLikedPartners);
    const [categoryList, setCategoryList] = useState<CategoryItem[]>([]);
    const [partners, setPartners] = useState<TotalPartners>(initPartners);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [selectedCategoryId, setSelectedCategoryId] = useState<string>('0');
    const [cardInfo, setCardInfo] = useState<CardInfo>(initCardInfo);
    const [sortState, setSortState] = useState<SortState>({sortType: '', isUsable: false});
    const isFirstRender = useRef(true);
    const [showGPSPopup, setShowGPSPopup] = useState(false);

    useImperativeHandle(ref, () => ({
        async triggerLocationBS() {
            setShowGPSPopup(true);
        }
    }));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [likedPartners, categories, simpleCardInfo] = await Promise.allSettled([
                    partnerApi.getPartnersLike({orderType: ''}, 'v1'),
                    partnerApi.getCategories('v1'),
                    membershipsServiceApi.getSimpleCardInfo('v1'),
                ]);
                setLikedPartners(handlePromiseResult(likedPartners, initialLikedPartners));
                let categoryList = handlePromiseResult(categories, initCategoryList).categoryList;
                // 전체 카테고리 추가
                categoryList = [{categoryId: '', categoryName: '전체', categoryType: ''}, ...categoryList];
                setCategoryList(categoryList);
                setCardInfo(handlePromiseResult(simpleCardInfo, initCardInfo));
            } catch (error) {
                console.log('Failed to fetch :', error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchPartners();
        fetchData();
    }, []);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        fetchPartners();

    }, [selectedCategoryId, sortState]);

    const fetchPartners = async () => {
        try {
            const requestParam = {
                cateDepth1: selectedCategoryId,
                cateDepth2: '',
                cateDepth3: '',
                // 150001:VVIP, 150002:VIP, 150003:우수, 150004:일반, 150005:장기고객, 150006:유쓰, 150007:VIP 콕
                longTermCode: sortState.isUsable ? cardInfo.longTermCode : '',
                youthYn: sortState.isUsable ? cardInfo.youthYn : '',
                sort: sortState.sortType, // 빈값: 최신, L: 인기, A: 가나다
                pageNo: '',
            }
            partnerApi.getPartners(requestParam, 'v1').then((res) => {
                console.log('fetchPartners res:', res);
                if (res.header.resultCode === '0000' && res.body) {
                    setPartners(res.body);
                }
            });

        } catch (error) {
            console.error('Failed to fetch :', error);
        }
    }

    const handleCategoryChange = (categoryId: string) => {
        setSelectedCategoryId(categoryId);
    };

    const handleSortChange = (sortId: string, isUsable: boolean) => {
        let sortType = '';
        switch (sortId) {
            case '1':
                sortType = '';
                break;
            case '2':
                sortType = 'L';
                break;
            case '3':
                sortType = 'A';
                break;
        }

        setSortState({sortType, isUsable});
    }

    const handleLike = async (ptnId: string, nowIsLike: boolean) => {
        if (nowIsLike) {
            const newPartnerList = partners.partnerList.map((item) => {
                if (item.ptnId === ptnId) {
                    return {...item, isLike: 'N'};
                }
                return item;
            });
            setPartners({...partners, partnerList: newPartnerList});

            // 좋아요 제휴처 목록에서 해당 제휴처 삭제, 제휴처 목록에서 좋아요 상태 변경
            const newLikedPartnerList = likedPartners.ptnList = likedPartners.ptnList.filter((item) => item.ptnId !== ptnId);
            setLikedPartners({ptnList: newLikedPartnerList});
        } else { // 좋아요 제휴처 목록에 해당 제휴처 추가, 제휴처 목록에서 좋아요 상태 변경
            const newPartnerList = partners.partnerList.map((item) => {
                if (item.ptnId === ptnId) {
                    return {...item, isLike: 'Y'};
                }
                return item;
            });
            setPartners({...partners, partnerList: newPartnerList});

            partners.partnerList.filter((item) => item.ptnId === ptnId).map((item) => {
                setLikedPartners({ptnList: [...likedPartners.ptnList, item]});
            });
        }
    }

    return (
        <>
            {/*{useLoading(isLoading)}*/}
            <div
                style={{
                    // width: '100%',
                    height: '100vh',
                }}
            >
                <AllIconHeader title='전체 제휴사' isNotiIcon={false}/>

                <PartnerCarousel partnerList={likedPartners.ptnList}/>
                <CommonDivider/>

                <div style={{
                    width: '100%',
                    backgroundColor: '#FFFFFF',
                }}>
                    <CategoryTabs title='혜택 카테고리' itemList={categoryList} onItemChange={handleCategoryChange}/>
                    <SortFilter sortList={sortList} onSortChange={handleSortChange} totalCount={partners.totalCount}/>

                    {/*TODO: 20개씩 보여주고, 스크롤시 20개 추가로 보여주기*/}
                    <PartnerList partners={partners} onClickLike={handleLike}/>
                </div>
            </div>
            <CommonPopup submitText={'설정하기'} onSubmit={() => navigate('/setting')} isOpen={showGPSPopup}
                         onClose={() => {
                             setShowGPSPopup(false);
                             navigate('/map');
                         }}
                         description={'내 주변 제휴사를 확인하려면\n "위치 서비스" 권한을 허용해 주셔야 해요.'} cancelText={'취소'}
            />
        </>
    );
});

export default Partners;
