import React from "react";
import { Box, Text, Button, Image } from "@lguuxe/2024_designsystem_uni";
import CommonDividerThin from "../../Common/CommonDividerThin";
import BenefitLongTermSection from "../BenefitLongTermSection";
import datacoupon from "../../../images/guide/intro/datacoupon.png"
import colorring from "../../../images/guide/intro/colorring.png"
import dualnumber from "../../../images/guide/intro/dualnumber.png"
import security from "../../../images/guide/intro/security.png"

const LongTermCustomerTab: React.FC = () => {

    const goUPlus = () => {
        window.location.href = "https://app.lguplus.com/apcm/html-push?url=benefit-uplus/loyal-member-perks/phishing";
    }
    return (
        <Box style={{ display: "flex", flexDirection: "column", gap: "12px", padding: "24px 0 12px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "6px", padding: "0" }}>
                <Text typo="font_heading_4B" color="color_text_neutral_6" style={{ fontSize: "18px", fontWeight: "800", padding: "0 20px" }}>
                    장기 고객
                </Text>
                <Text typo="font_detail_2M" color="color_text_neutral_3" style={{ padding: "0 20px" }}>
                    전년도 11월 30일 기준, U+모바일 이용 기간이 2년 이상이면 장기 고객 혜택을 받을 수 있어요.
                </Text>
            </div>

            <BenefitLongTermSection
                title="혜택1. 데이터 2GB쿠폰"
                target="2년 이상"
                description="내 데이터를 충전하거나 결합 가족에게 선물할 수 있어요."
                imageSrc={datacoupon}
            />
            <CommonDividerThin />

            <BenefitLongTermSection
                title="혜택2. V컬러링 쿠폰"
                target="2년 이상"
                description="전화를 건 상대방에게 내가 설정한 영상을 보여줄 수 있는 ‘V컬러링‘ 부가서비스를 1개월 무료로 이용할 수 있어요."
                imageSrc={colorring}
            />
            <CommonDividerThin />

            <BenefitLongTermSection
                title="혜택3. 듀얼넘버 할인"
                target="2년 이상 (*법인 제외)"
                description="하나의 휴대폰으로 두 개의 번호를 이용할 수 있는 ‘듀얼넘버‘ 부가서비스를 1개월 무료로 이용할 수 있어요.
법인 고객은 이용할 수 없어요."
                imageSrc={dualnumber}
            />
            <CommonDividerThin />

            <BenefitLongTermSection
                title="혜택4. 피싱/해킹 안심서비스"
                target="2년 이상 (*법인 제외)"
                description="피싱, 해킹 등으로 내 계좌 예금이 출금되거나 신용카드가 결제되면 최대 300만원을 보상 받을 수 있는 안심 서비스를 4개월 무료로 이용할 수 있어요.
                법인 고객은 이용할 수 없어요."
                imageSrc={security}
            />
            <div style={{ display: "flex", flexDirection: "column", gap: "16px", padding: "28px 20px 0" }}>
                <Button onClick={goUPlus} as="button" color="secondary" fill="solid" size="medium" state="default" style={{ width: "100%" }}>
                    장기고객 혜택 받기
                </Button>
            </div>
        </Box>
    );
};

export default LongTermCustomerTab;
