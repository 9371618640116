import React from 'react';
import { Box, Icon, Text, Button, CTA } from '@lguuxe/2024_designsystem_uni';

interface TermUnitedInfoBoxCTAProps {
    onClickCTA: () => void;  // onClick 핸들러를 prop으로 받음
    isButtonEnabled: boolean;  // 버튼 활성화 여부를 나타내는 prop
}

const TermUnitedInfoBoxCTA: React.FC<TermUnitedInfoBoxCTAProps> = ({ onClickCTA, isButtonEnabled }) => {

    return (
        <>
            <Box
                style={{
                    backgroundColor: '#F9FAFB',
                    borderRadius: '12px',
                    padding: '16px 20px',
                    margin: '0 20px 12px',
                }}
            >
                <Box
                    style={{
                        display: 'flex',
                        gap: '6px',
                        padding: '0',
                    }}
                >
                    <Icon color="#7f8a94" name="error_circle_filled" size="20px" />
                    <Text typo="font_body_2M" color="color_text_neutral_3">
                        모든 약관에 동의하면 선택동의를 포함하여 모두 동의됩니다. <br />
                        선택동의를 하지 않더라도 서비스에 가입할 수 있습니다.
                    </Text>
                </Box>
            </Box>
            <Box style={{ position: "fixed", bottom: "0", left: "0", right: "0", padding: "0", background: "#fff" }}>
                <CTA gradation layout="column">
                    <Button color="primary" size="large" onClick={onClickCTA} disabled={!isButtonEnabled}>
                        동의하고 진행하기
                    </Button>
                </CTA>
            </Box>
        </>
    );
};

export default TermUnitedInfoBoxCTA;
