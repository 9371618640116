import React, { FC } from 'react';
import { Text, Dropdown } from "@lguuxe/2024_designsystem_uni";
interface SectionSortingProps {
    setFilter : any;
}
const SectionSorting: React.FC<SectionSortingProps> = ({setFilter}) => {
    const handleDropdownChange = (e : any) => {
        let filterValue = '';
        switch (e) {
            case '1':
                filterValue = '202410';
                break;
            case '2':
                filterValue = '202411';
                break;
            case '3':
                filterValue = '202412';
                break;
            default:
                filterValue = '';
                break;
        }
        setFilter(filterValue);
    };
    return (
        <>
            <div
                style={{
                    padding: "12px 20px 0",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Text typo="font_heading_5B" color="color_text_neutral_6">총 10개</Text> {/* font_heading_5sb */}
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Dropdown
                        fill="none"
                        onChange={(e) => handleDropdownChange(e)}
                        items={[
                            {
                                id: '1',
                                label: '2024.10',
                            },
                            {
                                id: '2',
                                label: '2024.11',
                            },
                            {
                                id: '3',
                                label: '2024.12',
                            }
                        ]}
                        size="small"
                        value="1"
                        style={{color: "#525960"}}
                    />
                </div>
            </div>
        </>
    )
}

export default SectionSorting;
