import React, {useEffect, useState} from "react";
import {Badge, Box, FAB, Image, List, Text} from "@lguuxe/2024_designsystem_uni";
import CommonAllIconHeader from "../../../components/Common/CommonAllIconHeader";
import CustomTab from "../../../components/Entire/Pop/CustomTab";
import SortFilter from "../../../components/Partner/SortFilter";
import {eventApi} from "../../../api/attendanceAndEvent";
import {getItemByKey} from "../../../utils/indexedDB";
import {formatDate, toDate} from "../../../utils/dateUtiles";
import commonApi from "../../../api/common";
import {useNavigate} from "react-router-dom";
import useLoading from "../../../hooks/useLoading";
import useLandingPage from "../../../hooks/useLandingPage";

interface EventItem {
    evtImg: string;
    evtThumbImg: string;
    evtTitle: string;
    evtNewYn: string;
    evtStartDate: string;   // YYYYMMDD
    evtEndDate: string;     // YYYYMMDD
    evtId: string;
    evtTargetType: string;
    evtTargetLink: string;
}

const SORT_LIST1 = [
    {
        id: "1",
        label: "최신순"
    },
    {
        id: "2",
        label: "마감일순"
    }];

const SORT_LIST2 = [
    {
        id: "1",
        label: "최신순"
    },
    {
        id: "2",
        label: "오래된순"
    }];

const Event: React.FC = () => {
    const [today, setToday] = useState(new Date());
    const [selectedTabId, setSelectedTabId] = useState<string>('1');
    const [ongoingEventList, setOngoingEventList] = useState<EventItem[]>([]);
    const [endEventList, setEndEventList] = useState<EventItem[]>([]);
    const [prizeEventList, setPrizeEventList] = useState<EventItem[]>([]);
    const [sortList, setSortList] = useState(SORT_LIST1);
    const navigate = useNavigate();
    const {landingPage} = useLandingPage();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const TAB = [
        {
            id: "1",
            label: "진행중"
        },
        {
            id: "2",
            label: "당첨자발표"
        },
        {
            id: "3",
            label: "종료 이벤트"
        }];

    useEffect(() => {
        const loadData = async () => {
            await fetchData('P');  // 진행중인 이벤트 데이터를 먼저 로드
            setIsLoading(false);   // 로딩 상태를 false로 전환
        };

        loadData();

    }, []);

    const fetchData = async (type: string, sortType?: string) => {
        try {
            const resToday = await commonApi.getToday('v1');
            if (resToday.body) {
                const dateString = resToday.body.today;
                setToday(new Date(`${dateString.slice(0, 4)}-${dateString.slice(4, 6)}-${dateString.slice(6, 8)}T${dateString.slice(8, 10)}:${dateString.slice(10, 12)}:${dateString.slice(12, 14)}`));
            }
            const nowYear = new Date().getFullYear();
            let birth = await getItemByKey('birthday');
            if (birth) {
                birth = birth.slice(0, 4);
            }
            const age = nowYear - Number(birth) + 1;
            console.log('age:', age);

            const requestParams = {
                evtType: type, //진행 이벤트: P, 당첨자 발표 이벤트:W, 종료 이벤트:E
                sortType: sortType === 'E' ? 'E' : 'N', // 최신순: N, 마감일순: E,
                bannerType: '141001', //이벤트배너(공통): 141001, 출석이벤트 배너: 141002
                targetAge: '',   // 119001: 10대 미만, 119002: 10대, 119003: 20대, 119004: 30대, 119005: 40대, 119006: 50대 이상
                targetGender: 'A',
                categoryId: '',
                searchType: '',
                searchText: '',
                pageNo: '',
            }
            const res = await eventApi.getEvent(requestParams, 'v1');
            console.log('res:', res); // TODO: res가 있는지 확인
            switch (type) {
                case 'P':
                    setOngoingEventList(res.body.eventList);
                    break;
                case 'W':
                    setPrizeEventList(res.body.eventList);
                    break;
                case 'E':
                    setEndEventList(res.body.eventList);
                    break;
            }

        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }


    const handleTabChange = (id: string) => {
        setSelectedTabId(id);
        console.log(`Tab with id ${id} clicked`);
        if (id === '1') {
            setSortList(SORT_LIST1);
            fetchData('P');
        } else if (id === '2') {
            setSortList(SORT_LIST2);
            fetchData('W');
        } else {
            setSortList(SORT_LIST2);
            fetchData('E');
        }
    }

    const handleSortChange = (sortTypeId: string) => {
        const sortType = sortTypeId === '1' ? 'N' : 'E';
        fetchData(selectedTabId === '1' ? 'P' : selectedTabId === '2' ? 'W' : 'E', sortType);
    }

    const handleItemClick = (event: EventItem) => {
        landingPage(event.evtTargetType, event.evtTargetLink, event.evtTitle);
    }

    const isNewEvent = (startDate: Date): boolean => {
        const todayTime = today.getTime();
        const startTime = startDate.getTime();

        const differenceInMilliseconds = Math.abs(todayTime - startTime);
        // 7일을 밀리초 단위로 변환합니다.
        const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
        return differenceInMilliseconds <= sevenDaysInMilliseconds;
    }

    const renderEventList = (eventList: EventItem[]) => {
        return <Box type="1_b">
            {/* 정렬 */}
            <SortFilter totalCount={eventList.length.toString()} sortList={sortList} onSortChange={handleSortChange}
                        isUsableCheckBox={false}/>

            {/* 당첨자 발표 목록 */}
            <List
                type="view"
                fill="none"
                items={eventList.map(item => (
                    {
                        id: `${item.evtId}-${item.evtTitle}`,
                        onClick: () => handleItemClick(item),
                        leftSlot: [
                            <Image
                                src={item.evtThumbImg}
                                ratio="1:1"
                                width="48px"
                                rounded="0%"
                            />,
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "5px"
                                }}
                            >
                                <Text as="span" typo="font_body_1Sb" color="color_text_neutral_5">
                                    {item.evtTitle}
                                    {isNewEvent(toDate(item.evtStartDate)) &&
                                        <Badge text='N' style={{marginLeft: '8px'}}></Badge>}
                                </Text>
                                <Text as="span" typo="font_detail_2M" color="color_text_neutral_5"
                                      style={{color: "#66707A"}}>
                                    {formatDate(item.evtStartDate)} ~ {formatDate(item.evtEndDate)}
                                </Text>
                            </div>
                        ],
                    }
                ))}
            />
        </Box>
    }

    return (
        <>
            {useLoading(isLoading)}
            <Box
                type="1_b"
                style={{
                    width: "100%"
                }}
            >
                {/* header */}
                <CommonAllIconHeader title={'이벤트'}/>

                {/* 탭 메뉴 */}
                <CustomTab items={TAB} onTabChange={handleTabChange} selectedItemId={selectedTabId}/>

                {/* 당첨자 발표 목록 */}
                {selectedTabId === '1' && renderEventList(ongoingEventList)}
                {selectedTabId === '2' && renderEventList(prizeEventList)}
                {selectedTabId === '3' && renderEventList(endEventList)}

                {/* FAB */}
                <Box type="1_b" style={{bottom: "0"}}>
                    <FAB
                        icon="chevron_up_small_line"
                        label=""
                        onClick={function noRefCheck() {
                        }}
                        size="small"
                        style={{
                            position: "fixed",
                            right: "24px",
                            bottom: "47px"
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};

export default Event;
