import React from 'react';
import { CTA, Button, TextSet, Box , Text } from "@lguuxe/2024_designsystem_uni";
import { useNavigate } from "react-router-dom";

interface SectionPaymentInfo3Props {
    limit: {
        maxLimit: string;
        monthUseAmnt: string;
    } | null;
}

const SectionPaymentInfo3: React.FC<SectionPaymentInfo3Props> = ({ limit }) => {
    const navigate = useNavigate();

    const goLimitChange = () => {
        navigate('/mobile-pay/limit');
    };

    return (
        <div>
            <Box type="2_trbl">
                <TextSet
                    subComponent="Heading_1"
                    text={{
                        eyebrow: '사용 한도',
                        title: `${limit?.monthUseAmnt || '0'}원`, // 월 사용 가능금액 표시
                    }}
                    weight="bolder"
                />
            </Box>
            <div
                style={{
                    padding: "16px 20px 0",
                }}
            >
                <Text
                    style={{ flex: "1" }}
                    typo="font_body_1M"
                    color="color_text_neutral_4"
                >
                    최대 {limit?.maxLimit || '0'}원까지 설정 가능
                </Text>
            </div>
            <div
                style={{
                    paddingTop: "12px",
                }}
            >
                <CTA layout="column">
                    <Button color="secondary" onClick={goLimitChange}>한도 변경</Button>
                </CTA>
            </div>
        </div>
    );
};

export default SectionPaymentInfo3;
