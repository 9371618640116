import React, {useEffect, useState} from 'react';
import ShortcutSection from '../../components/Entire/ShortcutSection';

import MenuList from '../../components/Entire/MenuList';

import RecommendAppList from '../../components/Entire/RecommendAppList';
import CommonDivider from '../../components/Common/CommonDivider';
import TitleHeader from "../../components/Common/TitleHeader";
import GuideSection from "../../components/Entire/GuideSection";
import guideIcon from '../../images/icon/6_notification/guide_s.svg';
import chatbotIcon from '../../images/icon/6_notification/chatbot_s.svg';
import {useNavigate} from "react-router-dom";
import {getItemByKey} from "../../utils/indexedDB";
import {appTech, chatbot} from "../../api/externalApi";
import NavigationBar from "../../components/Common/NavigationBar";
import {goMoveData, goMovie} from "../../utils/helper";
import CommonLoginPopup from "../../components/Common/CommonLoginPopup";
import CommonPopup from "../../components/Common/CommonPopup";
import paymentApi from "../../api/payment";

const Entire: React.FC = () => {
    const navigate = useNavigate();
    const [memberStatus, setMemberStatus] = useState('0');
    const [usePay, setUsePay] = useState(false);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [showErrorPopup, setShowErrorPopup] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setMemberStatus(await getItemByKey('memberStatus'));
            paymentApi.getServiceUseTerm('v1').then((res) => {
                console.log('getServiceUseTerm:', res);
                if (res.header.resultCode === '0000' && res.body) {
                    if (res.body.useTermYn === "Y") {
                        setUsePay(true)
                    } else {
                        setUsePay(false)
                    }
                }
            })
        }
        fetchData();
    }, []);

    const handlePopup = (type: string) => {
        if (type === 'login') {
            setShowLoginPopup(true);
        }

        if (type === 'error') {
            setShowErrorPopup(true);
        }
    }


    const handleClickChatbot = async () => {
        const memberId = await getItemByKey('memberId');
        if (!memberId) {
            setShowLoginPopup(true);
            return;
        }
        chatbot();
    }


    const MEMBERSHIP_MENU = [
        ...(!(memberStatus === '0') ? [
            {title: "전체 제휴사", link: "/partners"},
            {title: "VVIP/VIP 콕 혜택", link: "/vip-kok"},
            {title: "유플투쁠", link: "/uptp"}] : []),
        ...((memberStatus === '1' || memberStatus === '3') ? [{
            title: "영화예매", link: "empty", onAction: () => {
                goMovie()
            }
        }, {
            title: "데이터 선물하기", link: "empty", onAction: () => {
                goMoveData()
            }
        }] : [])
    ];

    const MISSION_MENU = [
        ...((memberStatus === '1' || memberStatus === '2' || memberStatus === '5') ? [{
            title: "출석체크", link: "/mission/attendance"
        }, {title: "리워드 적립/사용내역", link: "", onAction: () => appTech()},] : []),
        ...((memberStatus === '1' || memberStatus === '2') ? [{
            title: "U+통신요금 할인 현황", link: "", onAction: () => appTech(true)
        }] : []),
    ];

    const MY_MENU = [
        ...(!(memberStatus === '0' || memberStatus === '5') ? [{
            title: "내가 받은 혜택", link: "/my/benefit"
        }] : []),
        ...((memberStatus === '1' || memberStatus === '2' || memberStatus === '5') ? [{
            title: "내 배지", link: "/my/badge"
        }] : []),
        ...(!(memberStatus === '0') ? [
            {title: "내 쿠폰", link: "/my/coupon"},
            {title: "좋아하는 제휴사", link: "/my/partners"}
        ] : []),
        ...((memberStatus === '1' || memberStatus === '2') ? [
            ...(usePay ? [{ title: "휴대폰결제", link: "/mobile-pay" }] : []), // usePay가 true일 때만 "휴대폰결제" 추가
            { title: "POP 서비스", link: "/pop" }
        ] : []),
    ]

    const HELP_MENU = [
        {title: "공지사항", link: "/help/announcement", isNew: true},
        {title: "자주하는 질문", link: "/help/faq"},
        ...(!(memberStatus === '0') ? [{title: "1:1 문의", link: "/help/ask"},] : []),
        {title: "이용약관", link: "/help/terms"},
        {title: "개인정보처리방침", link: "/help/private-policy", isBold: true},
    ]

    return (
        <>
            <div style={{paddingBottom: '66px'}}>
                <TitleHeader title='전체' isNotice={false} memberStatus={memberStatus}/>

                <ShortcutSection handlePopup={handlePopup} memberStatus={memberStatus}/>

                <GuideSection title={"U+멤버십 혜택 이용 GUIDE"} icon={guideIcon} onClick={() => {
                    navigate('/benefit-guide')
                }}/>
                <CommonDivider/>

                <MenuList title='U+ 멤버십' menuItems={MEMBERSHIP_MENU}/>

                <MenuList title='미션' menuItems={MISSION_MENU}/>

                <MenuList title='마이' menuItems={MY_MENU}/>

                <RecommendAppList handlePopup={handlePopup}/>
                <CommonDivider/>

                <GuideSection title={' 궁금한 내용 챗봇에게 물어보기'} icon={chatbotIcon} onClick={handleClickChatbot}/>

                <MenuList title='고객지원' menuItems={HELP_MENU} noDivider={true}/>
            </div>

            <CommonLoginPopup isOpen={showLoginPopup} onClose={() => setShowLoginPopup(false)}/>
            <CommonPopup submitText={'확인'} isOpen={showErrorPopup} onSubmit={() => setShowErrorPopup(false)}
                         description={'일시적인 오류입니다. 잠시후 다시 시도해주세요.'}/>

            <NavigationBar selectedItem={'entire'}/>
        </>
    );
};

export default Entire;
