// ****************************************************
// [배포] 5.전체 > PoP > POP서비스_가입안내 (UMembershipPOP)
// [배포] 5.전체 > PoP > POP서비스_가입안내 (펼침) (UMembershipPOP2)
// [배포] 5.전체 > PoP > POP서비스_가입안내 (가입상태) (UMembershipPOP3)
// ****************************************************
import React from 'react'
import {Box, Image, Text} from '@lguuxe/2024_designsystem_uni'
import box1Img from '../../../images/icon/8_POP/dcmembership.svg'
import box2Img from "../../../images/icon/8_POP/dcpop.svg"
import box3Img from "../../../images/icon/8_POP/dcgoods.svg"
import box4Img from "../../../images/icon/8_POP/free.svg"

const SectionContents2: React.FC = () => {
    return (
        <Box
            type="3_trbl"
            style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "8px"
            }}
        >
            <Box style={{
                padding: "0",
                border: "1px solid #DCE0E5",
                borderRadius: "12px",
                width: "calc(50% - 4px)",
                boxSizing: "border-box",
                height: "134px",
                position: "relative"
            }}>
                <Box style={{padding: "16px"}}>
                    <Text typo="font_detail_1Sb" color="color_text_neutral_5">
                        U+멤버십<br/>
                        할인
                    </Text>
                    <Box style={{position: "absolute", right: "16px", bottom: "16px", padding: "0"}}>
                        <Image
                            customClass="customClass"
                            ratio="1:1"
                            src={box1Img}
                            width="56"
                        />
                    </Box>
                </Box>
            </Box>
            <Box style={{
                padding: "0",
                border: "1px solid #DCE0E5",
                borderRadius: "12px",
                width: "calc(50% - 4px)",
                boxSizing: "border-box",
                height: "134px",
                position: "relative"
            }}>
                <Box style={{padding: "16px"}}>
                    <Text typo="font_detail_1Sb" color="color_text_neutral_5">
                        POP상품<br/>
                        추가할인
                    </Text>
                    <Box style={{position: "absolute", right: "16px", bottom: "16px", padding: "0"}}>
                        <Image
                            customClass="customClass"
                            ratio="1:1"
                            src={box2Img}
                            width="56"
                        />
                    </Box>
                </Box>
            </Box>
            <Box style={{
                padding: "0",
                border: "1px solid #DCE0E5",
                borderRadius: "12px",
                width: "calc(50% - 4px)",
                boxSizing: "border-box",
                height: "134px",
                position: "relative"
            }}>
                <Box style={{padding: "16px"}}>
                    <Text typo="font_detail_1Sb" color="color_text_neutral_5">
                        상품별<br/>
                        할인
                    </Text>
                    <Box style={{position: "absolute", right: "16px", bottom: "16px", padding: "0"}}>
                        <Image
                            customClass="customClass"
                            ratio="1:1"
                            src={box3Img}
                            width="56"
                        />
                    </Box>
                </Box>
            </Box>
            <Box style={{
                padding: "0",
                border: "1px solid #DCE0E5",
                borderRadius: "12px",
                width: "calc(50% - 4px)",
                boxSizing: "border-box",
                height: "134px",
                position: "relative"
            }}>
                <Box style={{padding: "16px"}}>
                    <Text typo="font_detail_1Sb" color="color_text_neutral_5">
                        무료쿠폰
                    </Text>
                    <Box style={{position: "absolute", right: "16px", bottom: "16px", padding: "0"}}>
                        <Image
                            customClass="customClass"
                            ratio="1:1"
                            src={box4Img}
                            width="56"
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default SectionContents2
