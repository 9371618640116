import React, {useEffect, useState} from 'react';
import {Box, Dropdown} from '@lguuxe/2024_designsystem_uni';
import {Carrier} from '../../pages/IdentityVerification/types';

const MOBILE_CARRIERS: Carrier[] = [
    {
        id: '1',
        label: 'LG U+',
        value: 'L',
    },
    {
        id: '2',
        label: 'SKT',
        value: 'S',
    },
    {
        id: '3',
        label: 'KT',
        value: 'K',
    },
    {
        id: '4',
        label: 'LG U+ 알뜰폰',
        value: 'LM',
    },
    {
        id: '5',
        label: 'SKT 알뜰폰',
        value: 'SM',
    },
    {
        id: '6',
        label: 'KT 알뜰폰',
        value: 'KM',
    },
];

interface MobileDropdownProps {
    onChange: (value: string) => void;
    resetSignal: number;
}

const MobileDropdown: React.FC<MobileDropdownProps> = ({onChange, resetSignal}) => {
    const [carrierIndex, setCarrierIndex] = useState('1');
    // 1. 안드로이드는 통신사를 알아내서 자동 설정해줌
    // 2. iOS는 LGU+가 기본값으로 설정되어 있음

    useEffect(() => {
        // console.log('MobileDropdown');
    }, []);

    useEffect(() => {
        setCarrierIndex('1');
    }, [resetSignal]);

    const handleChange = (event: any) => {
        console.log('event', event);
        setCarrierIndex(event);
        onChange(MOBILE_CARRIERS[event - 1].value);
    };

    return (
        <Box type='2_trbl'>
            <Dropdown
                fill='underline'
                items={MOBILE_CARRIERS.map((carrier) => ({
                    id: carrier.id,
                    label: carrier.label,
                }))}
                label='통신사'
                size='medium'
                onChange={handleChange}
                value={carrierIndex}
            />
        </Box>
    );
};

export default MobileDropdown;
