import React from "react";
import MembershipBenefitSection from "../MembershipBenefitSection";
import barcode from "../../../images/guide/usage/mem/barcode.webp";
import benefit from "../../../images/guide/usage/mem/mybenefit.webp";
import available from "../../../images/guide/usage/mem/available.webp";
import coupon from "../../../images/guide/usage/mem/coupon.webp";
import like from "../../../images/guide/usage/mem/like.webp";
import mymembership from "../../../images/guide/usage/mem/mymembership.webp";
import recommend from "../../../images/guide/usage/mem/recommend.webp";
import state from "../../../images/guide/usage/mem/state.webp";
import Upltwopl from "../../../images/guide/usage/mem/Upltwopl.webp";

import CommonDivider from "../../Common/CommonDivider";

const MembershipUsageTab: React.FC = () => {
    return (
        <>
            <MembershipBenefitSection
                title="바코드 사용하기"
                description="하단 바코드 아이콘을 클릭하면 바코드를 확인할 수 있어요. 바코드는 15분이 지나면 새로고침을 해주세요."
                imageSrc={barcode}
            />
            <CommonDivider />

            <MembershipBenefitSection
                title="U+멤버십 혜택 모아보기"
                description="고객님의 취향과 라이프스타일에 따라 필요한 혜택을 매일 추천해드려요."
                imageSrc={recommend}
                additionalContent={[
                    {
                        text: "U+멤버십 고객을 위한 다양한 혜택을 한눈에 확인해 보세요. 내 등급에서 받을 수 있는 혜택만 골라 볼 수 있어요.",
                        imageSrc: available,
                        imageAlt: "이미지 설명 1"
                    },
                    {
                        text: "VVIP/VIP 고객을 위한 혜택을 모아보세요. 나의 사용이력도 확인 가능해요.",
                        imageSrc: state,
                        imageAlt: "이미지 설명 2"
                    },
                    {
                        text: "매달 새롭게 변경되는 유플투쁠 혜택을 확인하고 사용해 보세요.",
                        imageSrc: Upltwopl,
                        imageAlt: "이미지 설명 3"
                    },
                    {
                        text: "다운받은 쿠폰은 내 쿠폰함에서 모아볼 수 있어요.",
                        imageSrc: coupon,
                        imageAlt: "이미지 설명 4"
                    },
                    {
                        text: "내가 좋아하는 제휴처에 ♥ 표시로 즐겨찾기를 할 수 있어요.",
                        imageSrc: like,
                        imageAlt: "이미지 설명 5"
                    }
                ]}
            />
            <CommonDivider />

            <MembershipBenefitSection
                title="나의 U+멤버십 확인하기"
                description="마이 탭에서 U+멤버십 등급을 확인할 수 있어요."
                imageSrc={mymembership}
                additionalContent={[{
                        text: "나는 얼마나 U+멤버십을 잘 쓰고 있을까? 내가 받은 혜택에서 확인해 보세요.",
                        imageSrc: benefit,
                        imageAlt: "이미지 설명 5"
                    }]}
            />
        </>
    );
};

export default MembershipUsageTab;
