import React from 'react';
import {Tab} from "@lguuxe/2024_designsystem_uni";
import {CLICK_LOCATION, EVENT_ACTION} from "../../utils/const";

interface SectionTabProps {
    selectedTab: string;
    setSelectedTab: (tab: string) => void;
}

const SectionTab: React.FC<SectionTabProps> = ({selectedTab, setSelectedTab}) => {
    return (
        <div>
            <Tab
                fill="underline"
                items={[
                    {
                        id: '1',
                        label: '배지함'
                    },
                    {
                        id: '2',
                        label: '전체 내역'
                    }
                ]}
                layout="fullWidth"
                selectedItemId={selectedTab}
                onItemSelected={(id) => setSelectedTab(id)}
                size="medium"
                style={{
                    padding: "0 20px"
                }}
                data-gtm-event-name={'all_click'}
                data-gtm-event-category={'클릭'}
                data-gtm-event-action={`${CLICK_LOCATION.LNB} - ${EVENT_ACTION.LINK_CLICK}`}
                data-gtm-event-label={`컨텐츠 : ${selectedTab === '1' ? '배지함' : '전체 내역'}`}
                data-gtm-click-url={''}
                data-gtm-click-location={`${CLICK_LOCATION.LNB}`}
                data-gtm-click-direction={'내부'}
                data-gtm-click-text={`${selectedTab === '1' ? '배지함' : '전체 내역'}`}
            />
        </div>
    );
}

export default SectionTab;
