import React, { FC, forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import CommonDivider from "../../components/Common/CommonDivider";
import CommonPopup from "../../components/Common/CommonPopup";
import { addItem, clearAllDatabases, getItemByKey } from "../../utils/indexedDB";
import AppInfoList from "../../components/Setting/AppInfoList";
import LogoutQuitButtons from '../../components/Setting/LogoutQuitButtons';
import {sendEvent, sendEventReturn, sendEventToAndroid, sendEventToIOS} from "../../api/bridgeApi";
import { settingApi } from "../../api/settingAndTerms";
import { handlePromiseResult } from "../../api/api";
import membershipsServiceApi from "../../api/memberships-service";
import {Box, Button, List, TextSet, Toggle} from "@lguuxe/2024_designsystem_uni";
import {TermsPersonalInfo} from "./types";
import AgreementsDetail from "../Agreements/AgreementsDetail";
import paymentApi from "../../api/payment";
import Snackbar from "../../components/Common/Snackbar";

export interface SettingHandles {
    setBIO: (enabled: boolean) => void;
    setAppLock: (enabled: boolean) => void;
    handleChangePassword: () => void;
    handleResetLoginInfo: () => void;
    handleModifyInfo: () => void;
    getBIO: (enabled: boolean) => void;
    getAppLock: (enabled: boolean) => void;
}

const Setting2 = forwardRef<SettingHandles>((props, ref) => {
    const [openPopup, setOpenPopup] = useState<string | null>(null);
    const [userStatus, setUserStatus] = useState<string>("0");
    const [termsPersonalInfo, setTermsPersonalInfo] = useState<TermsPersonalInfo[]>([]);
    const [recvInfo, setRecvInfo] = useState({ aprPushYn: "N", pushYn: "N", smsYn: "N" });
    const [membershipDiscount, setMembershipDiscount] = useState<string>("N")
    const [myVersion, setMyVersion] = useState<string>("");
    const [appLock, setAppLock] = useState<boolean>(false);
    const [useBio, setUseBio] = useState<boolean>(false);
    const [latestVersion, setLatestVersion] = useState<string>("");
    const [selectedTermId, setSelectedTermId] = useState<string | null>(null);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        checkFirst();
        fetchData();
    }, []);

    useImperativeHandle(ref, () => ({
        setBIO: async (enabled: boolean) => {
            await addItem("appLock", enabled)
            setAppLock(enabled)
        },
        setAppLock: async (enabled: boolean) => {
            await addItem("useBio", enabled)
            setUseBio(enabled)
        },
        getBIO: async (enabled: boolean) => {
            await addItem("appLock", enabled)
            setAppLock(enabled)
        },
        getAppLock: async (enabled: boolean) => {
            await addItem("useBio", enabled)
            setUseBio(enabled)
        },
        handleChangePassword: () => {
            console.log('changePassword done')
        },
        handleResetLoginInfo: async () => {
            await clearAllDatabases();
            sendEventToAndroid("APP_FINISH", {});
            sendEventToIOS("APP_FINISH", {});
            setOpenPopup(null);
        },
        handleModifyInfo: async () => {
            navigate('/setting/modify-user-info');
        }
    }));

    const handleSubmit = async (termId: string) => {
        // termId에 해당하는 약관의 체크 상태를 반대로 변경하고 API 호출
        const updatedTerms = termsPersonalInfo.map(term =>
            term.termId === termId ? { ...term, termYn: term.termYn === 'Y' ? 'N' : 'Y' } : term
        );

        const selectedTerm = updatedTerms.find(term => term.termId === termId);

        if (selectedTerm) {
            try {
                // 약관 상태를 서버로 전송하는 API 호출
                await settingApi.putTermsPersonalInfo({ termId, termYn: selectedTerm.termYn }, 'v1');
                setTermsPersonalInfo(updatedTerms); // 성공적으로 업데이트된 상태를 반영
            } catch (error) {
                console.error("Error updating term status:", error);
            }
        }
        setIsDetailOpen(false); // 세부 항목 창 닫기
    };

    const fetchData = async () => {
        const platform = await getItemByKey('platform');
        const [termsPersonalInfo, version, alarmData, discount] = await Promise.allSettled([
            settingApi.getTermsPersonalInfo({}, 'v1'),
            settingApi.getVersion({ osType: platform }, 'v1'),
            membershipsServiceApi.getAlarm('v1'),
            paymentApi.getMembershipDiscount({reqType: "G"}, 'v1')
        ]);
        setRecvInfo(handlePromiseResult(alarmData)?.recvInfo || { aprPushYn: "N", pushYn: "N", smsYn: "N" });
        setTermsPersonalInfo(handlePromiseResult(termsPersonalInfo)?.termsList || []);
        setLatestVersion(handlePromiseResult(version) || []);
        setMembershipDiscount(handlePromiseResult(discount)?.discountSetStus || [])
    };

    const checkFirst = async () => {
        await sendEventReturn('GET_BIO_AUTH', {})
        await sendEventReturn('GET_APP_LOCK', {})
        const myVersion = await getItemByKey('appVersion');
        const status = await getItemByKey('memberStatus');

        setMyVersion(myVersion);
        if (status) setUserStatus(status);
    };

    const handleToggle = async (e: any) => {
        console.log(e)
        if(e.id === "membershipDiscount") {
            if(membershipDiscount === "Y") {
                console.log(membershipDiscount)
                setOpenPopup("membershipDiscount")
                renderPopup()
                // setMembershipDiscount("N")
            } else {
                console.log(membershipDiscount)
                setMembershipDiscount("Y")
                await paymentApi.putMembershipDiscount({discountOnOff : "20"}, 'v1');
            }
        } else {
            // @ts-ignore
            if(recvInfo[e.id] === "Y") {
                setOpenPopup(e.id)
                renderPopup()
            }
            const newRecvInfo = { ...recvInfo, [e.id]: e.value === "value" ? "Y" : "N" };
            setRecvInfo(newRecvInfo);
            await membershipsServiceApi.putAlarm(newRecvInfo, 'v1');
        }
    };

    const handlePopupSubmit = (popupType: string) => {
        switch (popupType) {
            case 'logout':
                sendEventReturn('RESET_LOGIN_INFO', {});
                break;
            case 'bioIos':
                sendEvent("OPEN_APP_SETTINGS", {});
                break;
            case 'membershipDiscount':
                break;
            default:
                setOpenPopup(null);
                break;
        }
    };

    const handleTermClick = (termId: string) => {
        console.log(termId)
        setSelectedTermId(termId);
        setIsDetailOpen(true);  // Bottom Sheet 열기
        console.log("termId", termId)
        console.log("IsDetailOpen", isDetailOpen)
    };

    const renderPopup = () => {
        switch (openPopup) {
            case 'logout':
                return <CommonPopup description={"U+멤버십에 로그인 하려면\n본인인증을 다시 해야해요.\n로그아웃하시겠어요?"}
                                    submitText="로그아웃 후 앱 종료"
                                    cancelText="취소"
                                    onSubmit={() => handlePopupSubmit('logout')}
                                    onClose={() => setOpenPopup(null)}/>;
            case 'bioIos':
                return <CommonPopup description="Face ID를 설정하세요."
                                    submitText="이동"
                                    cancelText="취소"
                                    onSubmit={() => handlePopupSubmit('bioIos')} />;
            case 'membershipDiscount':
                return <CommonPopup description="멤버십 할인 승인 알림을 끄면 앱 푸시 또는 문자로 할인 내역을 받을 수 없습니다. 알림을 끄시겠어요?"
                                    submitText="알림 끄기"
                                    cancelText="취소"
                                    onSubmit={ async () =>{
                                        setMembershipDiscount("N")
                                        await paymentApi.putMembershipDiscount({discountOnOff : "10"}, 'v1');
                                        setOpenPopup(null)}}
                                    onClose={() => {
                                        setMembershipDiscount("Y")
                                        setOpenPopup(null)
                                    }}/>;
            case 'aprPushYn':
                return <CommonPopup description="앱 푸시를 끄면 멤버십 할인 승인 알림 등 푸시 정보를 받을 수 없습니다. 앱 푸시를 끄시겠어요?"
                                    submitText="알림 끄기"
                                    cancelText="취소"
                                    onSubmit={() =>{
                                        setRecvInfo({...recvInfo, ["aprPushYn"]: "N"})
                                        setOpenPopup(null)}}
                                    onClose={() => {
                                        setRecvInfo({...recvInfo, ["aprPushYn"]: "Y"})
                                        setOpenPopup(null)
                                    }}/>;
            case 'pushYn':
                return <CommonPopup description="앱 푸시를 끄면 멤버십 할인 승인 알림 등 푸시 정보를 받을 수 없습니다. 앱 푸시를 끄시겠어요?"
                                    submitText="알림 끄기"
                                    cancelText="취소"
                                    onSubmit={() =>{
                                        setRecvInfo({...recvInfo, ["pushYn"]: "N"})
                                        setOpenPopup(null)}}
                                    onClose={() => {
                                        setRecvInfo({...recvInfo, ["pushYn"]: "Y"})
                                        setOpenPopup(null)
                                    }}/>;
            default:
                return null;
        }
    };

    return (
        <>
        {!isDetailOpen &&
            <>
            <CommonLeftIconHeader title={"설정"} />
            <div style={{ paddingBottom: "12px" }}>
                <Box type="2_trbl">
                    <TextSet subComponent="Heading_4" text={{ title: '잠금' }} weight="default" />
                </Box>
                <List
                    fill="none"
                    items={[
                        {
                            linkIconOnlyId: 'empty',
                            text: {
                                title: '비밀번호 변경',
                                description: userStatus === "1" ? "휴대폰결제, 앱 잠금에 사용되는 비밀번호 변경할 수 있어요." : ""
                            }
                        }
                    ]}
                    type="view"
                    weight="bolder"
                    onClick={() => sendEventReturn("CHANGE_PASSWORD", {step:1})}
                />
                <List
                    fill="none"
                    size="large"
                    type="toggle"
                    items={[
                        {
                            checked: appLock,
                            id: "lock",
                            onClick: () => {
                                console.log(!appLock)
                                sendEventReturn("SET_APP_LOCK", {enable : !appLock})
                            },
                            leftSlot: [
                                <TextSet
                                    subComponent="Body_1"
                                    text={{
                                        title: '앱 잠금'
                                    }}
                                    weight="bolder"
                                />
                            ],
                        },
                        {
                            checked: useBio,
                            id: "bio",
                            onClick: () => {
                                console.log(!useBio)
                                sendEventReturn("SET_BIO_AUTH", {enable : !useBio})
                            },
                            leftSlot: [
                                <TextSet
                                    subComponent="Body_1"
                                    text={{
                                        title: '생체 인증 사용'
                                    }}
                                    weight="bolder"
                                />
                            ],
                        },
                    ]}
                />
            </div>

            <div style={{ marginBottom: "8px" }}>
                <CommonDivider />
            </div>

            <div style={{ marginBottom: "12px" }}>
                <Box type="2_trbl">
                    <TextSet subComponent="Heading_4" text={{ title: '알림' }} weight="default" />
                </Box>
                <div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: "16px 20px"
                    }}>
                    <TextSet
                        subComponent="Body_1"
                        text={{
                            title: '앱 푸시 설정'
                        }}
                        weight="bolder"
                    />
                    <Toggle
                        id="aprPushYn"
                        checked={recvInfo.aprPushYn === "Y"}
                        onChange={handleToggle}
                        size="medium"
                        trueValue="value"
                        value="value"
                        weight="default"
                        width="auto"
                    />
                </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: "16px 20px"
                    }}>
                        <TextSet
                            subComponent="Body_1"
                            text={{
                                title: '멤버십 할인 승인'
                            }}
                            weight="bolder"
                        />
                        <Toggle
                            id="membershipDiscount"
                            checked={membershipDiscount === "Y"}
                            onChange={handleToggle}
                            size="medium"
                            trueValue="value"
                            value="value"
                            weight="default"
                            width="auto"
                        />
                    </div>
                    <div
                        style={{
                            borderRadius: "12px",
                            backgroundColor: "#F3F5F6",
                            margin: "0 20px"
                        }}
                    >
                        <div
                            style={{
                                borderRadius: "12px",
                                display: "flex",
                                justifyContent: "space-between",
                                backgroundColor: "#F3F5F6",
                                padding: "16px 20px",
                            }}
                        >
                            <TextSet
                                subComponent="Body_1"
                                text={{
                                    title: '앱 푸시'
                                }}
                                weight="bolder"
                            />
                            <Toggle
                                id="pushYn"
                                checked={recvInfo.pushYn === "Y"}
                                onChange={handleToggle}
                                size="medium"
                                trueValue="value"
                                value="value"
                                weight="default"
                                width="auto"
                            />
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                padding: "16px 20px",
                            }}
                        >
                            <TextSet
                                subComponent="Body_1"
                                text={{
                                    title: '문자'
                                }}
                                weight="bolder"
                            />
                            <Toggle
                                id="smsYn"
                                checked={recvInfo.smsYn === "Y"}
                                onChange={handleToggle}
                                size="medium"
                                trueValue="value"
                                value="value"
                                weight="default"
                                width="auto"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ marginBottom: "8px" }}>
                <CommonDivider />
            </div>

            {userStatus !== '3' && userStatus !== '4' && (
                <>
                    <div style={{ paddingBottom: "12px" }}>
                        <Box type="2_trbl">
                            <TextSet
                                subComponent="Heading_4"
                                text={{
                                    title: '개인정보 이용',
                                }}
                                weight="default"
                            />
                        </Box>
                        {termsPersonalInfo.sort((a, b) => parseInt(a.dpOrder) - parseInt(b.dpOrder)).map((term) => (
                            <List
                                key={term.termId}
                                fill="none"
                                isSlotClickable={true}
                                items={[
                                    {
                                        leftSlot: (
                                            <TextSet
                                                subComponent="Body_1"
                                                text={{
                                                    title: (
                                                        <>
                                                            <span>{term.termName}</span>
                                                        </>
                                                    ),
                                                }}
                                                weight="bolder"
                                            />
                                        ),
                                        rightSlot: (
                                            <Button
                                                as="button"
                                                color="neutral"
                                                fill="text"
                                                size="large"
                                                state="default"
                                                rightIcon="chevron_right_small_line"
                                                onClick={() => handleTermClick(term.termId)}
                                            >
                                                {term.termYn === 'Y' ? '동의함' : '미동의'}
                                            </Button>
                                        ),
                                    },
                                ]}
                                type="view"
                                weight="bolder"
                            />
                        ))}
                    </div>
                    <div style={{ marginBottom: "8px" }}>
                        <CommonDivider />
                    </div>
                </>
            )}


            <AppInfoList currentVersion={myVersion} latestVersion={latestVersion} />
            <LogoutQuitButtons onLogoutClick={() => setOpenPopup('logout')} onQuitClick={() => navigate('/setting/quit')} />
            {renderPopup()}
            </>}
            {isDetailOpen && <AgreementsDetail
                selected={termsPersonalInfo.find(term => term.termId === selectedTermId)?.termYn === 'Y' || false}
                type={'exist'} termId={selectedTermId || ""}
                tempTermTitle={termsPersonalInfo.find(term => term.termId === selectedTermId)?.termName}
                handleSubmit={() => handleSubmit(selectedTermId || '')}
                handleClose={() => setIsDetailOpen(false)}
            />}
        </>
    );
});

export default Setting2;
