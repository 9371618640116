// ****************************************************
// [배포] 4.마이 - 내 등급, 혜택 이력 - 내 이력 (UMembershipReceivedBenefitHistory)
// [배포] 4.마이 - 내 등급, 혜택 이력 - 내 이력(혜택미사용) (UMembershipReceivedBenefitHistoryNodata)
// ****************************************************
import React, {useEffect, useState} from 'react';
import {Text} from '@lguuxe/2024_designsystem_uni';
import {formatCurrency} from '../../utils/currencyUtils';
import {BenefitSummery} from "../../pages/My/types";

interface BenefitGraphTextProps {
    benefitSummary: BenefitSummery;
}

const BenefitGraphText: React.FC<BenefitGraphTextProps> = ({benefitSummary}) => {
    const [plusMinus, setPlusMinus] = useState<string>('');
    const [moreDiscount, setMoreDiscount] = useState<string>('');

    useEffect(() => {
        const discountMoreThanAverage: string = (
            parseFloat(benefitSummary.thisMonthDiscount) - parseFloat(benefitSummary.userMonthAverageDiscount)
        ).toString();
        discountMoreThanAverage === '0' ? setPlusMinus('') : setPlusMinus(discountMoreThanAverage.includes('-') ? '' : '+');
        setMoreDiscount((discountMoreThanAverage));
    }, [benefitSummary]);

    return (
        <>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    padding: ' 0 20px',
                    margin: '12px 0',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        padding: '4px 0',
                    }}
                >
                    <Text style={{flex: '1'}} typo='font_body_1M' color='color_text_neutral_4'>
                        순위
                    </Text>
                    {benefitSummary.thisMonthDiscount === '0' ?
                        <Text typo='font_heading_5Sb'>혜택 미사용</Text> :
                        <Text typo='font_heading_5Sb'>상위 {benefitSummary.ranking}%</Text>}
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        padding: '4px 0',
                    }}
                >
                    <Text style={{flex: '1'}} typo='font_body_1M' color='color_text_neutral_4'>
                        평균대비
                    </Text>
                    <Text typo='font_heading_5Sb'>
                        {plusMinus}
                        {formatCurrency(moreDiscount)}원
                    </Text>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        padding: '4px 0',
                    }}
                >
                    <Text style={{flex: '1'}} typo='font_body_1M' color='color_text_neutral_4'>
                        올해 누적
                    </Text>
                    <Text typo='font_heading_5Sb'>{formatCurrency(benefitSummary.totalDiscount)}원</Text>
                </div>
            </div>
        </>
    );
};

export default BenefitGraphText;
