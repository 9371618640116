// ****************************************************
// [배포] 5.전체 > 설정 > 회원 탈퇴 (UMembershipSettingQuit)
// ****************************************************
import React from 'react';
import {Box, List, TextSet} from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";
import {moneymeEnter} from "../../api/externalApi";

// Define the type for the List item
interface ListItem {
    leftSlot: React.ReactNode[];
    linkIconOnlyId: string;
    onClick: () => void;
}

const SectionQuitList: React.FC = () => {
    const navigate = useNavigate()
    const goUPTP = () => {
        navigate('/uptp')
    }
    const goMoneyMe = () => {
        moneymeEnter()
    }

    const goPartners = () => {
        navigate('/partners')
    }
    const items: ListItem[] = [
        {
            leftSlot: [
                <TextSet
                    key="1"
                    subComponent="Body_1"
                    text={{
                        eyebrow: '누구나 받을 수 있는',
                        title: '혜택 브랜드 보기'
                    }}
                    weight="bolder"
                />
            ],
            onClick: goPartners,
            linkIconOnlyId: "id"
        },
        {
            leftSlot: [
                <TextSet
                    key="2"
                    subComponent="Body_1"
                    text={{
                        eyebrow: '매월 다양한 혜택이 가득!',
                        title: '유플투쁠'
                    }}
                    weight="bolder"
                />
            ],
            onClick: goUPTP,
            linkIconOnlyId: "id"
        },
        {
            leftSlot: [
                <TextSet
                    key="3"
                    subComponent="Body_1"
                    text={{
                        eyebrow: '혹시 이런 기능도 써보셨나요?',
                        title: '머니Me'
                    }}
                    weight="bolder"
                />
            ],
            onClick: goMoneyMe,
            linkIconOnlyId: "id"
        }
    ];

    return (
        <>
            <Box
                type="2_trbl"
            >
                <TextSet
                    subComponent="Heading_4"
                    text={{
                        title: <><span>U+ 멤버십을 유지하시면<br/>받을 수 있는 혜택을 살펴보세요.</span></>
                    }}
                    weight="bolder"
                />
            </Box>
            <Box style={{padding: "0 0 12px 0"}}>
                <List
                    fill="none"
                    type="view"
                    size="medium"
                    items={items}
                />
            </Box>
        </>
    );
}

export default SectionQuitList;
