import {BottomSheet, Button, CTA, Header, IconButton, List, Text} from "@lguuxe/2024_designsystem_uni";
import React, {useEffect, useState} from "react";
import paymentApi from "../../api/payment";
import {RequestPaymentInfo} from "./types";

interface BS_PaymentTermProps {
    isOpen: boolean;
    onClose: () => void;
    onAgree: () => void;
    reqInfo: RequestPaymentInfo;
}

const BS_PaymentTerm: React.FC<BS_PaymentTermProps> = ({isOpen, onClose, onAgree, reqInfo}) => {
    const [agreeList, setAgreeList] = useState<boolean[]>([false]);
    const [buttonActive, setButtonActive] = useState(false);

    const TERMS = [
        {
            title: '통신과금 서비스 이용약관',
            link: '/',
        }];

    useEffect(() => {
    }, []);

    const handleButtonClick = async () => {
        try {
            paymentApi.postServiceUseTerm(reqInfo, 'v1').then((res) => {
                console.log('postServiceUseTerm:', res);
                if (res.header.resultCode === '0000') {
                    onAgree();
                    onClose();
                }
            });
        } catch (e) {
            console.log(e);
        }
    }

    const handleTermCheck = (e: any) => {
        const checkedState = e.checkedState;
        const newAgreeList = TERMS.map((_, index) => checkedState[`terms-${index}`]);
        setAgreeList(newAgreeList);
    };

    return (
        <BottomSheet isOpen={isOpen} bottomSpace="2rem" onClose={onClose}>
            <BottomSheet.TopSlot>
                <div
                    style={{
                        marginTop: '8px',
                    }}
                >
                    <Header align='left' variant='heading'>
                        통신과금 서비스 이용약관
                        <Header.RightSlot>
                            <IconButton
                                color='neutral'
                                fill='icon'
                                icon='close_line'
                                noStates
                                onClick={onClose}
                            />
                        </Header.RightSlot>
                    </Header>
                </div>
            </BottomSheet.TopSlot>

            <BottomSheet.CenterSlot>
                <Text align='left' variant='body' weight='bold'>
                    휴대폰결제를 이용하려면 통신과금 서비스 이용약관에<br/>
                    동의해주세요.
                </Text>
                <List
                    id='terms'
                    fill='none'
                    items={agreeList.map((isAgree, index) => ({
                        text: {
                            title: TERMS[index].title,
                        },
                        linkIconOnlyId: 'link-' + index.toString(),
                        // link: TERMS[index].link,
                        checked: isAgree,
                        onClick: () => {
                            console.log('TODO: onClick:', index);
                        }
                    }))}
                    type='checkbox'
                    weight='default'
                    onChange={handleTermCheck}
                />
            </BottomSheet.CenterSlot>

            <BottomSheet.BottomSlot>
                <CTA layout='row'>
                    <Button color='primary' disabled={!buttonActive} onClick={handleButtonClick}>
                        동의하기
                    </Button>
                </CTA>
            </BottomSheet.BottomSlot>
        </BottomSheet>
    );
}

export default BS_PaymentTerm;