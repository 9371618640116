export interface Info {
    cardlevel: string; // 등급 코드
    cardLevelName: string; // 등급 명 (VVIP, VIP, 우수, 일반)
    isVIP: string; // VIP 이상 등급 여부 (VIP 이상: Y)
    longTermCode: string; // 장기고객 대상 코드 (빈값이면 장기고객 아님)
    longTermName: string; // 장기고객 명 (빈값이면 장기고객 아님)
    youthYn: string; // 유쓰 대상자 여부
    youthName: string; // 유쓰 명칭
    totalDiscout: string; // 당해년도 누적할인 금액 (빈값이면 할인받은 금액 없음)
    thisMonthDiscout: string; // 당월 누적할인 금액 (빈값이면 할인받은 금액 없음)
    barcode: string; // 고정 바코드 번호
}

export const initialInfo: Info = {
    // TODO: 값 변경예정. 특히 태그 정보
    cardlevel: '',
    cardLevelName: '',
    isVIP: '',
    longTermCode: '',
    longTermName: '',
    youthYn: '',
    youthName: '',
    totalDiscout: '0',
    thisMonthDiscout: '',
    barcode: '',
};

export interface MembershipBarcode {
    barcode: string;
    createDate: string; // yyyy-MM-dd HH:mm:ss
    currentTime: string;
}

export const initialMembershipBarcode: MembershipBarcode = {
    barcode: '',
    createDate: '',
    currentTime: '',
};

export type TabType = 'membership' | 'payment';

export interface MembershipDiscount {
    discountSetStus: string;
}

export interface PaymentSetting {
    payBlckYn: string; // 일반 휴대폰 결제 차단 여부
    autoPayBlckYn: string; // 월 정기 자동 결제 차단 여부
}

export const initialPaymentSetting: PaymentSetting = {
    payBlckYn: '',
    autoPayBlckYn: '',
};

export interface PaymentBarcode {
    barcode: string;
    expTime: string; // 만료시간 (yyyyMMddHHmmss)
}

export const initialPaymentBarcode: PaymentBarcode = {
    barcode: '',
    expTime: '',
};

export interface PaymentStatus {
    pwdSetStus: string,
    avlAmt: string
}

export const initialPaymentStatus: PaymentStatus = {
    pwdSetStus: '',
    avlAmt: ''
};

export interface RequestPaymentInfo {
    encSeq: string;
    otpId: string;
    passwordSha: string;
}