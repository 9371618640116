// ****************************************************
// [배포] 1.혜택 - 검색 - 진입 (UMembershipSearchEntry)
// [배포] 1.혜택 - 검색 - 진입(최근 검색어 이력없을 때) (UMembershipSearchEntry2)
// ****************************************************
import React, {Component, useState} from 'react'
import { List, Image, TextSet } from "@lguuxe/2024_designsystem_uni";
import chatbotIcon from "../../images/icon/5_full menu/chatbot_s.svg"
import {getItemByKey} from "../../utils/indexedDB";
import {chatbot} from "../../api/externalApi";
import CommonLoginPopup from "../Common/CommonLoginPopup";


const ChatbotItem = () => {
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const handleClickChatbot = async () => {
        const memberId = await getItemByKey('memberId');
        if (!memberId) {
            setShowLoginPopup(true);
            return;
        }
        chatbot();
    }
    return (
        <>
        <List
            items={[
                {
                    onClick: () => handleClickChatbot(),
                    leftSlot: [
                        <>
                            <Image
                                customClass="customClass"
                                ratio="1:1"
                                src={chatbotIcon}
                                width="48"
                            />
                            <TextSet
                                subComponent="Body_1"
                                text={{
                                    title: 'U+ 멤버십 챗봇'
                                }}
                                weight="default"
                            />
                        </>
                    ],
                    linkIconOnlyId: 'empty',
                }
            ]}
            type="view"
            fill="none"
        />
            <CommonLoginPopup isOpen={showLoginPopup} onClose={() => setShowLoginPopup(false)}/>
        </>
    )
}
export default ChatbotItem