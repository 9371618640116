import React from 'react';
import { Text } from "@lguuxe/2024_designsystem_uni";
import CommonNodata from "../Common/CommonNodata";

interface SectionListCustomProps {
    historyList: {
        issueDate: string;
        useAmt: string;
        cancleAmt: string;
        productGroupNm: string;
        slttTransactionNo: string;
    }[];
}

const SectionListCustom: React.FC<SectionListCustomProps> = ({ historyList }) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                padding: "0 20px 12px",
            }}
        >
            {historyList.length === 0 ? (
                <div
                    style={{
                        paddingTop: '30px'
                    }}
                >
                <CommonNodata text={"휴대폰결제 사용 내역이 없어요."} type={"mobilePay"}/>
                </div>
            ) : (
                historyList.map((history, index) => (
                    <div
                        key={index}
                        style={{
                            padding: "12px 0"
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                                padding: "4px 0"
                            }}
                        >
                            <Text
                                style={{ flex: "1" }}
                                typo="font_heading_5Sb"
                                color="color_text_neutral_6"
                            >
                                {history.productGroupNm} {/* 상품 그룹명 */}
                            </Text>
                            <Text typo="font_body_1M" color="color_text_neutral_4">
                                {parseInt(history.useAmt).toLocaleString()}원 {/* 사용 금액 */}
                            </Text>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                            }}
                        >
                            <Text
                                style={{ flex: "1" }}
                                typo="font_body_2M"
                                color="color_text_neutral_4"
                            >
                                {history.issueDate.split(' ')[0]} {/* 발생일시 */}
                            </Text>
                            <Text typo="font_body_2M" color={history.cancleAmt === "0" ? "color_text_neutral_4" : "color_status_error"}>
                                {history.cancleAmt === "0" ? "결제" : `취소: ${parseInt(history.cancleAmt).toLocaleString()}원`} {/* 취소 여부 및 금액 */}
                            </Text>
                        </div>
                    </div>
                ))
            )}
        </div>
    );
}


export default SectionListCustom;
