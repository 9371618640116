import React, { useState } from 'react';
import { Badge, Image, TextSet } from "@lguuxe/2024_designsystem_uni";
import badgeTripOneImg from "../../images/badge/trip_one.svg";
import badgeTripSeveralImg from "../../images/badge/trip_several.svg";
import badgeTripZeroImg from "../../images/badge/trip_zero.svg";
import badgeShoppingOneImg from "../../images/badge/shopping_one.svg";
import badgeShoppingSeveralImg from "../../images/badge/shopping_several.svg";
import badgeShoppingZeroImg from "../../images/badge/shopping_zero.svg";
import badgeStudyOneImg from "../../images/badge/study_one.svg";
import badgeStudySeveralImg from "../../images/badge/study_several.svg";
import badgeStudyZeroImg from "../../images/badge/study_zero.svg";
import badgeBeautyOneImg from "../../images/badge/beauty_one.svg";
import badgeBeautySeveralImg from "../../images/badge/beauty_several.svg";
import badgeBeautyZeroImg from "../../images/badge/beauty_zero.svg";
import badgeContentOneImg from "../../images/badge/content_one.svg";
import badgeContentSeveralImg from "../../images/badge/content_several.svg";
import badgeContentZeroImg from "../../images/badge/content_zero.svg";
import badgeDailyOneImg from "../../images/badge/daily_one.svg";
import badgeDailySeveralImg from "../../images/badge/daily_several.svg";
import badgeDailyZeroImg from "../../images/badge/daily_zero.svg";
import badgeFoodOneImg from "../../images/badge/food_one.svg";
import badgeFoodSeveralImg from "../../images/badge/food_several.svg";
import badgeFoodZeroImg from "../../images/badge/food_zero.svg";// 전체 import 경로는 상황에 맞게 조정하세요
import BS_Badge from './BS_Badge';

interface BadgeComponentProps {
    name: string;
    count: number;
    isNew?: boolean;
}

const imageMap: { [key: string]: { one: string, several: string, zero: string } } = {
    '나들이킹': {
        one: badgeTripOneImg,
        several: badgeTripSeveralImg,
        zero: badgeTripZeroImg
    },
    '쇼핑킹': {
        one: badgeShoppingOneImg,
        several: badgeShoppingSeveralImg,
        zero: badgeShoppingZeroImg
    },
    '스터디킹': {
        one: badgeStudyOneImg,
        several: badgeStudySeveralImg,
        zero: badgeStudyZeroImg
    },
    '뷰티킹': {
        one: badgeBeautyOneImg,
        several: badgeBeautySeveralImg,
        zero: badgeBeautyZeroImg
    },
    '컨텐츠킹': {
        one: badgeContentOneImg,
        several: badgeContentSeveralImg,
        zero: badgeContentZeroImg
    },
    '일상킹': {
        one: badgeDailyOneImg,
        several: badgeDailySeveralImg,
        zero: badgeDailyZeroImg
    },
    '푸드킹': {
        one: badgeFoodOneImg,
        several: badgeFoodSeveralImg,
        zero: badgeFoodZeroImg
    },
};

const BadgeComponent: React.FC<BadgeComponentProps> = ({ name, count, isNew }) => {
    const [showBSBadge, setShowBSBadge] = useState(false);
    const [selectedBadge, setSelectedBadge] = useState('');
    const badgeImageSet = imageMap[name] || imageMap['나들이킹'];
    const badgeImage = count === 0 ? badgeImageSet.zero : count === 1 ? badgeImageSet.one : badgeImageSet.several;

    const handleClick = () => {
        setSelectedBadge(badgeImageSet.one); // 'one' 이미지를 선택하여 전달
        setShowBSBadge(true);
    };

    const handleClose = () => {
        setShowBSBadge(false);
    };

    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
                onClick={handleClick}
            >
                {isNew ? (
                    <Badge text="N" style={{ marginRight: "8px" }}>
                        <Image
                            customClass="customClass"
                            ratio="1:1"
                            src={badgeImage}
                            width="100"
                        />
                    </Badge>
                ) : (
                    <Image
                        customClass="customClass"
                        ratio="1:1"
                        src={badgeImage}
                        width="100"
                    />
                )}
                <TextSet
                    blockSpacing={{
                        bottom: '4px',
                        top: '4px'
                    }}
                    subComponent="Heading_5"
                    text={{
                        title: (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <span>{name}</span>
                                <span style={{ color: "#E6007E" }}>{count}개</span>
                            </div>
                        )
                    }}
                    weight="default"
                />
            </div>
            {showBSBadge && (
                <BS_Badge imageSrc={selectedBadge} name={name} count={count} onClose={handleClose} />
            )}
        </>
    );
};

export default BadgeComponent;
