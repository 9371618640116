import {Box, List} from '@lguuxe/2024_designsystem_uni';
import React from 'react';
import {PartnerDetailData} from "../../pages/Partner/types";
import {useNavigate} from "react-router-dom";
import {sendEvent} from "../../api/bridgeApi";

interface QuickLinkProps {
    partnerInfo: PartnerDetailData;
}

const QuickLink: React.FC<QuickLinkProps> = ({partnerInfo}) => {
    const navigate = useNavigate();

    return (
        <>
            {/* 컴포넌트 수정 필요: 퀵링크 List 또는 Button 컴포넌트 모두 디자인 수정 가능하도록 변경 필요함 */}
            <Box style={{paddingBottom: '40px'}}>
                <Box style={{background: '#F3F5F6', borderRadius: '12px', padding: '0'}}>
                    <List
                        items={[
                            ...(partnerInfo.homepage ? [{
                                onClick: () => {
                                    sendEvent('CALL_WEBVIEW', {
                                        url: partnerInfo.homepage,
                                        title: partnerInfo.ptnName,
                                        showTitlebar: true
                                    })
                                },
                                text: {
                                    title: '제휴사 홈페이지',
                                },
                                linkIconOnlyId: 'placeholder',
                            }] : []),
                            ...(partnerInfo.youthYn === 'Y' ? [{
                                onClick: () => {
                                    sendEvent('CALL_WEBVIEW', {
                                        url: 'https://www.uth.co.kr/',
                                        title: '유쓰',
                                        showTitlebar: true
                                    });
                                },
                                text: {
                                    title: '유쓰 홈페이지',
                                },
                                linkIconOnlyId: 'placeholder',
                            }] : []),
                            ...(partnerInfo.longTermYn === 'Y' ? [{
                                onClick: () => {
                                    console.log('TODO: 장기고객 탭으로 이동');
                                    navigate('/benefit-guide');
                                },
                                text: {
                                    title: '장기고객 혜택',
                                },
                                linkIconOnlyId: 'placeholder',
                            }] : []),
                            ...(partnerInfo.youthYn === 'Y' ? [{
                                onClick: () => {
                                    console.log('TODO: 유쓰 혜택 탭으로 이동');
                                    navigate('/benefit-guide');
                                },
                                text: {
                                    title: '유쓰 혜택',
                                },
                                linkIconOnlyId: 'placeholder',
                            }] : []),
                            ...(partnerInfo.phone ? [{
                                onClick: () => {
                                    window.location.href = `tel:+82${partnerInfo.phone}`;
                                },
                                text: {
                                    title: '전화하기',
                                },
                                linkIconOnlyId: 'placeholder',
                            }] : []),
                            {
                                onClick: () => {
                                    console.log('TODO: 내 주변 매장 찾기');
                                    navigate('/map');
                                },
                                text: {
                                    title: '내 주변 매장 찾기',
                                },
                                linkIconOnlyId: 'placeholder',
                            },
                        ]}
                        type='view'
                        // style={{ marginBottom: 'none' }}
                    />
                </Box>
            </Box>
        </>
    );
};

export default QuickLink;
