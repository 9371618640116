import React from 'react';
import { Header, IconButton, Search } from '@lguuxe/2024_designsystem_uni';
import { _OnChangeEvent } from '@lguuxe/2024_designsystem_uni/dist/esm/types/components/Search/SearchTypes';

interface CommonSearchProps {
    inputValue: string;
    onInputChange: (event: _OnChangeEvent) => void;
    onSearch: (query: string) => void;
    onBackButtonClick: () => void;
    inputRef?: React.RefObject<HTMLInputElement>;  // inputRef를 옵셔널로 추가
}

const CommonSearch: React.FC<CommonSearchProps> = ({ inputValue, onInputChange, onSearch, onBackButtonClick, inputRef }) => {
    return (
        <>
            <Header>
                <Header.LeftSlot>
                    <IconButton
                        color='neutral'
                        fill='icon'
                        icon='chevron_left_line'
                        onClick={onBackButtonClick}
                        noStates
                    />
                </Header.LeftSlot>
                <Header.CenterSlot>
                    <Search
                        ref={inputRef}  // inputRef를 Search 컴포넌트에 전달
                        fill='none'
                        placeholder='멤버쉽, 쿠폰, 매장등을 검색하세요.'
                        onChange={onInputChange}
                        size='medium'
                    />
                </Header.CenterSlot>
                <Header.RightSlot>
                    <IconButton color='neutral' fill='icon' icon='search_line' onClick={() => onSearch(inputValue)} />
                </Header.RightSlot>
            </Header>
        </>
    );
};

export default CommonSearch;
