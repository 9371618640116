// ****************************************************
// [배포] 4.마이 > 내 등급, 혜택 이력 > 내 등급(등급안내) - (UMembershipReceivedBenefit)
// [배포] 4.마이 - 내 등급, 혜택 이력 - 내 등급(등급안내_일반등급) (UMembershipReceivedBenefit2)
// ****************************************************
import React from 'react';
import {Box, Button, CTA, Image, Text} from '@lguuxe/2024_designsystem_uni';
import vipImage from '../../images/icon/1_grade/membership_24=vip.svg';
import vvipImage from '../../images/icon/1_grade/membership_24=vvip.svg';
import excellentImage from '../../images/icon/1_grade/membership_24=excellent.svg';
import generalImage from '../../images/icon/1_grade/membership_24=general.svg';
import {useNavigate} from 'react-router-dom';


interface UserGradeInfoProps {
    cardLevel: string;
    gradeName: string;
    name: string;
}

const UserGradeInfo: React.FC<UserGradeInfoProps> = ({cardLevel, gradeName, name}) => {
    const navigate = useNavigate();

    const getGradeIcon = (cardLevel: string) => {
        switch (cardLevel) {
            case '6':
                return vvipImage;
            case '7':
                return vipImage;
            case '8':
                return excellentImage;
            default:
                return generalImage;
        }
    };

    const handleButtonClick = () => {
        navigate('/benefit-guide');
    };

    return (
        <Box
            style={{
                padding: '24px 0 4px',
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0 20px 32px',
                }}
            >
                <Box style={{display: 'flex', flexDirection: 'column', padding: '0'}}>
                    <Text typo='font_heading_3B'>{name}님은 올해</Text>
                    <Text typo='font_heading_3B'>
                        <Text typo='font_heading_3B' color='color_text_primary'>
                            {gradeName}
                        </Text>
                        &nbsp; 혜택을 받을 수 있어요
                    </Text>
                </Box>
                <Image customClass='customClass' ratio='1:1' src={getGradeIcon(cardLevel)} width='72'/>
            </Box>
            <CTA layout='column'>
                <Button color='neutral' size='medium' onClick={handleButtonClick}>
                    등급 및 혜택 안내
                </Button>
            </CTA>
        </Box>
    );
};

export default UserGradeInfo;
