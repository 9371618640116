import React from 'react'
import {Box, Image, Text, TextSet} from '@lguuxe/2024_designsystem_uni'
import popImg from '../../../images/icon/8_POP/pop_service.svg'

const ContentSection: React.FC = () => {
    return (
        <>
            <Box
                type="2_trbl"
            >
                <TextSet
                    subComponent="Heading_2"
                    text={{
                        description:
                            <>
                                <span style={{paddingTop: "6px"}}>GS25 편의점에서 U+멤버십 앱 '휴대폰결제' 바코드로 결제할 때 추가로 할인받을 수 있는 혜택이에요.</span>

                            </>
                        ,
                        title:
                            <>
                                <div>
                                    <Text typo="font_heading_2B" color="color_text_neutral_6"
                                          style={{display: "block"}}>U+ 멤버십의</Text>
                                    <Text typo="font_heading_2B" color="color_text_neutral_6"><span
                                        style={{color: "#E6007E"}}>POP 서비스</span>란?</Text>
                                </div>
                            </>
                    }}
                    weight="bolder"
                />
            </Box>
            <Box
                type="4_tb"
            >
                <Image
                    customClass="customClass"
                    ratio="1.935:1"
                    src={popImg}
                    width="100%"
                />
            </Box>
        </>
    )
}

export default ContentSection
