import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import CommonSearch from '../../components/Common/CommonSearch';
import CommonHeading from '../../components/Common/CommonHeading';
import RecentSearch from '../../components/Search/RecentSearch';
import RecommandSearch from '../../components/Search/RecommandSearch';
import Section4List from '../../components/Search/ChatbotItem';
import { addItem, getItemByKey, removeItemByKey } from '../../utils/indexedDB';
import { List, TextSet, Text } from '@lguuxe/2024_designsystem_uni';
import { api, ApiEndpoints } from '../../api/api';
import { _OnChangeEvent } from "@lguuxe/2024_designsystem_uni/dist/esm/types/components/Search/SearchTypes";
import CommonNodata from "../../components/Common/CommonNodata";
import CommonDivider from "../../components/Common/CommonDivider";
import useLoading from "../../hooks/useLoading";
interface ListItem {
    leftSlot: React.ReactNode[];
    linkIconOnlyId: string;
    onClick: () => void;
}

const Search: React.FC = () => {
    const [inputValue, setInputValue] = useState<string>('');
    const [showSearchEntry, setShowSearchEntry] = useState<boolean>(true);
    const [showSearchingList, setShowSearchingList] = useState<boolean>(false);
    const [nodata, setNodata] = useState<boolean>(false);
    const [items, setItems] = useState<ListItem[]>([]);
    const [storedSearchQueries, setStoredSearchQueries] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const initializeState = async () => {
            const storedInputValue = await getItemByKey('inputValue');

            if (storedInputValue) {
                setInputValue(storedInputValue);
                setShowSearchEntry(false);
                setShowSearchingList(true);
            } else {
                setShowSearchEntry(true);
                setShowSearchingList(false);
            }

            const recentSearch = await getItemByKey('recentSearch');
            setStoredSearchQueries(recentSearch || []);

            if (inputRef.current) {
                inputRef.current.focus();
            }
        };

        initializeState();
    }, []);

    const addRecent = async (term: string) => {
        let recentSearch = await getItemByKey("recentSearch");

        if (recentSearch) {
            recentSearch = [term, ...recentSearch.filter((t: string) => t !== term)];
        } else {
            recentSearch = [term];
        }

        await addItem("recentSearch", recentSearch);
        setStoredSearchQueries(recentSearch);
    };

    const handleInputChange = (event: _OnChangeEvent) => {
        setNodata(false);
        const value = event.value;
        setInputValue(value);
        setShowSearchEntry(value === '');
        setShowSearchingList(value !== '');

        const filteredItems = storedSearchQueries
            .filter((query) => query.includes(value))
            .map((query) => ({
                onClick: () => handleSearch(query),
                leftSlot: [
                    <TextSet
                        text={{
                            title: (
                                <Text typo='font_body_1B' color='color_text_primary'>
                                    {query}
                                </Text>
                            ),
                        }}
                        subComponent={'Body_2'}
                    />,
                ],
                linkIconOnlyId: "empty"
            }));

        setItems(filteredItems);
    };

    const handleSearch = async (inputValue: string) => {
        setIsLoading(true)
        setShowSearchEntry(false);
        setShowSearchingList(true); // 검색 후에는 검색 결과 화면을 표시
        await addRecent(inputValue);

        await addItem('searchQuery', inputValue);
        try {
            const response = await api.get(ApiEndpoints.SEARCH.INDEX, {
                keyword: inputValue,
            });
            const searchResultList = response.body.searchResultList;
            console.log(searchResultList)
            if (searchResultList.sectionCount === "0") {
                setNodata(true);
            } else {
                navigate('/result-detail', {
                    state: {
                        searchResults: searchResultList
                    }
                });
            }
        } catch (error) {
            console.error('Failed to fetch search results:', error);
        } finally {
            setIsLoading(false)
        }
    };

    const handleBackButtonClick = async () => {
        await removeItemByKey('searchQuery');

        setInputValue('');
        setShowSearchEntry(true);
        setShowSearchingList(false);
        if(showSearchEntry) {
            setTimeout(() => {
                navigate(-1);
            }, 0);
        }
    };

    return (
        <>
            {useLoading(isLoading)}
        <div
            style={{
                width: '100%',
                boxSizing: 'border-box',
                border: '1px solid #f4f4f4',
                height: '100vh',
            }}
        >
            <CommonSearch
                inputValue={inputValue}
                onInputChange={handleInputChange}
                onSearch={handleSearch}
                onBackButtonClick={handleBackButtonClick}
                inputRef={inputRef}
            />
            {showSearchingList ? (
                nodata ? (
                    <>
                    <div
                        style={{
                            padding: '24px 0 24px 0'
                        }}
                    >
                        <CommonNodata type={"search"} text={"검색 결과가 없어요"} />
                    </div>
                        <CommonDivider />
                        <RecommandSearch onSearch={handleSearch} />
                    </>
                ) : (
                    <List
                        fill='none'
                        highlightKeyword={inputValue}
                        highlightColor='#E6007E'
                        items={items}
                        name='list_name'
                        size='medium'
                        type='view'
                    />
                )
            ) : (
                showSearchEntry && (
                    <>
                        <RecentSearch onSearch={handleSearch} />
                        <RecommandSearch onSearch={handleSearch} />
                        <CommonHeading title={'챗봇 검색'} />
                        <Section4List />
                    </>
                )
            )}
            </div>
        </>
    );
};

export default Search;
