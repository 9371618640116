import { openDB } from 'idb';

const DB_NAME = 'GenericDB';
const DB_VERSION = 1;
const STORE_NAME = 'items';

const initDB = async () => {
    return openDB(DB_NAME, DB_VERSION, {
        upgrade(db) {
            if (!db.objectStoreNames.contains(STORE_NAME)) {
                db.createObjectStore(STORE_NAME);
            }
        }
    });
};

export const addItem = async (key: string, value: any) => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    await store.put(value, key);
    await tx.done;
};

export const getItems = async () => {
    const db = await initDB();
    return db.getAll(STORE_NAME);
};

export const getItemByKey = async (key: string) => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readonly');
    const store = tx.objectStore(STORE_NAME);
    const item = await store.get(key);
    await tx.done;
    return item;
};

export const clearItems = async () => {
    const db = await initDB();
    return db.clear(STORE_NAME);
};

export const removeItemByKey = async (key: string) => {
    const db = await initDB();
    const tx = db.transaction(STORE_NAME, 'readwrite');
    const store = tx.objectStore(STORE_NAME);
    await store.delete(key);
    await tx.done;
};

export const clearAllDatabases = async () => {
    const databases = await window.indexedDB.databases();
    if (databases) {
        await Promise.all(databases.map(async (dbInfo) => {
           if (dbInfo.name) {
               if ('serviceWorker' in navigator) {
                   await navigator.serviceWorker.getRegistrations().then((registrations) => {
                       registrations.forEach((registration) => {
                           registration.unregister();
                       });
                   });
               }
                const deleteRequest = window.indexedDB.deleteDatabase(dbInfo.name);
                return new Promise((resolve, reject) => {
                    deleteRequest.onsuccess = () => {
                        console.log(`Database ${dbInfo.name} deleted successfully.`);
                        resolve(true);
                    };
                    deleteRequest.onerror = () => {
                        console.error(`Failed to delete database ${dbInfo.name}.`);
                        reject(false);
                    };
                    deleteRequest.onblocked = () => {
                        console.warn(`Deletion of database ${dbInfo.name} is blocked.`);
                        resolve(false);
                    };
                });
            }
        }));
    }
};
