import React from "react";
import {Box, Image, Text} from "@lguuxe/2024_designsystem_uni";
import MobilePayGuide from "../MobilePayGuide";
import CommonDivider from "../../Common/CommonDivider";
import offline1 from "../../../images/guide/usage/pay/offline1.webp";
import offline2 from "../../../images/guide/usage/pay/offline2.webp";
import offline3 from "../../../images/guide/usage/pay/offline3.webp";
import offline4 from "../../../images/guide/usage/pay/offline4.webp";
import online1 from "../../../images/guide/usage/pay/online1.webp";
import online2 from "../../../images/guide/usage/pay/online2.webp";
import online3 from "../../../images/guide/usage/pay/online3.webp";
import online4 from "../../../images/guide/usage/pay/online4.webp";
import online5 from "../../../images/guide/usage/pay/online5.webp";

const MobilePaymentTab: React.FC = () => {
    return (
        <Box type="1_b">
            <MobilePayGuide
                title="오프라인 매장에서 결제하기"
                steps={[
                    { stepNumber: 1, description: "하단 바코드 아이콘을 선택하고, 휴대폰결제 탭을 선택해 주세요.", imageSrc: offline1 },
                    { stepNumber: 2, description: "비밀번호 또는 지문을 입력해 주세요.", imageSrc: offline2 },
                    { stepNumber: 3, description: "바코드가 나오면 매장 직원에게 보여주고,\n" +
                            "2분 이내에 결제해 주세요. 바코드 아래 ‘U+ 멤버십 할인‘ 체크박스를 선택하면 결제와 동시에 U+멤버십 할인을 받을 수 있어요.", imageSrc: offline3 },
                    { stepNumber: 4, description: "결제가 완료되면 이용 내역을 확인할 수 있어요.결제 요금은 다음 달 통신요금에 포함돼요.", imageSrc: offline4 },
                ]}
            />
            <CommonDivider />
            <MobilePayGuide
                title="온라인 매장에서 결제하기"
                steps={[
                    {
                        stepNumber: 1,
                        description: "결제수단을 ‘휴대폰결제'를 선택하고 인증방식으로 ‘U+ PASS 간편결제'를 선택해 인증을 요청해 주세요. (*이용하는 상점에 따라 화면이 다를 수 있어요.)",
                        imageSrc: online1,
                    },
                    { stepNumber: 2, description: "PASS 인증요청이 진행되며, 사전에 등록한 인증 방법(PIN 또는 생체인증)으로 인증을 진행해주세요.", imageSrc: online2 },
                    { stepNumber: 3, description: "상단의 ◀︎ 버튼을 눌러 이용중인 화면으로 이동해 주세요.", imageSrc: online3 },
                    { stepNumber: 4, description: "결제 창에서 ‘결제' 버튼을 누르면 결제가 완료돼요.", imageSrc: online4 },
                ]}
            />
            <CommonDivider />
            <Box style={{ display: "flex", flexDirection: "column", gap: "12px", padding: "24px 0 12px" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "12px", padding: "0" }}>
                    <Text typo="font_heading_4B" color="color_text_neutral_6" style={{ fontSize: "18px", fontWeight: "800", padding: "0 20px" }}>
                        휴대폰결제 이용 화면 안내
                    </Text>
                    <Text typo="font_detail_2M" color="color_text_neutral_3" style={{ padding: "0 20px" }}>
                        휴대폰결제 바코드에서 사용할 수 있는 금액을 확인할 수 있어요.
                    </Text>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "8px", padding: "0 20px" }}>
                    <ul style={{ padding: "0 0 0 20px", margin: "0", fontSize: "14px", color: "#66707A" }}>
                        <li>사용 가능 금액 영역을 선택하면 휴대폰결제 메뉴로 이동, 오른쪽 상단 ‘설정'을 눌러 사용 가능한 금액을 변경하거나 휴대폰결제 서비스 이용을 차단할 수 있어요.</li>
                        <li>휴대폰결제를 이용할 수 없는 고객은 바코드가 나타나지 않아요.</li>
                    </ul>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "16px", padding: "4px 20px" }}>
                    <Image
                        src={online5}
                        alt="placeholder"
                        width="100%"
                        ratio="4:3"
                    />
                </div>
            </Box>
            {/*<MobilePayGuide*/}
            {/*    title="휴대폰결제 이용 화면 안내"*/}
            {/*    steps={[*/}
            {/*        { stepNumber: 1, description: "휴대폰결제 바코드에서 사용할 수 있는 금액을 확인할 수 있어요.", imageSrc: online5 },*/}
            {/*        { stepNumber: 2, description: "사용 가능 금액 영역을 선택하면 휴대폰결제 메뉴로 이동, 오른쪽 상단 ‘설정'을 눌러 사용 가능한 금액을 변경하거나 휴대폰결제 서비스 이용을 차단할 수 있어요.", imageSrc: ExImg },*/}
            {/*        { stepNumber: 3, description: "휴대폰결제를 이용할 수 없는 고객은 바코드가 나타나지 않아요.", imageSrc: ExImg },*/}
            {/*    ]}*/}
            {/*/>*/}
        </Box>
    );
};

export default MobilePaymentTab;
