// ****************************************************
// [배포] 4.마이 > 내배지 > 선물하기 (UMembershipMyBadgeGift)
// ****************************************************
import React from 'react';
import { Accordion, TextSet, Text } from "@lguuxe/2024_designsystem_uni";

const SectionAccordion2: React.FC = () => {
    return (
        <>
            <Accordion
                divider={false}
                title={
                    <TextSet
                        subComponent="Body_1"
                        text={{
                            title: '유의사항'
                        }}
                    />
                }
                weight="bolder"
            >
                <Accordion.Body>
                    <div>
                        <ul
                            style={{
                                paddingLeft: "26px",
                                margin: "0"
                            }}
                        >
                            <li>
                                <Text typo="font_body_2M" color="color_text_neutral_3">
                                    배지는 U+ 가입 번호로만 선물할 수 있습니다.
                                </Text>
                            </li>
                            <li>
                                <Text typo="font_body_2M" color="color_text_neutral_3">
                                    1인당 선물 가능 횟수는 1일 1회이며, 배지 개수는 여러개를 선택할 수 있습니다.
                                </Text>
                            </li>
                            <li>
                                <Text typo="font_body_2M" color="color_text_neutral_3">
                                    배지 1개당 1일 1회 이동 가능하기에, 다른 사람으로부터 선물받은 배지는 선물 받은 다음 날 부터 선물할 수 있습니다.
                                </Text>
                            </li>
                        </ul>
                    </div>
                </Accordion.Body>
            </Accordion>
        </>
    );
}

export default SectionAccordion2;
