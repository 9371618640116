import React, { Component } from 'react';
import { Header, Text, IconButton, Box, TextSet, Image } from '@lguuxe/2024_designsystem_uni';
import { useNavigate } from 'react-router-dom';

const SectionHeader: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Box style={{ backgroundColor: '#262A3B', padding: '0' }}>
            <Header align='left' style={{ backgroundColor: '#262A3B' }} divider={false}>
                <Header.CenterSlot>
                    <Text typo='font_heading_4Sb' color='color_text_neutral' style={{ color: '#fff' }}>
                        마이
                    </Text>
                </Header.CenterSlot>
                <Header.RightSlot>
                    <IconButton
                        color='neutral'
                        fill='icon'
                        icon='search_line'
                        noStates
                        size='large'
                        onClick={() => {
                            navigate('/search');
                        }}
                    />
                    <IconButton
                        color='neutral'
                        fill='icon'
                        icon='notification_line'
                        size='large'
                        noStates
                        onClick={() => {
                            navigate('/noti');
                        }}
                    />
                </Header.RightSlot>
            </Header>
        </Box>
    );
};

export default SectionHeader;
