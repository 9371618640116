import React, {useEffect, useRef, useState} from 'react';
import '../../css/style.css';
import {BottomSheet, Segment} from '@lguuxe/2024_designsystem_uni';
import {
    initialMembershipBarcode,
    initialPaymentBarcode,
    initialPaymentSetting,
    initialPaymentStatus,
    MembershipBarcode,
    PaymentBarcode,
    PaymentSetting,
    PaymentStatus,
    TabType
} from './types';
import Snackbar from '../Common/Snackbar';
import membershipsServiceApi from "../../api/memberships-service";
import {defaultMembershipInfo, MembershipInfo} from "../../pages/Home/types";
import {getItemByKey} from "../../utils/indexedDB";
import paymentApi from "../../api/payment";
import MembershipBarcodeSection from "./MembershipBarcodeSection";
import PaymentBarcodeSection from "./PaymentBarcodeSection";
import CardInfo from "./CardInfo";
import NoLogin from "./NoLogin";
import CommonPopup from "../Common/CommonPopup";
import BS_PaymentTerm from "./BS_PaymentTerm";

interface UserInfo {
    memberStatus: string;
    userStatus: string;
    payExistYn: string;
}

const initialUserInfo: UserInfo = {
    memberStatus: '',
    userStatus: '',
    payExistYn: '',
};

interface BarcodeProps {
    onClose: () => void;
    initialTab?: TabType;
    isOpen: boolean;
    bottomSpace?: string;
}

const BS_Barcode: React.FC<BarcodeProps> = ({onClose, initialTab, isOpen, bottomSpace}) => {
    const [selectedTab, setSelectedTab] = React.useState<TabType>(initialTab || 'membership');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [cardInfo, setCardInfo] = useState<MembershipInfo>(defaultMembershipInfo);
    const [membershipBarcode, setMembershipBarcode] = useState<MembershipBarcode>(initialMembershipBarcode);
    const [membershipBarcodeFail, setMembershipBarcodeFail] = useState(false);
    const [paymentSetting, setPaymentSetting] = useState<PaymentSetting>(initialPaymentSetting);
    const [paymentStatus, setPaymentStatus] = useState<PaymentStatus>(initialPaymentStatus);
    const [paymentUseTerm, setPaymentUseTerm] = useState('');
    const [paymentBarcode, setPaymentBarcode] = useState<PaymentBarcode>(initialPaymentBarcode);
    const [showRepresentativePopup, setShowRepresentativePopup] = useState(false);
    const hasMounted = useRef(false);
    const [userInfo, setUserInfo] = useState<UserInfo>(initialUserInfo);

    useEffect(() => {
        const fetchInit = async () => {
            setUserInfo({
                memberStatus: await getItemByKey('memberStatus'),
                userStatus: await getItemByKey('userStatus'),
                payExistYn: await getItemByKey('payExistYn')
            });

            if (userInfo.memberStatus === '0' || userInfo.memberStatus === '5') return;
            fetchMembershipBarcode();
            fetchData();
        }
        const fetchData = async () => {
            try {
                membershipsServiceApi.getCardInfo({}, 'v1').then((res) => {
                    console.log('getCardInfo:', res);
                    if (res.header.resultCode === '0000' && res.body) {
                        setCardInfo(res.body);
                    }
                });

                // paymentApi.getSetting('v1'),
                // paymentApi.getStatus('v1'),
            } catch (error) {
                console.error('Failed to fetch :', error);
            }
        }

        if (hasMounted.current && isOpen) {
            fetchInit();
        } else {
            hasMounted.current = true;
        }
    }, [isOpen]);

    const fetchMembershipBarcode = async () => {
        try {
            membershipsServiceApi.getCardBarcode('v1').then((response) => {
                if (response.header.resultCode === '0000' && response.body) {
                    setMembershipBarcode(response.body);
                } else {
                    setMembershipBarcodeFail(true);
                }
            });
        } catch (error) {
            console.log('An unexpected error occurred:', error);
            setMembershipBarcodeFail(true);
        }
    }

    const fetchPaymentBarcode = async () => {
        try {
            const otpRes = await paymentApi.postPayOtp({certType: 20, uicc: ''}, 'v1');
            const otpId = otpRes.body.otpId;
            // const seqRes = await paymentApi.getPaySeq('v1');
            // const seq = seqRes.body.seq;

            paymentApi.postPayOtp({certType: '20', uicc: '10'}, 'v1').then((otpRes) => {
                const otpId = otpRes.body.otpId;
                paymentApi.getCardBarcode({otpId: otpId}, 'v1').then((res) => {
                    setPaymentBarcode(res.body);
                });
            });
        } catch (error) {
            console.log('An unexpected error occurred:', error);
        }
    }

    const fetchPaymentUseTerm = async () => {
        try {
            paymentApi.getServiceUseTerm('v1').then((res) => {
                console.log('getServiceUseTerm:', res);
                if (res.header.resultCode === '0000' && res.body) {
                    setPaymentUseTerm(res.body.useTermYn);
                }
            });
        } catch (error) {
            console.log('An unexpected error occurred:', error);
        }
    }

    const handleShowSnackbar = (message: string) => {
        setSnackbarMessage(message);
        setShowSnackbar(true);
        setTimeout(() => {
            setShowSnackbar(false);
        }, 1500);
    };

    const handleTabChange = (event: any) => {
        const match = event.id.match(/\d+$/);
        console.log('match:', match);

        if (match[0] === '0') {
            setSelectedTab('membership');

        } else if (match[0] === '1') {
            if (userInfo.userStatus === '44' && userInfo.payExistYn !== 'Y') {
                setShowRepresentativePopup(true);
                return;
            }
            setSelectedTab('payment');
            fetchPaymentUseTerm();
        }
    };

    return (
        <div>
            <BottomSheet isOpen={isOpen === undefined ? true : isOpen} hasHandlebar onClose={onClose}
                         bottomSpace={bottomSpace === undefined ? '2rem' : bottomSpace}>
                <BottomSheet.CenterSlot>
                    {userInfo.memberStatus === '0' ? (
                        <NoLogin/>
                    ) : (
                        <>
                            <div
                                style={{
                                    padding: '6px 20px',
                                    marginTop: '8px',
                                }}
                            >
                                {(userInfo.userStatus === '48' || userInfo.userStatus === '45' || userInfo.userStatus === '49' || userInfo.memberStatus === '5') ? (
                                    <></>
                                ) : (

                                    <Segment
                                        id='membershipTab'
                                        buttons={[
                                            {
                                                disabled: false,
                                                label: '멤버십',
                                                selected: selectedTab === 'membership',
                                            },
                                            {
                                                disabled: false,
                                                label: '휴대폰 결제',
                                                selected: selectedTab === 'payment',
                                            },
                                        ]}
                                        onClick={handleTabChange}
                                        size='medium'
                                        status='default'
                                        data-gtm-event-name={'all_click'}
                                        data-gtm-event-category={'클릭'}
                                        data-gtm-event-action={selectedTab === 'membership' ? '바코드|멤버십' : '바코드|휴대폰 결제' + '- 링크 클릭'}
                                        data-gtm-event-label={'컨텐츠 : ' + selectedTab === 'membership' ? '멤버십' : '휴대폰 결제'}
                                        data-gtm-click-url={''}
                                        data-gtm-click-location={selectedTab === 'membership' ? '바코드|멤버십' : '바코드|휴대폰 결제'}
                                        data-gtm-click-direction={'내부'}
                                        data-gtm-click-text={selectedTab === 'membership' ? '멤버십' : '휴대폰 결제'}
                                    />

                                )}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    padding: '12px 20px 28px',
                                    gap: '8px',
                                }}
                            >
                                {selectedTab === 'membership' ? (
                                    <MembershipBarcodeSection membershipBarcode={membershipBarcode}
                                                              barcodeFail={membershipBarcodeFail}
                                                              onFetchBarcode={() => {
                                                                  fetchMembershipBarcode()
                                                              }}
                                                              showSnackbar={handleShowSnackbar}/>
                                ) : (
                                    <PaymentBarcodeSection showSnackbar={handleShowSnackbar}
                                                           blocked={paymentSetting.payBlckYn === 'Y'}/>
                                )}

                                <CardInfo tabType={selectedTab} cardInfo={cardInfo} paymentStatus={paymentStatus}/>
                            </div>
                        </>
                    )}
                </BottomSheet.CenterSlot>
            </BottomSheet>
            {showSnackbar && <Snackbar text={snackbarMessage}/>}
            <CommonPopup title={'법정대리인 동의 안내'}
                         description={'법정대리인 동의 후 이용할 수 있어요.\n가까운 LG유플러스 매장 또는 고객센터,\nPASS 앱에서 동의 후 이용해 주세요.'}
                         submitText={'확인'} onSubmit={() => setShowRepresentativePopup(false)}
                         isOpen={showRepresentativePopup}/>

            <BS_PaymentTerm isOpen={false} onClose={() => {
            }} onAgree={() => {
                fetchPaymentBarcode()
            }} reqInfo={{encSeq: '', otpId: '', passwordSha: ''}}/>
        </div>
    );
};

export default BS_Barcode;
