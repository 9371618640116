import React from 'react';

const CommonDividerVertical: React.FC = () => {
    return (
        <div
            style={{
                height: "8px",
                width: "1px",
                backgroundColor: "#E7EBEE",
                margin: "0 8px",
            }}
        />
    );
};

export default CommonDividerVertical;
