import React, { FC, useEffect, useState } from 'react';
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import CommonDivider from "../../components/Common/CommonDivider";
import SectionBadgeInfo from "../../components/Badge/SectionBadgeInfo";
import SectionTab from "../../components/Badge/SectionTab";
import SectionBadge from "../../components/Badge/SectionBadge";
import SectionSorting from "../../components/Badge/SectionSorting";
import SectionAccordion from "../../components/Badge/SectionArcodion";
import nftApi from "../../api/nft";
import {getItemByKey} from "../../utils/indexedDB";
import CommonPopup from "../../components/Common/CommonPopup";
import {useNavigate} from "react-router-dom";
import {handlePromiseResult} from "../../api/api";
import {initialMyBadge, MyBadgeData} from "./types";

interface BadgeCategory {
    count: string;
    categoryName: string;
}

const MyBadge: FC = () => {
    const [badgeCategories, setBadgeCategories] = useState<BadgeCategory[]>([]);
    const [selectedTab, setSelectedTab] = useState<string>('1'); // 기본값을 '1'로 설정
    const [openPopup, setOpenPopup] = useState(false)
    const [myBadge, setMyBadge] = useState<MyBadgeData>(initialMyBadge);
    const [history, setHistory] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        const fetchData = async () => {
            const currentDate = await getItemByKey("currentDay")
            const [myBadge, history] =
                await Promise.allSettled([
                    nftApi.getMyBadge('v1'),
                    nftApi.getHistory({month: currentDate.slice(0,6)}, 'v1')
                ]);
            setMyBadge(handlePromiseResult(myBadge) || initialMyBadge)
            setHistory(handlePromiseResult(history).nftHistoryList)
            console.log(history)
        }
        fetchData()
    }, []);

    const onClickGift = async () => {
        const response = await nftApi.getCheckSending('v1')
        const isSend = response.body.isSend
        if(isSend === "Y") {
            navigate('/my/badge/gift')
        } else if(isSend === "N") {
            setOpenPopup(true)
        }
    }
    const handleSetFilter = async (filterValue: string) => {
        const response = await nftApi.getHistory({month: filterValue}, 'v1')
        setHistory(response.body.nftHistoryList)
    }
    useEffect(() => {
        const fetchedData = {
            sendableCount: myBadge.sendableCount,
            badgeCount: myBadge.badgeCount,
            category: myBadge.categoryList
        };

        setBadgeCategories(fetchedData.category);
    }, []);

    return (
        <>
            <CommonLeftIconHeader title={"내 배지"} />

            <SectionBadgeInfo myBadge={myBadge} onClickGift={onClickGift}/>

            <div style={{ marginBottom: "8px" }}>
                <CommonDivider />
            </div>

            <SectionTab selectedTab={selectedTab} setSelectedTab={setSelectedTab} />

            {selectedTab === '1' && <SectionBadge badgeCategories={badgeCategories} />}
            {selectedTab === '2' && (
                <>
                    <SectionSorting setFilter={handleSetFilter}/>
                    <SectionAccordion historyList={history}/>
                </>
            )}
            {openPopup && <CommonPopup description={"배지는 하루 한 번 선물할 수 있어요.\n내일 다시 시도해 주세요."} submitText={"확인"} onSubmit={() => setOpenPopup(false)}/>}
        </>
    );
}

export default MyBadge;
