import { useState } from 'react';

const useDevicePlatform = (): string => {
    // 플랫폼을 감지하여 초기 상태를 설정
    const getPlatform = (): string => {
        const userAgent = navigator.userAgent;

        if (/iPad|iPhone|iPod/.test(userAgent) || (/Mac/.test(userAgent) && 'ontouchend' in document)) {
            return 'IOS';
        } else if (/android/i.test(userAgent)) {
            return 'AOS';
        } else {
            return 'IOS'; // 감지할 수 없는 경우
        }
    };

    const [platform] = useState<string>(getPlatform());
    return platform;
};

export default useDevicePlatform;