import React, { Component } from 'react'
import { Text } from "@lguuxe/2024_designsystem_uni";

const CommonFooter = () => {
    return (
        <div className='footer'>
            <Text typo="font_detail_3Sb" color="color_text_neutral_2" textAlign="center">
                Copyright (주) LG 유플러스<br />
                All right reserved
            </Text>
        </div>
    )
}

export default CommonFooter
