// ****************************************************
// [배포] 4.마이 > 내 등급, 혜택 이력 > 내 등급(등급안내) - (UMembershipReceivedBenefit)
// ****************************************************
import React from 'react';
import {Icon, List, Tag, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import {Grade} from '../../pages/My/types';
import {getGradeTag, getLongTermTag, getYouthTag} from '../../utils/tagUtils';
import {useNavigate} from 'react-router-dom';

interface CurrentYearGradeProps {
    grade: Grade;
}

const CurrentYearGrade: React.FC<CurrentYearGradeProps> = ({grade}) => {
    // TODO: api 추가 예정
    const currentYear = new Date().getFullYear();
    const tags: any[] = [];
    const isNormal = grade.cardLevelName == '일반';
    const navigate = useNavigate();

    if (grade.cardLevelName) {
        tags.push(getGradeTag(grade.cardLevelName));
    }

    if (grade.longTermName) {
        tags.push(getLongTermTag(grade.longTermName));
    }

    if (grade.youthYn) {
        tags.push(getYouthTag(grade.youthName));
    }

    return (
        <>
            <List
                fill='none'
                items={[
                    {
                        leftSlot: [
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <TextSet
                                        subComponent='Body_1'
                                        text={{
                                            title: `${currentYear} 등급`,
                                        }}
                                        weight='bolder'
                                    />
                                    <div style={{marginTop: '8px'}}>
                                        <Tag item={tags}/>
                                    </div>
                                </div>
                            </>,
                        ],

                    },
                ]}
                type='view'
            />
            {!(['6', '7', '8'].includes(grade.cardlevel)) && (
                <div
                    style={{
                        alignItems: 'flex-start',
                        alignSelf: 'stretch',
                        backgroundColor: '#F3F5F6',
                        borderRadius: '12px',
                        display: 'flex',
                        gap: '6px',
                        padding: '16px 20px ',
                        border: '1px solid #E7EBEE',
                        boxSizing: 'border-box',
                        margin: '0 20px',
                    }}
                >
                    <Icon color='#7f8a94' name='error_circle_filled' size='24px'/>
                    <Text typo='font_body_2M'>U+모바일 고객이 아닌 분은 일반 혜택을 이용할 수 있어요.</Text>
                </div>
            )}
        </>
    );
};

export default CurrentYearGrade;
