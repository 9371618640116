import React, {useEffect, useState} from 'react';
import {Image, Text} from '@lguuxe/2024_designsystem_uni';

import HomeHeader from '../../components/Home/HomeHeader';
import UserMessage from '../../components/Home/UserMessage';
import EventAll from '../../components/Home/EventAll';
import BannerInfo from '../../components/Home/BannerInfo';
import QuickMenu from '../../components/Home/QuickMenu';
import BenefitInfo from '../../components/Home/BenefitInfo';
import SectionChip from '../../components/Home/SectionChip';
import TextInfo from '../../components/Home/TextInfo';
import CommonCarousel1 from '../../components/Home/CommonCarousel1';
import CommonCarousel2 from '../../components/Home/CommonCarousel2';

import Bugapan from '../../images/icon/11_emoji/emoji_01.svg';
import chevron_right_line from '../../images/main/benefit/chevron_right_line.svg';
import ixi from '../../images/main/by ixi.svg'

import '../../css/style.css';
import benefitApi from '../../api/benefit';
import {Benefit, Event, Movie, Msg, RecentUsedBenefit, SSPResponse, Tag, UptpData} from './types';

import EventInfo from '../../components/Home/EventInfo';
import {useNavigate, useSearchParams} from "react-router-dom";
import NavigationBar from '../../components/Common/NavigationBar';
import {handlePromiseResult} from "../../api/api";
import {extractMonth, getAgeCode, getCurrentYYYYMM, getGenderCode, goMovie} from "../../utils/helper";
import HeadingCard from "../../components/Home/NotLoginCard";
import {addItem, getItemByKey} from "../../utils/indexedDB";
import Tutorial from "../Tutorial/Tutorial";
import membershipsServiceApi from "../../api/memberships-service";
import {CheckVip, initialCheckVip} from "../../components/Home/types";
import {adSSP} from "../../api/adSSP";
import useLoading from "../../hooks/useLoading";
import {moneymeEnter} from "../../api/externalApi";
import CommonLoginPopup from "../../components/Common/CommonLoginPopup";

const HomeBenefit: React.FC = () => {
    //0.미로그인 / 1.14이상 VIP / 2.14이상 우수 / 3.14미만 vip / 4.14미만 우수 / 5.타사
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const [showTutorial, setShowTutorial] = useState<boolean>(false);
    const [prsMsg, setPrsMsg] = useState<Msg[]>([]);
    const [uptp, setUptp] = useState<UptpData>({currentMonth: '', uptpList: []}) // 초기값 설정);
    const [recentUsedBenefit, setRecentUsedBenefit] = useState<RecentUsedBenefit[]>([]);
    const [benefitTag, setBenefitTag] = useState<Tag[]>([]);
    const [movieRanking, setMovieRanking] = useState<Movie[]>([]);
    const [eventTag, setEventTag] = useState<Tag[]>([]);
    const [eventList, setEventList] = useState<Event[]>([]);
    const [benefitList, setBenefitList] = useState<Benefit[]>([]);
    const [userName, setUserName] = useState<string>("");
    const [checkVip, setCheckVip] = useState<CheckVip>(initialCheckVip);
    const [userStatus, setUserStatus] = useState<string>("0");
    const [sspData, setSspData] = useState<SSPResponse>({creatives: [], exist: false});
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    useEffect(() => {
        const checkFirst = async () => {
            if (await getItemByKey('first_loading')) {
                setShowTutorial(true)
            }
            const status = await getItemByKey('memberStatus')
            if (status) {
                setUserStatus(status)
            }
        }

        const fetchData = async () => {
            const YYYYMM = getCurrentYYYYMM()
            const ageCode = await getAgeCode()
            const genderCode = await getGenderCode()
            const name = await getItemByKey("customerName")
            setUserName(name)

            try {
                if (userStatus === "1" || userStatus === "3") {
                    const checkVip = await membershipsServiceApi.getVipBenefitThisMonth('v1');
                    setCheckVip(checkVip.body)
                    console.log('Check API result:', checkVip);
                }

                const [prsMsg, uptp, recentUsedBenefit, benefitTag, movieRanking, eventTag] =
                    await Promise.allSettled([
                        benefitApi.getPrsMsg({}, 'v1'),
                        benefitApi.getUptp({month: YYYYMM}, 'v1'),
                        benefitApi.getRecentUsedBenefit({}, 'v1'),
                        benefitApi.getBenefitTag({}, 'v1'),
                        benefitApi.getMovieRanking({theaterCode: 'CGV'}, 'v1'),
                        benefitApi.getEventTag({}, 'v1'),
                    ]);
                setPrsMsg(handlePromiseResult(prsMsg)?.msgList || []);
                const uptpData = handlePromiseResult(uptp);
                addItem("currentDay", uptpData.currentMonth)
                setUptp(uptpData);
                setRecentUsedBenefit(handlePromiseResult(recentUsedBenefit)?.recentUsedList || []);
                setBenefitTag(handlePromiseResult(benefitTag)?.tagList || []);
                setMovieRanking(handlePromiseResult(movieRanking)?.movieList || []);
                setEventTag(handlePromiseResult(eventTag)?.eventTagList || []);
                const benefitTagId = handlePromiseResult(benefitTag)?.tagList[0]?.tagId || null;
                const eventTagId = handlePromiseResult(eventTag)?.eventTagList[0]?.tagId || null;
                if (benefitTagId) {
                    const benefitTagDetailsResult = await benefitApi.getBenefitTagById(benefitTagId, {}, 'v1');
                    setBenefitList(benefitTagDetailsResult.body.benefitList);
                }
                if (eventTagId) {
                    const eventTagDetailsResult = await benefitApi.getEventTagById(eventTagId, {
                        targetAge: ageCode,
                        targetGender: genderCode
                    }, 'v1');
                    setEventList(eventTagDetailsResult.body.eventList);
                }
            } catch (error) {
                console.error('An unexpected error occurred:', error);
            } finally {
                setIsLoading(false)
            }
        };
        checkFirst()
        fetchData();
        // brazeEventApi.homemain_enter();
        // if (searchParams.get('state') === 'first') brazeAttributeApi.firstCollection();
    }, []);

    const handleBenefitChipClick = async (tagId: string) => {
        const benefitTagDetailsResult = await benefitApi.getBenefitTagById(tagId, {}, 'v1');
        setBenefitList(benefitTagDetailsResult.body.benefitList);
    }

    const handleEventChipClick = async (tagId: string) => {
        const ageCode = await getAgeCode()
        const genderCode = await getGenderCode()
        const eventTagDetailsResult = await benefitApi.getEventTagById(tagId, {
            targetAge: ageCode,
            targetGender: genderCode
        }, 'v1');
        setEventList(eventTagDetailsResult.body.eventList);
    }

    useEffect(() => {
        if (userStatus === "1" || userStatus === "2" || userStatus === "5") {
            console.log("call Add!!");
            const callAdd = async () => {
                try {
                    setSspData(await adSSP('식별'));
                } catch (e) {
                    console.log(e);
                }
            };
            callAdd();
        }
    }, [userStatus]);

    const handleUPTP = () => {
        if (userStatus === "0") {
            setShowLoginPopup(true)
        } else {
            navigate('/UPTP');
        }
    };

    //TODO 머니미
    const handleMoneyMe = async () => {
        if (userStatus === "0") {
            setShowLoginPopup(true)
        } else {
            await moneymeEnter()
        }
    };

    const handleCloseTutorial = async () => {
        await addItem('first_loading', false)
        setShowTutorial(false);
    };

    return (
        <>
            {useLoading(isLoading)}
            {showTutorial ? <Tutorial onClose={handleCloseTutorial}/> :
                <div style={styles.container} className='bg_gra'>
                    <HomeHeader/>

                    {/*--------------------------------개인화 메세지-------------------------------------*/}

                    {(userStatus !== "0") &&
                        <>
                            <div style={styles.sectionHeader}>
                                <Text typo='font_heading_4B' color='color_text_natural_6' style={{color: '#181A1B'}}
                                      data-gtm-event-name={'all_click'}
                                      data-gtm-event-category={'클릭'}
                                      data-gtm-event-action={'프로필 블록 - 링크 클릭'}
                                      data-gtm-event-label={'컨텐츠 : ***님을 위한 맞춤 메시지'}
                                      data-gtm-click-url={''}
                                      data-gtm-click-location={'프로필 블록'}
                                      data-gtm-click-direction={'내부'}
                                      data-gtm-click-text={'***님을 위한 맞춤 메시지'}>
                                    {userName}님을 위한 맞춤 메시지
                                </Text>
                                <Image src={Bugapan} alt='placeholder' width='22' ratio='1:1'/>
                            </div>
                        </>
                    }
                    <UserMessage prsMsg={prsMsg} userStatus={userStatus} checkVip={checkVip}/>

                    {/*--------------------------------유플투쁠------------------------------------*/}
                    {uptp?.uptpList?.length > 0 &&
                        <>
                            <div style={styles.sectionSubHeader}>
                                <Text typo='font_heading_4B' color='color_text_natural_6' style={{color: '#181A1B'}}>
                                    {extractMonth(uptp.currentMonth)}월의 유플투쁠 혜택
                                </Text>
                                <Image src={chevron_right_line} onClick={handleUPTP} alt='placeholder' width='10'
                                       ratio='1:2'/>
                            </div>
                            <EventAll uptp={uptp || {}} userStatus={userStatus}/>
                        </>
                    }

                    {/*--------------------------------퀵메뉴----------------------------------------*/}
                    <div style={styles.sectionBody}>
                        {(userStatus !== "0") &&
                            <QuickMenu userStatus={userStatus}/>
                        }
                        {/*--------------------------------머니ME 배너------------------------------------*/}
                        {(userStatus !== "2" && userStatus !== "3") &&
                            <BannerInfo onClick={handleMoneyMe}/>
                        }
                    </div>

                    {/*--------------------------------AI 추천 혜택------------------------------------*/}
                    {(userStatus !== "3" && userStatus !== "4") &&
                        <>
                            <div style={styles.sectionSubHeader}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                    <Text typo='font_heading_4B' color='color_text_natural_6'
                                          style={{color: '#181A1B'}}>
                                        AI 추천 혜택
                                    </Text>
                                    <div style={{
                                        paddingLeft: "5px"
                                    }}>
                                        <Image src={ixi} width={40} height={23}/>
                                    </div>
                                </div>
                            </div>
                            {userStatus !== "0" ? (
                                <div style={styles.sectionBody}>
                                    <SectionChip Tags={benefitTag} handleChipClick={handleBenefitChipClick}/>
                                    <BenefitInfo benefits={benefitList}/>
                                </div>
                            ) : (
                                <div style={styles.sectionBody}>
                                    <HeadingCard/>
                                </div>
                            )
                            }
                        </>
                    }
                    {/*--------------------------------최근 사용 혜택------------------------------------*/}
                    {(userStatus !== "0" && userStatus !== "5") &&
                        <>
                            <div style={styles.sectionSubHeader}>
                                <Text typo='font_heading_4B' color='color_text_natural_6' style={{color: '#181A1B'}}>
                                    최근 사용한 혜택
                                </Text>
                            </div>

                            <div style={styles.sectionBody}>
                                <CommonCarousel1 benefits={recentUsedBenefit}/>
                            </div>
                            {/*--------------------------------영화 예매------------------------------------*/}
                            <div style={styles.sectionSubHeader}>
                                <Text typo='font_heading_4B' color='color_text_natural_6' style={{color: '#181A1B'}}>
                                    VIP콕 무료로 영화 예매하세요!
                                </Text>
                                <Image src={chevron_right_line} onClick={async () => await goMovie("CGV")}
                                       alt='placeholder' width='10' ratio='1:2'/>
                            </div>
                            <CommonCarousel2 movies={movieRanking}/>
                        </>
                    }
                    {/*--------------------------------이벤트 추천------------------------------------*/}
                    {(userStatus !== "0" && userStatus !== "3" && userStatus !== "4") &&
                        <>
                            <div style={styles.sectionSubHeader}>
                                <Text typo='font_heading_4B' color='color_text_natural_6' style={{color: '#181A1B'}}>
                                    지금, 이벤트에 참여하세요
                                </Text>
                            </div>

                            <div style={styles.sectionBody}>
                                <SectionChip Tags={eventTag} handleChipClick={handleEventChipClick}/>
                                <EventInfo events={eventList} sspData={sspData}/>
                            </div>
                        </>
                    }
                    <div style={{
                        padding: '20px 20px 24px',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px'
                    }}>
                        <TextInfo/>
                    </div>
                    <NavigationBar/>
                    <CommonLoginPopup isOpen={showLoginPopup} onClose={() => setShowLoginPopup(false)}/>
                </div>
            }

        </>
    );
};

// 공통 스타일 객체 정의
const styles = {
    container: {
        width: '100%',
        height: '100%',
        padding: '0 0 70px 0',
        background: 'linear-gradient(-180deg, #ffffff 5px, #F3F5F6, #F3F5F6, #F3F5F6, #F3F5F6)',
    },
    sectionHeader: {
        display: 'flex',
        flexDirection: 'row' as 'row',
        gap: '8px',
        padding: '12px 20px',
    },
    sectionSubHeader: {
        padding: '24px 20px 0 20px',
        display: 'flex',
        flexDirection: 'row' as 'row',
        gap: '8px',
        justifyContent: 'space-between',
    },
    sectionBody: {
        padding: '20px 20px',
        display: 'flex',
        flexDirection: 'column' as 'column',
        gap: '12px',
    }
};

export default HomeBenefit;
