import React, { useEffect, useState } from 'react';
import CommonLeftIconHeader from "../../../components/Common/CommonLeftIconHeader";
import { termsApi } from "../../../api/settingAndTerms";
import { Agreement } from "../../Agreements/types";
import {Badge, IconButton, List, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import AgreementsDetail from "../../Agreements/AgreementsDetail";

const UseTerms = () => {
    const [agreementList, setAgreementList] = useState<Agreement[]>([]);
    const [selectedTermId, setSelectedTermId] = useState<string | null>(null);
    const [isDetailOpen, setIsDetailOpen] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            const response = await termsApi.getAgreements({}, 'v1');
            setAgreementList(response.body.termsList);
            console.log(response.body)
        };
        fetchData();
    }, []);

    const handleTermClick = (termId: string) => {
        setSelectedTermId(termId);
        setIsDetailOpen(true);  // Bottom Sheet 열기
    };

    return (
        <>
        {!isDetailOpen &&
            <><CommonLeftIconHeader title={'이용 약관'}/>
                <div style={{paddingBottom: "12px"}}>
                    <List
                        fill="none"
                        name="list_name"
                        size="medium"
                        type="view"
                        weight="default"
                        items={agreementList.map(item => ({
                            id: item.termId,
                            leftSlot: [
                                <TextSet
                                    subComponent="Body_1"
                                    text={{
                                        title: <>
                                            <Text typo="font_body_1Sb">{item.termName}</Text>
                                        </>,
                                    }}
                                    weight="bolder"/>
                            ],
                            linkIconOnlyId: "empty",
                            onClick: () => handleTermClick(item.termId)
                        }))}/>
                </div>
            </>}

            {isDetailOpen && <AgreementsDetail
                selected={agreementList.find(term => term.termId === selectedTermId)?.selected || false}
                type={'exist'}
                tempTermTitle={agreementList.find(term => term.termId === selectedTermId)?.termName}
                termId={selectedTermId || ""}
                handleClose={() => setIsDetailOpen(false)}
            />}
        </>

    );
};

export default UseTerms;