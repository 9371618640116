import React, { FC } from 'react';
import { Box, TextSet, List, Button } from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";
import {sendEventReturn} from "../../api/bridgeApi";
import {callCheckPassword} from "../../utils/helper";

interface AppInfoListProps {
    currentVersion: string;
    latestVersion: string;
}

const AppInfoList: FC<AppInfoListProps> = ({ currentVersion, latestVersion }) => {
    const navigate = useNavigate();

    const handleModifyInfo = () => {
        // callCheckPassword("modifyInfo")
        navigate('/setting/modify-user-info');
    }

    const handleAppVersion = () => {
        navigate('/setting/app-version');
    }

    const handleLicense = () => {
        navigate('/setting/license');
    }

    const isLatestVersion = currentVersion === latestVersion;

    return (
        <div>
            <Box type="2_trbl">
                <TextSet
                    subComponent="Heading_4"
                    text={{
                        title: '기타',
                    }}
                    weight="default"
                />
            </Box>
            <List
                fill="none"
                items={[
                    {
                        onClick: handleModifyInfo,
                        linkIconOnlyId: "id",
                        text: {
                            title: '나의 정보 수정하기',
                        },
                    },
                    {
                        onClick: handleAppVersion,
                        leftSlot: (
                            <TextSet
                                subComponent="Body_1"
                                text={{
                                    title: '앱 버전',
                                    description: isLatestVersion ? '최신 버전이에요' : '',
                                }}
                                weight="bolder"
                            />
                        ),
                        rightSlot: (
                            <Button
                                as="button"
                                color="neutral"
                                fill="text"
                                size="medium"
                                state="default"
                                rightIcon="chevron_right_small_line"
                            >
                                v {currentVersion}
                            </Button>
                        ),
                    },
                    {
                        onClick: handleLicense,
                        linkIconOnlyId: "id",
                        text: {
                            title: '오픈소스 라이센스',
                        },
                    },
                ]}
                type="view"
                weight="bolder"
            />
        </div>
    );
};

export default AppInfoList;
