import React, { useEffect, useState } from 'react';
import CommonDivider from "../../components/Common/CommonDivider";
import SectionHeader from "../../components/MobilePayment/SectionHeader";
import SectionPaymentInfo from "../../components/MobilePayment/SectionPaymentInfo";
import SectionListCustom from "../../components/MobilePayment/SectionListCustom";
import BS_List from "../../components/Common/BS_List";
import { Button, Text } from "@lguuxe/2024_designsystem_uni";
import paymentApi from "../../api/payment";
import { handlePromiseResult } from "../../api/api";
import { generateLast12Months } from "../../utils/helper";
import useLoading from "../../hooks/useLoading";

const MobilePayment: React.FC = () => {
    const [setting, setSetting] = useState<any>(null);
    const [limit, setLimit] = useState<any>(null);
    const [historyList, setHistoryList] = useState<any[]>([]);
    const [isMonthBsOpen, setIsMonthBsOpen] = useState<boolean>(false);
    const [dataList, setDataList] = useState<{ id: string, label: string }[]>([]);
    const today = new Date();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [year, setYear] = useState<number>(today.getFullYear());
    const [month, setMonth] = useState<number>(today.getMonth() + 1);

    // YYYYMM 형식으로 변환하는 함수
    const getFormattedDate = (year: number, month: number) => {
        const formattedMonth = month < 10 ? `0${month}` : month.toString();
        return `${year}${formattedMonth}`;
    };

    const handleSelectedMonth = async (id: string) => {
        // dataList에서 id 값이 일치하는 항목을 찾음
        const selectedData = dataList.find(item => item.id === id);
        console.log(selectedData)
        if (selectedData) {
            // label에서 연도와 월을 추출
            const match = selectedData.label.match(/(\d{4})년 (\d{1,2})월/);

            if (match) {
                const selectedYear = parseInt(match[1], 10);
                const selectedMonth = parseInt(match[2], 10);

                // 선택된 연도와 월 업데이트
                setYear(selectedYear);
                setMonth(selectedMonth);

                // 새로운 연도와 월로 데이터 가져오기
                try {
                    const historyResponse = await paymentApi.getUseHistory({
                        date: getFormattedDate(selectedYear, selectedMonth)
                    }, 'v1');

                    // 가져온 데이터로 historyList 업데이트
                    setHistoryList(historyResponse.body.useHistoryList);
                    console.log(historyResponse.body.useHistoryList);
                } catch (error) {
                    console.error('Error fetching use history:', error);
                }
            }
        }
    };

    useEffect(() => {
        setDataList(generateLast12Months(year, month));
        fetchData();
    }, [year, month]);

    const fetchData = async () => {
        try {
            const [setting, limit, history] = await Promise.allSettled([
                paymentApi.getSetting('v1'),
                paymentApi.getLimit({}, 'v1'),
                paymentApi.getUseHistory({ date: getFormattedDate(year, month) }, 'v1'),
            ]);

            setSetting(handlePromiseResult(setting) || null);
            setLimit(handlePromiseResult(limit) || null);
            setHistoryList(handlePromiseResult(history)?.useHistoryList || []);
        } catch (e) {
            console.log(e)
        } finally {
            setIsLoading(false)
        }

    };

    return (
        <>
            {useLoading(isLoading)}
            <SectionHeader />
            <SectionPaymentInfo setting={setting} limit={limit} />
            <CommonDivider />

            <div
                style={{
                    padding: "12px 20px 0",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                <Text typo="font_heading_5B" color="color_text_neutral_6">사용내역</Text>
                <Button
                    color="neutral"
                    rightIcon="chevron_down_small_line"
                    fill="text"
                    onClick={() => setIsMonthBsOpen(true)}
                >
                    <Text typo="font_heading_5Sb" color="color_text_neutral_4">{year}년 {month}월</Text>
                </Button>
            </div>

            <SectionListCustom historyList={historyList} />

            {isMonthBsOpen && (
                <BS_List
                    title='월 선택'
                    dataList={dataList}
                    onClose={() => setIsMonthBsOpen(false)}
                    onSelected={handleSelectedMonth}
                />
            )}
        </>
    );
};

export default MobilePayment;
