import React from 'react';
import CommonLeftIconHeader from "../../../components/Common/CommonLeftIconHeader";
import {Image} from "@lguuxe/2024_designsystem_uni";
import rewardAskImg from '../../../images/img/reward/rewardAsk.svg';

const RewardAsk: React.FC = () => {
    return (
        <>
            <CommonLeftIconHeader title={'리워드 적립 및 사용 문의'}/>
            <Image
                src={rewardAskImg}
                alt={'리워드 적립 및 사용 문의 이미지'}
                srcFallback={rewardAskImg}
                ratio={'1:11.475'}
            />
        </>
    );
}

export default RewardAsk;