import React, {useState} from 'react';
import { CTA, Button, Popup, Box, TextSet, Checkbox, Image } from '@lguuxe/2024_designsystem_uni';
import ExImg from "../../images/default/default thumbnail.svg";


interface CommonPopupWithImageProps {
    onSubmit: () => void;
    onClose?: () => void;
    submitText: string;
    cancelText?: string;
    showCheckbox?: boolean;
    imageUrl: string;
    title?: string;
    description?: string;
    onImageClick?: () => void;
    onCheckbox?: () => void;
    isOpen?: boolean;
}

const CommonPopupWithImage: React.FC<CommonPopupWithImageProps> = ({
    onClose,
    onSubmit,
    submitText,
    cancelText,
    showCheckbox = false,
    imageUrl,
    title,
    description,
    onImageClick,
    onCheckbox,
    isOpen,
}) => {
    const [isChecked, setIsChecked] = useState(false)
    const hasText = title || description;
    const handleSubmitButton = () => {
        if(isChecked) {
            if (onCheckbox) {
                onCheckbox()
            }
        }
        if (onSubmit) {
            onSubmit()
        }
    }
    const handleCheckBox = () => {
        setIsChecked(prevState => !prevState)
    }
    return (
        <Popup isOpen={isOpen === undefined ? true : isOpen}>
            <Popup.CenterSlot>
                <Box
                    style={{
                        padding: '0',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'relative',
                    }}
                >
                    {hasText ? (
                        <>
                            <TextSet
                                subComponent='Heading_3'
                                text={{
                                    title: title,
                                }}
                                weight='bolder'
                            />
                            <TextSet
                                subComponent='Body_1'
                                text={{
                                    title: (
                                        <>
                                            <span style={{paddingTop: '12px', textAlign: 'center'}}>
                                                {description?.split('\\n').map((line, index) => (
                                                    <>
                                                        {line}
                                                        <br/>
                                                    </>
                                                ))}
                                            </span>
                                        </>
                                    ),
                                }}
                                weight='default'
                            />
                            <div style={{ marginTop: '20px' }}>
                                <Image ratio='1:1' srcFallback={ExImg} src={imageUrl} width='280' onClick={onImageClick}/>

                            </div>
                        </>
                    ) : (
                        <div
                            style={{
                                display: 'flex',
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                minWidth: '320px',
                                minHeight: '380px',
                                justifyContent: 'center',
                            }}
                        >
                            <Image src={imageUrl} srcFallback={ExImg} alt='popup image' width='320' ratio='1:1' onClick={onImageClick}/>
                        </div>
                    )}
                </Box>
            </Popup.CenterSlot>
            <Popup.BottomSlot>
                {showCheckbox && (
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '0' }}>
                        <Checkbox
                            label='다시보지 않기'
                            size='medium'
                            width='auto'
                            onChange={handleCheckBox}/>
                    </Box>
                )}
                <CTA gradation layout='row'>
                    {cancelText && (
                        <Button color='neutral' size='large' onClick={onClose}>
                            {cancelText}
                        </Button>
                    )}
                    <Button color='primary' size='large' onClick={handleSubmitButton}>
                        {submitText}
                    </Button>
                </CTA>
            </Popup.BottomSlot>
        </Popup>
    );
};

export default CommonPopupWithImage;
