import {Button, Image, List, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import React, {CSSProperties} from 'react';
import defaultThumbnail from '../../images/default/default thumbnail.svg';
import {useNavigate} from 'react-router-dom';
import {PartnerItem} from "../../pages/Partner/types";
import {CLICK_LOCATION, CLICK_TEXT, EVENT_ACTION} from "../../utils/const";

interface PartnerSliderProps {
    partnerList: PartnerItem[];
    // isMoreButton?: boolean;
}

const PartnerCarousel: React.FC<PartnerSliderProps> = ({partnerList}) => {
    const navigate = useNavigate();

    const handlePartnerClick = (partnerId: string) => {
        console.log('partnerId:', partnerId);
        navigate(`/partner/${partnerId}`);
    }

    const handleMore = () => {
        navigate('/my/partners');
    };

    const renderNoDataMessage = () => (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '24px 0',
        }}>
            <Text typo='font_detail_1M' color='color_text_neutral_3' textAlign='center'>
                관심있는 제휴처 하트 누르고 <br/> 좋아하는 제휴처에 등록해 보세요!
            </Text>
        </div>
    );
    const renderDataList = () => (
        <List
            style={{
                overflow: 'auto',
                display: 'flex',
                flexDirection: 'row',
            }}
            fill='none'
            name='list_name'
            size='large'
            type='view'
            weight='default'
            isSlotClickable={true}
            items={[
                {
                    leftSlot: [
                        <div
                            style={{
                                overflow: 'auto',
                                display: 'flex',
                                flexDirection: 'row',
                                gap: '8px',
                            }}
                        >

                            {partnerList.slice(0, 15).map((item, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        gap: '6px',
                                        minWidth: '104px',
                                    }}
                                    onClick={() => handlePartnerClick(item.ptnId)}
                                >
                                    <Image
                                        src={item.ptnLogoImgUrl}
                                        alt='파트너 로고'
                                        width='56'
                                        ratio='1:1'
                                        rounded={54.86}
                                        srcFallback={defaultThumbnail}

                                    />
                                    <div
                                        style={{
                                            gap: '2px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Text typo='font_body_1B' color='color_text_neutral_6'>
                                            {item.ptnName}
                                        </Text>
                                        <Text typo='font_body_3M' color='color_text_neutral_3'>
                                            {item.benefitSummary}
                                        </Text>
                                    </div>
                                </div>
                            ))}
                        </div>,
                    ],
                },
            ]}
        />
    );
    const renderMoreButton = () => (
        <div style={styles.buttonContainer}>
            <Button
                color='neutral'
                rightIcon='chevron_right_small_line'
                size='large'
                fill='text'
                style={styles.button}
                onClick={handleMore}
                data-gtm-event-name={'all_click'}
                data-gtm-event-category={'클릭'}
                data-gtm-event-action={`${CLICK_LOCATION.FAVORITE_PARTNER} - ${EVENT_ACTION.LINK_CLICK}`}
                data-gtm-event-label={`컨텐츠 : ${CLICK_TEXT.FAVORITE_PARTNER_MORE}`}
                data-gtm-click-url={''}
                data-gtm-click-location={`${CLICK_LOCATION.FAVORITE_PARTNER}`}
                data-gtm-click-direction={'내부'}
                data-gtm-click-text={`${CLICK_TEXT.FAVORITE_PARTNER_MORE}`}
            >
                더보기
            </Button>
        </div>
    );

    return (
        <div>
            <TextSet
                subComponent='Heading_4'
                text={{
                    title: (
                        <>
                            <Text typo='font_heading_4B'>
                                좋아하는 제휴사
                                <Text typo='font_heading_4B' color='color_text_primary'>
                                    &nbsp;{partnerList.length}
                                </Text>
                            </Text>
                        </>
                    ),
                }}
                blockSpacing={{top: '24px'}}
                isIndent={true}
            />

            {partnerList.length === 0 ? renderNoDataMessage() : renderDataList()}
            {/*{partnerList.length === 0 ? null : renderMoreButton()}*/}
            {renderMoreButton()}
        </div>
    );
};

const styles: { [key: string]: CSSProperties } = {
    buttonContainer: {
        textAlign: 'center',
        borderTop: '1px solid #E7EBEE',
        marginTop: '12px',
    },
    button: {
        padding: '14px 0',
        width: '100%',
    },
};

export default PartnerCarousel;
