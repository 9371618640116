import React, {useState} from "react";
import {IconButton, Text} from "@lguuxe/2024_designsystem_uni";
import {formatBarcode} from "../../Barcode/utils";
import Snackbar from "../../Common/Snackbar";
import Barcode from "react-barcode";

interface BarcodeSectionProps {
    barcodeNumber: string;
}

const BarcodeSection: React.FC<BarcodeSectionProps> = ({barcodeNumber}) => {
    const [showSnackbar, setShowSnackbar] = useState(false);
    const handleCopy = () => {
        navigator.clipboard
            .writeText(barcodeNumber)
            .then(() => {
                setShowSnackbar(true);
                setTimeout(() => {
                    setShowSnackbar(false);
                }, 1000);
            })
            .catch((err) => {
                console.error('복사 실패', err);
            });
    };

    return (

        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "0 20px",
                gap: "8px"
            }}
        >
            <div
                style={{
                    borderRadius: "12px",
                    width: "100%",
                    boxSizing: "border-box",
                    background: "#F3F5F6"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "20px"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            minWidth: "280px",
                            height: "56px",
                            boxSizing: "border-box",
                        }}
                    >
                        <Barcode
                            value={barcodeNumber}
                            displayValue={false}
                            height={56}
                            width={2.4}
                            margin={0}
                        />
                    </div>
                    <div
                        style={{
                            display: "flex",
                            marginTop: "12px",
                            justifyContent: "center"
                        }}
                    >
                        <div style={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                            {formatBarcode(barcodeNumber).map((chunk, index) => (
                                <Text key={index} typo='font_body_2M' color='color_text_neutral_4'>
                                    {chunk}
                                </Text>
                            ))}
                            <IconButton
                                icon='copy_line'
                                size='small'
                                fill='icon'
                                onClick={handleCopy}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {showSnackbar && <Snackbar text='바코드 번호가 복사되었습니다.'/>}
        </div>
    );
};

export default BarcodeSection;
