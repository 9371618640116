import React, {useEffect, useState} from 'react';
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import CommonDivider from "../../components/Common/CommonDivider";
import SectionPaymentInfo3 from "../../components/MobilePayment/SectionPaymentInfo3";
import SectionHeadingListToggle from "../../components/MobilePayment/SectionHeadingListToggle";
import SectionHeadingMembershipDiscount from "../../components/MobilePayment/SectionMembershipDiscount";
import SectionHeadingList from "../../components/MobilePayment/SectionHeadingList";
import paymentApi from "../../api/payment";
import {handlePromiseResult} from "../../api/api";
import {getItemByKey} from "../../utils/indexedDB";
import useLoading from "../../hooks/useLoading";

const MobilePaymentSetting: React.FC = () => {
    const [setting, setSetting] = useState<any>(null);
    const [limit, setLimit] = useState<any>(null);
    const [userStatus, setUserStatus] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const [setting, limit] = await Promise.allSettled([
                paymentApi.getSetting('v1'),
                paymentApi.getLimit({}, 'v1'),
            ]);
            setSetting(handlePromiseResult(setting) || null);
            setLimit(handlePromiseResult(limit) || null);
            setUserStatus(await getItemByKey("userStatus"));
        } catch (error) {
            console.log("Error fetchData:", error);
        } finally {
            setIsLoading(false); // 데이터가 성공적으로 가져오거나 오류가 발생한 후 로딩 상태 변경
        }
    };

    return (
        <>
            {useLoading(isLoading)}
            <CommonLeftIconHeader title={"휴대폰 결제 설정"}/>

            <SectionPaymentInfo3 limit={limit}/>

            <CommonDivider />

            <SectionHeadingListToggle setting={setting} userStatus={userStatus}/>

            <CommonDivider />

            <SectionHeadingMembershipDiscount />

            <CommonDivider />

            <SectionHeadingList />
        </>
    );
}

export default MobilePaymentSetting;
