// ****************************************************
// [배포] 4.마이 - 내 등급, 혜택 이력 - 내 이력()
// ****************************************************
import React, {useEffect, useState} from 'react';
import CommonLeftIconHeader from '../../components/Common/CommonLeftIconHeader';
import CommonDivider from '../../components/Common/CommonDivider';
import BenefitGraphText from '../../components/My/BenefitGraphText';
import BenefitUseList from '../../components/My/BenefitUseList';
import BenefitGraph from '../../components/My/BenefitGraph';
import membershipsServiceApi from '../../api/memberships-service';
import {BenefitSummery, initialBenefitSummary, initialMonthUseHistory, MonthUseHistory} from './types';
import useLoading from '../../hooks/useLoading';
import {getItemByKey} from "../../utils/indexedDB";
import commonApi from "../../api/common";
import {toDate} from "../../utils/dateUtiles";


const MyBenefit = () => {
    const [benefitSummary, setBenefitSummary] = useState<BenefitSummery>(initialBenefitSummary);
    const [today, setToday] = useState<Date>(new Date());
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [birthday, setBirthday] = useState<string>('');
    const [monthUseHistory, setMonthUseHistory] = useState<MonthUseHistory>(initialMonthUseHistory);

    useEffect(() => {
        const fetchData = async () => {
            const birthday = await getItemByKey('birthday');
            setBirthday(birthday);
            try {
                await membershipsServiceApi.getVipBenefitSummary({userBirth: birthday}, 'v1').then(res => {
                    if (res.header.resultCode === '0000') {
                        setBenefitSummary(res.body);
                    }
                })
                await commonApi.getToday('v1').then(res => {
                    if (res.header.resultCode === '0000') {
                        setToday(toDate(res.body.today));
                    }
                });
            } catch (error) {
                console.log('Failed to fetch :', error);
            } finally {
                setIsLoading(false);
            }
        }

        fetchData();
    }, []);

    const fetchUseHistory = async (yearMonth: string) => {
        try {
            membershipsServiceApi.getUseHistory(yearMonth, 'v1').then(res => {
                if (res.header.resultCode === '0000') {
                    setMonthUseHistory(res.body);
                }
            });
        } catch (error) {
            console.log('Failed to fetch :', error);
        }
    }

    return (
        <>
            {useLoading(isLoading)}
            <CommonLeftIconHeader title='내가 받은 혜택'/>

            <BenefitGraph benefitSummary={benefitSummary} today={today} onFetchData={fetchUseHistory}/>

            <BenefitGraphText benefitSummary={benefitSummary}/>

            <div style={{marginBottom: '8px'}}>
                <CommonDivider/>
            </div>
            <BenefitUseList monthUseHistory={monthUseHistory}/>
        </>
    );
};

export default MyBenefit;
