// ****************************************************
// [배포] 4.마이 > 내 쿠폰 > VIP콕 상세 > 사용가능
// ****************************************************
import React, {useEffect, useState} from "react";
import {Box, Button, Image, List, Text, TextSet} from "@lguuxe/2024_designsystem_uni";

import CommonAllIconHeader from "../../components/Common/CommonAllIconHeader";
import useLoading from '../../hooks/useLoading';
import "../../css/style.css"
import {couponApi} from "../../api/other";
import {CouponItem, CouponSort, initialCouponSort, initialIssuedCoupon, IssuedCoupon} from "./types";
import TagTab from "../../components/My/Coupon/TagTab";
import BS_List from "../../components/Common/BS_List";
import CommonDividerVertical from "../../components/Common/CommonDividerVertical";
import emptyCouponImg from "../../images/img/empty/empty_coupon.svg";
import {formatDate} from "../../utils/dateUtiles";
import CustomTab from "../../components/Entire/Pop/CustomTab";
import SortFilter from "../../components/Partner/SortFilter";
import {useNavigate} from "react-router-dom";


const MyCoupon: React.FC = () => {
    const [couponSort, setCouponSort] = useState<CouponSort>(initialCouponSort);
    const [coupons, setCoupons] = useState<IssuedCoupon>(initialIssuedCoupon);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isSortBsOpen, setIsSortBsOpen] = React.useState(false);
    const navigate = useNavigate();

    const TAG_LIST = [
        {
            id: "0",
            label: "전체"
        },
        {
            id: "1",
            label: "사용 완료"
        },
        {
            id: "2",
            label: "기간 만료"
        }
    ]
    const tabItems = [
        {
            id: "0",
            label: `사용 가능 ${coupons.useYcouponCnt}`
        },
        {
            id: "1",
            label: `사용/기간 만료 ${coupons.useNcouponCnt}`
        }
    ];

    const sortFilterList = [{id: '0', label: '최근 받은 순'}, {id: '1', label: '만료 임박 순'}];


    useEffect(() => {
        fetchCoupon();
    }, []);

    useEffect(() => {
        fetchCoupon();
    }, [couponSort]);

    const fetchCoupon = async () => {
        try {
            couponApi.getCoupon(couponSort, 'v1').then((res) => {
                console.log(res);
                setCoupons(res.body);
            });
        } catch (error) {
            console.log('Failed to fetchCoupon :', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleTabSelected = (id: string) => {
        if (id === '0') setCouponSort(prevState => ({
            ...prevState,
            useYn: 'N'
        }));
        if (id === '1') setCouponSort(prevState => ({
            ...prevState,
            useYn: 'D'
        }));
    };

    const handleTagSelected = (id: string) => {
        if (id === '0') setCouponSort(prevState => ({
            ...prevState,
            sortType: 'D'
        }));
        if (id === '1' && couponSort.sortType !== 'U') setCouponSort(prevState => ({
            ...prevState,
            sortType: 'U'
        }));
        if (id === '2' && couponSort.sortType !== 'E') setCouponSort(prevState => ({
            ...prevState,
            sortType: 'E'
        }));
    }

    const handleSortBsOpen = () => {
        setIsSortBsOpen(!isSortBsOpen);
    }

    const handleSortSelected = (id: string) => {
        setCouponSort(prevState => ({
            ...prevState,
            sortType: id === '0' ? 'N' : 'S'
        }));
    }

    const handleCouponClick = (coupon: CouponItem) => {
        navigate(`/my/coupon/${coupon.cpnId}`);
    }

    const renderCouponList = () => (
        <List
            id="couponList"
            type="view"
            fill="none"
            items={coupons.couponList.map(coupon => ({
                id: coupon.cpnId,
                leftSlot: [
                    <Image
                        src={coupon.ptnLogoImgUrl}
                        ratio="1:1"
                        width="48px"
                        rounded={"50%"}

                    />,
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "0.5rem"
                        }}

                    >
                        <TextSet
                            subComponent="Body_1"
                            text={{
                                eyebrow: (
                                    <>
                                        <span>{coupon.ptnName}</span>
                                        <CommonDividerVertical/>
                                        <span>{coupon.cpnId}</span>
                                    </>
                                ),
                                title: coupon.summary,
                                description: `${formatDate(coupon.expStartDate)} ~ ${formatDate(coupon.expEndDate)}`
                            }}
                        />
                    </div>
                ],
                rightSlot: [
                    couponSort.useYn === 'N' ? (<Button size="small" color="primary" state="default">
                        쿠폰 보기
                    </Button>) : <Button size="small" color="primary" state="default" disabled>
                        기간 만료
                    </Button>

                ],
                onClick: () => handleCouponClick(coupon)
            }))}
        />
    );
    const renderNoCoupon = () => (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '172px 0',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Image src={emptyCouponImg} alt='placeholder' width='120px' ratio='1:1'/>
                <div style={{paddingTop: '20px'}}>
                    <Text typo='font_detail_1M' color='color_text_neutral_3' textAlign='center'>
                        {couponSort.useYn === 'N' ? '받은 쿠폰이 없어요.' : couponSort.useYn === 'D' ? '사용 완료 / 기간 만료 쿠폰이 없어요.' : couponSort.useYn === 'U' ? '사용 완료 쿠폰이 없어요.' : '기간 만료 쿠폰이 없어요.'}
                    </Text>
                </div>
            </div>
        </div>);

    return (

        <>
            {useLoading(isLoading)}
            <Box
                type="1_b"
                style={{
                    width: "100%"
                }}
            >
                <CommonAllIconHeader title='내 쿠폰'/>

                <Box type="1_b">
                    <CustomTab items={tabItems} selectedItemId={couponSort.useYn === 'N' ? '0' : '1'}
                               onTabChange={handleTabSelected}/>
                    {/* 태그 */}
                    {(couponSort.useYn === 'D' || couponSort.useYn === 'U' || couponSort.useYn === 'E') && (
                        <TagTab dataList={TAG_LIST} onSelected={handleTagSelected} selectedItem={'0'}/>)}
                    {/* 정렬 */}
                    {couponSort.useYn === 'N' &&
                        <SortFilter totalCount={coupons.couponList.length.toString()} sortList={sortFilterList}
                                    onSortChange={handleSortBsOpen} isUsableCheckBox={false}/>}
                    {/*--- 쿠폰 리스트 ---*/}
                    {coupons.couponList.length == 0 ? renderNoCoupon() : renderCouponList()}
                </Box>
            </Box>

            <BS_List isOpen={isSortBsOpen} title="정렬"
                     dataList={sortFilterList}
                     onSelected={handleSortSelected}
                     onClose={handleSortBsOpen} checkedItem={couponSort.sortType === 'N' ? '0' : '1'}/>
        </>
    )
};

export default MyCoupon;
