// ****************************************************
// [배포] 1.혜택 - 유플투쁠 (응모형) [응모작성]
// ****************************************************

import React from 'react';
import {TextArea} from "@lguuxe/2024_designsystem_uni";
import CommonRightIconHeader from "../../components/Common/CommonRightIconHeader";
import CommonHeading from "../../components/Common/CommonHeading";
import CommonCTAColumn from "../../components/Common/CommonCTAColumn";
import {useNavigate} from "react-router-dom";
import BS_Entry from "../../components/UPTP/BS_Entry";



const UPTPEntry: React.FC = () => {
    const [isEntryOpen, setIsEntryOpen] = React.useState(false);
    const navigate = useNavigate();
    const handleClose = () => {
        navigate(-1); // 이전 페이지로 이동
    };
    const openBottomSheet = () => {
        setIsEntryOpen(true);
    };

    return (
        <>
            {isEntryOpen && <BS_Entry setIsEntryOpen={setIsEntryOpen}/> }
            <CommonRightIconHeader icon={"close_line"} title={"응모하기"} onClick={handleClose} />

            <CommonHeading title={"당첨되고 싶은 이유를 적어주세요."}/>

            <div
                style={{
                    padding: "24px 20px"
                }}
            >
                <TextArea
                    maxLength={200}
                    placeholder="당첨되고 싶은 이유를 적어주세요. (최대 200자)"
                    rows={10}
                    status="default"
                />
            </div>

            <div
                style={{
                    position: "fixed",
                    bottom: "0",
                    width: "100%",
                }}
            >
                <CommonCTAColumn buttonText={"응모하기"} handleSummit={openBottomSheet}/>
            </div>
        </>
    );
}

export default UPTPEntry;
