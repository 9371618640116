import {appTech, chatbot, moneymeEnter} from "../api/externalApi";
import {useNavigate} from "react-router-dom";
import {sendEvent, sendEventReturn} from "../api/bridgeApi";
import {eventApi} from "../api/attendanceAndEvent";

const useLandingPage = () => {
    const navigate = useNavigate();

    const landingPage = (targetType: string, targetUrl: string, title?: string, pushParam?: string) => {
        console.log('landingPage', targetType, targetUrl);
        const [url, value] = targetUrl ? targetUrl.split('|') : ['', ''];

        switch (targetType) {
            case '117001': // 외부 페이지 이동
                sendEventReturn('EXTERNAL_CALL', {type: '1', url: url});
                break;
            case '117002': // 내부 페이지 이동
                if (url === 'chat') {
                    chatbot();
                    break;
                }
                navigate(`/${url}${value ? '/' + value : ''}`);
                break;
            case '117003': // 이벤트 상세페이지
                console.log("TODO: 이벤트 상세페이지 CALL_WEBVIEW");
                try {
                    eventApi.getEventById(value, 'v1').then((res) => {
                        console.log(res);
                        if (res.header.resultCode === '0000') {
                            const param = {
                                url: res.body.redirectUrl + (pushParam ? pushParam : ''),
                                title: title,
                                showTitlebar: title !== undefined,
                                evtId: value,
                            }
                            sendEvent('CALL_EVENT_DETAIL', param);
                        } else {
                            // TODO: 이벤트 api 콜 실패 처리
                        }
                    });
                } catch (error) {
                    console.log("Error getEventById:", error);
                }
                break;
            case '117005':
                console.log('TODO: 데이터 선물하기 AND 영화예메')
                break;
            case '117006': // 머니Me 메인
                moneymeEnter();
                break;
            case '117007': // 앱테크
                appTech();
                break;
            default:
                console.log('targetType is not matched');
                break;
        }
    };

    return {landingPage};
};

export default useLandingPage;