import React from 'react';
import { Toggle, Box, TextSet, List } from "@lguuxe/2024_designsystem_uni";
import {sendEvent, sendEventReturn} from "../../api/bridgeApi";
import {useNavigate} from "react-router-dom";

const SectionHeadingList: React.FC = () => {
    const navigate = useNavigate()
    const checkPassword = async () => {
        await sendEventReturn("CHANGE_PASSWORD", {step:1})
    }
    const resetPassword = () => {
        navigate('/phone-certification?target=app')
    };
    return (
        <div
            style={{
                paddingBottom: "12px",
                marginBottom: "40px",
            }}
        >
            <Box type="2_trbl">
                <TextSet
                    subComponent="Heading_4"
                    text={{
                        title: '결제 비밀번호 설정',
                    }}
                    weight="bolder"
                />
            </Box>
            <List
                fill="none"
                type="view"
                items={[
                    {
                        leftSlot: [
                            <TextSet
                                key="password-change"
                                subComponent="Body_1"
                                text={{
                                    title: '비밀번호 변경',
                                    description: '앱/머니Me 잠금을 해제할 때도 같은 비밀번호를 사용해요.',
                                }}
                                weight="bolder"
                            />,
                        ],
                        onClick: checkPassword
                    },
                    {
                        leftSlot: [
                            <TextSet
                                key="password-reset"
                                subComponent="Body_1"
                                text={{
                                    title: '비밀번호를 잊어버리셨나요?',
                                    description: '본인인증 후 비밀번호를 재설정할 수 있어요.',
                                }}
                                weight="bolder"
                            />,
                        ],
                        onClick: resetPassword
                    },
                ]}
            />
        </div>
    );
}

export default SectionHeadingList;
