
import React, {useEffect, useState} from 'react';
import { Toggle, Box, TextSet, List } from "@lguuxe/2024_designsystem_uni";
import CommonPopup from "../Common/CommonPopup";
import { sendEventReturn } from "../../api/bridgeApi";

interface SectionHeadingListToggleProps {
    setting: {
        payBlckYn: string;
        autoPayBlckYn: string;
    } | null;
    userStatus: string;
}

const SectionHeadingListToggle: React.FC<SectionHeadingListToggleProps> = ({ setting, userStatus }) => {
    console.log(setting)
    const [openPayBlockPopup, setOpenPayBlockPopup] = useState<boolean>()
    const [openPayOpenPopup, setOpenPayOpenPopup] = useState<boolean>()
    const [openMonthBlockPopup, setOpenMonthBlockPopup] = useState<boolean>()
    const [openMonthOpenPopup, setOpenMonthOpenPopup] = useState<boolean>()
    const [payBlckYn, SetPayBlckYn] = useState<string | undefined>("N")
    const [autoPayBlckYn, SetAutoPayBlckYn] = useState<string | undefined>( "N")

    useEffect(() => {
        SetPayBlckYn(setting?.payBlckYn || "N")
        SetAutoPayBlckYn(setting?.autoPayBlckYn || "N")
    }, [setting]);

    const handleGeneralPaymentToggle = (e: any) => {
        console.log("state", payBlckYn)
        console.log("toggle", e.value)
        if (e.value === "N") {
            //N -> Y
            setOpenPayBlockPopup(true)
        } else {
            //Y -> N
            setOpenPayOpenPopup(true)
        }
    };

    const handleAutoPaymentToggle = (e: any) => {
        console.log("state", autoPayBlckYn)
        console.log("toggle", e.value)
        if (e.value === "N") {
            setOpenMonthBlockPopup(true)
        } else {
            setOpenMonthOpenPopup(true)
        }
    };

    const handlePayBlockPopup = () => {
        console.log("call handlePayBlockPopup")
        SetPayBlckYn("N")
        setOpenPayBlockPopup(false)
    }

    const handleMonthBlockPopup = () => {
        console.log("call handleMonthBlockPopup")
        SetAutoPayBlckYn("N")
        setOpenMonthBlockPopup(false)
    }

    const handlePayOpenPopup = () => {
        console.log("call handlePayOpenPopup")
        SetPayBlckYn("Y")
        setOpenPayOpenPopup(false)
    }

    const handleMonthOpenPopup = () => {
        console.log("call handleMonthOpenPopup")
        SetAutoPayBlckYn("Y")
        setOpenMonthOpenPopup(false)
    }

    return (
        <div
            style={{
                paddingBottom: "12px",
            }}
        >
            <Box type="2_trbl">
                <TextSet
                    subComponent="Heading_4"
                    text={{
                        title: '차단 설정',
                    }}
                    weight="bolder"
                />
            </Box>
            <List
                fill="none"
                type="view"
                items={[
                    {
                        leftSlot: [
                            <TextSet
                                key="general-payment"
                                subComponent="Body_1"
                                text={{
                                    title: '일반 휴대폰 결제',
                                }}
                            />,
                        ],
                        rightSlot: [
                            <Toggle
                                key="general-payment-toggle"
                                falseValue="N"
                                trueValue="Y"
                                checked={payBlckYn === 'Y'}
                                value={payBlckYn}
                                onChange={handleGeneralPaymentToggle}
                                size="medium"
                                weight="default"
                                width="auto"
                            />,
                        ],
                    },
                    {
                        leftSlot: [
                            <TextSet
                                key="monthly-payment"
                                subComponent="Body_1"
                                text={{
                                    title: '월 정기 자동결제',
                                }}
                            />,
                        ],
                        rightSlot: [
                            <Toggle
                                key="monthly-payment-toggle"
                                falseValue="N"
                                trueValue="Y"
                                checked={autoPayBlckYn === 'Y'}
                                value={autoPayBlckYn}
                                onChange={handleAutoPaymentToggle}
                                size="medium"
                                weight="default"
                                width="auto"
                            />,
                        ],
                    },
                ]}
            />
            {openPayBlockPopup && (
                <CommonPopup
                    description='일반 휴대폰 결제를 차단하시겠어요?'
                    submitText='차단'
                    onSubmit={() => { sendEventReturn("CHECK_PASSWORD", "00") }}
                    cancelText='취소'
                    onClose={handlePayBlockPopup}
                />
            )}
            {(openPayOpenPopup && userStatus === "44") && (
                <CommonPopup
                    description='만 19세 미만 가입자는 PASS 앱 또는 당신의 U+앱에서 차단을 해제할 수 있어요.'
                    submitText='확인'
                    onSubmit={handlePayOpenPopup}
                />
            )}
            {(openPayOpenPopup && userStatus !== "44") && (
                <CommonPopup
                    description='차단을 해제하려면 비밀번호를 입력해주세요.'
                    submitText='비밀번호 입력'
                    onSubmit={() => { sendEventReturn("CHECK_PASSWORD", "00") }}
                    cancelText='취소'
                    onClose={handlePayOpenPopup}
                />
            )}
            {openMonthBlockPopup && (
                <CommonPopup
                    description='월 정기 자동 결제를 차단하시겠어요?'
                    submitText='차단'
                    onSubmit={() => { sendEventReturn("CHECK_PASSWORD", "00") }}
                    cancelText='취소'
                    onClose={handleMonthBlockPopup}
                />
            )}
            {(openMonthOpenPopup && userStatus === "44") && (
                <CommonPopup
                    description='만 19세 미만 가입자는 PASS 앱 또는 당신의 U+앱에서 차단을 해제할 수 있어요.'
                    submitText='확인'
                    onSubmit={handleMonthOpenPopup}
                />
            )}
            {(openMonthOpenPopup && userStatus !== "44") && (
                <CommonPopup
                    description='차단을 해제하려면 비밀번호를 입력해주세요.'
                    submitText='비밀번호 입력'
                    onSubmit={() => { sendEventReturn("CHECK_PASSWORD", "00") }}
                    cancelText='취소'
                    onClose={handleMonthOpenPopup}
                />
            )}
        </div>
    );
};

export default SectionHeadingListToggle;