import React, {useEffect, useState} from 'react'
import {Box, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import CommonLeftIconHeader from "../../../components/Common/CommonLeftIconHeader";
import CommonDividerThin from "../../../components/Common/CommonDividerThin";
import {useParams} from "react-router-dom";
import {faqApi} from "../../../api/notiAndQna";

interface Faq {
    date: string;
    title: string;
    contents: string;
}

const initFaq: Faq = {
    date: '',
    title: '',
    contents: ''
}

const FaqDetail: React.FC = () => {
    const {id} = useParams<{ id: string }>()
    const [faq, setFaq] = useState<Faq>(initFaq);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            if (!id) {
                console.error("id is undefined");
                setIsLoading(false);
                return;
            }
            try {
                faqApi.getContent(id, 'v1').then((res) => {
                    if (res.header.resultCode === '0000' && res.body) {
                        setFaq(res.body);
                    }
                });
            } catch (error) {
                console.log("Error fetchData:", error);
            }
        }
        fetchData();
    }, [])

    return (
        <>
            <CommonLeftIconHeader title={'자주하는 질문 상세'}/>

            <Box
                type="2_trbl"
            >
                <TextSet
                    subComponent="Heading_4"
                    text={{
                        title: faq.title,
                    }}
                    weight="bolder"
                />
            </Box>

            <Box style={{padding: "8px 20px"}}>
                <CommonDividerThin/>
            </Box>

            <Box
                type="3_trbl"
            >
                {/* \n으로 분할한 문자열을 map을 사용해 개별 출력 */}
                {faq.contents.split('\n').map((line, index) => (
                    <Text key={index} typo="font_body_1M" color="color_text_neutral_3">
                        {line}
                    </Text>
                ))}
            </Box>
        </>
    )
}

export default FaqDetail
