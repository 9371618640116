// ****************************************************
// [배포] 5.전체 - 도움말 - 개인정보 처리방침
// ****************************************************
import React, {useEffect, useState} from 'react'
import {Box, Icon, Text} from '@lguuxe/2024_designsystem_uni'
import CommonLeftIconHeader from "../../../components/Common/CommonLeftIconHeader";
import {termsApi} from "../../../api/settingAndTerms";
import BS_List from "../../../components/Common/BS_List";
import useLoading from "../../../hooks/useLoading";

interface Version {
    termsId: string;
    version: string;
}

const PrivacyPolicy: React.FC = () => {
    const [versionList, setVersionList] = useState<Version[]>([]);
    const [termContent, setTermContent] = useState('');
    const [showVersionBS, setShowVersionBS] = useState(false);
    const [selectedTermsId, setSelectedTermsId] = useState('');
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        fetchData(selectedTermsId);
    }, []);

    const fetchData = async (termsId: string) => {
        try {
            termsApi.getPrivacyPolicy({termsId: termsId}, 'v1').then((res) => {
                if (res.header.resultCode === '0000' && res.body) {
                    setTermContent(res.body.termsInfo.termsContent);
                    setVersionList(res.body.versionList);
                }
            });
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const handleVersionList = (id: string) => {
        fetchData(id);
        setSelectedTermsId(id);
        setShowVersionBS(false);
    }

    return (
        <>
            {useLoading(isLoading)}
            <CommonLeftIconHeader title={'개인정보처리방침'}/>

            <Box type="2_trbl">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "13px 12px",
                        backgroundColor: "#fff",
                        border: "1px solid #E7EBEE",
                        borderRadius: "12px",
                    }}
                    onClick={() => setShowVersionBS(true)}
                >
                    <Text typo="font_heading_5Sb"
                          color="color_text_neutral_6">{selectedTermsId === '' ? `최신 버젼 ${versionList.length !== 0 ? `(${versionList[0].version})` : ''}` : versionList.find(item => item.termsId === selectedTermsId)?.version}</Text>
                    <Icon color="#7f8a94" name="chevron_down_small_line" size="24px"/>
                </div>
            </Box>

            <Box style={{padding: "4px 28px 12px"}}>
                <Text typo="font_body_1M" color="color_text_neutral_4">{termContent}</Text>
            </Box>

            <BS_List title={'개인정보처리방침'}
                     dataList={versionList.map(item => ({id: item.termsId, label: item.version}))}
                     onClose={() => setShowVersionBS(false)} onSelected={handleVersionList} isOpen={showVersionBS}/>
        </>
    )
}

export default PrivacyPolicy
