// ****************************************************
// [배포] 2.미션 > 메인 (UMembershipMission)
// ****************************************************
import React from 'react';
import {Image, List, TextSet} from '@lguuxe/2024_designsystem_uni';
import ExImg from '../../images/default/default thumbnail.svg';
import {useNavigate} from 'react-router-dom';
import {CLICK_LOCATION, EVENT_ACTION} from "../../utils/const";
import badgeking from '../../images/icon/3_mission/badgeking.svg';

const MissionList: React.FC = () => {
    const navigate = useNavigate();

    const handleMissionClick = (event: any) => {
        console.log(event);
        if (event.target.id === 'badgeking') {
            navigate('/promotion');
        }
    };
    return (
        <>
            <TextSet
                subComponent='Heading_4'
                text={{
                    title: (
                        <>
                            <span style={{color: '#181A1B'}}>두 달 간의 특별한 미션</span>
                        </>
                    ),
                }}
                blockSpacing={{top: '24px', bottom: '12px'}}
                isIndent={true}
            />

            <div style={{paddingBottom: '12px'}}>
                <List
                    fill='none'
                    name='list_name'
                    size='medium'
                    type='view'
                    weight='default'
                    data-gtm-event-name={'all_click'}
                    data-gtm-event-category={'클릭'}
                    data-gtm-event-action={`${CLICK_LOCATION.MISSION_SPETIAL_MISSION} - ${EVENT_ACTION.LINK_CLICK}`}
                    data-gtm-event-label={`컨텐츠 : 배지킹 참여하기`}
                    data-gtm-click-url={''}
                    data-gtm-click-location={`${CLICK_LOCATION.MISSION_SPETIAL_MISSION}`}
                    data-gtm-click-direction={'내부'}
                    data-gtm-click-text={`배지킹 참여하기`}
                    items={[
                        {
                            id: 'badgeking',
                            leftSlot: [
                                <>
                                    {/* TODO: 이미지 뭐 불러옴?  */}
                                    <Image customClass='customClass' ratio='1:1' rounded='999' src={badgeking}
                                           width='48' srcFallback={ExImg}/>
                                    <TextSet
                                        subComponent='Body_1'

                                        text={{
                                            eyebrow: '혜택 사용하고',
                                            title: '배지킹 참여하기',
                                        }}
                                        weight='bolder'
                                    />
                                </>,
                            ],
                            linkIconOnlyId: 'badgeking',
                            onClick: handleMissionClick,
                        },
                    ]}
                />
            </div>
        </>
    );
};

export default MissionList;
