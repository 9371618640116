import React from 'react';

const CommonDivider: React.FC = () => {
    return (
        <div
            style={{
                height: "12px",
                width: "100%",
                backgroundColor: "#F3F5F6",
                marginBottom: "8px",
            }}
        >
        </div>
    );
}

export default CommonDivider;
