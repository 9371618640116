import { Text } from "@lguuxe/2024_designsystem_uni";
import React, {useEffect, useState} from "react";
import {getItemByKey} from "../../../utils/indexedDB";

const EarlyMonth : React.FC = () => {
    const [userName, setUserName] = useState("")
    const getName = async () => {
        setUserName(await getItemByKey('customerName'))
    }
    useEffect(() => {
        getName()
    }, []);

    return (
                <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    flex: "1"
                }}
            >
                <Text
                    typo="font_body_2Sb"
                    color="color_text_neutral_5"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "2px",
                        width: "100%"
                    }}
                >
                    {userName}님 오늘부터 새로운
                </Text>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                        gap: "2px",
                        width: "100%"
                    }}
                >
                    <Text typo="font_body_2Sb" color="color_text_primary">
                        VIP콕 혜택
                    </Text>
                    <Text typo="font_body_2Sb" color="color_text_neutral_5">
                        을 사용하실 수 있어요.
                    </Text>
                </div>
            </div>
        </>
    )
}

export default EarlyMonth