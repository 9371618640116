import React, { useEffect, useState } from 'react';
import { BottomSheet, Header, IconButton, CTA, Button, Text, List } from "@lguuxe/2024_designsystem_uni";
import CommonDividerThin from '../../components/Common/CommonDividerThin';
import CommonPopup from "../Common/CommonPopup";
import {useNavigate} from "react-router-dom";

interface BS_EntryProps{
    setIsEntryOpen: (open:boolean) => void;
}

interface Term {
    title: string;
    link: string;
    isChecked: boolean;
}

const TERMS: Term[] = [
    {
        title: '개인정보 수집 및 이용 동의',
        link: 'www.naver.com',
        isChecked: false,
    },
    {
        title: '개인정보 처리 및 위탁동의',
        link: 'www.naver.com',
        isChecked: false,
    },
];

const BS_Entry: React.FC<BS_EntryProps> = ({setIsEntryOpen}) => {
    const [terms, setTerms] = useState<Term[]>(TERMS);
    const [isNextButtonActive, setIsNextButtonActive] = useState(false);
    const [showPopup,setShowPopup] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = () => {
        navigate('/uptp'); // 이전 페이지로 이동
    };
    useEffect(() => {
        const allChecked = terms.every(term => term.isChecked);
        setIsNextButtonActive(allChecked);
    }, [terms]);

    const handleAllAgree = () => {
        const newAllAgreeState = !terms.every(term => term.isChecked);
        setTerms(terms.map(term => ({ ...term, isChecked: newAllAgreeState })));
    };

    const handleTermCheck = (index: number) => {
        const newTerms = terms.map((term, idx) =>
            idx === index ? { ...term, isChecked: !term.isChecked } : term
        );
        setTerms(newTerms);
    };

    return (
    <>
        {showPopup && <CommonPopup title={"참여완료"} description={"당첨 결과는 [알림] 또는 [이벤트] -> [당첨 확인]에서 확인하세요."} onSubmit={handleSubmit} submitText={"확인"}/>}

        <BottomSheet isOpen onClose={() => {}}>
            <BottomSheet.TopSlot>
                <Header align="left" variant="heading">
                    <Header.CenterSlot>
                        <Text typo="font_heading_3B" color="color_text_neutral_6">
                            이벤트 참여 동의
                        </Text>
                    </Header.CenterSlot>
                    <Header.RightSlot>
                        <IconButton
                            color="neutral"
                            fill="icon"
                            icon="close_line"
                            noStates
                            onClick={() => {setShowPopup(false)}}
                        />
                    </Header.RightSlot>
                </Header>
            </BottomSheet.TopSlot>

            <BottomSheet.CenterSlot>
                <div>
                    <List
                        fill="none"
                        items={[
                            {
                                checked: terms.every(term => term.isChecked),
                                text: {
                                    title: '전체 동의',
                                },
                            },
                        ]}
                        type="checkbox"
                        size="large"
                        weight="bolder"
                        onChange={handleAllAgree} // 전체 동의 핸들러
                    />
                </div>

                <div style={{ padding: '0 20px' }}>
                    <CommonDividerThin />
                </div>

                <div>
                    <List
                        fill="none"
                        items={terms.map((term, index) => ({
                            checked: term.isChecked,
                            text: {
                                title: term.title,
                            },
                            link: term.link,
                            onChange: () => handleTermCheck(index),
                        }))}
                        type="checkbox"
                        weight="bolder"
                    />
                </div>
            </BottomSheet.CenterSlot>

            <BottomSheet.BottomSlot>
                <CTA gradation layout="row">
                    <Button color="primary" size="large" disabled={!isNextButtonActive} onClick={() =>
                    {
                        setIsEntryOpen(false)
                        setShowPopup(true)
                    }}>
                        응모하기
                    </Button>
                </CTA>
            </BottomSheet.BottomSlot>
        </BottomSheet>
    </>
    );
}

export default BS_Entry;
