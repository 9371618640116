import React from "react";
import {Box, Header, IconButton, Image} from "@lguuxe/2024_designsystem_uni";
import logoLGuplusImg from "../../images/main/benefit/logoLGuplus.svg";
import {useNavigate} from "react-router-dom";

const HomeHeader: React.FC = () => {
    const navigate = useNavigate();
    const handleNative = () => {
        navigate('/native');
    };
    const handleNotification = () => {
        navigate('/notice');
    };
    const handleSearch = () => {
        navigate('/search');
    };
    return (
        <Box style={{backgroundColor: "transparent", padding: "0"}}>
            <Header align="left" style={{backgroundColor: "transparent", padding: "6px 20px"}} divider={false}>
                <Header.CenterSlot>
                    <Image src={logoLGuplusImg} alt="placeholder" width="78px" height="22px"
                           onClick={handleNative}/>
                </Header.CenterSlot>
                <Header.RightSlot>
                    <div style={{display: "flex", gap: "16px"}}>
                        <IconButton
                            color="neutral"
                            fill="icon"
                            icon="search_line"
                            noStates
                            size="large"
                            onClick={handleSearch}
                            data-gtm-event-name={'all_click'}
                            data-gtm-event-category={'클릭'}
                            data-gtm-event-action={'프로필 블록 - 링크 클릭'}
                            data-gtm-event-label={'컨텐츠 : 검색'}
                            data-gtm-click-url={''}
                            data-gtm-click-location={'프로필 블록'}
                            data-gtm-click-direction={'내부'}
                            data-gtm-click-text={'검색'}
                        />

                        <IconButton
                            color='neutral'
                            fill='icon'
                            icon='notification_line'
                            size='large'
                            noStates
                            onClick={handleNotification}
                            data-gtm-event-name={'all_click'}
                            data-gtm-event-category={'클릭'}
                            data-gtm-event-action={'프로필 블록 - 링크 클릭'}
                            data-gtm-event-label={'컨텐츠 : 알림'}
                            data-gtm-click-url={''}
                            data-gtm-click-location={'프로필 블록'}
                            data-gtm-click-direction={'내부'}
                            data-gtm-click-text={'알림'}
                        />
                    </div>
                </Header.RightSlot>
            </Header>
        </Box>
    );
};

export default HomeHeader;
