// ****************************************************
// [배포] 4.마이 > VIP콕 > VIP콕 > VIP 회원 VIP콕 (UMembershipVip)
// ****************************************************
// InfoBox 다른 유형 (하단)
import React from "react";
import { Text, Icon } from "@lguuxe/2024_designsystem_uni";

const BottomInfoBox: React.FC = () => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                padding: "16px 20px 40px",
                background: "#F3F5F6"
            }}
        >
            <div
                style={{
                    alignItems: "flex-start",
                    alignSelf: "stretch",
                    backgroundColor: "transparent",
                    borderRadius: "0.5rem",
                    display: "flex",
                    gap: "4px",
                    padding: "0"
                }}
            >
                <Icon color="#7f8a94" name="error_circle_filled" size="20px" />
                <Text typo="font_heading_6B">꼭 확인하세요!</Text>
            </div>
            <div
                style={{
                    margin: "0",
                    color: "#66707A"
                }}
            >
                    <Text typo="font_body_2M" color="color_text_neutral_3">
                        VIP 등급 부여 시점 : 74,800원 이상 모바일 요금제를 정지없이 1개월 꽉 채워 이용하면, 다음달 초에 VIP 혜택을 받을 수 있어요.
                    </Text>
            </div>
        </div>
    );
};

export default BottomInfoBox;
