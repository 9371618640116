import React from "react";
import {Image, List, Text} from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom"; // useNavigate import
import ExImg from "../../images/default/default thumbnail.svg";

type RecentUsedBenefit = {
    ptnId: string;
    ptnLogoImgUrl: string;
    pntName: string;
};

interface CommonCarousel1Props {
    benefits: RecentUsedBenefit[];
}

//TODO 리스트 확인용 나중에 삭제

const CommonCarousel1: React.FC<CommonCarousel1Props> = ({benefits}) => {
    const navigate = useNavigate(); // useNavigate hook

    // 클릭 시 ptnId로 이동하는 함수
    const handleClick = (ptnId: string) => {
        navigate(`/partners/${ptnId}`);
    };

    return (
        <>
            <div
                style={{
                    padding: "4px 0",
                    border: "1px solid #E7EBEE",
                    borderRadius: "12px",
                    background: "#fff",
                }}
            >
                <List
                    style={{
                        overflow: "auto",
                        display: "flex",
                        flexDirection: "row",
                        width: "calc(100vw - 40px)",
                    }}
                    fill="none"
                    name="list_name"
                    size="large"
                    type="view"
                    weight="default"
                    items={benefits.map((benefit) => ({
                        id: `benefit_${benefit.ptnId}`,  // 유니크한 ID
                        leftSlot: (
                            <div
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    gap: "4px",
                                    minWidth: "81px",
                                }}
                            >
                                <Image
                                    src={benefit.ptnLogoImgUrl || ExImg}
                                    alt="제휴이미지"
                                    width="56"
                                    ratio="1:1"
                                    rounded={30.27}
                                />
                                <div
                                    style={{
                                        gap: "2px",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <Text typo="font_heading_5Sb" color="color_text_neutral_5">
                                        {benefit.pntName}
                                    </Text>
                                </div>
                            </div>
                        ),
                        onClick: () => handleClick(benefit.ptnId),  // 클릭 이벤트를 item 레벨에서 처리
                    }))}
                />
            </div>
        </>
    );
};

export default CommonCarousel1;
