import React from "react";
import { Box, Text, Image } from "@lguuxe/2024_designsystem_uni";

interface SectionProps {
    title: string;
    description: string;
    imageSrc: string;
    imageAlt?: string;
    additionalContent?: {
        text: string;
        imageSrc: string;
        imageAlt?: string;
    }[];
}

const MembershipBenefitSection: React.FC<SectionProps> = ({ title, description, imageSrc, imageAlt = "placeholder", additionalContent }) => {
    return (
        <Box style={{ display: "flex", flexDirection: "column", gap: "12px", padding: "24px 0 12px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "6px", padding: "0" }}>
                <Text typo="font_heading_4B" color="color_text_neutral_6" style={{ fontSize: "18px", fontWeight: "800", padding: "0 20px" }}>
                    {title}
                </Text>
                <Text typo="font_detail_2M" color="color_text_neutral_3" style={{ padding: "0 20px" }}>
                    {description}
                </Text>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "16px", padding: "4px 20px" }}>
                <Image
                    src={imageSrc}
                    alt={imageAlt}
                    width="100%"
                    ratio="4:3"
                />
            </div>
            {additionalContent && (
                <div style={{ display: "flex", flexDirection: "column", gap: "16px", padding: "18px 20px 0" }}>
                    {additionalContent.map((content, index) => (
                        <div key={index} style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                            <Text typo="font_detail_2M" color="color_text_neutral_3">
                                {content.text}
                            </Text>
                            <Image
                                src={content.imageSrc}
                                alt={content.imageAlt || "placeholder"}
                                width="100%"
                                ratio="4:3"
                            />
                        </div>
                    ))}
                </div>
            )}
        </Box>
    );
};

export default MembershipBenefitSection;
