import React, { useState } from "react";
import { List, Text, Image, Button, Box } from "@lguuxe/2024_designsystem_uni";
import InfoBox from "../../components/Vipkok/InfoBox";
import Snackbar from "../../components/Vipkok/Snackbar";
import SectionHeadingText2row1 from "./SectionHeadingText2row1";
import { PartnerDetailData, PartnerType, UserGrade } from "../../pages/Partner/types"; // 필요한 타입 임포트

type Props = {
    partnerDetail: PartnerDetailData; // partnerDetail 타입 지정
    partnerType: PartnerType;
    initialState: '미사용' | '다운로드' | '사용완료';
    userGrade: UserGrade;
    onDownloadClick?: () => void; // 추가된 함수
    onDetailClick?: () => void; // 추가된 함수
};

const SectionContentHeaderBenefit: React.FC<Props> = ({ partnerDetail, partnerType, initialState, userGrade, onDownloadClick, onDetailClick }) => {
    const [state, setState] = useState<'미사용' | '다운로드' | '사용완료'>(initialState);
    const [isSnackbarVisible, setIsSnackbarVisible] = useState(false);

    const handleButtonClick = () => {
        if (userGrade === '미로그인') {
            // 로그인 처리
        } else if (userGrade === '일반' || userGrade === '우수') {
            // 등급 안내 처리
        } else {
            if (partnerType === 'normal') {
                // 혜택 사용하러 가기 처리
            } else if (state === '미사용' && partnerType === 'coupon') {
                setIsSnackbarVisible(true);
                setState('다운로드');
                if (onDownloadClick) onDownloadClick(); // 다운로드 클릭 시 실행할 함수
                setTimeout(() => setIsSnackbarVisible(false), 3000); // 3초 후에 사라지게 설정
            } else if (state === '다운로드' && partnerType === 'coupon') {
                if (onDetailClick) onDetailClick(); // 상세 가기 클릭 시 실행할 함수
            } else if (partnerType === 'movie') {
                // 예매하러 가기 처리
            }
        }
    };

    const fetchCoupon = () => {
    }
    const renderInfoBox = () => {
        if (userGrade === '미로그인') {
            return <InfoBox text="본인 인증 후 U+멤버스만의 다양한 혜택을 즐겨보세요." />;
        } else if (userGrade === '일반' || userGrade === '우수') {
            return <InfoBox text="U+ 멤버십 VVIP/VIP 회원만 사용 가능합니다." name={partnerDetail.ptnName} rank={userGrade} />;
        } else {
            if (state === '사용완료') {
                return <InfoBox text="이번 달 VIP콕 사용을 완료 하였습니다." />;
            } else if (state === '미사용' && partnerType === 'coupon') {
                return null;
            } else if (state === '다운로드' && partnerType === 'coupon') {
                return <InfoBox text="혜택 > 내 쿠폰에서 보유 쿠폰을 확인하실 수 있습니다." />;
            } else if (state === '미사용' && partnerType === 'normal') {
                return <InfoBox text="이번 달 VIP콕을 사용하지 않았습니다." />;
            }
        }
    };

    const renderBenefitContent = () => {
        let title = partnerDetail.ptnName || 'CGV'; // partnerDetail에서 name 가져옴
        let subTitle = partnerDetail.vipBnf || '2D영화 2천 원 할인'; // partnerDetail에서 혜택 정보 가져옴
        let imageSrc = partnerDetail.ptnLogoImgUrl || 'https://default-image-url.com'; // partnerDetail에서 이미지 URL 가져옴
        let buttonText = '';

        if (userGrade === '미로그인') {
            buttonText = 'U+모바일 인증 로그인';
        } else if (userGrade === '일반' || userGrade === '우수') {
            buttonText = '등급 및 혜택 안내';
        } else {
            if (partnerType === 'normal') {
                buttonText = '혜택 사용하러 가기';
            } else if (state === '미사용' && partnerType === 'coupon') {
                buttonText = '쿠폰 받기';
            } else if (state === '다운로드' && partnerType === 'coupon') {
                buttonText = '쿠폰 상세 가기';
            } else if (partnerType === 'movie') {
                buttonText = '예매하러 가기';
            }
        }

        return (
            <>
                <List
                    type="view"
                    fill="none"
                    items={[
                        {
                            leftSlot: (
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "4px"
                                    }}
                                >
                                    <Text typo="font_label_2Sb" color="color_text_neutral_3">
                                        {title}
                                    </Text>
                                    <Text typo="font_heading_3B" color="color_text_neutral_6">
                                        {subTitle}
                                    </Text>
                                </div>
                            ),
                            rightSlot: [
                                <Image
                                    src={imageSrc}
                                    ratio="1:1"
                                    width="48px"
                                    rounded={"50%"}
                                />
                            ]
                        }
                    ]}
                />
                {partnerType === 'movie' && <SectionHeadingText2row1 />}
                <div
                    style={{
                        display: "flex",
                        gap: "12px",
                        justifyContent: "center",
                        padding: "0 20px",
                        alignSelf: "stretch"
                    }}
                >
                    {renderInfoBox()}
                </div>
                <div
                    style={{
                        display: "flex",
                        gap: "12px",
                        justifyContent: "center",
                        padding: "0 20px"
                    }}
                >
                    {state !== '사용완료' &&
                        <Button
                            color={userGrade === '미로그인' ? 'primary' : 'secondary'}
                            fill="solid"
                            style={{
                                display: "flex",
                                flex: "1"
                            }}
                            onClick={handleButtonClick}
                        >
                            {buttonText}
                        </Button>
                    }
                </div>
            </>
        );
    };

    return (
        <div>
            <Box
                style={{
                    display: "flex",
                    gap: "8px",
                    flexDirection: "column",
                    padding: "0 0 24px",
                    width: "100%"
                }}
            >
                {renderBenefitContent()}
                <div
                    style={{
                        display: "flex",
                        gap: "12px",
                        flexDirection: "column",
                        padding: "12px 0 0",
                        width: "100%"
                    }}
                >
                </div>
                <Snackbar isVisible={isSnackbarVisible} onClose={() => setIsSnackbarVisible(false)} status="default" useDefaultIcon />
            </Box>
        </div>
    );
};

export default SectionContentHeaderBenefit;
