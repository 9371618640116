// ****************************************************
// [배포] 1.혜택 > 전체 제휴처 > 혜택 브랜드 상세 (VIP콕 바텀시트)
// ****************************************************
import {BottomSheet, Header, IconButton, TextSet} from '@lguuxe/2024_designsystem_uni';
import React from 'react';
import '../../pages/css/style.css';
import CommonDivider from '../Common/CommonDivider';
import {ProfileInfo} from "../../pages/Partner/types";
import PartnerProfile from "./PartnerProfile";

interface BS_VipKokDetailProps {
    isOpen: boolean;
    onClose: () => void;
    profileInfo: ProfileInfo;
}

const BS_VipKokDetail: React.FC<BS_VipKokDetailProps> = ({isOpen, onClose, profileInfo}) => {
    return (
        <>
            <BottomSheet isOpen={isOpen} onClose={onClose}>
                <BottomSheet.TopSlot>
                    <Header align='left' variant='heading'>
                        VIP 콕
                        <Header.RightSlot>
                            <IconButton color='neutral' fill='icon' icon='close_line' noStates onClick={onClose}/>
                        </Header.RightSlot>
                    </Header>
                </BottomSheet.TopSlot>

                <BottomSheet.CenterSlot>
                    <div style={{paddingTop: '0px', height: 'auto'}}>
                        <PartnerProfile profileInfo={profileInfo}/>
                        <CommonDivider/>

                        <div style={{padding: '0 0 24px'}}>
                            <TextSet
                                subComponent='Heading_4'
                                text={{
                                    title: '혜택',
                                    description:
                                        '하드코딩입니다... 수정 필요 ',
                                }}
                                blockSpacing={{top: '24px', bottom: '12px'}}
                                isIndent={true}
                            />

                            <TextSet
                                subComponent='Heading_4'
                                text={{
                                    title: '혜택 한도',
                                    description:
                                        '혜택 문구 혜택 문구 혜택 문구 혜택 문구 혜택 문구 혜택 문구 혜택 문구 혜택 문구 혜택 문구 혜택 문구 혜택 문구',
                                }}
                                blockSpacing={{top: '24px', bottom: '12px'}}
                                isIndent={true}
                            />

                            <TextSet
                                subComponent='Heading_3'
                                text={{
                                    title: '꼭 확인하세요*',
                                    description:
                                        '유의사항 문구유의사항 문구유의사항 문구유의사항 문구유의사항 문구유의사항 문구유의사항 문구유의사항 문구유의사항 문구유의사항 문구유의사항 문구유의사',
                                }}
                                blockSpacing={{top: '24px', bottom: '12px'}}
                                isIndent={true}
                            />
                        </div>
                    </div>
                </BottomSheet.CenterSlot>

                {/* <BottomSheet.BottomSlot>
          <CTA gradation layout="row">
            <Button color="secondary" fill="solid">
              혜택 사용하러 가기
            </Button>
          </CTA>
        </BottomSheet.BottomSlot> */}
            </BottomSheet>
        </>
    );
};

export default BS_VipKokDetail;
