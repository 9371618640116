// ****************************************************
// [배포] 4.마이 > 내배지 > 선물하기 (UMembershipMyBadgeGift)
// ****************************************************
import React, {useState} from 'react';
import { Box, TextSet, TextField, Button } from "@lguuxe/2024_designsystem_uni";

interface SectionCertifiedProps {
    onClick: () => void
    supportText: any[]
    onChange: (e: any) => void
}
const SectionCertified: React.FC<SectionCertifiedProps> = ({onClick, supportText, onChange}) => {
    return (
        <>
            <Box type="2_trbl">
                <TextSet
                    subComponent="Heading_6"
                    text={{
                        description: 'U+ 휴대폰을 이용하는 고객에게만 선물할 수 있습니다.',
                        title: '받는사람 휴대폰 번호'
                    }}
                    weight="default"
                />
                <div style={{ paddingTop: "16px" }}>
                    <TextField
                        placeholder="010 1234 5678"
                        maxLength={11}
                        inputType='number'
                        isAutoTab
                        supportText={supportText}
                        status='default'
                        onChange={onChange}
                        rightAddons={[
                            <Button size="small" onClick={onClick}>인증하기</Button>
                        ]}
                    />
                </div>
            </Box>
        </>
    );
}

export default SectionCertified;
