import React from "react";
import { Image, Text } from "@lguuxe/2024_designsystem_uni";

import moneyme_s from "../../images/main/benefit/moneyme_s.svg";
import arrow from "../../images/etc/chevron_right_small_24_line.svg";

interface BannerInfoProps {
    onClick: () => void;
}

const BannerInfo: React.FC<BannerInfoProps> = ({ onClick }) => {
    return (
        <div style={{ padding: "0" }}>
            <div
                onClick={onClick}
                style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "12px 16px",
                    border: "1px solid #FBDAED",
                    borderRadius: "12px",
                    gap: "8px",
                    alignItems: "center",
                    background: "#FDEDF6",
                    cursor: "pointer", // 클릭 가능하게 표시
                }}
            >
                <Image src={moneyme_s} alt="placeholder" width="24" ratio="1:1" />
                <Text
                    typo="font_body_1Sb"
                    color="color_text_natural_6"
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "2px",
                        width: "100%",
                        color: "#DF0C65",
                    }}
                >
                    U+멤버십 할인 찾아가기, 머니Me
                </Text>
                <Image src={arrow} alt="placeholder" width="24" ratio="1:1" />
            </div>
        </div>
    );
};

export default BannerInfo;
