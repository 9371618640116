import React from 'react';
import {Button, Text} from '@lguuxe/2024_designsystem_uni';
import {TabType} from './types';
import {gtmDataLayer} from "../../api/GaApi";

interface BarcodeTimeOutProps {
    onGenerateBarcode: () => void;
    tabType: TabType;
}

const BarcodeTimeOut: React.FC<BarcodeTimeOutProps> = ({onGenerateBarcode, tabType}) => {
    const handleGenerateBarcodeClick = () => {
        if (tabType === 'payment') {
            gtmDataLayer({clickLocation: '바코드|휴대폰 결제', clickText: '소액결제 바코드 생성하기',});
        }
        onGenerateBarcode();
    }
    return (
        <div
            style={{
                padding: '20px 0',
                backgroundColor: '#F3F5F6',
                borderRadius: '12px',
                width: '100%',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '12px',
                    padding: '4.5px 22px',
                }}
            >
                <Text typo='font_body_1M' color='color_text_neutral_3' textAlign='center'>
                    ‘바코드 생성하기’ 버튼을 눌러주시면
                    <br/>
                    바코드가 생성됩니다.
                </Text>
                <Button
                    as='button'
                    color='primary'
                    fill='solid'
                    size='medium'
                    state='default'
                    onClick={handleGenerateBarcodeClick}
                >
                    바코드 생성하기
                </Button>
            </div>
        </div>
    );
};

export default BarcodeTimeOut;