import React, { CSSProperties, useEffect, useState } from "react";
import {
    List,
    TextSet,
    Text,
    Image,
    Tab,
    Box,
    Dropdown,
    IconButton
} from "@lguuxe/2024_designsystem_uni";
import CommonAllIconHeader from "../../components/Common/CommonAllIconHeader";
import SectionChip from "../../components/Home/SectionChip";
import { handlePromiseResult } from "../../api/api";
import nftApi from "../../api/nft";
import useLoading from "../../hooks/useLoading";
import { Tag } from "../Home/types";
import emptyPartnerImg from "../../images/img/empty/empty_partner.svg";
import { Company } from "./types"; // 가정: api 파일에서 함수들을 가져옴
import ExImg from "../../images/default/default thumbnail.svg";
import CommonCTAColumn from "../../components/Common/CommonCTAColumn";
import {useNavigate} from "react-router-dom";
import {partnerApi} from "../../api/partnerAndAffiliate-service";

const exampleCategory: Tag[] = [
    {
        tagId: '0',
        tagName: '전체',
        tagType: '타입',
    },
    {
        tagId: '1',
        tagName: '나들이킹',
        tagType: '타입',
    },
    {
        tagId: '2',
        tagName: '쇼핑킹',
        tagType: '타입',
    },
    {
        tagId: '3',
        tagName: '푸드킹',
        tagType: '타입',
    },
    {
        tagId: '4',
        tagName: '콘텐츠킹',
        tagType: '타입',
    },
    {
        tagId: '5',
        tagName: '스터디킹',
        tagType: '타입',
    },
    {
        tagId: '6',
        tagName: '일상킹',
        tagType: '타입',
    },
    {
        tagId: '7',
        tagName: '뷰티킹',
        tagType: '타입',
    },
]

const Promotion: React.FC = () => {
    const [event, setEvent] = useState<string>("Y");
    const [categories, setCategories] = useState<Tag[]>([]);
    const [companyList, setCompanyList] = useState<Company[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedTab, setSelectedTab] = useState('1'); // State to track the selected tab
    const navigate = useNavigate()
    useEffect(() => {
        const fetchData = async () => {
            try {
                // API 요청 병렬 실행
                const [categories] = await Promise.allSettled([
                    partnerApi.getCategories('v1'),
                    // nftApi.getCategories({}, 'v1'),
                ]);
                console.log("categories", categories)
                // setCategories(handlePromiseResult(categories)?.categoryList || []);

                setCategories(exampleCategory)
                // 카테고리별 상세 데이터 요청
                const categoryId = handlePromiseResult(categories)?.categoryList[0]?.category || null;
                if (categoryId) {
                    const categoryDetailsResult = await nftApi.getCategoryById(categoryId, {}, 'v1');
                    setCompanyList(categoryDetailsResult.body.companyList || null);
                }
            } catch (error) {
                console.error('An unexpected error occurred:', error)
            } finally {
                setIsLoading(false); // 로딩 상태 해제
            }
        };
        fetchData();
    }, []);

    const handleTabChange = (selectedItemId: string) => {
        setSelectedTab(selectedItemId);
    };

    const handlePartnerClick = (e: any) => {
        const getIndexFromEvent = (e: { id: string }): string => {
            const match = e.id.match(/-(\d+)/);
            return match ? match[1] : '';
        };
        // const partner = companyList[Number(getIndexFromEvent(e))];

        // navigate(`/partners/${partner.ptnId}`);
    };

    const renderPartnerList = () => (
        <List
            type='view'
            fill='none'
            id='partnerList'
            onChange={handlePartnerClick}
            items={companyList.map((partner) => ({
                leftSlot: [
                    <Image src={partner.logo} ratio='1:1' width='48px' rounded={'50%'} srcFallback={ExImg} />,
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}>
                        <TextSet
                            subComponent='Body_1'
                            text={{
                                eyebrow: (
                                    <>
                                        <span>텍스트</span>
                                    </>
                                ),
                                title: partner.summary,
                            }}
                        />
                    </div>,
                ],
                rightSlot: (
                    <IconButton
                        key={`icon-${partner.name}`}
                        icon='chevron_right_small_line'
                        size='large'
                        fill='icon'
                        state='default'
                        selectable
                        onClick={() => {
                            console.log('click');
                        }}
                    />
                ),
            }))}
        />
    );

    const renderNoPartner = () => (
        <div>
            <div style={styles.noPartnerContainer}>
                <Image src={emptyPartnerImg} alt='placeholder' width='120px' ratio='1:1' />
                <div style={styles.noPartnerText}>
                    <Text typo='font_detail_1M' color='color_text_neutral_3' textAlign='center'>
                        제휴사가 없습니다.
                    </Text>
                </div>
            </div>
        </div>
    );

    const renderPromotionInfo = () => (
        <div style={{ display: 'flex', flexDirection: 'column'}}>
            <Image style={{padding: '20px'}} src={ExImg} ratio='1:1' width="auto" srcFallback={ExImg} />
            {event === 'Y' ? <CommonCTAColumn handleSummit={() => navigate('/my/badge')} buttonText={"내 배지 보기"}/>  :
                <CommonCTAColumn handleSummit={() => console.log("click")} buttonText={"당첨 확인"} onClose={() => console.log("close")} cancelText={"내 배지 보기"}/>
            }
        </div>
    );

    return (
        <>
            {useLoading(isLoading)}
            <Box
                type="1_b"
                style={{
                    width: "100%"
                }}
            >
                {/* header */}
                <CommonAllIconHeader title={"킹배지"} />

                {/* 탭 메뉴 */}
                <Tab
                    items={[
                        {
                            id: "1",
                            label: "프로모션 안내"
                        },
                        {
                            id: "2",
                            label: "브랜드"
                        }
                    ]}
                    layout="fullWidth"
                    selectedItemId={selectedTab}
                    onItemSelected={handleTabChange}
                    style={{
                        padding: "0 20px"
                    }}
                />
                {/* 컨텐츠 랜더링 */}
                {selectedTab === '1' ? (
                    renderPromotionInfo()
                ) : (
                    <>
                        {/* 서브탭 메뉴 */}
                        <div style={{
                            padding: "20px 0 0 20px"
                        }}>
                            <SectionChip Tags={categories} />
                        </div>
                        {/* 정렬 */}
                        <div
                            style={{
                                padding: "12px 20px 0 20px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Text typo='font_heading_5B' color='color_text_neutral_6'>
                                총 3개
                            </Text>
                            <div style={{ width: 'auto' }}>
                                <Dropdown
                                    fill='none'
                                    items={[
                                        { id: '1', label: '인기순' },
                                        { id: '2', label: '최신순' },
                                        { id: '3', label: '가나다 순' },
                                    ]}
                                    size='medium'
                                    value='1'
                                    onChange={() => { }}
                                />
                            </div>
                        </div>
                        {companyList.length === 0 ? renderNoPartner() : renderPartnerList()}
                    </>
                )}
            </Box>
        </>
    );
};

const styles: { [key: string]: CSSProperties } = {
    noPartnerContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '88px 0',
    },
    noPartnerText: {
        paddingTop: '20px',
    },
};
export default Promotion;
