import React, {useState} from 'react';
import {Chip} from "@lguuxe/2024_designsystem_uni";

interface SectionChipProps {
    setFilter: (filter: string) => void;
}

const SectionChip: React.FC<SectionChipProps> = ({setFilter}) => {
    const [selectedFilter, setSelectedFilter] = useState<string>('전체');

    const handleFilterClick = (filter: string) => {
        setSelectedFilter(filter);
        setFilter(filter);
    };

    return (
        <div
            style={{
                padding: "16px 0 12px 20px",
                display: "flex",
                gap: "6px"
            }}
            data-gtm-event-name={'all_click'}
            data-gtm-event-category={'클릭'}
            data-gtm-event-action={'카테고리 - 링크 클릭'}
            data-gtm-event-label={'컨텐츠 : '}
            data-gtm-click-url={''}
            data-gtm-click-location={'카테고리'}
            data-gtm-click-direction={'내부'}
            data-gtm-click-text={''}>
            <Chip.Filter
                isSelected={selectedFilter === '전체'}
                color="neutral"
                label="전체"
                onClick={() => handleFilterClick('전체')}
                size="medium"
                fill="solid"
            />
            <Chip.Filter
                isSelected={selectedFilter === '혜택'}
                color="neutral"
                fill="solid"
                label="혜택"
                onClick={() => handleFilterClick('혜택')}
                size="medium"
            />
            <Chip.Filter
                isSelected={selectedFilter === '휴대폰결제'}
                color="neutral"
                fill="solid"
                label="휴대폰결제"
                onClick={() => handleFilterClick('휴대폰결제')}
                size="medium"
            />
            <Chip.Filter
                isSelected={selectedFilter === '공지'}
                color="neutral"
                fill="solid"
                label="공지"
                onClick={() => handleFilterClick('공지')}
                size="medium"
            />
            <Chip.Filter
                isSelected={selectedFilter === '이벤트'}
                color="neutral"
                fill="solid"
                label="이벤트"
                onClick={() => handleFilterClick('이벤트')}
                size="medium"
            />
            {/*<Chip.Filter*/}
            {/*    isSelected={selectedFilter === '소액결제 승인알림'}*/}
            {/*    color="neutral"*/}
            {/*    fill="solid"*/}
            {/*    label="소액결제 승인알림"*/}
            {/*    onClick={() => handleFilterClick('소액결제 승인알림')}*/}
            {/*    size="medium"*/}
            {/*/>*/}
            {/*<Chip.Filter*/}
            {/*    isSelected={selectedFilter === 'U+멤버십승인알림'}*/}
            {/*    color="neutral"*/}
            {/*    fill="solid"*/}
            {/*    label="U+멤버십승인알림"*/}
            {/*    onClick={() => handleFilterClick('U+멤버십승인알림')}*/}
            {/*    size="medium"*/}
            {/*/>*/}
            {/*<Chip.Filter*/}
            {/*    isSelected={selectedFilter === 'NFT'}*/}
            {/*    color="neutral"*/}
            {/*    fill="solid"*/}
            {/*    label="NFT"*/}
            {/*    onClick={() => handleFilterClick('NFT')}*/}
            {/*    size="medium"*/}
            {/*/>*/}
        </div>
    );
}

export default SectionChip;
