import React from "react";
import { Text, Icon, SnackBar, Box } from "@lguuxe/2024_designsystem_uni";

import "../../css/style.css";

type SnackbarPageProps = {
    isVisible: boolean;
    onClose: () => void;
    status?: "default" | "warning" | "error" | "success" | "info"; // Assuming these are the possible statuses
    useDefaultIcon?: boolean;
};

const SnackbarPage: React.FC<SnackbarPageProps> = ({ isVisible, onClose, status = "default"}) => {
    return (
        <Box style={{ padding: "0" }} >
            <SnackBar isVisible={isVisible} onClose={onClose} status={status} useDefaultIcon={false}>
                <SnackBar.CenterSlot>
                    <Text typo="font_body_2M" color="color_text_onNeutral_low">
                        쿠폰 다운이 완료되었습니다.
                    </Text>
                </SnackBar.CenterSlot>
                <SnackBar.RightSlot>
                    <Text style={{ paddingRight: "8px" }} typo="font_label_3Sb" color="color_text_onNeutral_low">
                        쿠폰함 바로가기
                    </Text>
                    <Icon color="#7F8A94" name="chevron_right_small_line" size="16px" style={{ paddingTop: "0" }} />
                </SnackBar.RightSlot>
            </SnackBar>
            {/* SnackBar 컴포넌트: 오른쪽 닫기버튼 디자인과 다름, 없애는 기능 필요/ 위치 지정 style 변경안됨 */}
        </Box>
    );
};

export default SnackbarPage;
