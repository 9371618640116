import React, {useEffect} from "react";
import {appTech, chatbot, moneymeEnter} from "../../api/externalApi";
import {useNavigate} from "react-router-dom";

const Test: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const jsonObject = {
            param: {
                targetType: "117002",
                "targetUrl": "chat"
            }
        };
        const data = jsonObject.param
        console.log('targetUrl : ', data.targetUrl)
        const [url, value] = data.targetUrl ? data.targetUrl.split('|') : [null, null];
        console.log('url : ', url)
        console.log('value : ', value)
        switch (data.targetType) {

            case '117002': // 내부 페이지 이동
                if (url === 'chat') chatbot();
                navigate(`/${url}${value ? '/' + value : ''}`);
                break;
            case '117003': // 이벤트 상세페이지

                break;
            case '117005':
                console.log('영화예매')
                break;
            case '117006': // 머니Me 메인
                moneymeEnter();
                break;
            case '117007': // 앱테크
                appTech();
                break;
        }
    }, []);
    return (
        <div>
            <h1>Test</h1>
            <div>

            </div>
        </div>
    );
}
export default Test;