export type CheckVip = {
    isVIP: boolean;
    currentServerDate: string;
    useVipkok?: {
        ptnId: string;
        ptnName: string;
        ptnLogoImgUrl: string;
        benefitSummary: string;
        useStartDate: string;
        useEndDate: string;
        isUsed: string;
    };
};

export const initialCheckVip: CheckVip = {
    isVIP: false,
    currentServerDate: '',
    useVipkok: {
        ptnId: '',
        ptnName: '',
        ptnLogoImgUrl: '',
        benefitSummary: '',
        useStartDate: '',
        useEndDate: '',
        isUsed: '',
    },
};