import React from 'react';
import {Tab} from '@lguuxe/2024_designsystem_uni';

interface TabItem {
    id: string;
    label: string;
}

interface CustomTabProps {
    items: TabItem[];
    selectedItemId: string;
    onTabChange: (id: string) => void;
}

const CustomTab: React.FC<CustomTabProps> = ({items, selectedItemId, onTabChange}) => {
    return (
        <Tab
            fill="underline"
            items={items}
            layout="fullWidth"
            selectedItemId={selectedItemId}
            size="medium"
            style={{
                padding: "0 20px",
            }}
            onItemSelected={onTabChange}
        />
    );
};

export default CustomTab;