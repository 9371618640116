import React, {useState} from 'react';
import {Box, Button, Checkbox, CTA, Popup, TextSet} from '@lguuxe/2024_designsystem_uni';

interface CommonPopupProps {
    description?: string;
    submitText: string;
    onSubmit?: () => void;
    cancelText?: string;
    onClose?: () => void;
    title?: string; // title을 선택적으로 변경
    showCheckbox?: boolean;
    onCheckbox?: () => void;
    isOpen?: boolean;
}

const CommonPopup: React.FC<CommonPopupProps> = ({
                                                     description,
                                                     submitText,
                                                     onSubmit,
                                                     cancelText,
                                                     onClose,
                                                     title,
                                                     isOpen,
                                                     showCheckbox = false,
                                                     onCheckbox
                                                 }) => {
    const [isChecked, setIsChecked] = useState(false)
    const handleSubmitButton = () => {
        if (isChecked) {
            if (onCheckbox) {
                onCheckbox()
            }
        }
        if (onSubmit) {
            onSubmit()
        }
    }
    const handleCheckBox = () => {
        setIsChecked(prevState => !prevState)
    }
    return (
        <Popup isOpen={isOpen !== undefined ? isOpen : true}>
            <Popup.CenterSlot>
                <Box style={{padding: '0', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    {title && (
                        <TextSet
                            subComponent='Heading_3'
                            text={{
                                title: title,
                            }}
                            weight='bolder'
                        />
                    )}
                    <TextSet
                        subComponent='Body_1'
                        text={{
                            title: (
                                <span style={{paddingTop: '12px', textAlign: 'center'}}>
                                    {description?.split('\n').map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br/>
                                        </React.Fragment>
                                    ))}
                                </span>
                            ),
                        }}
                        weight='default'
                    />
                </Box>
            </Popup.CenterSlot>
            <Popup.BottomSlot>
                {showCheckbox && (
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '0 0 12px 0',
                        }}
                    >
                        <Checkbox
                            label='다시보지 않기'
                            size='medium'
                            width='auto'
                            onChange={handleCheckBox}/>
                    </Box>
                )}
                <CTA layout='row'>
                    {cancelText && (
                        <Button color='neutral' size='large' onClick={onClose}>
                            {cancelText}
                        </Button>
                    )}
                    <Button color='primary' size='large' onClick={handleSubmitButton}>
                        {submitText}
                    </Button>
                </CTA>
            </Popup.BottomSlot>
        </Popup>
    );
};

export default CommonPopup;