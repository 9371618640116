// ****************************************************
// [배포] 2.미션 > 메인 (UMembershipMission)
// ****************************************************
import React, {Component, useState} from 'react';
import { Header, Text, IconButton, Box, TextSet, Image } from '@lguuxe/2024_designsystem_uni';
import { useNavigate } from 'react-router-dom';
import CommonLoginPopup from "./CommonLoginPopup";

interface TitleHeaderProps {
    title: string;
    isSearch?: boolean;
    isNotice?: boolean;
    isSetting?: boolean;
    isBackground?: boolean;
    memberStatus?: string;
}

const TitleHeader: React.FC<TitleHeaderProps> = ({
    title,
    isSearch = true,
    isNotice = true,
    isSetting = true,
    isBackground = false,
    memberStatus = "0"
}) => {
    const navigate = useNavigate();
    const [showLoginPopup, setShowLoginPopup] = useState<boolean>(false)

    const headerStyle = isBackground ? { backgroundColor: '#262A3B' } : {};
    const textStyle = isBackground ? { color: '#fff' } : {};

    return (
        <>
        <Header align='left' style={headerStyle} divider={false}>
            <Header.CenterSlot>
                <Text typo='font_heading_4Sb' color='color_text_neutral' style={textStyle}>
                    {title}
                </Text>
            </Header.CenterSlot>
            <Header.RightSlot>
                {isSearch && (
                    <IconButton
                        color='neutral'
                        fill='icon'
                        icon='search_line'
                        noStates
                        size='large'
                        onClick={() => {
                            navigate('/search');
                        }}
                    />
                )}

                {isNotice && (
                    <IconButton
                        color='neutral'
                        fill='icon'
                        icon='notification_line'
                        size='large'
                        noStates
                        onClick={() => {
                            navigate('/notice');
                        }}
                    />
                )}

                {isSetting && (
                    <IconButton
                        color='neutral'
                        fill='icon'
                        icon='setting_line'
                        size='large'
                        noStates
                        onClick={() => {
                            if(memberStatus !== "0") {
                                navigate('/setting');
                            } else {
                                setShowLoginPopup(true)
                            }

                        }}
                    />
                )}
            </Header.RightSlot>
        </Header>
        <CommonLoginPopup isOpen={showLoginPopup} onClose={() => setShowLoginPopup(false)}/>
    </>
    );
};

export default TitleHeader;
