import React, {CSSProperties, useState} from 'react';
import {Button, Image, List, TextSet} from '@lguuxe/2024_designsystem_uni';
import DotIndicator from './DotIndicator';
import {CouponItem, initCouponItem} from "../../pages/My/types";
import BS_CouponDetail from "./BS_CouponDetail";
import {formatDate} from "../../utils/dateUtiles";
import {couponApi} from "../../api/other";
import Snackbar from "../Common/Snackbar";

interface CouponGroupProps {
    couponList: CouponItem[];
    onCouponIssue: (coupon: CouponItem) => void;
}

const CouponList: React.FC<CouponGroupProps> = ({couponList, onCouponIssue}) => {
    const [showCouponDetail, setShowCouponDetail] = useState(false);
    const [selectedCoupon, setSelectedCoupon] = useState<CouponItem>(initCouponItem);
    const [showIssueSnackbar, setShowIssueSnackbar] = useState(false);

    const fetchIssueCoupon = async (coupon: CouponItem) => {
        try {
            couponApi.postCoupon(coupon.cpnId, {cpnType: coupon.cpnType}, 'v1').then((res) => {
                if (res.header.resultCode === '0000') {
                    setShowIssueSnackbar(true);
                    setTimeout(() => {
                        setShowIssueSnackbar(false);
                    }, 1500);
                    onCouponIssue(coupon);
                }
            });
        } catch (e) {
            console.log(e);
        }
    }

    const handleCouponDetail = async (coupon: CouponItem) => {
        await setSelectedCoupon(coupon);
        setShowCouponDetail(true);
    };

    const handleCouponIssue = async (coupon: CouponItem) => {
        fetchIssueCoupon(coupon);
    }

    return (
        <div style={styles.container}>
            <div style={styles.textSetContainer}>
                <TextSet
                    subComponent='Heading_4'
                    text={{
                        title: '쿠폰',
                    }}
                    blockSpacing={{top: '24px', bottom: '12px'}}
                    // isIndent={true}
                />
                {/* TextSet 컴포넌트 오류: title의 font-size: 20px로 잘못됨 디자인은 18px임 */}
            </div>
            <List
                type='view'
                fill='none'
                id='couponList'
                isSlotClickable={true}
                items={couponList.map((coupon, index) => ({
                    leftSlot: [
                        <Image
                            key={`image-${index}`}
                            src={coupon.ptnLogoImgUrl}
                            ratio='1:1'
                            width='48px'
                            rounded={'50%'}
                            onClick={() => handleCouponDetail(coupon)}
                        />,
                        <div key={`text-${index}`} style={styles.couponTextContainer}
                             onClick={() => handleCouponDetail(coupon)}>
                            <TextSet
                                subComponent='Body_1'
                                text={{
                                    eyebrow: <span>{coupon.ptnName}</span>,
                                    title: `[${coupon.cpnKey}] ${coupon.summary}`,
                                    description: `사용기간: ${formatDate(coupon.expStartDate)} ~ ${formatDate(coupon.expEndDate)}`,
                                }}
                            />
                        </div>,
                    ],
                    rightSlot: [
                        coupon.isIssuedCoupon === 'N' ? (
                            <Button
                                onClick={() => handleCouponIssue(coupon)}
                            >
                                다운로드
                            </Button>
                        ) : (
                            <Button disabled>
                                다운 완료
                            </Button>
                        ),
                    ],
                }))}
            />
            {couponList.length > 5 && <DotIndicator/>}
            <BS_CouponDetail isOpen={showCouponDetail} coupon={selectedCoupon}
                             onClose={() => setShowCouponDetail(false)}/>
            {showIssueSnackbar && <Snackbar text='쿠폰이 발급되었습니다.'/>}
        </div>
    );
};

const styles: { [key: string]: CSSProperties } = {
    container: {
        display: 'flex',
        gap: '0px',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '0',
    },
    textSetContainer: {
        width: '360px',
        backgroundColor: '#FFFFFF',
    },
    couponTextContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '0.5rem',
    },
};

export default CouponList;
