// ****************************************************
// [배포] 1.혜택 > 메인 [자사14세이상_VVIP_VIP] (HomeBenefit)
// ****************************************************
import React from "react";
import { Text } from "@lguuxe/2024_designsystem_uni";

const TextInfo: React.FC = () => {

    return (
        <>
            <div
                style={{
                    padding: "0"
                }}
            >
                <Text typo="font_heading_4B" color="color_text_natural_3" style={{ color: "#66707A", fontSize: "14px", fontWeight: "normal" }}>
                    (주)LG유플러스는 ‘통신 판매 중개자’로서, 제휴사가 안내하는 상품 정보, 제휴사와의 거래 등을 책임지지 않습니다.
                </Text>
                {/* Text 컴포넌트:  font typo 디자인에 없음 */}
            </div>
        </>
    );
};

export default TextInfo;
