import React from 'react';
import {Button} from "@lguuxe/2024_designsystem_uni";
import {fetchAuth, fetchAuthConfirm} from "../../api/api";

const AuthTest: React.FC = () => {
    const [token, setToken] = React.useState<string>("");
    const handleClick = () => {
        fetchAuth('72', '01000001222')
            .then(response => {
                console.log('Response:', response);
                // 여기서 response를 처리합니다
                setToken(response.body.token);
            })
            .catch(error => {
                console.error('Error:', error);
            });

    }

    const handleClick2 = () => {
        fetchAuth('71', '01000001221')
            .then(response => {
                console.log('Response:', response);
                // 여기서 response를 처리합니다
                setToken(response.body.token);
            })
            .catch(error => {
                console.error('Error:', error);
            });

    }

    const handleClick3 = () => {
        fetchAuth('70', '01000001220')
            .then(response => {
                console.log('Response:', response);
                // 여기서 response를 처리합니다
                setToken(response.body.token);
            })
            .catch(error => {
                console.error('Error:', error);
            });

    }

    const handleClickConfirm = () => {
        fetchAuthConfirm(token).then(response => {
            console.log('Response:', response);
            // 여기서 response를 처리합니다

        }).catch(error => {
            console.error('Error:', error);
        });
    }

    return (
        <div>
            <h1>AuthTest</h1>
            {/*<Link to='/'>Home</Link>*/}
            <Button onClick={handleClick}>인증요청1 (jwt 토큰발급)</Button>
            <Button onClick={handleClick2}>인증요청2 (jwt 토큰발급)</Button>
            <Button onClick={handleClick3}>인증요청3 (jwt 토큰발급)</Button>
            <Button onClick={handleClickConfirm}>인증 확인 (토큰 유효 확인)</Button>
            <h2>Token: {token}</h2>
        </div>
    );
}

export default AuthTest;