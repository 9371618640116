import React from 'react';
import {Image, List, Text} from '@lguuxe/2024_designsystem_uni';

export interface CarouselItem {
    id: string;
    name: string;
    imgUrl: string;
    content: string;
}

interface CommonCarouselProps {
    carouselItemList: CarouselItem[];
}

const CommonCarousel: React.FC<CommonCarouselProps> = ({carouselItemList}) => {
    // TODO: 임시 데이터 제거
    // carouselItemList = templateCarouselItemList;
    return (
        <>
            <List
                fill='none'
                name='list_name'
                size='large'
                type='view'
                weight='default'
                items={[
                    {
                        leftSlot: [
                            <div
                                style={{
                                    overflow: 'auto',
                                    display: 'flex',
                                    width: '320px', // 임시로 320px잡음
                                    gap: '8px',
                                }}
                            >
                                {carouselItemList.map((item, index) => (
                                    <div
                                        key={index}
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            gap: '6px',
                                            minWidth: '104px',
                                        }}
                                    >
                                        <Image
                                            src={item.imgUrl}
                                            alt='제휴이미지'
                                            width='56'
                                            ratio='1:1'
                                            rounded={54.86}
                                        />
                                        <div
                                            style={{
                                                gap: '2px',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Text typo='font_body_1B' color='color_text_neutral_6'>
                                                {item.name}
                                            </Text>
                                            <Text typo='font_body_3M' color='color_text_neutral_3'>
                                                {item.content}
                                            </Text>
                                        </div>
                                    </div>
                                ))}
                            </div>,
                        ],
                    },
                ]}
            />
        </>
    );
};

export default CommonCarousel;
