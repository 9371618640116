import React from 'react';
import { CTA, Button } from "@lguuxe/2024_designsystem_uni";

interface SectionCTABtn2Props {
    status: string
    buttonDisable: boolean
}
const SectionCTABtn2: React.FC<SectionCTABtn2Props> = ({status, buttonDisable}) => {
    return (
        <div
            style={{
                position: "absolute",
                bottom: "0",
                width: "100%",
            }}
        >
            <CTA layout="row">
                <Button color="neutral">
                    취소
                </Button>
                <Button color="primary" disabled={buttonDisable}>
                    변경하기
                </Button>
            </CTA>
        </div>
    );
}

export default SectionCTABtn2;
