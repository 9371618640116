import React from 'react';
import {Button, Text} from '@lguuxe/2024_designsystem_uni';
import {useNavigate} from "react-router-dom";

const BarcodeBlock: React.FC = () => {
    const navigete = useNavigate();

    const handleClick = () => {
        navigete('/mobile-pay');
    }
    return (
        <>
            <div
                style={{
                    padding: '20px 0',
                    backgroundColor: '#F3F5F6',
                    borderRadius: '12px',
                    width: '100%',
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '12px',
                        padding: '16.5px 22px',
                    }}
                >
                    <Text typo='font_body_1M' color='color_text_neutral_3' textAlign='center'>
                        휴대결제 차단이 설정되어 있습니다.
                        <br/>
                        차단 해제 후 이용해 주세요.
                    </Text>
                    <Button as='button' color='secondary' fill='solid' size='medium' state='default'
                            onClick={handleClick}>
                        차단해제 하기
                    </Button>
                </div>
            </div>
        </>
    );
};

export default BarcodeBlock;
