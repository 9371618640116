// ExceedPopup.tsx
import React from 'react';
import { Popup, Box, TextSet, CTA, Button } from '@lguuxe/2024_designsystem_uni';

interface ExceedPopupProps {
    isOpen: boolean;
    onClose: () => void;
}

const ExceedPopup: React.FC<ExceedPopupProps> = ({ isOpen, onClose }) => {
    return (
        <Popup isOpen={isOpen}>
            <Popup.CenterSlot>
                <Box style={{ padding: '0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <TextSet
                        subComponent='Body_1'
                        text={{
                            title: (
                                <>
                                    <span style={{ paddingTop: '12px', textAlign: 'center' }}>
                                        인증번호 요청 가능 횟수를
                                        <br />
                                        초과하였습니다.
                                        <br />
                                        10분 후 다시 요청해주세요.
                                    </span>
                                </>
                            ),
                        }}
                        weight='default'
                    />
                </Box>
            </Popup.CenterSlot>
            <Popup.BottomSlot>
                <CTA layout='column'>
                    <Button color='primary' onClick={onClose}>
                        확인
                    </Button>
                </CTA>
            </Popup.BottomSlot>
        </Popup>
    );
};

export default ExceedPopup;