import React from 'react';
import { Header, IconButton, Text } from "@lguuxe/2024_designsystem_uni";

// Define a type for the props
interface CommonRightIconHeaderProps {
    title: string;
    icon: string;
    onClick: () => void;
}

const CommonRightIconHeader: React.FC<CommonRightIconHeaderProps> = ({ title, icon, onClick }) => {
    return (
        <Header
            align="center"
            variant="heading"
            divider={false}
        >
            <Header.CenterSlot>
                <Text
                    typo="font_heading_4Sb"
                    style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                    {title}
                </Text>
            </Header.CenterSlot>
            <Header.RightSlot>
                <IconButton
                    color="neutral"
                    fill="icon"
                    icon={icon}
                    noStates
                    onClick={onClick}
                />
            </Header.RightSlot>
        </Header>
    );
};

export default CommonRightIconHeader;
