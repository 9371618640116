import React, { useEffect, useState } from 'react';
import { Box, CTA, Button } from '@lguuxe/2024_designsystem_uni';
import { useNavigate } from "react-router-dom";
import commonApi from "../../api/common";
import useLoading from "../../hooks/useLoading";
import { termsApi } from "../../api/settingAndTerms";
import SectionBodyText from "../../components/Agreements/SectionBodyText";
import CommonRightIconHeader from "../../components/Common/CommonRightIconHeader";

interface AgreementsDetailProps {
    type?: string;
    termId?: string;
    selected: boolean; // 선택 상태를 추가
    handleClose: () => void;
    handleSubmit?: () => void;
    tempTermTitle?: string;
}

const AgreementsDetail: React.FC<AgreementsDetailProps> = ({ type, termId, selected, handleClose, handleSubmit , tempTermTitle=''}) => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [termTitle, setTermTitle] = useState<string>('');
    const [termContent, setTermContent] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTerm = async () => {
            if (!termId) {
                console.error("termId가 정의되지 않았습니다.");
                setIsLoading(false);
                return;
            }
            if (!type) {
                console.error("type이 정의되지 않았습니다.");
                setIsLoading(false);
                return;
            }

            try {
                let response;
                let data;
                if (type === "join") {
                    response = await termsApi.getJoinAgreementsById(termId, {}, 'v1');
                    data = response.body.termsInfo;
                    setTermTitle(data.termTitle);
                    setTermContent(data.termContent);
                } else if (type === "exist") {
                    response = await termsApi.getAgreementsById(termId, {}, 'v1');
                    data = response.body;
                    setTermTitle(tempTermTitle);
                    setTermContent(data.termContents);
                } else {
                    response = await commonApi.terms.getAgreementsById(termId, {}, 'v1');
                }
            } catch (error) {
                console.error("Error fetching detail:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchTerm();
    }, [termId, type]);

    return (
        <>
            {useLoading(isLoading)}
            {/* 상단의 약관 제목 */}
            <CommonRightIconHeader icon={"close_line"} title={termTitle|| ''} onClick={handleClose} />

            {/* 약관 내용 */}
            <SectionBodyText text={termContent} />

            {/* 동의하기/철회하기 버튼 */}
            {handleSubmit &&
            <Box style={{ position: "fixed", bottom: "0", width: "100%", padding: "0" }}>
                <CTA gradation layout="column">
                    <Button color="primary" size="large" onClick={handleSubmit}>
                        {selected ? "철회하기" : "동의하기"}
                    </Button>
                </CTA>
            </Box>}
        </>
    );
};

export default AgreementsDetail;
