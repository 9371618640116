import React, { useState, useEffect, useRef } from "react";
import {UptpData, UptpEvent} from "../../pages/Home/types";
import { Text } from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";
import {formatDateToYYYYMMDD} from "../../utils/helper";
import {sendEventReturn} from "../../api/bridgeApi";
import CommonLoginPopup from "../Common/CommonLoginPopup";

interface EventAllProps {
    uptp: UptpData;
    userStatus: string;
}

const EventAll: React.FC<EventAllProps> = ({  uptp , userStatus}) => {
    const navigate = useNavigate();

    // 상태로 메인 이미지를 관리
    const [mainEvent, setMainEvent] = useState<UptpEvent>();
    const [selectedEventId, setSelectedEventId] = useState<string | null>(null); // 선택된 이벤트의 ID를 저장하는 상태
    const [containerMaxHeight, setContainerMaxHeight] = useState<number | undefined>(undefined);
    const [isLogin, setIsLogin] = useState<boolean>(false)
    const mainImageRef = useRef<HTMLImageElement>(null); // 메인 이미지에 대한 참조

    const filteredEvents = uptp.uptpList ? uptp.uptpList.filter((event) => {
        const eventDateString = formatDateToYYYYMMDD(new Date(event.startDate)); // event.startDate가 이미 YYYYMMDD 형식으로 되어있다고 가정
        return eventDateString >= uptp.currentMonth;
    }) : [];

    useEffect(() => {
        if (filteredEvents.length > 0 && !mainEvent) {
            setMainEvent(filteredEvents[0]);
            setSelectedEventId(filteredEvents[0].evtId);
        }
    }, [filteredEvents, mainEvent]);

    const updateContainerMaxHeight = () => {
        if (mainImageRef.current) {
            setContainerMaxHeight(mainImageRef.current.clientHeight);
        }
    };

    useEffect(() => {
        updateContainerMaxHeight();
        window.addEventListener('resize', updateContainerMaxHeight);

        return () => {
            window.removeEventListener('resize', updateContainerMaxHeight);
        };
    }, []);

    const handleEventClick = () => {
        if(userStatus === "0") {
            setIsLogin(true)
        } else {
            sendEventReturn('WAITING_LINE_POPUP',{})
            navigate(`/uptp-detail/${mainEvent?.targetLink}`)
        }
    }

    const getLabel = (startDate: string) => {
        const eventStartDateNumber = parseInt(formatDateToYYYYMMDD(new Date(startDate)),10); // event의 시작 날짜를 숫자로 변환
        const currentMonthNumber = parseInt(uptp.currentMonth, 10); // 현재 월을 숫자로 변환

        if (eventStartDateNumber === currentMonthNumber) {
            return "Today";
        } else if (eventStartDateNumber > currentMonthNumber) {
            // 단순 숫자 차이 계산
            const diffDays = Math.floor((eventStartDateNumber - currentMonthNumber) % 100); // 날짜 차이만 추출
            return `D-${diffDays}`;
        } else {
            return null;
        }
    };
    return (
        <>
            <div style={{ padding: "0 20px" }}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "20px 0 0 0",
                        gap: "8px",
                        justifyContent: "space-between",
                        maxHeight: containerMaxHeight ? `${containerMaxHeight}px` : "auto", // 동적으로 maxHeight 설정
                        overflow: "hidden", // 필요에 따라 추가 (이미지가 컨테이너를 넘지 않도록)
                    }}
                >
                    {/* 메인 큰 이미지 */}
                    {filteredEvents.length > 0 && mainEvent && (
                        <div
                            className="event_big"
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "flex-start",
                                flexWrap: "wrap",
                                alignItems: "stretch",
                                width: "100%",
                                minWidth: "240px",
                                minHeight: "312px"
                            }}
                        >
                            <img
                                ref={mainImageRef} // 메인 이미지에 ref 추가
                                src={mainEvent.mainImgUrl || ""}
                                style={{
                                    borderRadius: "16px",
                                    width: "100%",
                                }}
                                onLoad={updateContainerMaxHeight} // 이미지 로드 후 높이 설정
                                onClick={handleEventClick}
                                alt={filteredEvents[0]?.categoryName || "Event Image"}
                            />
                        </div>
                    )}

                    {/* 작은 썸네일 이미지들 */}
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            padding: "0",
                            gap: "3px",
                            position: "relative",
                            width: "24%",
                            minWidth: "72px",
                            overflowY: "auto",
                            overflowX: "hidden",
                        }}
                    >
                        {filteredEvents.map((event, index) => {
                            const label = getLabel(event.startDate);
                            const isSelected = event.evtId === selectedEventId; // 해당 이벤트가 선택되었는지 확인
                            return (
                                <div key={event.evtId}
                                     className={`event_small hover_arrow ${isSelected ? 'selected' : ''}`} // 선택된 경우 'selected' 클래스 추가
                                     style={{
                                         width: "100%",
                                         position: "relative"
                                     }}>
                                    {label && (
                                        <div style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            padding: "7px",
                                            borderRadius: "4px",
                                            color: "white"
                                        }}>
                                            <Text typo='font_body_2Sb'>
                                                {label}
                                            </Text>
                                        </div>
                                    )}
                                    <img
                                        src={event.thumbImgUrl || ""}
                                        onClick={() => {
                                            setMainEvent(event || ""); // 클릭된 이미지를 메인 이미지로 설정
                                        }}
                                        alt={event.categoryName || "Event Thumbnail"}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <CommonLoginPopup isOpen={isLogin} onClose={() => setIsLogin(false)}/>
        </>
    );
};

export default EventAll;
