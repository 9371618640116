// BoxDotIndicator
import React from 'react';
import { Header, IconButton } from '@lguuxe/2024_designsystem_uni';

const DotIndicator: React.FC = () => {
    return (
        <div
            style={{
                display: 'flex',
                width: '100%',
                padding: '20px 0',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    padding: '4px 0px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '6px',
                }}
            >
                <span
                    style={{
                        backgroundColor: '#66707A',
                        width: '18px',
                        height: '6px',
                        borderRadius: '24px',
                        cursor: 'pointer',
                    }}
                ></span>
                <span
                    style={{
                        backgroundColor: '#66707A',
                        width: '6px',
                        height: '6px',
                        borderRadius: '50%',
                        opacity: '0.4',
                        cursor: 'pointer',
                    }}
                ></span>
                <span
                    style={{
                        backgroundColor: '#66707A',
                        width: '6px',
                        height: '6px',
                        borderRadius: '50%',
                        opacity: '0.4',
                        cursor: 'pointer',
                    }}
                ></span>
                <span
                    style={{
                        backgroundColor: '#66707A',
                        width: '6px',
                        height: '6px',
                        borderRadius: '50%',
                        opacity: '0.4',
                        cursor: 'pointer',
                    }}
                ></span>
                <span
                    style={{
                        backgroundColor: '#66707A',
                        width: '6px',
                        height: '6px',
                        borderRadius: '50%',
                        opacity: '0.4',
                        cursor: 'pointer',
                    }}
                ></span>
            </div>
        </div>
    );
};

export default DotIndicator;
