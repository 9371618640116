import React, {useEffect} from 'react';
import {IconButton, List, Tag, Text} from '@lguuxe/2024_designsystem_uni';
import {useNavigate} from 'react-router-dom';
import {PaymentStatus, TabType} from './types';
import {formatCurrency} from "../../utils/currencyUtils";
import {MembershipInfo} from "../../pages/Home/types";
import {getItemByKey} from "../../utils/indexedDB";

interface CardInfoProps {
    tabType: TabType;
    cardInfo: MembershipInfo;
    paymentStatus: PaymentStatus
}

const CardInfo: React.FC<CardInfoProps> = ({tabType, cardInfo, paymentStatus}) => {
    const navigate = useNavigate();
    const [tagList, setTagList] = React.useState<any[]>([]);
    const [userInfo, setUserInfo] = React.useState<any>({});

    useEffect(() => {
        const fetchIndexedDb = async () => {
            const name = await getItemByKey('customerName');
            const payExistYn = await getItemByKey('payExistYn');
            const memberStatus = await getItemByKey('memberStatus');
            setUserInfo({name, payExistYn, memberStatus});

            if (memberStatus === '5') {
                setTagList([{colorToken: 'default', label: '일반', size: 'xSmall', style: 'solid'}]);
            }
        }
        fetchIndexedDb();
    }, []);


    useEffect(() => {
        const tags = [];
        if (cardInfo.isVIP === 'Y') {
            tags.push({colorToken: 'purple_low', label: 'VIP', size: 'xSmall', style: 'solid'});
        }
        if (cardInfo.longTermCode === 'Y') {
            tags.push({colorToken: 'blue_low', label: cardInfo.longTermName, size: 'xSmall', style: 'solid'});
        }
        if (cardInfo.youthYn === 'Y') {
            tags.push({colorToken: 'green_low', label: '유쓰', size: 'xSmall', style: 'solid'});
        }

        setTagList(tags);
    }, [cardInfo]);

    const handleNameClick = (event: any) => {
        navigate('/my/benefit');
    };

    return (
        <>
            <div
                style={{
                    border: '1px solid #E7EBEE',
                    borderRadius: '12px',
                    width: '100%',
                    padding: userInfo.memberStatus === '5' ? '8px 0 8px' : '8px 0 20px',
                }}
            >
                <List
                    id='cardName'
                    fill='none'
                    items={[
                        {
                            leftSlot: [
                                <div style={{display: 'flex', gap: '6px'}}>
                                    <Text type='font_heading_4B' color='color_text_neutral_6'>
                                        {userInfo.name}님
                                    </Text>
                                    <Tag
                                        item={tagList}
                                    />
                                </div>,
                            ],
                            rightSlot: <IconButton size='small' fill='ghost' icon='chevron_right_small_line'
                                                   data-gtm-event-name={'all_click'}
                                                   data-gtm-event-category={'클릭'}
                                                   data-gtm-event-action={tabType === 'membership' ? '바코드|멤버십' : '바코드|휴대폰 결제' + '- 링크 클릭'}
                                                   data-gtm-event-label={'컨텐츠 : 멤버십 더보기'}
                                                   data-gtm-click-url={''}
                                                   data-gtm-click-location={tabType === 'membership' ? '바코드|멤버십' : '바코드|휴대폰 결제'}
                                                   data-gtm-click-direction={'내부'}
                                                   data-gtm-click-text={'멤버십 더보기'}
                            />,
                        },
                    ]}
                    type='view'
                    onChange={handleNameClick}
                />
                {userInfo.memberStatus !== '5' && (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            padding: '0 20px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                padding: '4px 0',
                            }}
                        >
                            <Text style={{flex: '1'}} typo='font_body_1M' color='color_text_neutral_4'>
                                {tabType == 'membership' ? '올해 받은 혜택' : '사용 가능 금액'}
                            </Text>
                            <Text typo='font_heading_5Sb' color='color_text_primary'>
                                {tabType == 'membership' && (cardInfo.totalDiscount === '0' ? '포인트 무제한' : formatCurrency(cardInfo.totalDiscount))}
                                {tabType == 'payment' && (userInfo.payExistYn === 'Y' ? formatCurrency(paymentStatus.avlAmt) : '휴대폰결제 차단')}
                            </Text>
                        </div>
                    </div>)}

            </div>
        </>
    );
};

export default CardInfo;
