import React from 'react';
import {Box, List, TextSet, Checkbox, Icon, IconButton} from '@lguuxe/2024_designsystem_uni';
import CommonDividerThin from '../Common/CommonDividerThin';
import { Agreement, AgreementType } from '../../pages/Agreements/types';
import {useNavigate} from "react-router-dom";

interface SectionChkListProps {
    agreementList: Agreement[];
    allCheck: boolean;
    onAgreementChange: (termId: string, isChecked: boolean) => void;
    onAllAgreementsChange: (isChecked: boolean) => void;
    type: AgreementType;
    onTermClick: (termId: string) => void;
}

const SectionChkList: React.FC<SectionChkListProps> = ({ agreementList, allCheck, onAgreementChange, onAllAgreementsChange, type, onTermClick }) => {
    const navigate = useNavigate()
    const handleCheckboxChange = (termId: string) => {
        const agreement = agreementList.find((term) => term.termId === termId);
        if (agreement) {
            onAgreementChange(termId, !agreement.selected);
        }
    };

    const handleAllAgreementsChange = () => {
        onAllAgreementsChange(!allCheck);
    };

    const getTermType = (isRequired: boolean): string => {
        return isRequired ? '필수' : '선택';
    };

    return (
        <Box type="3_tb">
            <List
                fill="none"
                size="large"
                items={[
                    {
                        checked: allCheck,
                        text: {
                            title: (
                                <TextSet
                                    subComponent="Body_1"
                                    text={{
                                        title: <span style={{ color: "#181A1B" }}>모든 이용약관에 동의</span>,
                                    }}
                                    weight="bolder"
                                />
                            ),
                        },
                    },
                ]}
                type="checkbox"
                onChange={handleAllAgreementsChange}
            />
            <Box style={{ padding: "0 20px" }}>
                <CommonDividerThin />
            </Box>
            <Box style={{ padding: "0" }}>
                <List
                    fill="none"
                    size="medium"
                    type="checkbox"
                    items={agreementList.map((agreement) => ({
                        checked: agreement.selected,
                        id: agreement.termId,
                        leftSlot: [
                            <div style={{flexDirection: "row", display: "flex", justifyContent: "space-between"}}>
                                <TextSet
                                    subComponent="Body_1"
                                    text={{
                                        title: (
                                            <span style={{ color: "#181A1B" }}>
                                                [{getTermType(agreement.isRequired)}] {agreement.termName}
                                            </span>
                                        ),
                                    }}
                                />
                            </div>
                        ],
                        rightSlot: [
                            <>
                                <IconButton
                                    color='neutral'
                                    fill='icon'
                                    size={"large"}
                                    icon='chevron_right_small_line'
                                    onClick={() => onTermClick(agreement.termId)}
                                      noStates
                                />
                            </>
                        ]
                    }))}
                    onChange={(e) => handleCheckboxChange(e.id)}
                />
                {/*<div*/}
                {/*    style={{*/}
                {/*        backgroundColor: "#F3F5F6",*/}
                {/*        borderRadius: "12px",*/}
                {/*        margin: "0 20px",*/}
                {/*        padding: "12px 20px",*/}
                {/*        display: "flex",*/}
                {/*        alignItems: "center",*/}
                {/*        justifyContent: "space-between",*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Checkbox fill="icon" checked>*/}
                {/*        혜택 정보, 광고 수신 동의*/}
                {/*    </Checkbox>*/}
                {/*    <Icon color="#7f8a94" name="chevron_right_small_line" size="24px" />*/}
                {/*</div>*/}
            </Box>
        </Box>
    );
};

export default SectionChkList;
