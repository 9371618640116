// Define types for the API responses
export type VipInfo = {
    cardLevelInfo: string;
    cardLevel: string;
    cardLevelName: string;
    useYn: string;
};

export interface History {
    totalCnt: string;            // 총 갯수
    pointUseList?: PointUseItem[];  // 포인트 사용 목록 (Optional)
}

export interface PointUseItem {
    bizCatCode: string;   // 업종코드
    useSiteNm: string;    // 사용처명
    useKokYn: string;     // 콕혜택 사용여부 (Y 인것만 노출)
    usePt: string;        // 사용포인트 ('-' 이면 적립, '+' 이면 사용)
    useDt: string;        // 사용일자 (ex. yyyy-mm-dd HH24:mi:ss)
    coopCode: string;     // 제휴사 코드
}

export type UseVipkok = {
    ptnId: string;
    ptnName: string;
    ptnLogoImgUrl: string;
    benefitSummary: string;
    useStartDate: string;
    useEndDate: string;
    isUsed: string;
};


export type Category = {
    categoryId: string;
    categoryName: string;
    categoryType: string;
};

export type Item = {
    ptnId: string;
    ptnLogoImgUrl: string;
    pntName: string;
    benefitSummary: string;
    categoryName: string;
};

export type PartnerDetail = {
    ptnId: string;
    ptnName: string;
    ptnLogoImgUrl: string;
    ptnImgUrl: string;
    phone?: string;
    homepage?: string;
    ptnKokType: string;
    benefit?: string;
    benefitLimit?: string;
    notice?: string;
    usageInfo?: string;
    couponId?: string;
    couponIssueYn?: string;
};

export enum VipStatus {
    VIP_NOT_USED = "VIP_NOT_USED",
    VIP_USED = "VIP_USED",
    EXCELLENT = "EXCELLENT",
    GENERAL = "GENERAL",
    NOT_LOGGED_IN = "NOT_LOGGED_IN"
}


//TODO 추후삭제
export type BenefitItem = {
    ptnId: string;
    ptnLogoImgUrl: string;
    pntName: string;
    benefitSummary: string;
    startDate: string;
    endDate: string;
};





