import React, { useState } from 'react';
import { CTA, Button, Checkbox, Box } from '@lguuxe/2024_designsystem_uni';

interface SectionChkCTAProps {
    setOpenPopup: React.Dispatch<React.SetStateAction<boolean>>;
    selectedReason: string | null
}

const SectionChkCTA: React.FC<SectionChkCTAProps> = ({ setOpenPopup, selectedReason }) => {
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    const handleCheckboxChange = (e: any) => {
        if(e.value === '선택되었습니다') {
            setIsCheckboxChecked(true);
        } else {
            setIsCheckboxChecked(false);
        }
    };

    return (
        <Box
            style={{
                position: "fixed",
                bottom: "0",
                width: "100%",
                padding: "0"
            }}
        >
            <CTA
                gradation
                layout="row"
            >
                <Button color="neutral">
                    취소
                </Button>
                <Button
                    color="primary"
                    disabled={!(isCheckboxChecked && selectedReason)}
                    onClick={() => setOpenPopup(true)}
                >
                    회원탈퇴
                </Button>
                <Checkbox
                    label="안내사항을 모두 확인하였으며, 이에 동의합니다."
                    size="medium"
                    width="auto"
                    weight="default"
                    style={{ backgroundColor: "#fff" }}
                    onChange={(e) => handleCheckboxChange(e)}
                />
            </CTA>
        </Box>
    );
}

export default SectionChkCTA;
