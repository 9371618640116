import CommonPopup from "./CommonPopup";
import React from "react";
import {useNavigate} from "react-router-dom";

interface CommonLoginPopupProps {
    isOpen: boolean;
    onClose: () => void;
}

const CommonLoginPopup: React.FC<CommonLoginPopupProps> = ({isOpen, onClose}) => {
    const navigate = useNavigate();

    const handleGoLogin = () => {
        navigate('/identity-verification?target=web');
    }

    return (
        <>
            <CommonPopup onSubmit={handleGoLogin} submitText='본인인증하기' description='본인인증 후 이용할 수 있어요.' cancelText='취소'
                         onClose={onClose} isOpen={isOpen}/>
        </>

    )
}

export default CommonLoginPopup;