import React from "react";
import { Box, Text, Image } from "@lguuxe/2024_designsystem_uni";
import ExImg from "../../../images/default/default thumbnail.svg"
import youth from "../../../images/guide/intro/youth.png"

const YouthTab: React.FC = () => {
    return (
        <Box style={{ display: "flex", flexDirection: "column", gap: "12px", padding: "24px 0 12px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "12px", padding: "0" }}>
                <Text typo="font_heading_4B" color="color_text_neutral_6" style={{ fontSize: "18px", fontWeight: "800", padding: "0 20px" }}>
                    유쓰
                </Text>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "8px", padding: "0 20px" }}>
                <ul style={{ padding: "0 0 0 20px", margin: "0", fontSize: "14px", color: "#66707A" }}>
                    <li>유쓰는 매일매일 성장하고 싶은 20대를 위한 LG U+의 20대 전용 브랜드예요.</li>
                    <li>매월 20일, ‘유쓰’에게 인기 있는 제품을 선물로 받거나 이벤트에 참여할 수 있어요.</li>
                </ul>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "16px", padding: "4px 20px" }}>
                <Image
                    src={youth}
                    alt="placeholder"
                    width="100%"
                    ratio="4:3"
                />
            </div>
        </Box>
    );
};

export default YouthTab;
