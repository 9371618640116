import React from 'react';
import { CTA, Button } from "@lguuxe/2024_designsystem_uni";

interface CommonCTAColumnProps {
    handleSummit: () => void;
    buttonText: string;
    cancelText? :string;
    onClose? : () => void;
}

const CommonCTAColumn: React.FC<CommonCTAColumnProps> = ({ buttonText, handleSummit, cancelText, onClose }) => {

    return (
        <CTA layout="row">
            {cancelText && (
                <Button color="neutral" size="large" onClick={onClose}>
                    {cancelText}
                </Button>
            )}
            <Button color="primary" size="large" onClick={handleSummit}>
                {buttonText}
            </Button>
        </CTA>
    );
}

export default CommonCTAColumn;
