import React from 'react';
import { TextSet, List, Image, Button, Text } from "@lguuxe/2024_designsystem_uni";

interface CouponItemProps {
    itemList: {
        itemType: string;
        itemName: string;
        itemId: string;
        itemImgUrl: string;
        itemSummary: string;
        itemStartDate: string;
        itemEndDate: string;
    }[];
    threshold?: number;
}

const CouponItem: React.FC<CouponItemProps> = ({ itemList, threshold }) => {
    const itemsToRender = itemList.slice(0, threshold ?? itemList.length);
    return (
        <div>
            <List
                fill="none"
                name="list_name"
                size="large"
                type="view"
                weight="default"
                isSlotClickable={true}
                items={itemsToRender.map(item => ({
                    leftSlot: [
                        <Image
                            key={item.itemId}
                            ratio="1:1"
                            rounded="55"
                            src={item.itemImgUrl}
                            width="48"
                        />,
                        <div key={item.itemId + '_details'}>
                            <TextSet
                                subComponent="Body_1"
                                text={{
                                    title: item.itemSummary,
                                    description: (
                                        <Text typo="font_detail_2M">
                                            {item.itemStartDate.slice(0, 4)}.{item.itemStartDate.slice(4, 6)}.{item.itemStartDate.slice(6, 8)} ~ {item.itemEndDate.slice(0, 4)}.{item.itemEndDate.slice(4, 6)}.{item.itemEndDate.slice(6, 8)}
                                        </Text>
                                    ),
                                    eyebrow: item.itemName
                                }}
                            />
                        </div>
                    ],
                    rightSlot: [
                        <Button key={item.itemId + '_button'}>버튼명</Button>
                    ]
                }))}
            />
        </div>
    );
}

export default CouponItem;