import React, { FC, useState } from 'react';
import { Box, Radio, TextArea, TextSet } from "@lguuxe/2024_designsystem_uni";
import {_OnChangeEvent} from "@lguuxe/2024_designsystem_uni/dist/esm/types/components/Search/SearchTypes";

interface SectionRadioListProps {
    onRadioChange: (reason: string) => void;
}

const SectionRadioList: FC<SectionRadioListProps> = ({ onRadioChange }) => {
    const [selectedReason, setSelectedReason] = useState<string | null>(null);
    const [showTextArea, setShowTextArea] = useState<boolean>(false);
    const [textAreaValue, setTextAreaValue] = useState<string>("");

    const reasonOptions = [
        "자주 사용하지 않아요",
        "회원 혜택이 없어요",
        "시스템 오류가 잦아요",
        "개인 정보 및 보안이 걱정돼요",
        "사용하기 어려워요",
        "기타 (직접 입력)"
    ];

    const handleChange = (eve: any) => {
        const selectedId = parseInt(eve.id, 10);
        const selectedLabel = reasonOptions[selectedId - 1];

        if (eve.id === "6") {
            setShowTextArea(true);
            setSelectedReason("");
        } else {
            setShowTextArea(false);
            setSelectedReason(selectedLabel);
            onRadioChange(selectedLabel);
        }
        console.log(selectedReason)
    };

    const handleTextAreaChange = (event: _OnChangeEvent) => {
        console.log(event)
        const value = event.value;
        setTextAreaValue(value);
        setSelectedReason(value);
        onRadioChange(value);
    };

    return (
        <>
            <Box type="2_trbl" style={{ display: 'flex', flexDirection: 'column' }}>
                <TextSet
                    subComponent="Heading_4"
                    text={{
                        description: (
                            <>
                                <span>
                                    고객님의 소중한 의견을 반영하여 더 나은 서비스를
                                    <br />
                                    제공하겠습니다.
                                </span>
                            </>
                        ),
                        title: '회원 탈퇴 사유를 선택해 주세요',
                    }}
                    weight="default"
                />
            </Box>
            <div style={{ display: 'flex', flexDirection: 'column', padding: '0px 12px 10px 12px' }}>
                <Radio.Group weight="bolder" name="radiogroup" onChange={handleChange}>
                    {reasonOptions.map((label, index) => (
                        <Radio label={label} id={(index + 1).toString()} key={index} />
                    ))}
                </Radio.Group>
                {showTextArea && (
                    <div style={{ padding: "10px 5px 0px 5px" }}>
                        <TextArea
                            maxLength={100}
                            placeholder="탈퇴 이유를 작성해 주세요."
                            rows={4}
                            status="default"
                            value={textAreaValue}
                            onChange={handleTextAreaChange}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default SectionRadioList;
