export interface PartnerDetailData {
    ptnId: string;
    ptnName: string;
    ptnLogoImgUrl: string;
    isVIPKok: string;
    isMembershipAliance: string;
    isLike: string;
    phone?: string;
    summary: string;
    youthYn: string;
    longTermYn: string;
    locYn: string;
    locType?: string;
    addr?: string;
    homepage?: string;
    likeNum: string;
    couponAvailable: string;
    vipSummary?: string;
    vipBnf?: string;
    vipBnfLimit?: string;
    vipNotice?: string;
    vipGuide?: string;
    metaTag?: string;
    benefitInfoList?: BenefitInfo[];
    usageNoticeList?: UsageNotice[];
    imgList?: Img[];
    menuList?: Menu[];
}

export interface BenefitInfo {
    benefitCode: string;
    benefitName: string;
    benefitSummary: string;
}

export interface UsageNotice {
    code: string;
    content: string;
}

export interface Img {
    imgUrl: string;
}

export interface Menu {
    menuName: string;
    price: string;
}

export interface VipKokInfo {
    ptnId: string;
    ptnName: string;
    vipSummary: string;
    ptnLogoImgUrl: string;
    vipKokUsed?: string; // 당월 VIP콕 이용여부 (Y: 사용)
    vipBnf?: string; // VIP콕 혜택 정보
    vipBnfLimit?: string; // VIP콕 혜택 한도
    vipNotice?: string; // VIP콕 꼭 확인하세요
    vipGuide?: string; // VIP콕 이용 방법
}

export type PartnerType = 'normal' | 'coupon' | 'movie';

export type InitialState = '미사용' | '다운로드' | '사용완료';
export type UserGrade = '미로그인' | '일반' | '우수' | 'VIP/VVIP';

export const initialPartnerDetailData: PartnerDetailData = {
    ptnId: '',
    ptnName: '',
    ptnLogoImgUrl: '',
    isVIPKok: '',
    isMembershipAliance: '',
    isLike: '',
    phone: '',
    summary: '',
    youthYn: '',
    longTermYn: '',
    locYn: '',
    locType: '',
    addr: '',
    homepage: '',
    likeNum: '',
    couponAvailable: '',
    vipSummary: '',
    vipBnf: '',
    vipBnfLimit: '',
    vipNotice: '',
    vipGuide: '',
    metaTag: '',
    benefitInfoList: [],
    usageNoticeList: [],
    imgList: [],
    menuList: [],
};

export interface LikedPartners {
    ptnList: PartnerItem[];
}

export interface CategoryItem {
    categoryId: string;
    categoryName: string;
    categoryType: string;
}

export interface TotalPartners {
    totalCount: string;
    partnerList: PartnerItem[];
}

export interface PartnerItem {
    ptnId: string;
    ptnName: string;
    ptnLogoImgUrl: string;
    benefitSummary: string;
    isLike: string;
    benefitTagList: benefitTagItem[]
}


export interface benefitTagItem {
    benefitCode: string;
    benefitSummary: string
}

export interface SortItem {
    id: string;
    label: string;
}

export interface MainRegion {
    categoryId: string;
    categoryName: string;
    categoryType: string;
    subRegionList: Region[];
}

export interface Region {
    categoryId: string;
    categoryName: string;
    categoryType: string;
}

export interface ProfileInfo {
    ptnId: string;
    ptnName: string;
    ptnImgUrl: string;
    isLike: string;
    likeNum: string;
    isMembershipAliance: string;
    metaTag?: string;
}
