import React, {useEffect, useState} from 'react';
import {sendEventReturnToAndroid, sendEventReturnToIOS, sendEventToAndroid, sendEventToIOS,} from '../../api/bridgeApi';
import {useNavigate} from 'react-router-dom';
import {IconButton} from '@lguuxe/2024_designsystem_uni';

const NativeInterfaceTest: React.FC = () => {
    const [result, setResult] = useState<string>('');
    const [platform, setPlatform] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        const userAgent = navigator.userAgent;

        if (/android/i.test(userAgent)) {
            console.log('Android')
            setPlatform('Android');
        } else if (/iPad|iPhone|iPod/.test(userAgent) || (/Mac/.test(userAgent) && 'ontouchend' in document)) {
            console.log('IOS')
            setPlatform('iOS');
        } else {
            setPlatform('Unknown');
        }
    }, []);

    const handleSendEvent = (key: string, param: any) => {
        if (platform === 'Android') {
            const message = sendEventToAndroid(key, param);
            setResult(`${key} called!\n${message}`);
        } else if (platform === 'iOS') {
            const message = sendEventToIOS(key, param);
            setResult(`${key} called!\n${message}`);
        } else {
            setResult('Platform not supported');
        }
    };

    const handleSendEventReturn = (key: string, param: any) => {
        if (platform === 'Android') {
            const result = sendEventReturnToAndroid(key, param);
            setResult(`${key} called!\n${result}`);
        } else if (platform === 'iOS') {
            const result = sendEventReturnToIOS(key, param);
            setResult(`${key} called!\n${result}`);
        } else {
            setResult('Platform not supported');
        }
    };

    const handleBackClick = () => {
        navigate(-1); // Navigate back
    };

    return (
        <div style={containerStyle}>
            <div style={buttonContainerStyle}>
                <IconButton color='neutral' fill='icon' icon='chevron_left_line' noStates onClick={handleBackClick}/>
                <button
                    style={buttonStyle}
                    onClick={() => handleSendEvent('INITIAL_LOADING_FINISHED', {version: '10'})}
                >
                    INITIAL_LOADING_FINISHED
                </button>
                <button
                    style={buttonStyle}
                    onClick={() => handleSendEvent('INITIAL_SPLASH_FINISHED', {version: '10'})}
                >
                    INITIAL_SPLASH_FINISHED
                </button>
                <button style={buttonStyle} onClick={() => handleSendEvent('SET_SPLASH_IMAGE', {url: 'http://~~~'})}>
                    SET_SPLASH_IMAGE
                </button>
                <button style={buttonStyle} onClick={() => handleSendEventReturn('GET_MEMBER_ID', {})}>
                    GET_MEMBER_ID
                </button>
                <button style={buttonStyle} onClick={() => handleSendEvent('SET_MEMBER_ID', {memberId: '12345'})}>
                    SET_MEMBER_ID
                </button>
                <button
                    style={buttonStyle}
                    onClick={() => handleSendEvent('SET_CTN_INFO', {ctn_info: '010012341234'})}
                >
                    SET_CTN_INFO
                </button>
                <button style={buttonStyle} onClick={() => handleSendEvent('SET_CARRIER', {carrier: 'L'})}>
                    SET_CARRIER
                </button>
                <button style={buttonStyle} onClick={() => handleSendEventReturn('FCM_TOKEN', {})}>
                    GET_FCM_TOKEN
                </button>
                <button
                    style={buttonStyle}
                    onClick={() => handleSendEvent('SET_UPM_BARCODE', {barcode: '11111111111111111'})}
                >
                    SET_UPM_BARCODE
                </button>
                <button
                    style={buttonStyle}
                    onClick={() => handleSendEventReturn('EXTERNAL_CALL', {type: 4, url: 'param'})}
                >
                    EXTERNAL_CALL
                </button>
                <button style={buttonStyle} onClick={() => handleSendEventReturn('DEVICE_SYSTEM_INFO', {})}>
                    DEVICE_SYSTEM_INFO
                </button>
                <button style={buttonStyle} onClick={() => handleSendEvent('CLOSE_ACTIVITY', {})}>
                    CLOSE_ACTIVITY
                </button>
                <button style={buttonStyle} onClick={() => handleSendEvent('APP_FINISH', {})}>
                    APP_FINISH
                </button>
                <button style={buttonStyle} onClick={() => handleSendEvent('LOGIN_REFRESH', {})}>
                    LOGIN_REFRESH
                </button>
                <button style={buttonStyle} onClick={() => handleSendEventReturn('GET_AUTHORIZATION', {})}>
                    GET_AUTHORIZATION
                </button>
                <button
                    style={buttonStyle}
                    onClick={() =>
                        handleSendEvent('CALL_WEBVIEW', {
                            url: 'http://www.naver.com',
                            showTitlebar: true,
                            title: 'TV 포인트 충전',
                        })
                    }
                >
                    CALL_WEBVIEW
                </button>
                <button style={buttonStyle} onClick={() => handleSendEventReturn('CHECK_PASSWORD', {})}>
                    CHECK_PASSWORD
                </button>
                <button style={buttonStyle} onClick={() => handleSendEventReturn('CHANGE_PASSWORD', {})}>
                    CHANGE_PASSWORD
                </button>
                <button style={buttonStyle} onClick={() => handleSendEventReturn('SET_PASSWORD', {})}>
                    SET_PASSWORD
                </button>
                <button style={buttonStyle} onClick={() => handleSendEventReturn('PASSWORD', {})}>
                    PASSWORD
                </button>
                <button style={buttonStyle} onClick={() => handleSendEventReturn('CALL_LOGIN', {url: 'http://1234'})}>
                    CALL_LOGIN
                </button>
                <button
                    style={buttonStyle}
                    onClick={() =>
                        handleSendEvent('CALL_TOAST_POPUP', {
                            message: '미성년자는 이용할 수 없습니다.',
                            timer: '4000',
                        })
                    }
                >
                    CALL_TOAST_POPUP
                </button>
                <button
                    style={buttonStyle}
                    onClick={() => handleSendEvent('CALL_SHARE', {url: 'https://lguplusmobiletv.page.link/DANV'})}
                >
                    CALL_SHARE
                </button>
                <button style={buttonStyle} onClick={() => handleSendEvent('CALL_PERMISSIONS', {})}>
                    CALL_PERMISSIONS
                </button>
                <button
                    style={buttonStyle}
                    onClick={() =>
                        handleSendEvent('OPEN_MOVIE_RESERVATION', {
                            url: 'https://membership.lguplus.co.kr:30001/movie/mobile/intro.lgum?isMembers=Y&agent=CGV',
                            token: '',
                            encKey: '',
                            clv: '',
                            ci: '',
                        })
                    }
                >
                    OPEN_MOVIE_RESERVATION
                </button>
                <button
                    style={buttonStyle}
                    onClick={() =>
                        handleSendEvent('OPEN_MONEYME', {
                            url: 'http://~',
                            key_mydata_cardlevel: '',
                            extraLinkUrl: '',
                            userId: '',
                            key_mydata_code: '',
                        })
                    }
                >
                    OPEN_MONEYME
                </button>
                <button
                    style={buttonStyle}
                    onClick={() =>
                        handleSendEvent('OPEN_UPKOK', {
                            url: 'http://~',
                            param: '',
                            extraLinkUrl: '',
                            appUpd: 'Y',
                            mappver: 'Y',
                            rid: 'AD_MBS2',
                            lrid: 'AD_MBS2',
                            liveYn: 'Y',
                        })
                    }
                >
                    OPEN_UPKOK
                </button>
                <button
                    style={buttonStyle}
                    onClick={() => handleSendEvent('OPEN_APPTECH', {url: 'http://~', carrier: 'L'})}
                >
                    OPEN_APPTECH
                </button>
            </div>
            <div style={resultContainerStyle}>
                <div style={resultStyle}>
                    결과창: {result ? <pre>{`${result}`}</pre> : 'Click a function to see the result'}
                </div>
                <div style={{marginTop: '20px', fontSize: '18px'}}>현재 플랫폼: {platform}</div>
            </div>
        </div>
    );
};

const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
    position: 'relative',
    overflow: 'hidden', // 전체 화면의 스크롤을 없앰
    // paddingTop: '5vh', // 화면 상단 공백을 줄임
};

const buttonContainerStyle: React.CSSProperties = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '10px', // 버튼 간의 간격을 줄입니다
    maxHeight: '50vh', // 버튼 컨테이너의 최대 높이 설정
    overflowY: 'auto', // 수직 스크롤 추가
    paddingBottom: '5px', // 결과창과의 간격 확보
};

const buttonStyle: React.CSSProperties = {
    padding: '10px 20px',
    fontSize: '12px',
    cursor: 'pointer',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#f0f0f0',
    flex: '1 1 calc(50% - 10px)', // 버튼의 크기를 줄여 한 행에 최대 2개 버튼이 배치되도록 합니다
    maxWidth: 'calc(50% - 10px)', // 버튼의 최대 너비를 설정합니다
};

const resultContainerStyle: React.CSSProperties = {
    position: 'absolute',
    bottom: '20px',
    width: '100%',
    textAlign: 'center',
};

const resultStyle: React.CSSProperties = {
    margin: '0 auto',
    padding: '10px',
    fontSize: '18px',
    border: '4px solid #ccc',
    borderRadius: '4px',
    height: '80px',
    width: '90%',
    maxWidth: '600px',
};

export default NativeInterfaceTest;
