// ****************************************************
// [배포] 4.마이 > VIP > VIP콕 사용내역 > VIP콕 상세 > 영화예매형_사용 전 (UMembershipVipDetails_3)
// ****************************************************
import React from "react";
import { Text } from "@lguuxe/2024_designsystem_uni";

const SectionHeadingText2row1: React.FC = () => {
    return (
        <>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                    padding: "0 20px",
                    margin: "12px 0 0"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "12px 20px",
                        borderRadius: "12px",
                        background: "#F3F5F6"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            padding: "4px 0"
                        }}
                    >
                        <Text style={{ flex: "1" }} typo="font_heading_5Sb" color="color_text_neutral_6">
                            예매하러 가기
                        </Text>
                        <Text typo="font_body_1M" color="color_text_primary">
                            1 / 3회
                        </Text>
                    </div>

                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                            padding: "4px 0"
                        }}
                    >
                        <Text style={{ flex: "1" }} typo="font_heading_5Sb" color="color_text_neutral_6">
                            1+1 예매 사용
                        </Text>
                        <Text typo="font_body_1M" color="color_text_primary">
                            1 / 9회
                        </Text>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SectionHeadingText2row1;
