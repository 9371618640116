// ****************************************************
// [배포] 5.전체 > PoP > POP서비스_가입안내 (UMembershipPOP)
// [배포] 5.전체 > PoP > POP서비스_가입안내 (펼침) (UMembershipPOP2)
// [배포] 5.전체 > PoP > POP서비스_가입안내 (가입상태) (UMembershipPOP3)
// ****************************************************
import {Box, Text} from '@lguuxe/2024_designsystem_uni'
import React from 'react'

const SectionContents3 = () => {
    return (
        <Box
            type="1_trbl"
        >
            <Box style={{padding: "16px 20px", backgroundColor: "#F3F5F6", borderRadius: "12px"}}>
                <Text typo="font_body_2M" color="color_text_neutral_3">POP 서비스 약관에 동의하면 GS&POINT도 자동 적립됩니다.</Text>
            </Box>
        </Box>
    )
}

export default SectionContents3
