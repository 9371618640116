import React from 'react';
import { Text, Button, Icon } from '@lguuxe/2024_designsystem_uni';

interface BarcodeFailedProps {
    onGenerateBarcode: () => void;
}

const BarcodeFailed: React.FC<BarcodeFailedProps> = ({ onGenerateBarcode }) => {
    return (
        <div
            style={{
                padding: '20px 0',
                backgroundColor: '#F3F5F6',
                borderRadius: '12px',
                width: '100%',
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '12px',
                    padding: '16.5px 22px',
                }}
            >
                <Text typo='font_body_1M' color='cxwolor_text_neutral_3' textAlign='center'>
                    정보를 불러오지 못했습니다.
                </Text>
                <Button
                    as='button'
                    color='secondary'
                    fill='solid'
                    size='medium'
                    state='default'
                    onClick={onGenerateBarcode}
                >
                    <Icon
                        name='refresh_line'
                        size='20px'
                        color='#fff'
                        style={{
                            marginRight: '4px',
                        }}
                    />
                    새로고침
                </Button>
            </div>
        </div>
    );
};

export default BarcodeFailed;
