import React from 'react';
import { Text, Button, Icon } from '@lguuxe/2024_designsystem_uni';
import { Link } from 'react-router-dom';

import '../../pages/css/style.css';

interface SnackbarProps {
    text: string;
    buttonText?: string;
    buttonLinkUrl?: string;
}

const Snackbar: React.FC<SnackbarProps> = ({ text, buttonText, buttonLinkUrl }) => {
    const handleButtonClick = () => {
        if (buttonLinkUrl) {
            window.location.href = buttonLinkUrl;
        }
    };

    return (
        <div className='snackbar' style={{ margin: '0, 0, 35px, 0', zIndex: 1001 }}>
            <Text typo='font_body_2M' color='color_text_onNeutral_low' textAlign='center'>
                {text.split('\\n').map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                ))}
            </Text>
            {buttonLinkUrl && buttonText && (
                <Link to={buttonLinkUrl}>
                    <Button
                        color='secondary'
                        fill='ghost'
                        style={{
                            display: 'flex',
                            gap: '8px',
                        }}
                        onClick={handleButtonClick}
                    >
                        <Text style={{ paddingRight: '8px' }} typo='font_label_3Sb' color='color_text_onNeutral_low'>
                            {buttonText}
                        </Text>
                        <Icon color='#7F8A94' name='chevron_right_small_line' size='16px' />
                    </Button>
                </Link>
            )}
        </div>
    );
};

export default Snackbar;
