import {getItemByKey} from "../utils/indexedDB";

declare global {
    interface Window {
        BrazeBridge: {
            trackingEvent: (stringifiedObject: string) => void;
            trackingAttr: (stringifiedObject: string) => void;
        };
    }
}

const trackingEvent = (jsonObject: any) => {
    const stringifiedObject = JSON.stringify(jsonObject);
    if (window.BrazeBridge && window.BrazeBridge.trackingEvent) {
        window.BrazeBridge.trackingEvent(stringifiedObject);
    } else {
        console.error('BrazeBridge is not available');
    }
};

export const trackingAttr = (jsonObject: any) => {
    console.log('trackingAttr', jsonObject);
    const stringifiedObject = JSON.stringify(jsonObject);
    if (window.BrazeBridge && window.BrazeBridge.trackingAttr) {
        window.BrazeBridge.trackingAttr(stringifiedObject);
    } else {
        console.log('BrazeBridge is not available');
    }
};

export const brazeAttributeApi = {
    firstCollection: async () => {
        const useVipkokYn = await getItemByKey('useVipkokYn');
        const maketingInfoYn = await getItemByKey('maketingInfoYn');
        const isVIP = await getItemByKey('isVIP');
        const moneyMe = await getItemByKey('moneyMeUse');

        trackingAttr({own_third_type: '자사'});
        trackingAttr({member_type: isVIP === 'Y' ? 'VIP' : '일반'});
        trackingAttr({mydata_join: moneyMe === 'Y'});
        // trackingAttr({user_mykok_type}); TODO: 추가 필요
        trackingAttr({user_id: await getItemByKey('memberId')});
        trackingAttr({gender: await getItemByKey('gender')});
        const birthday = await getItemByKey('birthday');
        trackingAttr({birthdate: birthday.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3')});
        trackingAttr({marketing_agreement_status: await getItemByKey('maketingInfoYn') === 'Y'});
        trackingAttr({ad_agreement_app: await getItemByKey('adAgreeYn') === 'Y'});
        const longTermName = await getItemByKey('longTermName');
        const memberLoyalty = longTermName === 'NG1' ? '2년 이상' : 'NG2' ? '5년 이상' : 'NG3' ? '10년 이상' : '';
        trackingAttr({member_loyalty: memberLoyalty});
        trackingAttr({member_uth: await getItemByKey('youthYn') === 'Y'});
    }
}

const timeFormat = (date: Date): string => {
    const currentTime = date.toISOString();
    const offset = new Date().getTimezoneOffset() * -1; // Timezone offset in minutes (KST is UTC+9)
    const offsetHours = Math.floor(offset / 60);
    const offsetMinutes = offset % 60;

    const formattedOffset = `${offsetHours >= 0 ? "+" : "-"}${String(Math.abs(offsetHours)).padStart(2, "0")}:${String(offsetMinutes).padStart(2, "0")}`;

    return `${currentTime.split(".")[0]}${formattedOffset}`;
}

const getBrazeInfo = async () => {
    const memberId = await getItemByKey('memberId');
    const memberStatus = await getItemByKey('memberStatus');
    const useVipkokYn = await getItemByKey('useVipkokYn');
    const maketingInfoYn = await getItemByKey('maketingInfoYn');
    const isVIP = await getItemByKey('isVIP');
    const now = new Date();
    return {
        custom_event_time: timeFormat(new Date()),
        login_status: memberId !== '',
        signup_date: timeFormat(now),
        own_third_type: '자사',
        mykok_use_month: useVipkokYn === 'Y',
        member_type: isVIP === 'Y' ? 'VIP' : '일반',
        ad_agreement_app: await getItemByKey('adAgreeYn') === 'Y',
        marketing_agreement_status: maketingInfoYn === 'Y',
        available_mileage: 0,
    };
}

export const brazeEventApi = {
    tutorial_close: async () => {
        const info = await getBrazeInfo();
        const params = {
            EventName: 'tutorial_close',
            custom_event_time: info.custom_event_time,
            event_occur_platform: 'app',
            login_status: info.login_status,
            signup_date: info.signup_date,
            own_third_type: '자사',
            mykok_use_month: info.mykok_use_month,
            member_type: info.member_type,
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
            ad_agreement_app: info.ad_agreement_app,
            marketing_agreement_status: info.marketing_agreement_status,
        };
        trackingEvent(params);
    },
    mission_tab_enter: async () => {
        // 미션 탭 진입 시
        const info = await getBrazeInfo();
        const params = {
            EventName: 'mission_tab_enter',
            customEventTime: info.custom_event_time,
            platform: 'app',
            isLoggedIn: info.login_status,
            availableMileage: info.available_mileage,
            mykok_use_month: info.mykok_use_month,
            member_type: info.member_type,
            click_location: '탭 영역',
            entry_type: '잠금화면',
            ad_agreement_app: info.ad_agreement_app,
            marketing_agreement_status: info.marketing_agreement_status,
        };
        trackingEvent(params);
    },
    mission_tab_exit: async () => {
        // 미션탭 진입 > 타 페이지로 이동 시
        const info = await getBrazeInfo();
        const params = {
            EventName: 'mission_tab_enter',
            custom_event_time: info.custom_event_time,
            platform: 'app',
            isLoggedIn: info.login_status,
            availableMileage: info.available_mileage,
            mykok_use_month: info.mykok_use_month,
            member_type: info.member_type,
            click_location: '탭 영역',
            entry_type: '잠금화면',
            ad_agreement_app: info.ad_agreement_app,
            marketing_agreement_status: info.marketing_agreement_status,
        };
        trackingEvent(params);
    },
    logIn_complete: async () => {
        // 로그인 완료 시 (자동/수동)
        const info = await getBrazeInfo();
        const params = {
            EventName: 'logIn_complete',
            customEventTime: info.custom_event_time,
            event_occur_platform: 'app',
            login_status: info.login_status,
            isAppPushSubscribed: info.ad_agreement_app,
            ownThirdType: info.own_third_type,
            mykok_use_month: info.mykok_use_month,
            member_type: info.member_type,
            login_type: '간편로그인',
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
            ad_agreement_app: info.ad_agreement_app,
            marketing_agreement_status: info.marketing_agreement_status,
        };
        trackingEvent(params);
    },

    attendance_check_complete: async (isSuccess: boolean, attendanceCnt: number) => {
        const info = await getBrazeInfo();
        const params = {
            EventName: 'attendance_check_complete',
            custom_event_time: info.custom_event_time,
            event_occur_platform: 'app',
            login_status: info.login_status,
            attendance_success: isSuccess,
            attendace_cnt: attendanceCnt,
            mykok_use_month: info.mykok_use_month,
            member_type: info.member_type,
            click_location: '탭 영역',
            entry_type: '잠금화면',
            ad_agreement_app: info.ad_agreement_app,
            marketing_agreement_status: info.marketing_agreement_status,
        };
        trackingEvent(params);
    },
    my_tab_enter: async (useMoneyMe: boolean, moneyMePoint: number) => {
        const info = await getBrazeInfo();
        const params = {
            EventName: 'my_tab_enter',
            custom_event_name: '마이탭 진입',
            custom_event_time: info.custom_event_time,
            event_occur_platform: 'app',
            login_status: info.login_status,
            mydata_join: useMoneyMe,
            mydata_money: moneyMePoint,
            mykok_use_month: info.mykok_use_month,
            member_type: info.member_type,
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
            ad_agreement_app: info.ad_agreement_app,
            marketing_agreement_status: info.marketing_agreement_status,
        };
        trackingEvent(params);
    },
    my_tab_exit: async (useMoneyMe: boolean, moneyMePoint: number) => {
        const info = await getBrazeInfo();
        const params = {
            EventName: 'my_tab_exit',
            custom_event_time: info.custom_event_time,
            event_occur_platform: 'app',
            login_status: info.login_status,
            mydata_join: useMoneyMe,
            mydata_money: moneyMePoint,
            mykok_use_month: info.mykok_use_month,
            member_type: info.member_type,
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
            ad_agreement_app: info.ad_agreement_app,
            marketing_agreement_status: info.marketing_agreement_status,
        };
        trackingEvent(params);
    },
    benefitplus_page_enter: async () => {
        const info = await getBrazeInfo();
        const params = {
            EventName: 'benefitplus_page_enter',
            custom_event_time: info.custom_event_time,
            event_occur_platform: 'app',
            login_status: info.login_status,
            own_third_type: info.own_third_type,
            mykok_use_month: info.mykok_use_month,
            member_type: info.member_type,
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
            ad_agreement_app: info.ad_agreement_app,
            marketing_agreement_status: info.marketing_agreement_status,
        };
        trackingEvent(params);
    },
    event_participation_complete: async () => {
        const info = await getBrazeInfo();
        const params = {
            EventName: 'event_participation_complete',
            custom_event_time: info.custom_event_time,
            event_occur_platform: 'app',
            login_status: info.login_status,
            mkt_event_name: '7월 출석체크 이벤트',
            mkt_event_id: 'E8982402',
            is_push_subscribed_event: false,
            is_attendance_event: true,
            user_id: '12345678',  // 또는 해시값 처리
            mykok_use_month: info.mykok_use_month,
            member_type: 'VIP',
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
            ad_agreement_app: info.ad_agreement_app,
            marketing_agreement_status: info.marketing_agreement_status,
        };
        trackingEvent(params);
    },

    homemain_enter: async () => {
        const info = await getBrazeInfo();
        const params = {
            EventName: 'homemain_enter',
            custom_event_time: info.custom_event_time,
            event_occur_platform: 'app',
            login_status: info.login_status,
            own_third_type: info.own_third_type, // '자사' 또는 '타사'
            mobilePlanExpDate: '', // TODO: 모바일 약정 만료일 예시 이거 어캐 암?
            mykok_use_month: info.mykok_use_month,
            member_type: info.member_type,
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
            ad_agreement_app: info.ad_agreement_app,
            marketing_agreement_status: info.marketing_agreement_status,
        };
        trackingEvent(params);
    },
    event_list_enter: async () => {
        const info = await getBrazeInfo();
        const params = {
            EventName: 'event_list_enter',
            custom_event_time: info.custom_event_time,
            event_occur_platform: 'app',
            login_status: info.login_status,
            own_third_type: info.own_third_type, // '자사' 또는 '타사'
            mykok_use_month: info.mykok_use_month,
            member_type: info.member_type,
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
            ad_agreement_app: info.ad_agreement_app,
            marketing_agreement_status: info.marketing_agreement_status,
        };
        trackingEvent(params);
    },
    event_detail_page_enter: async (eventName: string, eventId: string) => {
        const info = await getBrazeInfo();
        const params = {
            EventName: 'event_detail_page_enter',
            custom_event_time: info.custom_event_time,
            event_occur_platform: 'app',
            login_status: info.login_status,
            mkt_event_name: eventName,
            mkt_event_id: eventId,
            own_third_type: info.own_third_type, // '자사' 또는 '타사'
            mykok_use_month: info.mykok_use_month,
            member_type: info.member_type,
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
            ad_agreement_app: info.ad_agreement_app,
            marketing_agreement_status: info.marketing_agreement_status,
        };
        trackingEvent(params);
    },
    affiliateInfo_detail_page_enter: async (partnerName: string, partnerId: string, patnerCategory: string) => {
        const info = await getBrazeInfo();
        const params = {
            EventName: 'affiliateInfo_detail_page_enter',
            custom_event_time: info.custom_event_time,
            event_occur_platform: 'app',
            login_status: info.login_status,
            affiliate_name: partnerName,
            affiliate_id: partnerId,
            affiliate_sector: patnerCategory,
            own_third_type: info.own_third_type, // '자사' 또는 '타사'
            mykok_use_month: info.mykok_use_month,
            member_type: 'VIP',
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
            ad_agreement_app: info.ad_agreement_app,
            marketing_agreement_status: info.marketing_agreement_status,
        };
        trackingEvent(params);
    },
    alltap_menu_click: async (tabName: string) => {
        const info = await getBrazeInfo();
        const params = {
            EventName: 'alltap_menu_click',
            custom_event_time: info.custom_event_time,
            event_occur_platform: 'app',
            login_status: info.login_status,
            own_third_type: info.own_third_type,
            tap_menu: tabName,
            mykok_use_month: info.mykok_use_month,
            member_type: info.member_type,
            click_location: '탭 영역',
            entry_type: '잠금화면',
            ad_agreement_app: info.ad_agreement_app,
            marketing_agreement_status: info.marketing_agreement_status,
        };
        trackingEvent(params);
    },
    home_barcode_floating_close: async () => {
        const info = await getBrazeInfo();
        const params = {
            EventName: 'home_barcode_floating_close',
            custom_event_time: info.custom_event_time,
            login_status: info.login_status,
            own_third_type: info.own_third_type, // '자사' 또는 '타사'
            mykok_use_month: info.mykok_use_month,
            currentmember_type: info.member_type,
            click_location: '탭 영역', // 예시 값: '컨텐츠 영역', '탭 영역'
            entry_type: '잠금화면',
            ad_agreement_app: info.ad_agreement_app,
            marketing_agreement_status: info.marketing_agreement_status,
        };
        trackingEvent(params);
    },
    vipKok_info_enter: async () => {
        const info = await getBrazeInfo();
        const params = {
            EventName: 'vipKok_info_enter',
            custom_event_time: info.custom_event_time,
            event_occur_platform: 'app',
            login_status: info.login_status,
            mykok_use_month: info.mykok_use_month,
            member_type: info.member_type,
            click_location: '컨텐츠 영역',
            entry_type: '잠금화면',
            ad_agreement_app: info.ad_agreement_app,
            marketing_agreement_status: info.marketing_agreement_status,
        };
        trackingEvent(params);
    },
};
