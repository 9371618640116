import React, { FC } from 'react';
import { Accordion, TextSet, Text } from "@lguuxe/2024_designsystem_uni";
import CommonDividerVertical from "../Common/CommonDividerVertical";
import CommonNodata from "../../components/Common/CommonNodata";
interface SectionAccordionProps {
    historyList: {
        serialNumber: string;
        createDate: string;
        receiveDate: string;
        receiverCTN: string;
        sendDate: string;
        senderCTN: string;
        badgeName: string;
    }[];
}

const SectionAccordion: React.FC<SectionAccordionProps> = ({ historyList }) => {
    return (
        <>
            <div style={{ paddingBottom: "12px" }}>
                {historyList.length === 0 ? (
                    <div style={{
                        paddingTop: "40px"}}>
                        <CommonNodata type={"nftHistory"} text={"획득한 배지가 없어요"} />
                    </div>
                ) : (
                    historyList.map((history, index) => (
                        <Accordion
                            key={index}
                            description={
                                <TextSet
                                    subComponent="Body_1"
                                    text={{
                                        title: '',
                                        description: (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <span>{history.createDate}</span>
                                                <CommonDividerVertical />
                                                <span>발급</span>
                                            </div>
                                        ),
                                    }}
                                />
                            }
                            title={
                                <TextSet
                                    subComponent="Body_1"
                                    text={{
                                        title: history.badgeName,
                                    }}
                                    weight="bolder"
                                />
                            }
                            weight="bolder"
                        >
                            <Accordion.Body>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "4px",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                        }}
                                    >
                                        <Text
                                            style={{ flex: "1" }}
                                            typo="font_body_2M"
                                            color="color_text_neutral_4"
                                        >
                                            받은 사람
                                        </Text>
                                        <Text typo="font_body_2M" color="color_text_neutral_6">
                                            {history.receiverCTN}
                                        </Text>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                        }}
                                    >
                                        <Text
                                            style={{ flex: "1" }}
                                            typo="font_body_2M"
                                            color="color_text_neutral_4"
                                        >
                                            시리얼번호
                                        </Text>
                                        <Text typo="font_body_2M" color="color_text_neutral_6">
                                            {history.serialNumber}
                                        </Text>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            width: "100%",
                                        }}
                                    >
                                        <Text
                                            style={{ flex: "1" }}
                                            typo="font_body_2M"
                                            color="color_text_neutral_4"
                                        >
                                            배지 상세
                                        </Text>
                                        <Text
                                            typo="font_body_2M"
                                            color="color_text_neutral_6"
                                            style={{ width: "180px", textAlign: "right" }}
                                        >
                                            {history.badgeName}
                                        </Text>
                                    </div>
                                </div>
                            </Accordion.Body>
                        </Accordion>
                    ))
                )}
            </div>
        </>
    );
};

export default SectionAccordion;
