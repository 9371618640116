import React from "react";
import { Box, Text, Image } from "@lguuxe/2024_designsystem_uni";
import ExImg from "../../images/default/default thumbnail.svg";

interface Step {
    stepNumber: number;
    description: React.ReactNode;
    imageSrc?: string;
    imageAlt?: string;
    additionalContent?: React.ReactNode;
}

interface MoneyMeGuideSectionProps {
    title: string;
    description?: string;
    steps: Step[];
}

const MoneyMeGuideSection: React.FC<MoneyMeGuideSectionProps> = ({ title, description, steps }) => {
    return (
        <Box style={{ display: "flex", flexDirection: "column", gap: "12px", padding: "24px 0 12px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "12px", padding: "0" }}>
                <Text typo="font_heading_4B" color="color_text_neutral_6" style={{ fontSize: "18px", fontWeight: "800", padding: "0 20px" }}>
                    {title}
                </Text>
                {description && (
                    <Text typo="font_detail_2M" color="color_text_neutral_3" style={{ padding: "0 20px" }}>
                        {description}
                    </Text>
                )}
            </div>
            {steps.map((step, index) => (
                <React.Fragment key={index}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "4px", padding: "24px 20px 0" }}>
                        <Text typo="font_body_1B" color="color_text_neutral_3" style={{ fontSize: "16px", fontWeight: "600" }}>
                            STEP {step.stepNumber}.
                        </Text>
                        <Text typo="font_body_2M" color="color_text_neutral_3">
                            {step.description}
                        </Text>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "16px", padding: "4px 20px" }}>
                        <Image
                            src={step.imageSrc || ExImg}
                            alt={step.imageAlt || "placeholder"}
                            width="100%"
                            ratio="4:3"
                        />
                    </div>
                    {step.additionalContent && (
                        <div style={{ display: "flex", flexDirection: "column", gap: "4px", padding: "12px 20px 0" }}>
                            {step.additionalContent}
                        </div>
                    )}
                </React.Fragment>
            ))}
        </Box>
    );
};

export default MoneyMeGuideSection;
