import { api, ApiEndpoints } from './api';
import { ApiParams, ApiResponse } from './types';

const settingApi = {
    getCouponAlarm: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.SETTING.COUPON.ALARM, params, version),
    putCouponAlarm: (body: Record<string, any>, version: string): Promise<ApiResponse> => api.put(ApiEndpoints.SETTING.COUPON.ALARM, body, version),
    getTermsBnfAd: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.SETTING.TERMS.BNF_AD, params, version),
    putTermsBnfAd: (body: Record<string, any>, version: string): Promise<ApiResponse> => api.put(ApiEndpoints.SETTING.TERMS.BNF_AD, body, version),
    getTermsPersonalInfo: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.SETTING.TERMS.PERSONAL_INFO, params, version),
    putTermsPersonalInfo: (body: Record<string, any>, version: string): Promise<ApiResponse> => api.put(ApiEndpoints.SETTING.TERMS.PERSONAL_INFO, body, version),
    getMyInfo: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.SETTING.MY_INFO, params, version),
    putMyInfo: (body: Record<string, any>, version: string): Promise<ApiResponse> => api.put(ApiEndpoints.SETTING.MY_INFO, body, version),
    getVersion: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.VERSION, params, version),
    putPushReg: (body: Record<string, any>, version: string): Promise<ApiResponse> => api.put(ApiEndpoints.SETTING.PUSH_REG, body, version),
};

const termsApi = {
    getAgreements: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.TERMS.AGREEMENTS, params, version),
    getAgreementsById: (termId: string, params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.TERMS.AGREEMENTS_BY_ID(termId), params, version),
    getPrivacyPolicy: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.TERMS.PRIVACY_POLICY, params, version),
    getJoinAgreements: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.TERMS.JOIN.AGREEMENTS, params, version),
    getJoinAgreementsById: (termId: string, params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.TERMS.JOIN.AGREEMENTS_BY_ID(termId), params, version),
};

export { settingApi, termsApi };
