// ****************************************************
// [배포] 4.마이 - VIP - VIP콕_상세[기본형_사용 전]
// ****************************************************
// Heading_6 Design-Asset에는 Heading_5로 되어 있슴
import React from "react";
import { TextSet } from "@lguuxe/2024_designsystem_uni";

type BoxHeaderInfoProps = {
    title: string;
    description?: string;
};

const BoxHeaderInfo: React.FC<BoxHeaderInfoProps> = ({ title, description }) => {
    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                gap: "8px",
                padding: "24px 20px 12px"
            }}
        >
            <TextSet
                subComponent="Heading_5"
                text={{
                    title: title
                }}
                weight="bolder"
            />
            {description &&
            <TextSet
                subComponent="Body_2"
                text={{
                    title: description.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            {line}
                            <br />
                        </React.Fragment>
                    ))
                }}
                weight="default"
            />}
        </div>
    );
};

export default BoxHeaderInfo;
