// ****************************************************
// [배포] 진입 > 법정대리인 동의(14세 미만)
// ****************************************************
import React, {useState} from 'react';
import CommonHeaderNoTitle from '../../components/Common/CommonHeaderNoTitle';
import {Box, Button, CTA, Icon, List, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import {useLocation, useNavigate} from 'react-router-dom';

const RepresentativeTerm: React.FC = () => {
    const [checked, setChecked] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleCheckBox = (event: any) => {
        if (event.value == '선택되었습니다') {
            setChecked(true);
        } else {
            setChecked(false);
        }
    };

    const handlePhoneVerification = () => {
        navigate('/phone-certification?target=representative', {state: location.state});
    };
    return (
        <>
            <CommonHeaderNoTitle/>

            <Box type='2_trbl'>
                <TextSet
                    subComponent='Heading_2'
                    text={{
                        title: (
                            <>
                                <span>
                                    14세 미만 고객님은
                                    <br/>
                                    법정대리인 동의가 필요해요
                                </span>
                            </>
                        ),
                    }}
                    weight='bolder'
                />
            </Box>
            <List
                id='representativeTerm'
                fill='none'
                items={[
                    {
                        checked: checked,
                        leftSlot: [
                            <TextSet
                                subComponent='Body_1'
                                text={{
                                    title: '법정대리인 휴대폰 인증을 동의합니다.',
                                    description: '개인정보 수집 및 이용안내',
                                }}
                                weight='bolder'
                            />,
                        ],
                        link: 'www.naver.com',
                    },
                ]}
                type='checkbox'
                onChange={handleCheckBox}
            />

            <Box
                style={{
                    position: 'fixed',
                    bottom: '0',
                    width: '100%',
                    padding: '0',
                }}
            >
                <Box
                    style={{
                        backgroundColor: '#F9FAFB',
                        borderRadius: '12px',
                        padding: '16px 20px',
                        margin: '0 20px 4px',
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                            padding: '0',
                        }}
                    >
                        <Icon color='#7f8a94' name='error_circle_filled' size='24px'/>
                        <TextSet
                            subComponent='Heading_6'
                            text={{
                                title: (
                                    <>
                                        <span style={{color: '#66707A'}}>꼭 확인하세요!</span>
                                    </>
                                ),
                            }}
                            weight='bolder'
                        />
                    </Box>
                    <ul
                        style={{
                            paddingLeft: '26px',
                            margin: '8px 0 0 0',
                            color: '#66707A',
                        }}
                    >
                        <li>
                            <Text typo='font_body_2M' color='color_text_neutral_3'>
                                휴대폰 개통 시 등록한 법정대리인 정보로 인증을 해주셔야 합니다.
                            </Text>
                        </li>
                        <li>
                            <Text typo='font_body_2M' color='color_text_neutral_3'>
                                법정 대리인 정보를 변경하고 싶다면 LG유플러스 매장을 방문해 고객 정보를 수정하세요.
                            </Text>
                        </li>
                    </ul>
                </Box>
                <CTA layout='column'>
                    <Button color='primary' size='large' disabled={!checked} onClick={handlePhoneVerification}>
                        법정대리인 휴대폰 인증하기
                    </Button>
                </CTA>
            </Box>
        </>
    );
};

export default RepresentativeTerm;
