import React from 'react';
import { Text, Image } from "@lguuxe/2024_designsystem_uni";
import emptyNoticeImg from "../../images/img/empty/empty_notice_120.svg"; // 기본 이미지
import emptySearchImg from "../../images/img/empty/empty_result_120.svg";
import emptyPartnerImg from "../../images/img/empty/empty_partner.svg";
import emptyMobilePayImg from "../../images/img/empty/empty_phone_pay.svg";
import emptyNFTHistoryImg from "../../images/img/empty/empty_coupon.svg";

interface CommonNodataProps {
    type: 'notice' | 'search' | 'event' | 'partner' | 'mobilePay' | 'nftHistory' | 'default';  // type에 따라 다른 이미지를 사용하도록
    text: string;  // 표시할 텍스트
}

const CommonNodata: React.FC<CommonNodataProps> = ({ type, text }) => {
    const getImageSrc = (type: string) => {
        switch (type) {
            case 'notice':
                return emptyNoticeImg;
            case 'search':
                return emptySearchImg;
            case 'partner':
                return emptyPartnerImg;
            case 'mobilePay':
                return emptyMobilePayImg;
            case 'nftHistory':
                return emptyNFTHistoryImg;
            default:
                return emptyNoticeImg; // 기본 이미지
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
            }}
        >
            <Image
                src={getImageSrc(type)}
                alt="placeholder"
                width="120px"
                ratio="1:1"
            />
            <div style={{ paddingTop: "20px" }}>
                <Text typo="font_detail_1M" color="color_text_neutral_3" textAlign="center">
                    {text}
                </Text>
            </div>
        </div>
    );
}

export default CommonNodata;
