// ****************************************************
// [배포] 4.마이 > 내 등급, 혜택 이력 > 내 등급(등급안내) - (UMembershipReceivedBenefit)
// [배포] 4.마이 - 내 등급, 혜택 이력 - 내 등급(등급안내_일반등급) (UMembershipReceivedBenefit2)
// ****************************************************
import React from 'react';
import {Accordion, Text, TextSet} from '@lguuxe/2024_designsystem_uni';

const LevelUpTips: React.FC = () => {
    return (
        <>
            <Accordion
                divider={false}
                title={
                    <TextSet
                        subComponent='Body_1'
                        text={{
                            title: '등급 업그레이트 팁',
                        }}
                        weight='bolder'
                    />
                }
            >
                <Accordion.Body>
                    <ul style={{paddingLeft: '26px', margin: '0'}}>
                        <li>
                            <Text typo='font_body_2M' color='color_text_neutral_3'>
                                이용 중인 모바일 요금제나 1년 동안 낸 통신 요금 조건을
                                <br/>
                                만족하면 등급을 올릴 수 있어요.
                            </Text>
                        </li>
                        <li>
                            <Text typo='font_body_2M' color='color_text_neutral_3'>
                                모바일 요금제를 변경하면 매월 1일에 등급을 결정해요.
                            </Text>
                        </li>
                    </ul>
                    <br/>
                    <Text typo='font_body_2M' color='color_text_neutral_3'>
                        Tip 1. VVIP 되는 방법
                    </Text>
                    <ul style={{paddingLeft: '26px', margin: '0'}}>
                        <li>
                            <Text typo='font_body_2M' color='color_text_neutral_3'>
                                95,000 이상 모바일요금제 가입하기
                            </Text>
                        </li>
                        <li>
                            <Text typo='font_body_2M' color='color_text_neutral_3'>
                                전년도 연간납부실적 200만원 이상 충족하기
                            </Text>
                        </li>
                    </ul>
                    <br/>
                    <Text typo='font_body_2M' color='color_text_neutral_3'>
                        Tip 2. VIP 되는 방법
                    </Text>
                    <ul style={{paddingLeft: '26px', margin: '0'}}>
                        <li>
                            <Text typo='font_body_2M' color='color_text_neutral_3'>
                                74,800 이상 모바일요금제 가입하기
                            </Text>
                        </li>
                        <li>
                            <Text typo='font_body_2M' color='color_text_neutral_3'>
                                전년도 연간납부실적 100만원 이상 충족하기
                            </Text>
                        </li>
                    </ul>
                </Accordion.Body>
            </Accordion>
        </>
    );
};

export default LevelUpTips;
