// validators.ts
import {User} from './types';

export const validateNameLength = (name: string): boolean => {
    return name.length >= 2;
};

export const validateNameCharacter = (name: string): boolean => {
    const nameRegex = /[!@#$%^&*(),.?":{}|<>]/;
    return !nameRegex.test(name);
};

export const isValidRRNFront = (input: string): boolean => {
    const rrnFrontRegex = /^\d{6}$/;
    // 년,월,일 검증이 추가될 수 있음
    return rrnFrontRegex.test(input);
};

export const isValidRRNBack = (input: string): boolean => {
    const validDigits = ['1', '2', '3', '4', '5', '6', '7', '8'];
    return validDigits.includes(input);
};

// 핸드폰 번호 검증 함수: 핸드폰 번호는 010-XXXX-XXXX 형식이어야 합니다.
export const isValidPhoneNumber = (phoneNumber: string): boolean => {
    const phoneNumberRegex = /^(01[016789]\d{7,8})|(0\d{1,2}\d{7,8})$/;
    return phoneNumberRegex.test(phoneNumber);
};

export const validateUserInfo = (user: User): boolean => {
    return (
        isValidPhoneNumber(user.phoneNumber) &&
        validateNameLength(user.name) &&
        validateNameCharacter(user.name) &&
        isValidRRNFront(user.birth) &&
        isValidRRNBack(user.gender)
    );
};

export const checkUnder14 = (birth8Digit: string): boolean => {
    const birthYear = parseInt(birth8Digit.slice(0, 4));
    return new Date().getFullYear() - birthYear < 14;
}