import React from "react";
import { Image, Text, List, Button } from "@lguuxe/2024_designsystem_uni";
import { useNavigate } from "react-router-dom";  // useNavigate import
import { Benefit, Event } from "../../pages/Home/types";

interface BenefitInfoProps {
    benefits: Benefit[];
}

const BenefitInfo: React.FC<BenefitInfoProps> = ({ benefits }) => {
    const navigate = useNavigate();  // useNavigate hook

    return (
        <>
            <div style={{ padding: "0" }}>
                <div
                    style={{
                        paddingTop: "12px",
                        width: "100%",
                        borderRadius: "12px",
                        border: "1px solid #DCE0E5",
                        backgroundColor: "#FFFFFF",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "3px",
                        }}
                    >
                        <List
                            type="view"
                            fill="none"
                            highlightColor="#FF0000"
                            items={benefits.map((benefit) => ({
                                onClick: (() => navigate(`/partners/${benefit.ptnId}`)),
                                leftSlot: [
                                    <Image
                                        key={benefit.ptnId}
                                        src={benefit.ptnLogoImgUrl}
                                        ratio="1:1"
                                        width="48px"
                                        rounded="50%"
                                    />,
                                    <div
                                        key={`${benefit.ptnId}-details`}
                                        style={{
                                            gap: "2px",
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Text
                                            typo="font_body_1M"
                                            color="color_text_neutral_3"
                                            style={{ fontSize: "12px" }}
                                        >
                                            {benefit.benefitSummary}
                                        </Text>
                                        <Text
                                            typo="font_detail_2M"
                                            color="color_text_neutral_5"
                                            style={{ fontSize: "16px" }}
                                        >
                                            {benefit.ptnName}
                                        </Text>
                                    </div>,
                                ],
                                linkIconOnlyId: "empty"
                            }))}
                        />
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: "9px",
                            }}
                        >
                            <div
                                style={{
                                    width: "calc(100% - 40px)",
                                    height: "1px",
                                    backgroundColor: "#E7EBEE",
                                }}
                            ></div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                height: "46px",
                            }}
                        >
                            <Button
                                fill="ghost"
                                color="neutral"
                                rightIcon="chevron_right_small_line"
                                onClick={() => navigate("/partners")}  // 클릭 시 네비게이트
                            >
                                <Text typo="font_label_2Sb" color="color_text_neutral_3">
                                    더보기
                                </Text>
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BenefitInfo;
