import React from 'react';
import { TextSet } from "@lguuxe/2024_designsystem_uni";

interface CommonHeadingProps {
    title: String;
}

const CommonHeading: React.FC<CommonHeadingProps> = ({title}) => {

    return (
        <TextSet
            subComponent="Heading_4"
    text={{
        title: title,
    }}
    blockSpacing={{ top: "24px", bottom: "12px" }}
    isIndent={true}
    />
);
}

export default CommonHeading;
