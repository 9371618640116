import React, { useState } from 'react';
import { Text, Box, IconButton } from "@lguuxe/2024_designsystem_uni";
import CommonDividerThin from '../Common/CommonDividerThin';
import Snackbar from "../Common/Snackbar";

// Badge 데이터 타입 정의
interface Badge {
    categoryName: '나들이킹' | '쇼핑킹' | '스터디킹' | '뷰티킹' | '컨텐츠킹' | '일상킹' | '푸드킹';
    count: number;
}

interface SectionStepperProps {
    maxCounts: Partial<Record<Badge['categoryName'], number>>;
    badgeData: Badge[];
    setBadgeData: React.Dispatch<React.SetStateAction<Badge[]>>;
}

const SectionStepper: React.FC<SectionStepperProps> = ({
                                                           maxCounts,
                                                           badgeData,
                                                           setBadgeData,
                                                       }) => {
    const [showSnackbar, setShowSnackbar] = useState(false);

    const totalBadgeCount = Object.values(maxCounts).reduce((sum, count) => sum + (count || 0), 0);

    // + 버튼 클릭 시 수량 증가
    const increaseCount = (index: number) => {
        setBadgeData(prevState => {
            return prevState.map((badge, i) => {
                if (i === index) {
                    const badgeName = badge.categoryName;
                    if (maxCounts[badgeName] && badge.count < maxCounts[badgeName]!) {
                        return { ...badge, count: badge.count + 1 };
                    } else {
                        setShowSnackbar(true);
                        setTimeout(() => {
                            setShowSnackbar(false);
                        }, 1000);
                    }
                }
                return badge;
            });
        });
    };

    // - 버튼 클릭 시 수량 감소
    const decreaseCount = (index: number) => {
        setBadgeData(prevState => {
            return prevState.map((badge, i) => {
                if (i === index && badge.count > 0) {
                    return { ...badge, count: badge.count - 1 };
                }
                return badge;
            });
        });
    };

    return (
        <>
            <Box type="2_trbl">
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: "16px",
                        backgroundColor: "#F9FAFB",
                        borderRadius: "12px"
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            gap: "4px"
                        }}
                    >
                        <Text typo="font_label_1Sb" color="color_text_neutral_6">
                            선물 가능한 배지
                        </Text>
                    </div>
                    <Text typo="font_heading_5Sb" color="color_text_neutral_6">총 {totalBadgeCount}개</Text>
                </div>
            </Box>

            <div
                style={{
                    padding: "16px 20px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "12px"
                }}
            >
                {badgeData.map((badge, index) => (
                    <div
                        key={badge.categoryName}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: "4px 0"
                        }}
                    >
                        <Text typo="font_body_1M" color="color_text_neutral_4">{badge.categoryName}</Text>
                        <div className='stepper'>
                            <IconButton
                                color="primary"
                                fill="solid"
                                icon="remove_line"
                                onClick={() => decreaseCount(index)}
                                size="medium"
                                className="minus"
                            />
                            <span className='num'>{badge.count}</span>
                            <IconButton
                                color="primary"
                                fill="solid"
                                icon="add_line"
                                onClick={() => increaseCount(index)}
                                size="medium"
                                className="plus"
                            />
                        </div>
                    </div>
                ))}
            </div>

            <div
                style={{
                    padding: "0 20px"
                }}
            >
                <CommonDividerThin />
            </div>

            <Box type="4_trbl">
                <div
                    style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        padding: "4px 0"
                    }}
                >
                    <Text
                        style={{ flex: "1" }}
                        typo="font_body_1M"
                        color="color_text_neutral_4">
                        보낼 배지
                    </Text>
                    <Text typo="font_heading_5Sb" color="color_text_primary">
                        총 {badgeData.reduce((acc, badge) => acc + badge.count, 0)}개
                    </Text>
                </div>
                {showSnackbar && <Snackbar text='선물 할 수 있는 최대 개수를 초과했어요.\n배지 개수를 확인해주세요.' />}
            </Box>
        </>
    );
}

export default SectionStepper;
