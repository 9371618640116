import React from 'react'
import { Box, Text } from '@lguuxe/2024_designsystem_uni'

interface SectionBodyTextProps {
    text: string;
}


const SectionBodyText: React.FC<SectionBodyTextProps> = ({ text }) => {
    //TODO 어떤 형태로 넘어오는 포멧 파악한 후 그리기
    console.log(text)
    return (
        <Box>
            <div dangerouslySetInnerHTML={{ __html: text }} />
        </Box>
    )
}

export default SectionBodyText
