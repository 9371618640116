import React, { useEffect, useState } from 'react';
import { Text, Button, Chip } from "@lguuxe/2024_designsystem_uni";
import { addItem, getItemByKey, removeItemByKey, clearItems } from '../../utils/indexedDB';

interface RecentSearchProps {
    onSearch: (query: string) => void;
}

const RecentSearch: React.FC<RecentSearchProps> = ({ onSearch }) => {
    const [recentSearches, setRecentSearches] = useState<string[]>([]);

    useEffect(() => {
        loadSearchTerms();
    }, []);

    const loadSearchTerms = async () => {
        const recentSearch = await getItemByKey("recentSearch");
        console.log(recentSearch)
        if (recentSearch) {
            setRecentSearches(recentSearch);
        } else {
            setRecentSearches([]);
        }
    };

    const handleSearch = async (term: string) => {
        onSearch(term);

        // Get current recent searches
        let recentSearch = await getItemByKey("recentSearch");
        console.log(recentSearch)
        if (recentSearch) {
            // Add the new term to the front of the array, if it doesn't already exist
            recentSearch = [term, ...recentSearch.filter((t: string) => t !== term)];
        } else {
            recentSearch = [term];
        }

        // Save the updated recent searches
        await addItem("recentSearch", recentSearch);

        await loadSearchTerms();
    };

    const handleClear = async () => {
        await clearItems();  // 전체 삭제
        await loadSearchTerms();
    };

    const handleRemove = async (term: string) => {
        let recentSearch = await getItemByKey("recentSearch");
        if (recentSearch) {
            const updatedTerms = recentSearch.filter((t: string) => t !== term);
            if (updatedTerms.length > 0) {
                await addItem("recentSearch", updatedTerms);
            } else {
                await removeItemByKey("recentSearch");
            }
            await loadSearchTerms();
        }
    };

    return (
        <div>
            <div style={{ padding: "0 20px" }}>
                <div>
                    <div style={{ paddingTop: "24px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Text typo="font_heading_4B" color="color_text_neutral_6">
                            최근검색
                        </Text>
                        <Button
                            color="neutral"
                            fill="text"
                            size="small"
                            state="default"
                            onClick={handleClear}
                        >
                            <Text typo="font_label_3Sb" color="color_text_neutral_3">
                                전체삭제
                            </Text>
                        </Button>
                    </div>
                    <div style={{ display: "flex", gap: "6px", paddingTop: "16px", overflow: "auto" }}>
                        {recentSearches.length > 0 ? (
                            recentSearches.map((term, index) => (
                                <Chip.Button
                                    key={index}
                                    color="neutral"
                                    label={term}
                                    fill='outline'
                                    size='medium'
                                    onClick={() => handleSearch(term)}
                                    rightIcon="close_small_line"
                                    onRightIconClick={() => handleRemove(term)}
                                    style={{ minWidth: 'auto' }}
                                />
                            ))
                        ) : (
                            <Text typo="font_body_2M" color="color_text_neutral_3">
                                검색 이력이 없습니다.
                            </Text>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RecentSearch;
