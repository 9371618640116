import React from 'react';
import CommonLeftIconHeader from '../../components/Common/CommonLeftIconHeader';
import CouponItem from '../../components/ListItem/CouponItem';
import EventItem from '../../components/ListItem/EventItem';
import NoticeItem from '../../components/ListItem/NoticeItem';
import QnAItem from '../../components/ListItem/QnAItem';
import StoreItem from '../../components/ListItem/StoreItem';
import UPTPItem from '../../components/ListItem/UPTPItem';
import {useLocation} from "react-router-dom";

const ResultMore: React.FC = () => {
    const location = useLocation();
    const state = location.state || { title: "", items: [] };
    const renderItems = (title: string, items: any[]) => {
        switch (title) {
            case '쿠폰':
                return <CouponItem itemList={items}/>;
            case '이벤트':
                return <EventItem itemList={items}/>;
            case '공지사항':
                return <NoticeItem itemList={items}/>;
            case '자주하는 질문':
                return <QnAItem itemList={items}/>;
            case '이용 가능한 매장':
                return <StoreItem itemList={items}/>;
            case '유플투쁠':
                return <UPTPItem itemList={items}/>;
            default:
                return null;
        }
    }
        return (
        <div
            style={{
                width: '100%',
                height: '100vh',
            }}
        >
            <CommonLeftIconHeader title={`${state.title}(${state.items.length})`} />
                {renderItems(state.title, state.items)}
        </div>
    );
};

export default ResultMore;
