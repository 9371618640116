import CommonLeftIconHeader from "../../../components/Common/CommonLeftIconHeader";
import {Box, Button, CTA, List, TextSet} from "@lguuxe/2024_designsystem_uni";
import React, {useEffect, useState} from "react";
import CommonDividerThin from "../../../components/Common/CommonDividerThin";
import {useNavigate} from "react-router-dom";
import {affiliateServiceApi} from "../../../api/partnerAndAffiliate-service";
import {joinRequestParam, Term} from "./types";
import CommonPopup from "../../../components/Common/CommonPopup";

const PopTerms: React.FC = () => {
    const navigate = useNavigate();
    const [allAgree, setAllAgree] = useState(false);
    const [isNextButtonActive, setIsNextButtonActive] = useState(false);
    const [termList, setTermList] = useState<Term[]>([]);
    const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>(false);
    const [showFailPopup, setShowFailPopup] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                affiliateServiceApi.getAgreements({serviceId: '129001'}, 'v1').then((response) => {
                    console.log(response);
                    setTermList(response.body.agreementList.map((item: any) => {
                        return {
                            termId: item.termId,
                            termName: item.termName,
                            termType: item.termType,
                            isAgree: false,
                        }
                    }));
                });
            } catch (error) {
                console.log('Failed to fetch :', error);
            }
        }
        fetchData();
    }, []);

    useEffect(() => {
        setTermList(termList.map(term => ({...term, isAgree: allAgree})));
    }, [allAgree]);

    useEffect(() => {
        const isAllAgree = termList
            .filter(term => term.termType === 'Y')
            .every(term => term.isAgree);
        setIsNextButtonActive(isAllAgree);
    }, [termList]);

    const fetchJoin = async () => {
        try {
            const requestParam = joinRequestParam(termList);
            affiliateServiceApi.postJoin(requestParam, 'v1').then((response) => {
                console.log(response);
                switch (response.header.resultCode) {
                    case '0000':
                        setShowSuccessPopup(true);
                        break;
                    case '1012': // 이메일 정보 없음
                        navigate('/pop/input', {state: termList});
                        break;
                    default:
                        setShowFailPopup(true);
                        break;
                }
            });
        } catch (error) {
            setShowFailPopup(true);
            console.log('Failed to fetch :', error);
        }
    }

    const generateTermText = (text: string, termType: string) => {

        const prefix = termType === 'Y' ? '[필수]' : '[선택]';
        return `${prefix} ${text}`;
    };

    const handleTermCheck = (e: any) => {
        const checkedState = e.checkedState; // {terms-0: true, terms-1: true, terms-2: false}
        const updatedTermList = termList.map((term, index) => {
            const key = `terms-${index}`;
            return {
                ...term,
                isAgree: checkedState[key],
            };
        });
        setTermList(updatedTermList);
    };

    const handleAllAgree = () => {
        setAllAgree(!allAgree);
    };

    const handleNext = () => {
        fetchJoin();
        // navigate('/pop/input', {state: termList});
    }

    return (
        <>
            <CommonLeftIconHeader title={''}/>

            <Box
                type="2_trbl"
            >
                <TextSet
                    subComponent="Heading_2"
                    text={{
                        title: 'U+멤버십 GS 약관'
                    }}
                    weight="bolder"
                />
            </Box>

            <Box
                type="3_tb"
            >
                <List
                    fill="none"
                    size="large"
                    items={[
                        {
                            text: {
                                title:
                                    <TextSet
                                        subComponent="Body_1"
                                        text={{
                                            title: '모든 이용약관에 동의'
                                        }}
                                        weight="bolder"
                                    />
                            },
                            checked: allAgree
                        }
                    ]}
                    type="checkbox"
                    onChange={handleAllAgree}
                />
                <Box style={{padding: "0 20px"}}>
                    <CommonDividerThin/>
                </Box>
                <Box style={{padding: "12px 0 0"}}>
                    <List
                        id='terms'
                        fill="none"
                        size="large"
                        items={termList.map((term, index) => ({
                            checked: termList[index].isAgree,
                            text: {
                                title: (
                                    <TextSet
                                        subComponent="Body_1"
                                        text={{
                                            title: generateTermText(term.termName, term.termType)
                                        }}
                                    />
                                )
                            },
                            // link: term.link,
                        }))}
                        type="checkbox"
                        onChange={handleTermCheck}
                    />
                </Box>
            </Box>

            <Box className="fixedBtn" style={{padding: "0"}}>
                <CTA
                    layout="row"
                >
                    <Button color="neutral" onClick={() => {
                        navigate(-1)
                    }}>
                        취소
                    </Button>
                    <Button color="primary" disabled={!isNextButtonActive} onClick={handleNext}>
                        동의하기
                    </Button>
                </CTA>
            </Box>
            {showSuccessPopup && (
                <CommonPopup description={'POP서비스 가입했어요.\nGS25에 휴대결제하고\n추가할인 받으세요.'} submitText={'확인'} onSubmit={() => {
                    navigate('/pop')
                }}/>)}
            {showFailPopup && (
                <CommonPopup description={'일시적인 오류로 POP서비스\n가입하지 못했어요.\n잠시 후 다시 시도해주세요.'} submitText={'확인'}
                             onSubmit={() => {
                                 navigate('/pop')
                             }}/>)}
        </>
    )


}

export default PopTerms;