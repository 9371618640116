import {getItemByKey} from "./indexedDB";
import {
    sendEvent, sendEventReturn,
    sendEventReturnToAndroid,
    sendEventReturnToIOS,
    sendEventToAndroid,
    sendEventToIOS
} from "../api/bridgeApi";

export const getCurrentYYYYMM = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = (now.getMonth() + 1).toString().padStart(2, '0'); // 월은 0부터 시작하므로 +1 필요
    return `${year}${month}`;
}

export const callCheckPassword = async (page:string) => {
    const bio = await getItemByKey("useBio")
    if(bio) {
        sendEventReturn("CHECK_PASSWORD", {reqAuthType : "01", page: page})
    } else {
        sendEventReturn("CHECK_PASSWORD", {reqAuthType : "00", page: page})
    }
}

export const getAgeCode = async () => {
    const birthday = await getItemByKey('birthday');
    if (!birthday) {
        return '119001';
    }
    // 생년월일을 YYYYMMDD 형식에서 연, 월, 일로 분리
    const birthYear = parseInt(birthday.substring(0, 4), 10);
    const birthMonth = parseInt(birthday.substring(4, 6), 10);
    const birthDay = parseInt(birthday.substring(6, 8), 10);

    // 현재 날짜 가져오기
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1; // getMonth()는 0부터 시작하므로 +1
    const currentDay = today.getDate();

    // 나이 계산
    let age = currentYear - birthYear;

    // 생일이 아직 지나지 않았다면 나이를 1살 줄임
    if (currentMonth < birthMonth || (currentMonth === birthMonth && currentDay < birthDay)) {
        age--;
    }

    // 나이에 따른 코드 반환
    if (age < 10) {
        return '119001';
    } else if (age >= 10 && age < 20) {
        return '119002';
    } else if (age >= 20 && age < 30) {
        return '119003';
    } else if (age >= 30 && age < 40) {
        return '119004';
    } else if (age >= 40 && age < 50) {
        return '119005';
    } else if (age >= 50) {
        return '119006';
    } else {
        return '119001';
    }
}

export const getGenderCode = async () => {
    const sex = await getItemByKey('gender')
    if (sex === 'M') {
        return 'M';
    } else if (sex === 'A') {
        return 'F';
    } else {
        return 'A';
    }
}
export const handleSendEvent = (platform: string, key: string, param: any) => {
    console.log(platform, key, param)
    if (platform === 'Android') {
        const message = sendEventToAndroid(key, param);
        console.log('Android handleSendEvent', message);
    } else if (platform === 'IOS') {
        const message = sendEventToIOS(key, param);
        console.log('iOS handleSendEvent', message);
    } else {
        console.log('Platform not supported');
    }
};

export const handleSendEventReturn = (platform: string, key: string, param: any) => {
    if (platform === 'Android') {
        const message = sendEventReturnToAndroid(key, param);
        console.log('Android handleSendEvent', message);
    } else if (platform === 'IOS') {
        const message = sendEventReturnToIOS(key, param);
        console.log('IOS handleSendEvent', message);
    } else {
        console.log('Platform not supported');
    }
};

export const handleBackClick = (navigate: (to: number) => void) => {
    navigate(-1); // Navigate back
};

export const getMemberStatus = (memberId: string | undefined, memberStatus: string | undefined, cardLevel: string | undefined) => {
    console.log('memberId:', memberId, 'memberStatus:', memberStatus, 'cardLevel:', cardLevel);
    if (!memberId || !memberStatus) return '0';
    if (!cardLevel) return '5';

    if (['41', '42', '43', '44'].includes(memberStatus) && ['6', '8'].includes(cardLevel)) return '1'
    if (['41', '42', '43', '44'].includes(memberStatus) && cardLevel === '7') return '2';
    if (memberStatus === '48' && ['6', '8'].includes(cardLevel)) return '3';
    if (memberStatus === '48' && cardLevel === '7') return '4';

    return '0';
};

export const formatTimeAgo = (itemDate: string): string => {
    const itemTime = new Date(itemDate);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - itemTime.getTime()) / 1000);

    if (diffInSeconds < 60) {
        return "방금";
    }

    const diffInMinutes = Math.floor(diffInSeconds / 60);
    if (diffInMinutes < 60) {
        return `${diffInMinutes}분 전`;
    }

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) {
        return `${diffInHours}시간 전`;
    }

    const diffInDays = Math.floor(diffInHours / 24);
    if (diffInDays < 7) {
        return `${diffInDays}일 전`;
    }

    return itemDate;  // 7일 이상인 경우에는 원래 날짜를 반환합니다.
};

export const generateLast12Months = (year: number, month: number): { id: string, label: string }[] => {
    const months = [];
    let currentYear = year;
    let currentMonth = month;

    for (let i = 0; i < 12; i++) {
        const month = `${currentYear}년 ${currentMonth}월`;
        months.push({id: i.toString(), label: month});
        currentMonth--;
        if (currentMonth === 0) {
            currentMonth = 12;
            currentYear--;
        }
    }

    return months;
};

export const getBenefitTagByUserLevel = (userLevel: string) => {
    const benefitMap: { [key: string]: string } = {
        "6": "127001", // VVIP
        "7": "127003", // 우수
        "8": "127002", // VIP
    };

    return benefitMap[userLevel];
}

export const getTagInfoByBenefitCode = (benefitCode: string) => {
    const tagInfoMap: { [key: string]: { label: string; colorToken: string } } = {
        "127001": {label: 'VVIP', colorToken: 'primary_low'},
        "127002": {label: 'VIP', colorToken: 'purple_low'},
        "127003": {label: '우수', colorToken: 'blue_low'},
        "127004": {label: '일반', colorToken: 'default'},
        "127005": {label: '장기고객', colorToken: 'blue_low'},
        "127006": {label: '유쓰', colorToken: 'green_low'},
        "127007": {label: 'VIP 콕', colorToken: 'purple_low'},
    };

    return tagInfoMap[benefitCode] || {label: '기본', size: 'xSmall', style: 'solid', colorToken: 'gray_low'};
}

export const goMovie = async (agent?: string) => {
    const token = await getItemByKey("token");
    const encKey = await getItemByKey("encKey");
    const clv = await getItemByKey("cardLevel");
    const ci = await getItemByKey("ci");
    const userName = await getItemByKey("customerName");

    // Construct the URL based on whether agent is passed as a parameter
    let url = "http://106.103.227.32:30001/movie/mobile/intro.lgum?isMembers=Y";
    if (agent) {
        url += `&agent=${agent}`;
    }

    const movieParam = {
        url: url,
        token: token,
        encKey: encKey,
        clv: clv,
        ci: ci,
        userName: userName
    };
    console.log("movieURL:", movieParam)
    await sendEvent("OPEN_MOVIE_RESERVATION", movieParam);
}

export const goMoveData = async () => {
    const token = await getItemByKey("token")
    const encKey = await getItemByKey("encKey")
    const clv = await getItemByKey("cardLevel")
    const ci = await getItemByKey("ci")
    const userName = await getItemByKey("customerName")
    const moveDataParam = {
        //TODO 추후 상용수정 필요
        url: "http://106.103.227.32:30001/renew/html/datakok/data_give.html?isMembers=Y&tabIndex=0&kokCode=CL",
        token: token,
        encKey: encKey,
        clv: clv,
        ci: ci,
        userName: userName,
    }
    await sendEvent("MOVE_DATA", moveDataParam)
}


export const extractMonth = (dateString: string) => {
    // MM 부분을 추출 (index 4, 5)
    const month = dateString.substring(4, 6);

    // 숫자로 변환하여 앞의 0을 제거하고 반환
    return parseInt(month, 10).toString();
}

export const formatDateToYYYYMMDD = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
};

 export const goAppStore = async () => {
     const platform = await getItemByKey("platform")
     const url = platform === "IOS" ? "https://itunes.apple.com/kr/app/apple-store/uplus.membership" : "https://play.google.com/store/apps/details?id=uplus.membership"
     sendEventReturn("EXTERNAL_CALL", {
         type: '1',
         url:url
     })
 }

export const getColorTokenForLabel = (label: string): string => {
    switch (label) {
        case "유쓰":
            return "green_low";
        case "멤버십":
            return "blue_low";
        case "장기고객":
            return "green_low";
        default:
            return "orange_low";
    }
};