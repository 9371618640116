import {addItem, getItemByKey} from '../utils/indexedDB';
import {handleSendEvent} from "../utils/helper";
import {Header} from "./types";

const BASE_URL = process.env.REACT_APP_NODE_ENV === 'production'
    ? 'https://api-services.members.lgudevplatform.com'
    : 'https://api-serviced.members.lgudevplatform.com'
const SERVER_HOST = 'frontService'

interface ApiEndpointsType {
    [key: string]: any;
}

const handlePromiseResult = <T, H>(result: PromiseSettledResult<{
    body: T;
    header: Header
}>, defaultValue?: T): T | undefined => {
    if (result.status === 'fulfilled' && result.value.header.resultCode === '0000') {
        return result.value.body;
    } else {
        console.error(`Failed to fetch :`, result);
        return defaultValue;
    }
};
const ApiEndpoints: ApiEndpointsType = Object.freeze({
    JOIN: '/join',
    BENEFIT: {
        PRS_MSG: '/benefit/prs-msg',
        UPTP: '/benefit/uptp',
        QUICK_MENU: '/benefit/quick-menu',
        RECENT_USED_BENEFIT: '/benefit/recent-used-benefit',
        BENEFIT_TAG: '/benefit/benefit-tag',
        BENEFIT_TAG_ID: (tagId: string) => `/benefit/benefit-tag/${tagId}`,
        MOVIE_RANKING: '/benefit/movie-ranking',
        EVENT_TAG: '/benefit/event-tag',
        EVENT_TAG_ID: (tagId: string) => `/benefit/event-tag/${tagId}`,
        RANDOM: {
            CATEGORIES: '/benefit/random/categories',
            CATEGORIES_ID: (categoryId: string) => `/benefit/random/categories/${categoryId}`,
        },
    },
    MEMBERSHIP: {
        UPTP: {
            MONTH_EVENT: '/membership-service/uptp/month-event',
            MONTH_EVENT_BY_DATE: (evtDate: string) => `/membership-service/uptp/month-event/${evtDate}`,
            BASIC_BENEFIT: '/membership-service/uptp/basic-benefit',
            VIP_BENEFIT: '/membership-service/uptp/vip-benefit',
            LOYAL_MEMBER_BENEFIT: '/membership-service/uptp/loyal-member-benefit',
            EVENT_DETAIL: (eventId: string) => `/membership-service/uptp/event/${eventId}`,
        },
        CARD_INFO: '/membership-service/card-info',
        CARD_BARCODE: '/membership-service/card-barcode',
        VIP_BENEFIT_SUMMARY: '/membership-service/vip-benefit-summary',
        VIP_BENEFIT_THIS_MONTH: '/membership-service/vip-benefit-this-month',
        ALARM: '/membership-service/alarm',
        RANK_INFO: '/membership-service/rank-info',
        RANK_UP_TIPS: '/membership-service/rank-up-tips',
        VIP_KOK: {
            INFO: '/membership-service/vip-kok/info',
            USE_HISTORY: '/membership-service/vip-kok/use-history',
            PARTNER_CATEGORIES: '/membership-service/vip-kok/partner-categories',
            PARTNERS: '/membership-service/vip-kok/partners',
            CHECK: '/membership-service/vip-kok/please-check',
            CATEGORIES: '/membership-service/vip-kok/categories',
            CATEGORIES_BY_ID: (categoryId: string) => `/membership-service/vip-kok/categories/${categoryId}`,
            PLEASE_CHECK: '/membership-service/vip-kok/please-check',
            PARTNER_BY_ID: (ptnId: string) => `/membership-service/vip-kok/partners/${ptnId}`,
        },
        DATA_GIFTS: {
            LIMIT: '/membership-service/data-gifts/limit',
            AUTH: {INDEX: '/membership-service/data-gifts/auth', SMS: '/membership-service/data-gifts/auth/sms'},
            SEND: '/membership-service/data-gifts/send',
            HISTORY: '/membership-service/data-gifts/history',
        },
        MOVIE: {
            THEATER: '/membership-service/movie/theater',
            THEATER_BY_CODE: (theaterCode: string) => `/membership-service/movie/theater/${theaterCode}`,
            THEATER_LIST: (theaterCode: string, locationCode: string) =>
                `/membership-service/movie/theater-list/${theaterCode}/${locationCode}`,
            MOVIE_LIST: (theaterCode: string, locationCode: string) =>
                `/membership-service/movie/movie-list/${theaterCode}/${locationCode}`,
        },
        SIMPLE_CARD_INFO: '/membership-service/simple-card-info',
        USE_HISTORY: (yearMonth: string) => `/membership-service/use-history/${yearMonth}`,
    },
    PARTNER: {
        PARTNERS: {
            LIKE: '/partner/partners/like',
            LIKE_BY_ID: (ptnId: string) => `/partner/partners/like/${ptnId}`,
            INDEX: '/partner/partners',
            BY_ID: (ptnId: string) => `/partner/partners/${ptnId}`,
            RECOMM_BY_ID: (ptnId: string) => `/partner/partners/${ptnId}/recomm`,
            VIP_COK_BY_ID: (ptnId: string) => `/partner/ay/${ptnId}/vip-kok`,
            COUPON_BY_ID: (ptnId: string) => `/partner/partners/${ptnId}/coupon`,
        },
        CATEGORIES: '/partner/categories',
        CATEGORIES_SUB: (categoryId: string) => `/partner/categories/${categoryId}/sub`,
    },
    AFFILIATE_SERVICE: {
        JOIN_STATUS: '/affiliate-service/join-status',
        INFO: '/affiliate-service/info',
        AGREEMENTS: '/affiliate-service/agreements',
        AGREEMENTS_BY_ID: (agrId: string) => `/affiliate-service/agreements/${agrId}`,
        JOIN: '/affiliate-service/join',
    },
    ATTENDANCE: {
        INDEX: '/attendance',
        CHECK: '/attendance/check',
        EVENT_INFO: '/attendance/event-info',
        LAST_MONTH_RESULT: '/attendance/last-month-result',
    },
    EVENT: {
        INDEX: '/event',
        BY_ID: (evtId: string) => `/event/${evtId}`,
        WINNER_INFO: (eventId: string) => `/event/winner-info/${eventId}`,
    },
    EVENT_BANNER: '/event-banner',
    MISSION: {
        REWARD_POINT: '/mission/reward-point',
    },
    MONEYME: '/money/benefit',
    COUPON: {
        ISSUED: {
            INDEX: '/coupon/issued',
            BY_ID: (cpnId: string) => `/coupon/issued/${cpnId}`,
            CREW_CONFORM: (cpnKey: string) => `/coupon/issued/${cpnKey}/crew-conform`,
        },
        BY_ID: (cpnId: string) => `/coupon/${cpnId}`,
    },
    PAYMENT: {
        SERVICE_USE_TERM: '/payment/service-use-term',
        STATUS: '/payment/status',
        MEMBERSHIP_DISCOUNT: '/payment/membership-discount',
        CARD_BARCODE: '/payment/card-barcode',
        SETTING: '/payment/setting',
        LIMIT: '/payment/limit',
        USE_HISTORY: '/payment/use-history',
        PASSWORD: '/payment/password',
        PAY_REQUEST: '/payment/pay-request',
        PAY_CONFIRM: '/payment/pay-confirm',
        PAY_OTP: '/payment/pay-otp',
        PAY_SEQ: '/payment/pay-seq',
        LOCK_DISABLE: '/payment/lock-disable',
    },
    NOTI: {PAY_RESULT: '/noti/pay-result'},
    SEARCH: {
        INDEX: '/search',
        RECOMM: '/search/recomm',
    },
    ALARM: '/alarm',
    MAP: {
        PARTNERS: '/map/partners',
        PARTNERS_BY_ID: (ptnId: string) => `/map/partners/${ptnId}`,
    },
    COMMON: {
        SESSION: '/common/session',
        TERMS: {
            AGREEMENTS: '/common/terms/agreemets',
            AGREEMENTS_BY_ID: (termId: string) => `/common/terms/agreemets/${termId}`,
            AGREEMENTS_CONFIRM: '/common/terms/agreements-confirm',
        },
        SERVICE_NOTI: '/common/service-noti',
        MARKETING_NOTI: '/common/marketing-noti',
        TUTORIAL_INFO: '/common/tutorial-info',
        BLOCK_INFO: '/common/block-info',
        SLASH_INFO: '/common/slash-info',
        TODAY: '/common/today',
    },
    EXTERNAL_SERVICE: {
        MONEYME: '/external-service/moneyme',
        KOKSHOP: '/external-service/kokshop',
    },
    GUIDE: {BENEFIT: '/guide/benefit'},
    SETTING: {
        COUPON: {
            ALARM: '/setting/coupon/alarm',
        },
        TERMS: {
            BNF_AD: '/setting/terms/bnf-ad',
            PERSONAL_INFO: '/setting/terms/personal-info',
        },
        MY_INFO: '/setting/myinfo',
        PUSH_REG: '/setting/pushRegId'
    },
    TERMS: {
        AGREEMENTS: '/terms/agreements',
        AGREEMENTS_BY_ID: (termId: string) => `/terms/agreements/${termId}`,
        PRIVACY_POLICY: '/terms/privacy-policy',
        JOIN: {
            INDEX: '/terms/join',
            AGREEMENTS: '/terms/join/agreements',
            AGREEMENTS_BY_ID: (termId: string) => `/terms/join/agreements/${termId}`,
        },
    },
    VERSION: '/version/app',
    NOTICE: {
        CATEGORIES: {
            LIST: '/notice/categories/list',
        },
        CATEGORIES_BY_ID: (categoryId: string) => `/notice/categories/${categoryId}`,
        CONTENT: (noticedId: string) => `/notice/content/${noticedId}`,
    },
    FAQ: {
        CATEGORIES: '/faq/categories',
        CATEGORIES_BY_ID: (categoryId: string) => `/faq/categories/${categoryId}`,
        CONTENT: (faqId: string) => `/faq/content/${faqId}`,
    },
    INQUIRY: {
        INDEX: '/inquiry',
        REG: '/inquiry/reg',
        BY_ID: (inqId: string) => `/inquiry/${inqId}`,
    },
    AUTH: {
        SMS: '/auth/sms',
        SMS_RESEND: '/auth/sms-resend',
        CONFIRM: '/auth/confirm',
        PARENT_CHECK: '/auth/parent-check',
    },
    DAS: '/das/user-info',
    NFT: {
        MY_BADGE: '/nft/my-badge',
        EVENT: '/nft/event',
        PROMOTION: '/nft/promotion',
        CHECK_WINNING: '/nft/check-winning',
        CATEGORIES: '/nft/categories',
        CATEGORY_BY_ID: (categoryId: string) => `/nft/categories/${categoryId}`,
        TERMS: '/nft/terms',
        TERMS_AGREEMENTS: '/nft/terms/agreements',
        RECEIPT_INFO: '/nft/receipt-info',
        CHECK_SENDING: '/nft/check-sending',
        HISTORY: '/nft/history',
        SEND: '/nft/send',
        CHECK_RECEIVER: '/nft/check-receiver',
        BADGE_BY_ID: (badgeId: string) => `/nft/${badgeId}`,
    },
    USER: {
        WITHDRAWAL: '/withdrawal',
    },
    EMERGENCY: {
        POPUP: '/emergency/popup',
    },
    IPIN: {
        REDIRECT: '/ipin/redirect',
    },
});

export const fetchAuth = async <T>(memberId: string, CTN: string): Promise<any> => {
    const url = `${BASE_URL}/api-service/v1/auth/sign-in`;
    // const CTN = '01000001221';
    // const memberId = '71';

    const MCP_TR_KEY = generateMCP_TR_KEY();
    const customHeader = {
        MCP_TR_KEY: MCP_TR_KEY,
    }
    const defaultHeaders = {
        'Content-Type': 'application/json',
    };

    const accessKey = await encrypt(MCP_TR_KEY, CTN);

    const body = {
        svc: memberId,
        accessKey: accessKey,
    }

    const options: RequestInit = {
        method: 'POST',
        body: JSON.stringify(body),
    }
    const headers = {
        ...defaultHeaders,
        ...customHeader
    };

    const response = await fetch(url, {...options, headers});
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
}

export const encrypt = async (mcpTrKey: string, value: string) => {
    const encryptKeyStr = getEncryptedKey(mcpTrKey);
    // convert to bytes
    const encryptKey = new TextEncoder().encode(encryptKeyStr);
    const plaintext = new TextEncoder().encode(value);
    const iv = crypto.getRandomValues(new Uint8Array(12));

    const decoder = new TextDecoder();
    const decodedEncryptKey = decoder.decode(encryptKey);
    const decodedPlaintext = decoder.decode(plaintext);
    const decodedIv = decoder.decode(iv);

    try {
        const key = await crypto.subtle.importKey(
            'raw',
            encryptKey,
            {name: 'AES-GCM'},
            false,
            ['encrypt']
        );
        const encryptedBuffer = await crypto.subtle.encrypt(
            {
                name: 'AES-GCM',
                iv: iv,
            },
            key,
            plaintext
        );

        const combinedBuffer = new Uint8Array(iv.byteLength + encryptedBuffer.byteLength);
        combinedBuffer.set(new Uint8Array(iv), 0);
        combinedBuffer.set(new Uint8Array(encryptedBuffer), iv.byteLength);

        // encode to base64
        // Uint8Array를 문자열로 변환
        const binaryString = Array.from(combinedBuffer).map(byte => String.fromCharCode(byte)).join('');

        // encode to base64
        const encryptedStr = btoa(binaryString);
        return encryptedStr;
    } catch (error) {
        console.error('Error:', error);
    }
    return '';
}

export const decrypt = async (mcpTrKey: string, value: string) => {
    mcpTrKey = getEncryptedKey(mcpTrKey)
    const keyData = new TextEncoder().encode(mcpTrKey);
    console.log(keyData)
    const combinedBuffer = Uint8Array.from(atob(value), c => c.charCodeAt(0));
    console.log(combinedBuffer)
    const iv = combinedBuffer.slice(0, 12);
    const encryptedBuffer = combinedBuffer.slice(12);

    try {
        const key = await crypto.subtle.importKey(
            'raw',
            keyData,
            {name: 'AES-GCM'},
            false,
            ['decrypt']
        );

        const decryptedBuffer = await crypto.subtle.decrypt(
            {
                name: 'AES-GCM',
                iv: iv
            },
            key,
            encryptedBuffer
        );

        return new TextDecoder().decode(decryptedBuffer);
    } catch (e) {
        console.error(e);
        return null;
    }
}

export const fetchAuthConfirm = async <T>(token: string) => {
    const url = `${BASE_URL}/api-service/v1/auth/test`;
    const MCP_TR_KEY = generateMCP_TR_KEY();

    const customHeader = {
        Authorization: 'Bearer ' + token,
        MCP_TR_KEY: MCP_TR_KEY,
    }
    const defaultHeaders = {
        'Content-Type': 'application/json',
    };

    const options: RequestInit = {
        method: 'GET',
    }
    const headers = {
        ...defaultHeaders,
        ...customHeader
    };
    const response = await fetch(url, {...options, headers});
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
}

const fetchJson = async <T>(url: string, options: RequestInit = {}, mcpTrKey?: string): Promise<T> => {
    mcpTrKey = mcpTrKey || generateMCP_TR_KEY();

    const memberId = await getItemByKey('memberId') || ''
    const ctn = await getItemByKey('ctn') || ''
    const birthday = await getItemByKey('birthday') || ''
    console.log(`headerValue ctn:${ctn}, memberId:${memberId}, birthday:${birthday}`)
    const encryptedBirthDay = await encrypt(mcpTrKey, birthday)
    const encryptedMemberId = await encrypt(mcpTrKey, memberId)
    const encryptedCtn = await encrypt(mcpTrKey, ctn)
    const customHeader = {
        userBirth: encryptedBirthDay,
        ctn: encryptedCtn,
        memberId: encryptedMemberId,
        os: await getItemByKey('osVersion'),
        osName: await getItemByKey('platform') || 'IOS',
        appVer: await getItemByKey('appVersion'),
        carrier: await getItemByKey('telcoCode') === 'L' ? 'L' : 'O', // L: 자사, O: 타사(알뜰폰 포함)
        Authorization: `Bearer ${await getItemByKey('sessionKey')}`,
        MCP_TR_KEY: mcpTrKey,
    };
    console.log('fetchJson customHeader :', customHeader);
    const defaultHeaders = {
        'Content-Type': 'application/json',
    };

    const headers = {
        ...defaultHeaders,
        ...customHeader,
    };
    console.log("logging", url, mcpTrKey)
    const response = await fetch(url, {...options, headers});
    if (!response.ok) {
        if (response.status === 401) {
            const response = await fetchAuth(memberId, ctn);
            const token = response.body.token
            await addItem('sessionKey', token)
            const platform = await getItemByKey('platform')
            handleSendEvent(platform, 'SET_AUTHORIZATION', {
                authorization: `${token}`
            })
        }
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    return response.json();
};

export const generateMCP_TR_KEY = () => {
    const serverHost = SERVER_HOST; // 실제 서버 호스트로 대체
    const yyyyMMddHHmmss = getDate();
    const randomString = generateRandomString();
    return `${serverHost}_${yyyyMMddHHmmss}_${randomString}`;
}

const getDate = () => {
    const now = new Date();
    const date = now
        .toISOString()
        .replace(/[^0-9]/g, '')
        .slice(0, 14);
    return date;
}

const generateRandomString = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const randomStringLength = 14;

    let result = '';
    for (let i = 0; i < randomStringLength; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
}

const getEncryptedKey = (MCP_TR_KEY: string) => {
    const match = MCP_TR_KEY.match(/_(\d{14})_/);
    const dateTime = match ? match[1] : '';
    let result = '';
    if (dateTime) {
        const lastTwo = dateTime.slice(-2);
        result = dateTime + lastTwo + dateTime + lastTwo;
    }
    return result;
}

interface Api {
    get: (url: string, params?: Record<string, any>, version?: string, mcpTrKey?: string) => Promise<any>;
    post: (url: string, body: Record<string, any>, version?: string, mcpTrKey?: string) => Promise<any>;
    put: (url: string, body: Record<string, any>, version?: string, mcpTrKey?: string) => Promise<any>;
    delete: (url: string, body: Record<string, any>, version?: string, mcpTrKey?: string) => Promise<any>;
}

const api: Api = {
    get: (url: string, params: Record<string, any> = {}, version: string = 'v1', mcpTrKey): Promise<any> => {
        console.log(params)
        const queryString = new URLSearchParams(params).toString();
        return fetchJson(`${BASE_URL}/api-service/${version}${url}?${queryString}`, {}, mcpTrKey);
    },
    post: (url: string, body: Record<string, any>, version: string = 'v1', mcpTrKey): Promise<any> => {
        return fetchJson(`${BASE_URL}/api-service/${version}${url}`, {
            method: 'POST',
            body: JSON.stringify(body),
        }, mcpTrKey);
    },
    put: (url: string, body: Record<string, any>, version: string = 'v1'): Promise<any> => {
        return fetchJson(`${BASE_URL}/api-service/${version}${url}`, {
            method: 'PUT',
            body: JSON.stringify(body),
        });
    },
    delete: (url: string, body: Record<string, any>, version: string = 'v1'): Promise<any> => {
        return fetchJson(`${BASE_URL}/api-service/${version}${url}`, {
            method: 'DELETE',
            body: JSON.stringify(body),
        });
    },
};

export {ApiEndpoints, api, handlePromiseResult};
