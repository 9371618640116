import React from 'react';
import {BottomSheet, Header, IconButton, List, Text, TextSet} from '@lguuxe/2024_designsystem_uni';

import CommonDivider from '../Common/CommonDivider';

import '../../pages/css/style.css';
import {CouponItem} from "../../pages/My/types";

interface BS_CouponDetailProps {
    isOpen: boolean;
    coupon: CouponItem;
    onClose: () => void;
}

const BS_CouponDetail: React.FC<BS_CouponDetailProps> = ({isOpen, coupon, onClose}) => {
    return (
        <BottomSheet isOpen={isOpen} onClose={onClose}>
            <BottomSheet.TopSlot>
                <Header align='left' variant='heading'>
                    {coupon.ptnName} 쿠폰 상세
                    <Header.RightSlot>
                        <IconButton color='neutral' fill='icon' icon='close_line' noStates onClick={onClose}/>
                    </Header.RightSlot>
                </Header>
            </BottomSheet.TopSlot>

            <BottomSheet.CenterSlot>
                <div style={{paddingTop: '0px', height: 'auto', paddingBottom: '24px'}}>
                    {/* 혜택 내용3 */}
                    {/*<PartnerProfile*/}
                    {/*    isMembershipAliance={coupon.cpnType}*/}
                    {/*    ptnName={coupon.ptnName}*/}
                    {/*    ptnImgUrl={coupon.ptnLogoImgUrl}*/}
                    {/*    isLike={coupon.isIssuedCoupon}*/}
                    {/*    metaTag={coupon.summary}*/}
                    {/*    likeNum={coupon.cpnId}*/}
                    {/*/>*/}

                    {/* 유효기간, 사용처 */}
                    <List
                        fill='underline'
                        items={[
                            {
                                leftSlot: (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '0.5rem',
                                        }}
                                    >
                                        <TextSet text={{title: '유효기간'}} subComponent='Body_2'/>
                                    </div>
                                ),
                                rightSlot: (
                                    <Text typo='font_heading_5Sb' color='color_text_neutral_6'>
                                        {coupon.expStartDate} ~ {coupon.expEndDate}
                                    </Text>
                                ),
                                text: 'Item 1',
                            },
                            {
                                leftSlot: (
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '0.5rem',
                                            width: '250px',
                                        }}
                                    >
                                        <TextSet text={{title: '사용처'}} subComponent='Body_2'/>
                                    </div>
                                ),

                                rightSlot: (
                                    <Text typo='font_heading_5Sb' color='color_text_neutral_6'>
                                        {coupon.ptnName}
                                    </Text>
                                ),
                                text: 'Item 2',
                            },
                        ]}
                        type='view'
                    />
                    {/* 오류: List 컴포넌트 하단 라인 없애는 기능 필요 */}

                    <CommonDivider/>

                    {/* 이용방법 및 유의사항 */}
                    {/*<UsageAccordion/>*/}
                </div>
            </BottomSheet.CenterSlot>

            {/* <BottomSheet.BottomSlot>
        <CTA gradation layout="row">
          <Button color="secondary" fill="solid">
            혜택 사용하러 가기
          </Button>
        </CTA>
      </BottomSheet.BottomSlot> */}
        </BottomSheet>
    );
};

export default BS_CouponDetail;
