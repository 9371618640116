import {api, ApiEndpoints} from './api';
import {ApiParams, ApiResponse} from './types';

const notiApi = {
    getCategoriesList: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.NOTICE.CATEGORIES.LIST, params, version),
    getCategoriesById: (categoryId: string, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.NOTICE.CATEGORIES_BY_ID(categoryId), {}, version),
    getContent: (noticeId: string, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.NOTICE.CONTENT(noticeId), {}, version),
    getAlarm: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.ALARM, params, version),
};

const faqApi = {
    getCategories: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.FAQ.CATEGORIES, params, version),
    getCategoriesById: (categoryId: string, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.FAQ.CATEGORIES_BY_ID(categoryId), {}, version),
    getContent: (faqId: string, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.FAQ.CONTENT(faqId), {}, version),

    getInquiry: (params: ApiParams, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.INQUIRY.INDEX, params, version),
    postInquiryReg: (body: Record<string, any>, version: string): Promise<ApiResponse> => api.post(ApiEndpoints.INQUIRY.REG, body, version),
    getInquiryById: (inqId: string, version: string): Promise<ApiResponse> => api.get(ApiEndpoints.INQUIRY.BY_ID(inqId), {}, version),
};

export {notiApi, faqApi};
