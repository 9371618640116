import {Image, List, TextSet} from '@lguuxe/2024_designsystem_uni';
import React from 'react';
import {VipKokInfo} from "../../pages/Partner/types";

interface VipKokBenefitProps {
    vipKokInfo: VipKokInfo;
    onVipKokPopup: () => void;
}

const VipKokBenefit: React.FC<VipKokBenefitProps> = ({vipKokInfo, onVipKokPopup}) => {
    return (
        <div
            style={{
                display: 'flex',
                gap: '0px',
                // alignItems: 'center',
                flexDirection: 'column',
                padding: '0',
            }}
        >
            <div
                style={{
                    width: '360px',
                    backgroundColor: '#FFFFFF',
                }}
            >
                <TextSet
                    subComponent='Heading_4'
                    text={{
                        title: 'VIP콕',
                    }}
                    blockSpacing={{top: '24px', bottom: '12px'}}
                    isIndent={true}
                />
            </div>

            <List
                type='view'
                fill='none'
                items={[
                    {
                        onClick: onVipKokPopup,
                        leftSlot: [
                            <Image key='image' src={vipKokInfo.ptnLogoImgUrl} ratio='1:1' width='48px'
                                   rounded={'50%'}/>,
                            <div
                                key='text'
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '2px',
                                }}
                            >
                                <TextSet
                                    subComponent='Body_1'
                                    text={{
                                        eyebrow: (
                                            <>
                                                <span>{vipKokInfo.ptnName}</span>
                                            </>
                                        ),
                                        title: vipKokInfo.vipSummary,
                                    }}
                                />
                            </div>,
                        ],
                        linkIconOnlyId: vipKokInfo.ptnId
                    },
                ]}
            />

        </div>
    );
};

export default VipKokBenefit;
