// ****************************************************
// [배포] 4.마이 > 내 쿠폰 > VIP콕 상세 > 사용기간만료 (UMembershipMyCouponExpiration > SectionSortingListTag2)
// ****************************************************
// 서브탭 메뉴
import React from "react";
import {Box, Tab} from "@lguuxe/2024_designsystem_uni";

interface SubTabProps {
    dataList: { id: string; label: string }[];
    selectedItem?: string;
    onSelected: (id: string) => void;
}

const TagTab: React.FC<SubTabProps> = ({dataList, selectedItem, onSelected}) => {
    console.log(dataList);
    return (
        <Box
            type="4_tb"
            style={{
                paddingTop: "16px",
                paddingBottom: "0",
                display: "flex",
                gap: "6px"
            }}
        >
            <Tab
                onItemSelected={onSelected}
                fill="solid"
                items={dataList.map((item) => ({
                    id: item.id,
                    label: item.label
                }))}
                layout="fitContent"
                size="small"
                selectedItemId={selectedItem || dataList[0].id}
            />
            {/* Tab 컴포넌트: 서브탭 미선택시 컬러 디자인과 동일하게 화이트로 변경 필요 */}
        </Box>
    );
};

export default TagTab;
