import React, { useEffect, useState } from 'react';
import { Image } from "@lguuxe/2024_designsystem_uni";

import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import CommonDivider from "../../components/Common/CommonDivider";
import SectionBannerInfo from "../../components/UPTP/SectionBannerInfo";
import SectionHeadingTabList from "../../components/UPTP/SectionHeadingTabList";
import ExImg from "../../images/uptp/Upltwopl_default.svg";
import {
    BenefitPtn,
    initialMonthEvent,
    initialMonthEventByDate,
    LongTerm,
    MonthEvent,
    MonthEventByDate,
    VipPtn
} from "./types";
import membershipsServiceApi from "../../api/memberships-service";
import SectionCalendar from "../../components/UPTP/SectionCalendar";
import SectionHeadingListNoBtn3 from "../../components/UPTP/SectionHeadingListNoBtn3";
import CommonHeadingTab from "../../components/Common/CommonHeadingTab";
import { handlePromiseResult } from "../../api/api";
import { brazeEventApi } from "../../api/brazeBridge";
import { getItemByKey } from "../../utils/indexedDB";
import useLoading from "../../hooks/useLoading";
import {sendEvent} from "../../api/bridgeApi";
import {eventApi} from "../../api/attendanceAndEvent";

const UPTP: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [monthEvent, setMonthEvent] = useState<MonthEvent>(initialMonthEvent);
    const [monthEventByDate, setMonthEventByDate] = useState<MonthEventByDate>(initialMonthEventByDate);
    const [basicBenefit, setBasicBenefit] = useState<BenefitPtn[]>([]);
    const [vipBenefit, setVipBenefit] = useState<VipPtn[]>([]);
    const [loyalMemberBenefit, setLoyalMemberBenefit] = useState<LongTerm[]>();
    const [eventBanner, setEventBanner] = useState<[]>();
    const [selectedDay, setSelectedDay] = useState<string>(""); // 클릭된 아이템의 날짜를 추적
    const [currentDay, setCurrentDay] = useState<string>(""); // 초기값을 빈 문자열로 설정
    const [selectedTab, setSelectedTab] = useState<string>('1'); // 선택된 탭을 추적
    const [imgSrc, setImgSrc] = useState<string>(ExImg); // 이미지 소스 상태

    // 로딩 훅을 최상위에서 호출
    const loadingComponent = useLoading(isLoading);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const currentDay = await getItemByKey("currentDay");
                if (currentDay) {
                    setCurrentDay(currentDay);
                    setSelectedDay(currentDay);

                    const [
                        monthEventResult,
                        basicBenefitResult,
                        vipBenefitResult,
                        loyalMemberBenefitResult,
                        eventBanner,
                    ] = await Promise.allSettled([
                        membershipsServiceApi.getMonthlyEvent({ month: currentDay.slice(0, 6) }, 'v1'),
                        membershipsServiceApi.getBasicBenefit('v1'),
                        membershipsServiceApi.getVipBenefit('v1'),
                        membershipsServiceApi.getLoyalMemberBenefit('v1'),
                        eventApi.getEventBanner({dpYn:'Y', status:'Y'}, 'v1'),
                    ]);
                    setMonthEvent(handlePromiseResult(monthEventResult));
                    setBasicBenefit(handlePromiseResult(basicBenefitResult).benefitPtnList);
                    setVipBenefit(handlePromiseResult(vipBenefitResult).vipPtnList);
                    setLoyalMemberBenefit(handlePromiseResult(loyalMemberBenefitResult).longTermList);
                    setEventBanner(handlePromiseResult(eventBanner).bannerList);
                    if (handlePromiseResult(monthEventResult)?.thisMonthImgUrl) {
                        setImgSrc(handlePromiseResult(monthEventResult)?.thisMonthImgUrl);
                    }
                    sendEvent("KAKAO_PIXEL", {param: "ParticipationEvent",tag:"Uplusmo"})
                }
            } catch (error) {
                console.error('An unexpected error occurred:', error);
            } finally {
                setIsLoading(false); // 로딩 상태 해제
            }
        };
        fetchData();
        brazeEventApi.benefitplus_page_enter();
    }, []);

    const getCurrentTabItems = () => {
        switch (selectedTab) {
            case '1':
                return basicBenefit;
            case '2':
                return vipBenefit;
            case '3':
                return loyalMemberBenefit || [];
            default:
                return [];
        }
    };

    useEffect(() => {
        if (selectedDay) {
            const fetchEventByDate = async () => {
                try {
                    const monthEventByDateResult = await membershipsServiceApi.getMonthlyEventByDate(selectedDay, 'v1');
                    setMonthEventByDate(monthEventByDateResult.body);
                } catch (error) {
                    console.error('Failed to fetch event by date:', error);
                }
            };
            fetchEventByDate();
        }
    }, [selectedDay]);

    // 로딩 중일 때는 로딩 컴포넌트만 렌더링
    if (isLoading) {
        return loadingComponent;
    }

    return (
        <>
            <CommonLeftIconHeader title={"유플투플"} />

            {/* 이미지 예시 */}
            <div>
                <Image
                    customClass="customClass"
                    ratio="1:1"
                    src={imgSrc}
                    width="100%"
                    alt="This Month Event Image"
                    srcFallback={ExImg}
                />
            </div>

            <SectionCalendar monthEvent={monthEvent} currentDay={currentDay} selectedDay={selectedDay} setSelectedDay={setSelectedDay} />

            <SectionHeadingListNoBtn3 monthEventData={monthEventByDate} currentDay={currentDay} />

            <div style={{ paddingTop: "12px" }} />

            <div style={{ padding: "20px 20px 24px" }}>
                <SectionBannerInfo bannerList={eventBanner || []}/>
            </div>

            <div style={{ marginBottom: "8px" }}>
                <CommonDivider />
            </div>

            <CommonHeadingTab selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
            <SectionHeadingTabList items={getCurrentTabItems()} selectedTab={selectedTab}/>
        </>
    );
}

export default UPTP;
