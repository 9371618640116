export enum EVENT_ACTION {
    LINK_CLICK = '링크 클릭',
    BUTTON_CLICK = '버튼 클릭'
}

export enum CLICK_TEXT {
    BENEFIT_MORE = '혜택 더보기',
    MY_INFO_MORE = '내 정보 더보기',
    USE_HISTORY_MORE = '사용내역 더보기',
    MY_BADGES_MORE = '내 배지 더보기',
    FAVORITE_PARTNER_MORE = '즐겨찾기 제휴사 더보기',
}

export enum CLICK_LOCATION {
    UPTP_DATE = '날짜',
    UPTP_DAY_BENEFIT = '일별혜택',
    MIDDLE_BANNER = '중간배너',
    UPTP_BENEFIT_CATEGORY = '전체혜택|카테고리',
    UPTP_BENEFIT_ = '전체혜택|',
    MISSION_SPETIAL_MISSION = '특별미션',
    MISSION_REWORD = '리워드',
    MISSION_EARN = '적립',
    BENEFIT = '혜택',
    PROFILE = '프로필',
    USE_HISTORY = '사용내역',
    VIPKOK_BENEFIT = 'VIP콕 혜택',
    MY_BADGES = '내 배지',
    MY_MENU = '나의 메뉴',
    FAVORITE_PARTNER = '즐겨찾기 제휴사',
    BADGE_PROFILE = '배지 프로필',
    LNB = 'LNB',
    BADGE_LIST = '배지리스트',
    BADGE_DETAIL_POPUP = '배지 상세 팝업',
}
