import React from 'react';
import { Image, Button, TextSet } from "@lguuxe/2024_designsystem_uni";

interface CouponItemProps {
    icon?: string;
    eyebrow?: string;
    title?: string;
    buttonText?: string;
    buttonDisabled?: boolean;
    buttonOnClick?: () => void;
    buttonColor?: 'primary' | 'secondary' | 'neutral'; // 버튼 색상 선택
}

export const CouponItem: React.FC<CouponItemProps> = ({
                                                          icon = "",
                                                          eyebrow = "",
                                                          title = "",
                                                          buttonText = "",
                                                          buttonDisabled = false,
                                                          buttonOnClick = null,
                                                          buttonColor = 'primary',
                                                      }) => {
    return (
        <div className="coupon-item">
            <div className="left-slot">
                <Image alt="coupon icon" ratio="1:1" src={icon} width="48" />
                <TextSet
                    subComponent="Body_1"
                    text={{
                        eyebrow: eyebrow,
                        title: title,
                    }}
                />
            </div>
            <div className="right-slot">
                <Button
                    size="small"
                    onClick={buttonOnClick}
                    disabled={buttonDisabled}
                    color={buttonColor}
                >
                    {buttonText}
                </Button>
            </div>
        </div>
    );
};

export default CouponItem;
