export const joinRequestParam = (termList: Term[]) => {
    return {
        serviceId: '129001',
        termsList: termList.map((term) => ({
            termId: term.termId,
            termYn: term.isAgree ? 'Y' : 'N',
            termType: term.termType,
        })),
    }
}

export interface Term {
    termId: string;
    termName: string;
    termType: string;
    isAgree: boolean;
}