import React, {useEffect, useState} from 'react';
import {Button, Icon, IconButton, Tag, Text} from '@lguuxe/2024_designsystem_uni';
import Barcode from 'react-barcode';
import CommonDividerVertical from '../Common/CommonDividerVertical';
import {MembershipBarcode} from './types';
import BarcodeFailed from './BarcodeFailed';
import BarcodeTimeOut from './BarcodeTimeOut';
import {formatBarcode, formatTime, getBarcodeWidth} from './utils';
import BarcodeLoading from "./BarcodeLoading";
import {getItemByKey} from "../../utils/indexedDB";
import {useNavigate} from "react-router-dom";

const BARCODE_TIMER = 60; // 15분 = 900초

interface MembershipBarcodeSectionProps {
    membershipBarcode: MembershipBarcode;
    onFetchBarcode: () => void;
    barcodeFail: boolean;
    showSnackbar: (message: string) => void;
}

const MembershipBarcodeSection: React.FC<MembershipBarcodeSectionProps> = ({
                                                                               membershipBarcode,
                                                                               onFetchBarcode,
                                                                               barcodeFail,
                                                                               showSnackbar
                                                                           }) => {
    const [time, setTime] = useState(BARCODE_TIMER);
    const [barcodeState, setBarcodeState] = useState('init');
    const [loadingFailed, setLoadingFailed] = useState(false);
    const [memberStatus, setMemberStatus] = useState('0');
    const [barcodeWidth, setBarcodeWidth] = useState(2);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchIndexedDb = async () => {
            setMemberStatus(await getItemByKey('memberStatus'));
        }
        fetchIndexedDb();

        const updateBarcodeWidth = () => {
            setBarcodeWidth(getBarcodeWidth());
        };
        // 처음 로드시 크기를 설정
        updateBarcodeWidth();
        // 창 크기가 변경될 때마다 실행
        window.addEventListener('resize', updateBarcodeWidth);
        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener('resize', updateBarcodeWidth);
        };
    }, []);

    useEffect(() => {
        if (barcodeFail) {
            setBarcodeState('failed');
            return;
        }

        if (membershipBarcode.barcode !== '' && barcodeState === 'ready') {
            setBarcodeState('running');
            const timer = setInterval(() => {
                setTime((prevTime) => {
                    if (prevTime > 1) {
                        return prevTime - 1;
                    } else {
                        clearInterval(timer);
                        setBarcodeState('timeout');
                        return 0;
                    }
                });
            }, 1000);
            return () => clearInterval(timer);
        }
    }, [barcodeState, membershipBarcode]);

    const handleCopy = () => {
        navigator.clipboard
            .writeText(membershipBarcode.barcode)
            .then(() => {
                // 1.3v 이후 삭제
                showSnackbar('바코드 번호가 복사되었습니다.');
            })
            .catch((err) => {
                console.error('복사 실패', err);
            });
    };

    const handleGenerateBarcode = () => {
        setBarcodeState('ready');
        setTime(BARCODE_TIMER);
        onFetchBarcode();
    };
    // TODO: api 만료 시간에 맞춰 타이머 세팅

    if (barcodeState === 'failed') {
        return <BarcodeFailed onGenerateBarcode={handleGenerateBarcode}/>;
    }

    if (barcodeState === 'timeout') {
        return <BarcodeTimeOut onGenerateBarcode={handleGenerateBarcode} tabType='membership'/>;
    }

    if (memberStatus === '5') {
        return <>
            <div
                style={{
                    padding: "20px 0",
                    backgroundColor: "#F3F5F6",
                    borderRadius: "12px",
                    width: "100%"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "12px",
                        padding: "4.5px 22px",
                    }}
                >
                    <Text typo="font_body_1M" color="color_text_neutral_3" textAlign="center">
                        U+모바일 가입 고객만<br/>
                        U+멤버십 카드를 발급받을 수 있어요.
                    </Text>
                    <Button
                        as="button"
                        color="primary"
                        fill="solid"
                        size="medium"
                        state="default"
                        onClick={() => {
                            navigate('/partners');
                        }}
                    >
                        제휴사 혜택 보기
                    </Button>
                </div>
            </div>
        </>
    }

    return (
        <>
            <div
                style={{
                    border: '2px solid transparent',
                    borderRadius: '12px',
                    backgroundImage: 'linear-gradient(#fff, #fff), linear-gradient(90deg, #F6257F 0%, #AE0ABC 100%)',
                    borderImageSlice: '1',
                    backgroundOrigin: 'border-box',
                    backgroundClip: 'content-box, border-box',
                    width: '100%',
                    height: '155px',
                    boxSizing: 'border-box',
                }}
            >
                <div
                    style={{
                        padding: '16px 20px',
                    }}
                >
                    <Tag
                        item={[
                            {
                                colorToken: 'gradient_primary',
                                label: '할인 바코드',
                                size: 'small',
                                style: 'solid',
                            },
                        ]}
                    />
                    <div
                        style={{
                            display: "flex",
                            justifyContent: 'center',
                            width: 'auto',
                            height: '56px',
                            margin: '12px auto 0',
                            boxSizing: "border-box",
                        }}
                        data-gtm-event-name={'all_click'}
                        data-gtm-event-category={'클릭'}
                        data-gtm-event-action={'바코드|멤버십 - 링크 클릭'}
                        data-gtm-event-label={'컨텐츠 : 멤버십 바코드'}
                        data-gtm-click-url={''}
                        data-gtm-click-location={'바코드|멤버십'}
                        data-gtm-click-direction={'내부'}
                        data-gtm-click-text={'멤버십 바코드'}
                    >
                        {membershipBarcode.barcode === '' ? <BarcodeLoading/> :
                            <Barcode value={membershipBarcode.barcode} displayValue={false} height={56}
                                     width={barcodeWidth}
                                     margin={0}/>}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            marginTop: '12px',
                        }}
                    >
                        <div style={{display: 'flex', gap: '4px', alignItems: 'center'}}>
                            {formatBarcode(membershipBarcode.barcode).map((chunk, index) => (
                                <Text key={index} typo='font_body_2M' color='color_text_neutral_4'>
                                    {chunk}
                                </Text>
                            ))}
                            <IconButton icon='copy_line' size='small' fill='icon' onClick={handleCopy}/>
                        </div>

                        <CommonDividerVertical/>
                        <Button color='primary' fill='text' onClick={handleGenerateBarcode}>
                            {formatTime(time)}
                            <Icon
                                name='refresh_line'
                                size='16px'
                                color='#E6007E'
                                style={{
                                    marginLeft: '2px',
                                }}
                            />
                        </Button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MembershipBarcodeSection;
