import React, {FC} from 'react';
import {Accordion, Text, TextSet} from '@lguuxe/2024_designsystem_uni';

interface MustCheckAccordionProps {
    items: string[];
}

const MustCheckAccordion: FC<MustCheckAccordionProps> = ({items}) => {
    return (
        <div>
            <Accordion
                fill="underline"
                size="large"
                weight="bolder"
                divider={false}
                title={
                    <TextSet
                        subComponent="Body_1"
                        text={{
                            title: '꼭 확인하세요',
                        }}
                        weight="bolder"
                    />
                }
            >
                <Accordion.Body>
                    <div>
                        <ul style={{paddingLeft: "26px", margin: "0"}}>
                            {items.map((text, index) => (
                                <li key={index}>
                                    <Text typo="font_body_2M" color="color_text_neutral_3">
                                        {text.split('\n').map((line, lineIndex) => (
                                            <React.Fragment key={lineIndex}>
                                                {line}
                                                {lineIndex !== text.split('\n').length - 1 && <br/>}
                                            </React.Fragment>
                                        ))}
                                    </Text>
                                </li>
                            ))}
                        </ul>
                    </div>
                </Accordion.Body>
            </Accordion>
        </div>
    );
};

export default MustCheckAccordion;
