import {api, ApiEndpoints} from './api';
import {ApiParams, ApiResponse} from './types';

const attendanceApi = {
    get: (version: string): Promise<ApiResponse> => api.get(ApiEndpoints.ATTENDANCE.INDEX, {}, version),
    postCheck: (body: Record<string, any>, version: string): Promise<ApiResponse> =>
        api.post(ApiEndpoints.ATTENDANCE.CHECK, body, version),
    getEventInfo: (params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.ATTENDANCE.EVENT_INFO, params, version),
    getLastMonthResult: (params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.ATTENDANCE.LAST_MONTH_RESULT, params, version),
};

const eventApi = {
    getEvent: (params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.EVENT.INDEX, params, version),
    getEventById: (evtId: string, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.EVENT.BY_ID(evtId), {}, version),
    getEventBanner: (params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.EVENT_BANNER, params, version),
    getWinnerInfo: (eventId: string, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.EVENT.WINNER_INFO(eventId), {}, version),
    
};

export {attendanceApi, eventApi};
