import React, {useEffect, useState} from "react";
import {Accordion, Box, Button, CTA, Image, List, Text} from "@lguuxe/2024_designsystem_uni";

import CommonRightIconHeader from "../../components/Common/CommonRightIconHeader";
import CommonDivider from "../../components/Common/CommonDivider";
import CommonDividerThin from "../../components/Common/CommonDividerThin";
import defaultThumbnail from '../../images/default/default thumbnail.svg';

import {useNavigate, useParams} from "react-router-dom";
import BarcodeSection from "../../components/My/Coupon/BarcodeSection";
import DateSection from "../../components/My/Coupon/DateSection";
import {couponApi} from "../../api/other";
import {CouponInfo, initCouponInfo} from "./types";
import ButtonSection from "../../components/My/Coupon/ButtonSection";
import NumberSection from "../../components/My/Coupon/NumberSection";
import CouponMessage from "../../components/My/Coupon/CouponMessage";
import CommonPopup from "../../components/Common/CommonPopup";
import Snackbar from "../../components/Common/Snackbar";
import BS_CouponEmployee from "../../components/My/Coupon/BS_CouponEmployee";
import {sendEvent} from "../../api/bridgeApi";
import BS_CouponEmployeeCheck from "../../components/My/Coupon/BS_CouponEmployeeCheck";

const CouponDetails: React.FC = () => {
    const {cpnId} = useParams<{ cpnId: string }>() || {cpnId: ''};
    const [couponInfo, setCouponInfo] = useState<CouponInfo>(initCouponInfo);
    const [couponState, setCouponState] = useState<'ready' | 'used' | 'expired'>('ready');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('쿠폰을 삭제하시겠어요?');
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [showEmployeeBS, setShowEmployeeBS] = useState(false);
    const [showEmployeeCheckBS, setShowEmployeeCheckBS] = useState(false);

    useEffect(() => {
        if (!cpnId) {
            console.error("termId가 정의되지 않았습니다.");
            setIsLoading(false);
            return;
        }
        const fetchData = async () => {
            try {
                const response = await couponApi.getCouponById(cpnId, 'v1');
                if (response.header.resultCode !== '0000' || !response.body) {
                    console.log('error fetchData:', response);
                    return;
                }
                const couponInfo = response.body;
                setCouponInfo(couponInfo);

                if (couponInfo.useYn === 'Y') {
                    setCouponState('used');
                    return;
                }
                const endDate = new Date(couponInfo.expEndDate.replace(' ', 'T'));
                const now = new Date(response.header.timeStamp.replace(' ', 'T'));
                if (now.getTime() > endDate.getTime()) {
                    setCouponState('expired');
                    return;
                }
            } catch (e) {
                console.error('error fetchData:', e);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleRemoveClick = () => {
        setPopupMessage(couponState === 'ready' ? '삭제한 쿠폰은 다시 받을 수 없어요.\n쿠폰을 삭제하시겠어요?' : '쿠폰을 삭제하시겠어요?');
        setShowPopup(true);
    }

    const handleRemove = () => {
        const fetchCouponRemove = async () => {
            try {
                const response = await couponApi.deleteCouponById(couponInfo.cpnId, 'v1');
                if (response.header.resultCode === '0000') {
                    setSnackbarMessage('쿠폰을 삭제했어요.');
                    setShowSnackbar(true);
                    setTimeout(() => {
                        setShowSnackbar(false);
                    }, 1000);
                    setShowPopup(false);
                    return;
                }
            } catch (e) {
                console.error('error fetchCouponRemove:', e);
            }
        }
    }

    const handleEmployeeClick = () => {
        setShowEmployeeBS(true);
        setShowEmployeeCheckBS(false);
    }

    const handleEmployeeAgree = () => {
        setShowEmployeeBS(false);
        setShowEmployeeCheckBS(true);
    }

    const handleEmployeeCheckSuccess = () => {
        setSnackbarMessage('쿠폰을 사용했어요.')
        setShowSnackbar(true);
        setTimeout(() => {
            setShowSnackbar(false);
        }, 1000);
    }
    return (
        <>
            {/*{useLoading(isLoading)}*/}
            <Box style={{padding: "0 0 100px 0"}}>
                {/* header */}
                <CommonRightIconHeader title={couponInfo.ptnName} icon={'close_line'} onClick={() => navigate(-1)}/>

                {/* 내쿠폰 혜택 요약 */}
                <div>
                    <Box
                        style={{
                            display: "flex",
                            gap: "8px",
                            flexDirection: "column",
                            width: "100%"
                        }}
                        type="2_tb"
                    >
                        <List
                            type="view"
                            fill="none"
                            items={[
                                {
                                    leftSlot: (
                                        <>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    gap: "4px"
                                                }}
                                            >
                                                <Text typo="font_label_2Sb" color="color_text_neutral_3">
                                                    {couponInfo.ptnName}
                                                </Text>
                                                <Text typo="font_heading_3B" color="color_text_neutral_6">
                                                    {couponInfo.summary}
                                                </Text>
                                            </div>
                                        </>
                                    ),
                                    rightSlot: [
                                        <Image
                                            src={couponInfo.ptnLogoImgUrl}
                                            srcFallback={defaultThumbnail}
                                            ratio="1:1"
                                            width="48px"
                                            rounded={"50%"}
                                        />
                                    ]
                                }
                            ]}
                        />
                        {/*<SectionHeadingText2row1/>*/}

                        {/* -------- 쿠폰 종류에 따라 달라지는 영역 ---------*/}
                        {couponState === 'ready' ? (
                            <>
                                {/* 현장형 */}
                                {couponInfo.cpnType === '111006' && <ButtonSection onClick={handleEmployeeClick}/>}
                                {/* 비바코드형 */}
                                {couponInfo.cpnType === '111007' && <NumberSection couponNumber={couponInfo.cpnNo}/>}

                                {/* 바코드형 */}
                                {(couponInfo.cpnType !== '111006' && couponInfo.cpnType !== '111007') &&
                                    <BarcodeSection barcodeNumber={couponInfo.cpnNo}/>}
                            </>
                        ) : <CouponMessage couponState={couponState}/>}
                        
                        {/* test 용 */}
                        {/*<ButtonSection onClick={handleEmployeeClick}/>*/}
                        {/*<NumberSection couponNumber={couponInfo.cpnNo}/>*/}
                        {/*<BarcodeSection barcodeNumber={couponInfo.cpnNo}/>*/}

                    </Box>
                </div>

                <Box
                    style={{
                        display: "flex",
                        gap: "8px",
                        flexDirection: "column",
                        width: "100%"
                    }}
                    type="2_tb"
                >
                    {/* 쿠폰 기간 정보 */}
                    <div style={{padding: "0 20px 8px"}}>
                        <CommonDividerThin/>
                    </div>
                    <DateSection couponInfo={couponInfo}/>
                    <CommonDivider/>

                    {/* 이용방법 및 유의사항 */}
                    <div style={{paddingBottom: "40px"}}>
                        <Accordion
                            fill="underline"
                            size="large"
                            weight="bolder"
                            divider={false}
                            title={
                                <Text typo="font_body_1B" color="color_text_neutral_5">유의사항</Text> // typo="font_body_1SB" 변경
                            }
                        >
                            <Accordion.Body>
                                <Text typo="font_body_2M" color="color_text_neutral_3">
                                    {couponInfo.useGuide.split('\n').map((line, index) => (
                                        <React.Fragment key={index}>
                                            {line}
                                            <br/>
                                        </React.Fragment>
                                    ))}
                                </Text>
                            </Accordion.Body>
                        </Accordion>
                    </div>
                    {/*<MustCheckAccordion items={[couponInfo.useGuide]}/>*/}
                </Box>

                {/* 하단 버튼 */}
                <div style={{position: "fixed", bottom: "0", left: "0", right: "0"}}>
                    <CTA layout="row">
                        <Button color="neutral" size="large" onClick={handleRemoveClick}>
                            쿠폰 삭제
                        </Button>
                        {couponState === 'ready' && couponInfo.homepageUrl && (
                            <Button color="primary" size="large" onClick={() => sendEvent('CALL_WEBVIEW', {
                                url: couponInfo.homepageUrl,
                                title: couponInfo.ptnName,
                                showTitlebar: true
                            })}>
                                사용하러가기
                            </Button>
                        )}
                    </CTA>
                </div>
            </Box>
            <CommonPopup submitText={'삭제'} cancelText={'취소'} isOpen={showPopup} onSubmit={handleRemove}
                         onClose={() => setShowPopup(false)} description={popupMessage}/>
            {showSnackbar && <Snackbar text={snackbarMessage}/>}
            <BS_CouponEmployee isOpen={showEmployeeBS} onClose={() => setShowEmployeeBS(false)}
                               onAgree={handleEmployeeAgree}/>
            <BS_CouponEmployeeCheck isOpen={showEmployeeCheckBS} onClose={() => setShowEmployeeCheckBS(false)}
                                    onSuccess={handleEmployeeCheckSuccess} cpnKey={couponInfo.cpnKey}/>
        </>
    );
};

export default CouponDetails;
