import React from "react";
import MoneyMeGuideSection from "../MoneyGuideSection";
import CommonDivider from "../../Common/CommonDivider";
import {Box, Image, Text} from "@lguuxe/2024_designsystem_uni";
import m1 from "../../../images/guide/usage/moneyme/1.webp";
import m2 from "../../../images/guide/usage/moneyme/2.webp";
import m3 from "../../../images/guide/usage/moneyme/3.webp";
import m4 from "../../../images/guide/usage/moneyme/4.webp";
import m5 from "../../../images/guide/usage/moneyme/5.webp";
import m6 from "../../../images/guide/usage/moneyme/6.webp";
import m7 from "../../../images/guide/usage/moneyme/7.webp";
import m8 from "../../../images/guide/usage/moneyme/8.webp";

const MoneyMeTab: React.FC = () => {
    return (
        <>
            <MoneyMeGuideSection
                title="서비스 가입 & 가입하고 기관 연결하기"
                steps={[
                    { stepNumber: 1, description: "홈에서 머니미 배너를 선택하세요.", imageSrc: m1 },
                    { stepNumber: 2, description: "머니Me 가입 버튼을 클릭하고,\n안내에 따라 쉽게 가입해보세요.", imageSrc: m2 },
                    {
                        stepNumber: 3,
                        description: "사용중인 금융기관을 연결하세요.\n어떤 금융기관을 선택해야 하나 고민된다면 “알아서 찾아줘” 기능을 이용해보세요.",
                        imageSrc: m3,
                    },
                ]}
            />
            <CommonDivider />
            <MoneyMeGuideSection
                title="놓친 U+ 멤버십 할인 찾기"
                steps={[
                    { stepNumber: 1, description: "놓친 U+멤버십을 찾은 경우 홈에서 확인할 수 있어요. 또 다시 놓치지 않도록 푸시 알림 설정도 가능해요.\n놓친 할인을 찾기 위해서는 카드 연결이 꼭 필요해요.", imageSrc: m4 },
                    { stepNumber: 2, description: "받은 머니는 원하는 쿠폰으로 바꿔 이용할 수 있어요. 머니는 매 달 5일 사라지니 잊지 말고 사용하세요.", imageSrc: m5 },
                ]}
            />
            <CommonDivider />
            <Box style={{ display: "flex", flexDirection: "column", gap: "12px", padding: "24px 0 12px" }}>
                <div style={{ display: "flex", flexDirection: "column", gap: "12px", padding: "0" }}>
                    <Text typo="font_heading_4B" color="color_text_neutral_6" style={{ fontSize: "18px", fontWeight: "800", padding: "0 20px" }}>
                        내 자산 · 소비 · 구독 관리하기
                    </Text>
                </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "4px", padding: "24px 20px 0" }}>
                        <Text typo="font_body_1B" color="color_text_neutral_3" style={{ fontSize: "16px", fontWeight: "600" }}>
                            자산 탭
                        </Text>
                        <Text typo="font_body_2M" color="color_text_neutral_3">
                            내 입출금, 예적금, 주식, 펀드 현황을 한 번에 확인할 수 있어요.
                        </Text>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: "16px", padding: "4px 20px" }}>
                        <Image
                            src={m6}
                            alt={"placeholder"}
                            width="100%"
                            ratio="4:3"
                        />
                    </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "4px", padding: "24px 20px 0" }}>
                    <Text typo="font_body_1B" color="color_text_neutral_3" style={{ fontSize: "16px", fontWeight: "600" }}>
                        소비 탭 & 구독 탭
                    </Text>
                    <Text typo="font_body_2M" color="color_text_neutral_3">
                        이번 달 내 쓴 돈을 기반으로 나의 스타일을 알려줘요. 주기적인 구독, 정기결제 내역도 한 눈에 볼 수 있어요.
                    </Text>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "16px", padding: "4px 20px" }}>
                    <Image
                        src={m7}
                        alt={"placeholder"}
                        width="100%"
                        ratio="4:3"
                    />
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "4px", padding: "24px 20px 0" }}>
                    <Text typo="font_body_1B" color="color_text_neutral_3" style={{ fontSize: "16px", fontWeight: "600" }}>
                        머니Me 실험실
                    </Text>
                    <Text typo="font_body_2M" color="color_text_neutral_3">
                        나와 관련된 재미있는 머니 콘텐츠에 참여하고 추가 머니도 받을 수 있어요.
                    </Text>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "16px", padding: "4px 20px" }}>
                    <Image
                        src={m8}
                        alt={"placeholder"}
                        width="100%"
                        ratio="4:3"
                    />
                </div>
            </Box>
        </>
    );
};

export default MoneyMeTab;
