import {sendEvent, sendEventReturn} from "./bridgeApi";
import {getItemByKey} from "../utils/indexedDB";

const CHATBOT_DEV_URL = 'https://chatbot-dev.lguplus.com/?fromChan=MEM';
const CHATBOT_STG_URL = 'https://chatbot-stg.lguplus.com/?fromChan=MEM';
const CHATBOT_PROD_URL = 'https://chatbot.lguplus.com/?fromChan=MEM';
export const chatbot = async () => {
    const memberId = await getItemByKey('memberId');
    const os = await getItemByKey('platform')
    const param = {
        url: os === 'IOS' ? CHATBOT_STG_URL + 'IOS' : CHATBOT_STG_URL + 'APP',
        interfaceId: '202',
        custNo: memberId,
        entrNo: await getItemByKey(''),  //가입번호
        loginYn: 'Y',
        deviceType: 'MOBILE',
        openChan: 'MEMAPP',
        serviceChan: 'APP',
        openMenu: '',
        openMenuId: '',
        openUrl: '',
        userId: await getItemByKey('ctn'),
        loginTypeCode: 'H',
        loginTypeName: 'ID로그인',
    }
    sendEvent('CALL_CHATBOT', param)
}

const APPTECH_STG_URL = 'https://webapp-front.stg.dhadbiz.co.kr'
const APPTECH_DEV_URL = 'https://daehong-offerwall-webapp-front-test.mock.pe.kr'
const COUPON_MALL = '/?target=COUPON_MALL'
export const appTech = async (couponMall?: boolean) => {
    const carrier = await getItemByKey('telcoCode');
    const url = couponMall ? APPTECH_DEV_URL + COUPON_MALL : APPTECH_DEV_URL;
    const param = {
        url: url,
        carrier: carrier,
    }
    sendEvent('OPEN_APPTECH', param);
}

const MONEYME_DEV_URL = 'https://dev-mydata.lguplus.com:8081';
const MONEYME_STG_URL = 'https://stg-mydata.lguplus.com:8081'
const MONEYME_PROD_URL = 'https://mydata.lguplus.com:8081'
export const moneymeEnter = async (type?: string) => {
    // type 정의 : 빈값 - home, 1 - 1:1문의
    const extraLinkUrl = type === '1' ? 'menuCode=H1&scrCode=E2' : '';
    const param = {
        url: MONEYME_DEV_URL,
        extraLinkUrl: extraLinkUrl,
    };
    sendEventReturn('OPEN_MONEYME', param);
}
