import React, {useEffect, useState} from 'react';
import {FAB, List, TextSet} from '@lguuxe/2024_designsystem_uni';
import CommonLeftIconHeader from "../../../components/Common/CommonLeftIconHeader";
import ChipButtonGroup from "../../../components/Entire/Help/ChipButtonGroup";
import {notiApi} from "../../../api/notiAndQna";
import {useNavigate} from "react-router-dom";
import {formatDate} from "../../../utils/dateUtiles";

interface Notice {
    noticeId: string;
    title: string;
    date: string;
}


const Announcement: React.FC = () => {
    const navigate = useNavigate();
    const [activeChipId, setActiveChipId] = useState<number>(1);
    const [noticeList, setNoticeList] = useState<Notice[]>([]);
    const [page, setPage] = useState<number>(1);

    const chipButtons = [
        {id: 1, label: '전체'},
        {id: 116001, label: '서비스 안내'},
        {id: 116002, label: 'U+멤버십'},
        {id: 116005, label: '휴대폰결제'},
        {id: 116003, label: '당첨자 발표'},
    ];

    useEffect(() => {
        const handleScroll = () => {
            // 현재 스크롤 위치
            const scrollPosition = window.scrollY + window.innerHeight;
            // 문서의 전체 높이
            const documentHeight = document.documentElement.scrollHeight;

            // 스크롤이 끝에 도달했는지 확인
            if (scrollPosition >= documentHeight) {
                console.log("스크롤 끝에 도달!");
                // 원하는 이벤트 실행
                fetchData(activeChipId.toString(), (page + 1).toString());
                setPage(page + 1);
            }
        };

        // 스크롤 이벤트 리스너 추가
        window.addEventListener("scroll", handleScroll);

        // 컴포넌트 언마운트 시 이벤트 리스너 제거
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);


    useEffect(() => {
        setNoticeList([]);
        setPage(1);
        if (activeChipId === 1) {
            fetchData('', '1');
            return;
        }
        fetchData(activeChipId.toString(), '1');

    }, [activeChipId]);

    const fetchData = async (sortType: string, page: string) => {
        try {
            const request = {
                svcType: sortType,  // - 116001: 서비스 안내, 116002:U+멤버십, 116004 : 공통, 116005 : 휴대폰 결제, 빈값 전달시 전체
                pageNo: page, // 빈값시 전체
            }
            notiApi.getCategoriesList(request, 'v1').then((res) => {
                if (res.header.resultCode === '0000') {
                    setNoticeList((prevList) => prevList.concat(res.body.noticeList));
                }
            });
        } catch (error) {
            console.log('Failed to fetch :', error);
        }
    }

    const handleChipClick = (id: number) => {
        setActiveChipId(id);
        console.log(`Chip with id ${id} clicked`);
    };

    const handleClick = (event: any) => {
        console.log(event.target.id);
        navigate(`/help/announcement/${event.target.id}`);
    }

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    return (
        <>
            <CommonLeftIconHeader title="공지사항"/>

            <ChipButtonGroup
                chipButtons={chipButtons}
                activeChipId={activeChipId}
                onChipClick={handleChipClick}
            />

            <List
                fill="none"
                type="view"
                size="large"
                items={noticeList.map((item, index) => ({
                    id: item.noticeId,
                    leftSlot: (
                        <TextSet
                            key={index}
                            subComponent="Body_1"
                            text={{
                                title: item.title,
                                description: formatDate(item.date),
                            }}
                            weight="bolder"
                        />
                    ),
                    linkIconOnlyId: item.noticeId,
                    onClick: handleClick,
                }))}
            />

            <div
                style={{
                    position: 'fixed',
                    right: '24px',
                    bottom: '40px',
                }}
            >
                <FAB icon="chevron_up_small_line" onClick={scrollToTop} size="small"/>
            </div>
        </>
    );
};

export default Announcement;