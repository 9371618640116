import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {Button} from "@lguuxe/2024_designsystem_uni";

const Chatbot: React.FC = () => {
    const navigate = useNavigate();
    const [popup, setPopup] = useState<Window | null>(null);
    useEffect(() => {
        // const popup = window.open('https://chatbot-stg.lguplus.com/?fromChan=MEMAPP');
        // setPopup();
        if (popup) {
            const data = {
                interfaceId: '202',
                custNo: '111',
                openChan: 'MEMAPP',
            }
            popup.postMessage(data, 'https://chatbot.lguplus.com');
        }

        if (popup) {
            console.log('popup 창 종료');
            popup.postMessage({interfaceId: '209'}, 'https://chatbot.lguplus.com/?openChan=MEMAPP');
        }

    }, []);

    const handleClick = () => {
        if (popup) {
            console.log('popup 창 종료');
            popup.postMessage({interfaceId: '209'}, 'https://chatbot.lguplus.com/?openChan=MEMAPP');
        }
    }

    return (
        <div>
            <Button onClick={handleClick}>챗봇 종료</Button>
        </div>
    )
}

export default Chatbot;