import React, { useEffect, useState } from "react";
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import CommonDivider from "../../components/Common/CommonDivider";
import SectionContentHeaderBenefit from "../../components/Vipkok/SectionContentHeaderBenefit";
import BoxHeaderInfo from "../../components/Vipkok/BoxHeaderInfo";
import CallCenter from "../../components/Vipkok/CallCenter";
import { useParams } from "react-router-dom";
import { PartnerDetail } from "./types";
import useLoading from "../../hooks/useLoading";
import { partnerApi } from "../../api/partnerAndAffiliate-service";
import {PartnerDetailData, PartnerType, UserGrade} from "../Partner/types";
import {getItemByKey} from "../../utils/indexedDB";

const VipkokDetail: React.FC = () => {
    const [partnerDetail, setPartnerDetail] = useState<PartnerDetailData | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [useVipkokYn, setUseVipkokYn] = useState<string>("N");
    const [partnerType, setPartnerType] = useState<PartnerType>("normal");
    const [userGrade, setUserGrade] = useState<UserGrade>("미로그인");
    const { ptnId } = useParams<{ ptnId: string }>();

    useEffect(() => {
        const fetchPartnerDetail = async () => {
            const memberStatus = await getItemByKey("memberStatus")
            console.log(memberStatus)
            setUserGrade(
                memberStatus === "0" ? "미로그인" :
                memberStatus === "1" || memberStatus === "3" ? "VIP/VVIP" :
                memberStatus === "2" || memberStatus === "4" ? "우수" :
                memberStatus === "5" ? "일반" : "미로그인"
            );
            setUseVipkokYn(await getItemByKey("useVipkokYn"))
            if (!ptnId) {
                console.error("ptnId is undefined");
                setIsLoading(false);
                return;
            }
            try {
                const response = await partnerApi.getPartnerById(ptnId, 'v1');
                const data = response.body
                if(data.couponAvailable === 'Y') {
                    setPartnerType('coupon')
                }
                setPartnerDetail(response.body);
            } catch (error) {
                console.error("Error fetching partner detail:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchPartnerDetail();
    }, []);

    return (
        <>
            {useLoading(isLoading)}
            <CommonLeftIconHeader title={'VIP콕'} />

            {partnerDetail && (
                <>

                    <SectionContentHeaderBenefit partnerDetail={partnerDetail} partnerType={partnerType} initialState={ useVipkokYn === "Y" ? '사용완료': '미사용'} userGrade={userGrade}/>
                    <CommonDivider />

                    {/* VIP콕 혜택 정보 */}
                    <BoxHeaderInfo title="혜택" description={partnerDetail.vipBnf} />
                    <BoxHeaderInfo title="혜택한도" description={partnerDetail.vipBnfLimit} />
                    <BoxHeaderInfo title="꼭 확인하세요*" description={partnerDetail.vipNotice} />
                    <BoxHeaderInfo title="이용방법" description={partnerDetail.vipGuide} />

                    <CommonDivider />

                    {/* 대표문의 */}
                    <CallCenter phone={partnerDetail.phone || ""} homepage={partnerDetail.homepage || ""} name={partnerDetail.ptnName} />
                </>
            )}
        </>
    );
};

export default VipkokDetail;
