// ****************************************************
// [배포] 1.혜택 > 전체 제휴처 > 혜택브랜드 (지역 선택 바텀시트)
// ****************************************************
import {BottomSheet, Header, IconButton, List, TextSet} from '@lguuxe/2024_designsystem_uni';
import React from 'react';
import {MainRegion} from '../../pages/Partner/types';

interface BS_MainRegionBSProps {
    regionList: MainRegion[];
    selectedRegionId?: string;
    isOpen: boolean;
    onClose: () => void;
    onSelect: (region: MainRegion) => void;
}

const LIST_NAME = 'region';
const BS_MainRegionBS: React.FC<BS_MainRegionBSProps> = ({
                                                             regionList,
                                                             selectedRegionId,
                                                             isOpen,
                                                             onClose,
                                                             onSelect,
                                                         }) => {
    const handleSelect = (region: MainRegion) => {
        onSelect(region);
        onClose();
    };
    return (
        <>
            <BottomSheet isOpen={isOpen} onClose={onClose}>
                <BottomSheet.TopSlot>
                    <Header align='left' variant='heading'>
                        지역 선택
                        <Header.RightSlot>
                            <IconButton color='neutral' fill='icon' icon='close_line' noStates onClick={onClose}/>
                        </Header.RightSlot>
                    </Header>
                </BottomSheet.TopSlot>

                <BottomSheet.CenterSlot>
                    <div style={{paddingTop: '0px', height: 'auto'}}>
                        <List
                            name={LIST_NAME}
                            items={regionList.map((region) => ({
                                id: region.categoryId,
                                leftSlot: [<TextSet subComponent='Body_1' text={region.categoryName}/>],
                                checked: selectedRegionId === region.categoryId,
                                linkIconOnlyId: region.subRegionList.length > 1 ? 'placeholder' : undefined,
                                onClick: () => handleSelect(region),
                            }))}
                            type='select'
                        />
                    </div>
                </BottomSheet.CenterSlot>
            </BottomSheet>
        </>
    );
};

export default BS_MainRegionBS;
