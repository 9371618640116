import React from "react";
import {Box, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import BoxTableBenefit from "../BoxTableBenefit";
import BoxTableBenefit2 from "../BoxTableBenefit2";
import BoxTableBenefit3 from "../BoxTableBenefit3";
import CommonDivider from "../../Common/CommonDivider";
import TextBlock from "../TextBlock";

const MembershipInfoTab: React.FC = () => {
    return (
        <>
            {/* 멤버십 */}
            <TextSet
                subComponent="Heading_4"
                text={{ title: <span>멤버십</span> }}
                blockSpacing={{ top: "24px", bottom: "12px" }}
                isIndent={true}
            />
            <BoxTableBenefit />
                <TextBlock
                    textList ={["U+모바일 요금제를 바꾸면 다음 달 1일에 U+멤버십 등급도 변경돼요.",
                                "전년도’는 재작년 11월 1일부터 작년 10월 31일까지를 말해요. U+모바일 이용요금과 U+인터넷, U+tv, U+스마트홈, U+인터넷전화 요금을 합한 금액이에요."
                    ]}
                />
            <CommonDivider />

            {/* 장기고객 */}
            <TextSet
                subComponent="Heading_4"
                text={{ title: <span>장기고객</span> }}
                blockSpacing={{ top: "24px", bottom: "12px" }}
                isIndent={true}
            />
            <BoxTableBenefit2 />
                <TextBlock
                    textList ={["작년 11월 30일 기준으로 올해 혜택이 정해져요."
                    ]}
                />
            <CommonDivider />

            {/* 유쓰 등급 안내 */}
            <TextSet
                subComponent="Heading_4"
                text={{ title: <span>유쓰</span> }}
                blockSpacing={{ top: "24px", bottom: "12px" }}
                isIndent={true}
            />
            <BoxTableBenefit3 />
                <TextBlock
                    textList ={["2024년 기준, 1995년~2005년에 태어난 U+모바일 20대 고객 누구나"]}
                />
        </>
    );
};

export default MembershipInfoTab;
