// ****************************************************
// [배포] 1.혜택 > 전체제휴처 > 헤택브랜드 > 상세
// ****************************************************
import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import CommonDivider from '../../components/Common/CommonDivider';
import CommonLeftIconHeader from '../../components/Common/CommonLeftIconHeader';

import PartnerProfile from '../../components/Partner/PartnerProfile';
import BenefitInfoSection from '../../components/Partner/BenefitInfoSection';
import SectionCouponGroup from '../../components/Partner/CouponGroup';
import RecommendPartners from '../../components/Partner/RecommendPartners';
import UsageAccordion from '../../components/Partner/UsageAccordion';
import QuickLink from '../../components/Partner/QuickLink';
import {partnerApi} from '../../api/partnerAndAffiliate-service';
import '../css/style.css';
import {initialPartnerDetailData, PartnerDetailData, ProfileInfo, VipKokInfo} from './types';
import {brazeEventApi} from "../../api/brazeBridge";
import VipKokBenefit from "../../components/Partner/VipKokBenefit";
import BS_VipKokDetail from "../../components/Partner/BS_VipKokDetail";
import {CouponItem, RecommendPartner} from "../My/types";
import {handlePromiseResult} from "../../api/api";
import CommonPopup from "../../components/Common/CommonPopup";

const initialProfileInfo: ProfileInfo = {
    ptnId: '',
    ptnName: '',
    ptnImgUrl: '',
    isLike: '',
    likeNum: '0',
    isMembershipAliance: '',
    metaTag: '',
};

const initialVipKokInfo: VipKokInfo = {
    ptnId: '',
    ptnName: '',
    vipSummary: '',
    ptnLogoImgUrl: '',
    vipKokUsed: '',
    vipBnf: '',
    vipBnfLimit: '',
    vipNotice: '',
    vipGuide: '',
};

const PartnersDetails: React.FC = () => {
    const {ptnId} = useParams<{ ptnId: string }>();
    const [partnerInfo, setPartnerInfo] = useState<PartnerDetailData>(initialPartnerDetailData);
    const [couponList, setCouponList] = useState<CouponItem[]>([]);
    const [profileInfo, setProfileInfo] = useState<ProfileInfo>(initialProfileInfo);
    const [vipKokInfo, setVipKokInfo] = useState(initialVipKokInfo);
    const [recommendList, setRecommendList] = useState<RecommendPartner[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showVipkokPopup, setShowVipkokPopup] = useState(false);
    const navigate = useNavigate();
    const [showErrorPopup, setShowErrorPopup] = useState(false);

    useEffect(() => {
        if (!ptnId) {
            console.error('ptnId is empty');
            return;
        }
        const fetchPartnerDetailData = async () => {
            try {
                await partnerApi.getPartnerById(ptnId, 'v1').then((response) => {
                    if (response.header.resultCode === '0000') {
                        if (response.body) {
                        }
                        setPartnerInfo(response.body);
                        setProfileInfo({
                            ptnId: response.body.ptnId,
                            ptnName: response.body.ptnName,
                            ptnImgUrl: response.body.ptnLogoImgUrl,
                            isLike: response.body.isLike,
                            likeNum: response.body.likeNum,
                            isMembershipAliance: response.body.isMembershipAliance,
                            metaTag: response.body.metaTag,
                        });
                        setVipKokInfo({
                            ptnId: response.body.ptnId,
                            ptnName: response.body.ptnName,
                            vipSummary: response.body.vipSummary,
                            ptnLogoImgUrl: response.body.ptnLogoImgUrl,
                            vipKokUsed: response.body.isVIPKok,
                            vipBnf: response.body.vipBnf,
                            vipBnfLimit: response.body.vipBnfLimit,
                            vipNotice: response.body.vipNotice,
                            vipGuide: response.body.vipGuide,
                        });
                    }
                });

                const [couponRes, recommendRes] = await Promise.allSettled([
                    partnerApi.getCoupon(ptnId, 'v1'),
                    partnerApi.getPartnerRecomm(ptnId, 'v1'),]);
                setCouponList(handlePromiseResult(couponRes, {couponList: []}).couponList);
                setRecommendList(handlePromiseResult(recommendRes, {recommPtnList: []}).recommPtnList);
            } catch (error) {
                console.log('Failed to fetch partner detail data:', error);
                setShowErrorPopup(true);
            } finally {
                brazeEventApi.affiliateInfo_detail_page_enter(partnerInfo.ptnName, partnerInfo.ptnId, partnerInfo.isLike);
                setIsLoading(false);
            }
        }
        fetchPartnerDetailData();

    }, []);

    const handleVipKokPopup = () => {
        setShowVipkokPopup(true);
    }

    const handleCouponIssue = (coupon: CouponItem) => {
        setCouponList(couponList.map((item) => {
            if (item.cpnId === coupon.cpnId) {
                return {...item, isIssuedCoupon: 'Y'};
            }
            return item;
        }));
    }

    return (
        <>
            {/*{useLoading(isLoading)}*/}
            <div
                style={{
                    width: '100%',
                    height: '100vh',
                }}
            >
                {/* header */}
                <CommonLeftIconHeader title={partnerInfo.ptnName}/>

                {/* 제휴사 소개 */}
                <PartnerProfile profileInfo={profileInfo}/>
                <CommonDivider/>

                {/* 혜택정보 */}
                <BenefitInfoSection benefitContent={partnerInfo.summary} benefitInfoList={partnerInfo.benefitInfoList}/>
                <CommonDivider/>

                {/*VIP콕 혜택*/}
                {partnerInfo.isVIPKok === 'Y' && (
                    <>
                        <VipKokBenefit vipKokInfo={vipKokInfo} onVipKokPopup={handleVipKokPopup}/>
                        <CommonDivider/>
                    </>
                )}

                {/* 쿠폰 */}
                {partnerInfo.couponAvailable === 'Y' && couponList.length !== 0 && <>
                    <SectionCouponGroup couponList={couponList} onCouponIssue={handleCouponIssue}/>
                    <CommonDivider/>
                </>}

                {/* [제휴사]에 관심있는 고객님을 위한 추천 */}
                {recommendList.length !== 0 && <>
                    <RecommendPartners recommendList={recommendList}/>
                    <CommonDivider/>
                </>}


                {/* 이용방법 및 유의사항 */}
                {partnerInfo.usageNoticeList && <UsageAccordion usageNoticeList={partnerInfo.usageNoticeList}/>}

                {/* 퀵링크 */}
                <QuickLink partnerInfo={partnerInfo}/>

                <BS_VipKokDetail isOpen={showVipkokPopup} onClose={() => setShowVipkokPopup(false)}
                                 profileInfo={profileInfo}/>

                <CommonPopup submitText={'데이터를 확인해주세요~'} onSubmit={() => navigate(-1)} isOpen={showVipkokPopup}/>
            </div>
        </>
    );
};

export default PartnersDetails;
