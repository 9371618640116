// src/api/partner.js
import {api, ApiEndpoints} from './api';
import {ApiParams, ApiResponse} from './types';

const partnerApi = {
    // 좋아요 등록한 제휴처 목록 조회
    getPartnersLike: (params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.PARTNER.PARTNERS.LIKE, params, version),
    // 좋아요 ON/OFF
    putPartnersLikeById: (ptnId: string, body: Record<string, any>, version: string): Promise<ApiResponse> =>
        api.put(ApiEndpoints.PARTNER.PARTNERS.LIKE_BY_ID(ptnId), body, version),
    getCategories: (version: string): Promise<ApiResponse> => api.get(ApiEndpoints.PARTNER.CATEGORIES, {}, version),
    getCategoriesSub: (categoryId: string, params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.PARTNER.CATEGORIES_SUB(categoryId), params, version),
    getPartners: (params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.PARTNER.PARTNERS.INDEX, params, version),
    getPartnerById: (ptnId: string, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.PARTNER.PARTNERS.BY_ID(ptnId), {}, version),
    getPartnerRecomm: (ptnId: string, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.PARTNER.PARTNERS.RECOMM_BY_ID(ptnId), {}, version),
    getPartnersVipCok: (ptnId: string, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.PARTNER.PARTNERS.VIP_COK_BY_ID(ptnId), {}, version),
    getCoupon: (ptnId: string, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.PARTNER.PARTNERS.COUPON_BY_ID(ptnId), {}, version),
};

const affiliateServiceApi = {
    getJoinStatus: (params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.AFFILIATE_SERVICE.JOIN_STATUS, params, version),
    // getInfo: (params: ApiParams, version: string): Promise<ApiResponse> =>
    //     api.get(ApiEndpoints.AFFILIATE_SERVICE.INFO, params, version),
    getAgreements: (params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.AFFILIATE_SERVICE.AGREEMENTS, params, version),
    getAgreementById: (agrId: string, params: ApiParams, version: string): Promise<ApiResponse> =>
        api.get(ApiEndpoints.AFFILIATE_SERVICE.AGREEMENTS_BY_ID(agrId), params, version),
    postJoin: (body: Record<string, any>, version: string): Promise<ApiResponse> =>
        api.post(ApiEndpoints.AFFILIATE_SERVICE.JOIN, body, version),
};

export {partnerApi, affiliateServiceApi};
