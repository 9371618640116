import { getItemByKey } from "../utils/indexedDB";
export const adSSP = async (type: string) => {
    const appVersion = await getItemByKey('appVersion');
    const platform = await getItemByKey("platform");
    const subNo = await getItemByKey("subNo") || '';
    const osVersion = await getItemByKey("osVersion") || '';
    const model = await getItemByKey('model');
    const adid = await getItemByKey('adid');

    // 기본 URL 설정
    let baseUrl;
    let placement;
    let queryParams: any = {
        'device-model': model,
        country: 'KOR',
        'app-id': 'uplus.membership',
        'device-orientation': 'VERTICAL',
        language: 'ko',
        'app-name': 'membership',
        'app-version': appVersion,
        'device-os-version': osVersion,
        application: 'APP',
        publisher: 'LGUPLUS',
        'user-agent': 'UNKNOWN',
    };
    const nonIdentifiedURL = process.env.REACT_APP_NODE_ENV === 'production' ? 'https://api-adid.stg-adssp.uplus.co.kr/api/v1/recommend': "https://api-adid.dev-adssp.uplus.co.kr/api/v1/recommend";
    const identifiedURL = process.env.REACT_APP_NODE_ENV === 'production' ? "https://api.stg-adssp.uplus.co.kr/api/v1/recommend"  : "https://api.dev-adssp.uplus.co.kr/api/v1/recommend";
    if (platform === 'AOS') {
        queryParams.platform = 'ANDROID';

        if (type === '비식별') {
            baseUrl = nonIdentifiedURL
            placement = 'MEMBERS_POPUP_APP_AOS';
            queryParams['device-adid'] = adid;
        } else if (type === '식별') {
            baseUrl = identifiedURL
            placement = 'MEMBERS_MAIN_LIST_APP_AOS';
            queryParams['user-id'] = subNo;
            // console.log('plz here')
        } else {
            throw new Error("Invalid type specified");
        }
    } else if (platform === 'IOS') {
        baseUrl = identifiedURL
        placement = 'MEMBERS_MAIN_LIST_APP_IOS';
        queryParams.platform = 'IOS';
        queryParams['user-id'] = subNo; // iOS는 항상 식별 가능한 형태로 요청
    } else {
        throw new Error("Unsupported platform");
    }

    queryParams.placement = placement;

    // 쿼리 스트링 생성
    const queryString = new URLSearchParams(queryParams).toString();
    const url = `${baseUrl}?${queryString}`;
    console.log(baseUrl);
    console.log(queryString);

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            throw new Error(`API request failed with status ${response.status}`);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error("API request error:", error);
        throw error;
    }
};