import React from "react";
import { Box, Image, Text } from "@lguuxe/2024_designsystem_uni";

import membershipVvipIcon from "../../images/icon/1_grade/membership_80=vvip.svg";
import membershipVipIcon from "../../images/icon/1_grade/membership_80=vip.svg";
import membershipExcellentIcon from "../../images/icon/1_grade/membership_80=excellent.svg";
import membershipGeneralIcon from "../../images/icon/1_grade/membership_80=general.svg";

const BoxTableBenefit: React.FC = () => {
    return (
        <Box type="3_trbl">
            <div className="tbl" style={{ marginBottom: "0" }}>
                <table>
                    <caption>멤버십 등급 혜택</caption>
                    <colgroup>
                        <col width="85px" />
                        <col />
                    </colgroup>
                    <tbody>
                    <tr>
                        <th>등급</th>
                        <th>등급 기준 안내</th>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Image src={membershipVvipIcon} alt="VVIP 등급 아이콘" width="32" ratio="1:1" />
                                <Text typo="font_body_1B" color="color_text_neutral_5" style={{ fontSize: "12px" }}>
                                    VVIP
                                </Text>
                            </div>
                        </td>
                        <td className="left">95,000원 이상 U+모바일 요금제를 사용하거나 전년도 통신료 납부 금액이 200만원 이상인 고객</td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Image src={membershipVipIcon} alt="VIP 등급 아이콘" width="32" ratio="1:1" />
                                <Text typo="font_body_1B" color="color_text_neutral_5" style={{ fontSize: "12px" }}>
                                    VIP
                                </Text>
                            </div>
                        </td>
                        <td className="left">74,800원 이상 U+모바일 요금제를 사용하거나 전년도 통신료 납부 금액이 100만원 이상인 고객</td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Image src={membershipExcellentIcon} alt="우수 등급 아이콘" width="32" ratio="1:1" />
                                <Text typo="font_body_1B" color="color_text_neutral_5" style={{ fontSize: "12px" }}>
                                    우수
                                </Text>
                            </div>
                        </td>
                        <td className="left">74,800원 미만 U+모바일 요금제를 사용하거나 전년도 통신료 납부 금액이 100만원 미만인 고객</td>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Image src={membershipGeneralIcon} alt="일반 등급 아이콘" width="32" ratio="1:1" />
                                <Text typo="font_body_1B" color="color_text_neutral_5" style={{ fontSize: "12px" }}>
                                    일반
                                </Text>
                            </div>
                        </td>
                        <td className="left">U+모바일을 이용하지 않는 고객</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </Box>
    );
};

export default BoxTableBenefit;
