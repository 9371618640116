import React from 'react';
import {Header, IconButton, Text} from '@lguuxe/2024_designsystem_uni';
import {useNavigate} from 'react-router-dom';

interface CommonAllIconHeaderProps {
    title: string;
    isBackIcon?: boolean;
    isNotiIcon?: boolean;
}

const AllIconHeader: React.FC<CommonAllIconHeaderProps> = ({title, isBackIcon = true, isNotiIcon = true}) => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleSearchClick = () => {
        navigate('/search');
    };

    const handleNotiClick = () => {
        navigate('/noti');
    };

    return (
        <Header align='center' variant='heading'>
            {isBackIcon && (
                <Header.LeftSlot>
                    <IconButton
                        color='neutral'
                        fill='icon'
                        icon='chevron_left_line'
                        onClick={handleBackClick}
                        noStates
                    />
                </Header.LeftSlot>
            )}

            <Header.CenterSlot>
                <Text typo='font_heading_4Sb'>{title}</Text>
            </Header.CenterSlot>
            <Header.RightSlot>
                <IconButton color='neutral' fill='icon' icon='search_line' onClick={handleSearchClick} noStates/>
                {isNotiIcon && (
                    <IconButton
                        color='neutral'
                        fill='icon'
                        icon='notification_line'
                        size='large'
                        noStates
                        onClick={handleNotiClick}
                    />
                )}
            </Header.RightSlot>
        </Header>
    );
};

export default AllIconHeader;
