import React, {useEffect, useState} from 'react'
import CommonLeftIconHeader from "../../../components/Common/CommonLeftIconHeader";
import MenuList from "../../../components/Entire/MenuList";
import CustomTab from "../../../components/Entire/Pop/CustomTab";
import {getItemByKey} from "../../../utils/indexedDB";
import {Accordion, Image, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import CommonDividerVertical from "../../../components/Common/CommonDividerVertical";
import {faqApi} from "../../../api/notiAndQna";
import emptyNoticeImg from '../../../images/img/empty/empty_notice_120.svg'
import {chatbot, moneymeEnter} from "../../../api/externalApi";
import {sendEvent} from "../../../api/bridgeApi";
import {formatDate} from "../../../utils/dateUtiles";

interface Inquiry {
    contentId: string;
    type: string;
    title: string;
    contents: string;
    answer: string;
    replyYn: string;
    regDate: string;
    replyDate: string;
}

const CustomerAsk = () => {
    const [selectedTabId, setSelectedTabId] = useState<string>('1');
    const [memberStatus, setMemberStatus] = useState<string>('0');
    const [inquiryList, setInquiryList] = useState<Inquiry[]>([]);

    useEffect(() => {
        const fetchIndexedDB = async () => {
            setMemberStatus(await getItemByKey('memberStatus'));
        }
        const fetchData = async () => {
            try {
                const requestParams = {
                    pageNo: '1',
                }
                faqApi.getInquiry(requestParams, 'v1').then((res) => {
                    if (res.header.resultCode === '0000') {
                        setInquiryList(res.body.inquiryList);
                    }
                });
            } catch (e) {
                console.log(e);
            }
        }

        fetchIndexedDB();
        fetchData();

    }, []);

    const handleTabChange = (id: string) => {
        setSelectedTabId(id);
        console.log(`Tab with id ${id} clicked`);
    };

    const tabItems = [
        {
            id: '1',
            label: '1:1 문의 안내',
        },
        {
            id: '2',
            label: '나의 문의 내역',
        },
    ];
    const MEMBERSHIP_MENU = [
        {title: "1:1 문의하기", link: "/help/ask/write"},
        {
            title: "이메일로 문의하기", link: "", onAction: () => {
                sendEvent('CALL_WEBVIEW', {
                    url: 'https://cv.uplus.kr/143.html',
                    title: '당신의 U+',
                    showTitlebar: true
                })
            }
        },
        {title: "챗봇에게 물어보기", link: "/", onAction: chatbot},
    ]
    const MONEYME_MENU = [
        {
            title: "1:1 문의하기", link: "", onAction: () => {
                moneymeEnter('1')
            }
        },
        {
            title: "이메일로 문의하기", link: "", onAction: () => {
                window.location.href = 'mailto:moneyme@lguplus.co.kr';
            }
        },
    ]
    const MISSION_MENU = [
        {title: "리워드 적립 및 사용 문의하기", link: "/help/ask/reward"},
    ];

    return (
        <>
            <CommonLeftIconHeader title={'1:1 문의'}/>
            <CustomTab items={tabItems} selectedItemId={selectedTabId} onTabChange={handleTabChange}/>

            {selectedTabId === '1' && (<>
                <MenuList title={'U+ 멤버십'} menuItems={MEMBERSHIP_MENU}/>

                {!(memberStatus === '3' || memberStatus === '4') && (
                    <>
                        <MenuList title={'머니Me'} menuItems={MONEYME_MENU}/>

                        <MenuList title={'미션 리워드'} menuItems={MISSION_MENU}/>
                    </>
                )}
            </>)}

            {selectedTabId === '2' && (inquiryList.length !== 0 ? (
                <>
                    {inquiryList.map((inquiry, index) => (
                        <Accordion
                            key={index}
                            title={
                                <TextSet
                                    subComponent="Body_1"
                                    text={{
                                        description:
                                            <>
                                                <span>{inquiry.replyYn === 'Y' ? '답변완료' : '답변 대기 중'}</span>
                                                <CommonDividerVertical/>
                                                <span>{formatDate(inquiry.regDate)}</span>
                                            </>,
                                        title: inquiry.title,
                                    }}
                                    weight="bolder"
                                />
                            }
                        >

                            <Accordion.Body>
                                <Text typo="font_body_2M">
                                    {inquiry.contents}
                                </Text>
                                {inquiry.replyYn === 'Y' && (<div
                                    style={{
                                        alignItems: 'flex-start',
                                        alignSelf: 'stretch',
                                        backgroundColor: '#F9FAFB',
                                        borderRadius: '0.5rem',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '0.5rem',
                                        padding: '16px 20px'
                                    }}
                                >
                                    <Text
                                        color="color_text_neutral_5"
                                        typo="font_heading_6Sb"
                                    >
                                        답변
                                    </Text>
                                    <Text typo="font_body_2M">
                                        {inquiry.answer}
                                    </Text>
                                </div>)}

                            </Accordion.Body>
                        </Accordion>
                    ))}
                </>) : (<div style={{marginTop: "86px"}}>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Image
                        src={emptyNoticeImg}
                        alt="placeholder"
                        width="120px"
                        ratio="1:1"
                    />
                    <div style={{paddingTop: "20px"}}>
                        <Text typo="font_detail_1M" color="color_text_neutral_3" textAlign="center">
                            입력하신 내용에<br/>
                            해당하는 결과가 없어요
                        </Text>
                    </div>
                </div>
            </div>))}
        </>
    )
}

export default CustomerAsk
