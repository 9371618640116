import React, {FC, forwardRef, useImperativeHandle, useState} from 'react';
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import CommonDivider from "../../components/Common/CommonDivider";
import SectionQuitList from "../../components/Setting/SectionQuitList";
import SectionRadioList from "../../components/Setting/SectionRadioList";
import MustCheckAccordion from '../../components/Setting/SectionAccordion';
import SectionChkCTA from '../../components/Setting/SectionChkCTA';
import CommonPopup from "../../components/Common/CommonPopup";
import commonApi from "../../api/common";
import {clearAllDatabases, getItemByKey} from "../../utils/indexedDB";
import {sendEvent, sendEventReturn, sendEventToAndroid, sendEventToIOS} from "../../api/bridgeApi";

export interface QuitHandles {
    handleResetLoginInfo: () => void;
}


const UMembershipQuit = forwardRef<QuitHandles>((props, ref) => {
    const [openPopup, setOpenPopup] = useState(false);
    const [openLastPopup, setOpenLastPopup] = useState(false);
    const [selectedReason, setSelectedReason] = useState<string | null>(null);

    const handleRadioChange = (reason: string) => {
        setSelectedReason(reason);
    };

    useImperativeHandle(ref, () => ({
        handleResetLoginInfo: async () => {
            await clearAllDatabases();
            sendEventToAndroid("APP_FINISH", {});
            sendEventToIOS("APP_FINISH", {});
            setOpenPopup(false);
        }
    }))

    const handleConfirm = async () => {
        try {
            const subNo = await getItemByKey('subNo')
            let response
            if(subNo) {
                response = await commonApi.withdrawal({subNo: subNo, dropDetail: selectedReason}, 'v1');
            } else {
                response = await commonApi.withdrawal({dropDetail: selectedReason}, 'v1');
            }
            console.log(response)
        } catch (error) {
            console.error("Error fetchAlarm detail:", error);
        }
        setOpenPopup(false);
        setOpenLastPopup(true);
    };

    const handleLastConfirm = async () => {
        setOpenLastPopup(false);
        sendEventReturn('RESET_LOGIN_INFO', {});
    };

    const text = [
        "회원 탈퇴 시 모든 데이터는 즉시 삭제됩니다. 단, 결제 관련 정보는 전자금융거래법에 따라 5년간 보관됩니다.",
        "적립금은 초기화됩니다. 단, 제휴사 멤버십 회원 탈퇴는 각 제휴사에 직접 요청해 주세요",
        "LG페이 멤버십을 이용 중인 U+멤버스 회원은 회원 탈퇴 시 LG페이 이용이 제한될 수 있습니다.",
        "U+멤버스 앱에만 해당되며, U+멤버스 서비스 해지는 별도의 절차가 필요합니다.",
        "멤버스 쇼핑(제휴사 (주)LGCNS 제공)에서 제공하는 적립/특별포인트적립은 중지되며, 모든 적립금(포인트)은 즉각 소멸됩니다. 소멸된 적립금은 복구가 불가능합니다.",
        "구매 및 결제 이력은 관련법규에 의거하여 5년간 보관하며, 관련문의는 (주)LGCNS 고객센터 0000-0000에서 답변드립니다.",
    ]
    return (
        <>
            <CommonLeftIconHeader title={"회원탈퇴"} />
            <div style={{
                maxHeight: "calc(100vh - 200px)", // 100vh에서 하단 고정 영역의 높이를 뺀 나머지 부분
                overflowY: "auto", // 세로 스크롤 허용
            }}>
            <SectionQuitList />

            <div style={{ marginBottom: "8px" }}>
                <CommonDivider />
            </div>

            <div style={{ marginBottom: "12px" }}>
                <SectionRadioList onRadioChange={handleRadioChange} />
            </div>

            <CommonDivider />
                <MustCheckAccordion items={text} />
            </div>
            <SectionChkCTA setOpenPopup={setOpenPopup} selectedReason={selectedReason}/>

            {openPopup && (
                <CommonPopup
                    description="정말로 탈퇴하시겠어요?"
                    submitText="탈퇴하기"
                    cancelText="취소"
                    onSubmit={handleConfirm}
                    onClose={() => setOpenPopup(false)}
                />
            )}

            {openLastPopup && (
                <CommonPopup
                    description={"U+멤버십 앱을 탈퇴했어요.\n그동안 U+멤버십 앱을 이용해 주셔서\n감사합니다."}
                    submitText="확인"
                    onSubmit={handleLastConfirm}
                />
            )}
        </>
    );
})

export default UMembershipQuit;