import React, { useState } from 'react';
import { TextSet, Box, TextField, Button, Text } from '@lguuxe/2024_designsystem_uni';
import AmountButton from "./AmountButton";

interface SectionChange2Props {
    setButtonDisable:(disable: boolean) => void;
}

const SectionChange2: React.FC<SectionChange2Props> = ({setButtonDisable}) => {
    const [amount, setAmount] = useState<number>(250000); // 초기 금액
    const [displayValue, setDisplayValue] = useState<string>(amount.toLocaleString()); // 포맷된 값
    const [error, setError] = useState<string | null>(null);
    const [supportText, setSupportText] = useState<Array<{ icon: string; status: "default" | "disabled" | "error" | "success" | "readOnly"; text: string }>>([]);

    const maxLimit = 1000000; // 최대 한도

    const handleAmountChange = (increment: number) => {
        const newAmount = amount + increment;
        if (newAmount < 10000 || newAmount > maxLimit) {
            const errorMessage = '최소 10,000원~최대 한도까지 만원 단위로 설정해 주세요.';
            setError(errorMessage);
            setSupportText([
                {
                    icon: 'error_circle_filled',
                    status: 'error',
                    text: errorMessage,
                },
            ]);
            setButtonDisable(true)
        } else {
            setError(null);
            setSupportText([]);
            setAmount(newAmount);
            setButtonDisable(false)
            setDisplayValue(newAmount.toLocaleString()); // 포맷된 값 설정
        }
    };

    const handleTextFieldChange = (event: any) => {
        let value = event.value.replace(/,/g, ''); // 콤마 제거

        // 빈 값일 때 초기화
        if (value === '') {
            setDisplayValue('');
            setAmount(0);
            setError(null);
            setButtonDisable(true)
            setSupportText([]);
            return;
        }

        let numericValue = parseInt(value, 10);

        if (!isNaN(numericValue)) {
            if (numericValue < 10000 || numericValue > maxLimit) {
                console.log(numericValue)
                console.log(maxLimit)
                const errorMessage = '최소 10,000원~최대 한도까지 만원 단위로 설정해 주세요.';
                setError(errorMessage);
                setSupportText([
                    {
                        icon: 'error_circle_filled',
                        status: 'error',
                        text: errorMessage,
                    },
                ]);
                setButtonDisable(true)
            } else {
                setButtonDisable(false)
                setError(null);
                setSupportText([]);
                setAmount(numericValue);
                setDisplayValue(numericValue.toLocaleString()); // 포맷된 값 설정
            }
        } else {
            setError('숫자만 입력해 주세요.');
            setSupportText([
                {
                    icon: 'error_circle_filled',
                    status: 'error',
                    text: '숫자만 입력해 주세요.',
                },
            ]);

            // 콤마 제거 후 빈 문자열 또는 0으로 초기화
            setDisplayValue(value === '' ? '' : '0');
        }
    };

    return (
        <div>
            <Box type="2_trbl">
                <TextSet
                    subComponent="Heading_2"
                    text={{
                        eyebrow: '현재 한도',
                        title: (
                            <span style={{ paddingTop: '2px' }}>500000원</span>
                        ),
                        description: (
                            <span style={{ paddingTop: '6px' }}>최대 한도 {maxLimit.toLocaleString()}원</span>
                        ),
                    }}
                    weight="default"
                />
            </Box>
            <div
                style={{
                    padding: '4px 20px 16px',
                }}
            >
                <TextField
                    inputType={"text"} // inputType을 "text"로 유지
                    isOutline={true}
                    placeholder="원하는 금액을 입력해주세요"
                    status={error ? "error" : "default"}
                    value={displayValue || ''}  // value가 항상 정의된 상태로 유지
                    onChange={handleTextFieldChange}
                    supportText={supportText}
                />

                <div
                    style={{
                        paddingTop: '8px',
                        display: 'flex',
                        gap: '6px',
                        justifyContent: 'space-between',
                    }}
                >
                    <AmountButton label="+1만" increment={10000} onClick={handleAmountChange} />
                    <AmountButton label="+5만" increment={50000} onClick={handleAmountChange} />
                    <AmountButton label="+10만" increment={100000} onClick={handleAmountChange} />
                </div>
            </div>
            <Box type="1_rbl">
                <Text typo="font_detail_3Sb" color="color_text_neutral_2">
                    • 최대 가능 한도 내에서 만원 단위로 변경하실 수 있습니다.
                    <br />
                    • 사용가능 금액 변경 시 결제 비밀번호를 입력하셔야 합니다.
                </Text>
            </Box>
        </div>
    );
}

export default SectionChange2;
