import React, {useEffect} from "react";
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import {mapApi} from "../../api/other";
import {Box, Button, Image, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import refeshIcon from '../../images/etc/refresh_24_line.svg'
import bluePin from '../../images/icon/inavi/bluePin.svg'
import purplePin from '../../images/icon/inavi/PurplePin.svg'
import herePin from '../../images/icon/inavi/herePin.svg'
import selectedBluePin from '../../images/icon/inavi/selectedBluePin.svg'
import selectedPurplePin from '../../images/icon/inavi/selectedPurplePin.svg'

const stgUrl = 'https://openapi.testmap.uplus.co.kr:18000/mapinfra/api/map/jsVectorMapDownload?authKey=8bb5975c1d6f4f919a047765cd6dbd2f&svcId=90fc7981cb634638a06bd57351063cf3';

interface partner {
    ptnId: string;
    branchName: string;
    lat: string;
    lng: string;
    address: string;
    pinType: string;
}

const partners_test: partner[] = [{
    ptnId: '1',
    branchName: 'IT 노동자를 위한 함바집',
    lat: '37.481097',
    lng: '126.894123',
    address: '서울 구로구 디지털로26길 43 지하 1층',
    pinType: '10',
}, {
    ptnId: '2',
    branchName: '풍무양꼬치',
    lat: '37.483873',
    lng: '126.902789',
    address: '서울 관악구 시흥대로 572 한성빌딩',
    pinType: '20',
}, {
    ptnId: '3',
    branchName: '바나프레소',
    lat: '37.480702',
    lng: '126.893084',
    address: '서울특별시 서초구 강남대로 343',
    pinType: '10',
}, {
    ptnId: '4',
    branchName: '퐁닭',
    lat: '37.483278',
    lng: '126.896571',
    address: '서울 구로구 디지털로32길 30 1층 106호',
    pinType: '20',
}];

const TILE_BANNER_HEIGHT = 0.0005;

const PartnerMap: React.FC = () => {
    const [selectedPartner, setSelectedPartner] = React.useState<any>(null);


    const sleep = (ms: number) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    };

    useEffect(() => {
        // script 생성 및
        const script = document.createElement('script');
        script.type = 'text/javascript';
        // script.src = 'https://openapi.testmap.uplus.co.kr:18000/mapinfra/api/map/jsVectorMapDownload?authKey=8bb5975c1d6f4f919a047765cd6dbd2f&svcId=90fc7981cb634638a06bd57351063cf3&callback=initMap'
        script.src = 'https://openapi.testmap.uplus.co.kr:18000/Script/iNavi_v_Map.js';
        document.body.appendChild(script);

        const initMap = async () => {
            await sleep(1000);

            const currentLocation = { // 한강대로 32
                lat: '37.523775',
                lng: '126.963579',
            }
            // 내 위치 마커 생성
            initMyLocation(currentLocation);
            fetchData(currentLocation);
        }
        initMap();
    }, []);

    const fetchData = async (location: any) => {
        try {
            const res = await mapApi.getMapPartners({lat: location.lat, lng: location.lng}, 'v1');
            console.log(res);
            let partners: partner[] = res.body.ptnList;
            if (partners.length === 0) { //TODO: api 정상 작동시 삭제
                partners = partners_test;
            }

            // 파트너 위치 마커 생성
            createPartnersMarker(partners);
        } catch (e) {
            console.log(e);
            //TODO: 제거
            createPartnersMarker(partners_test);
        }
    }

    const initMyLocation = (defaultLocation: any) => {
        const currentMarker = (current: any) => {
            // 마커
            const currentMarker = new (window as any).inavi.maps.Marker({
                map: (window as any).INAVI.map,
                position: current,
                icon: herePin
            });

            (window as any).INAVI.map.flyTo(current, {
                zoom: (window as any).INAVI.map.getMaxZoom(),
            });
        }

        if ((window as any).navigator.geolocation) {
            (window as any).navigator.geolocation.getCurrentPosition(
                (position: any) => {
                    const current = {
                        lat: String(position.coords.latitude),
                        lng: String(position.coords.longitude),
                    };
                    currentMarker(current);
                },
                (error: any) => {
                    currentMarker(defaultLocation);
                }
            );
        }
    }

    const createPartnersMarker = (partners: partner[]) => {
        partners.forEach((partner) => {
            const position = [Number(partner.lng), Number(partner.lat)];

            const marker = new (window as any).inavi.maps.Marker({
                position: position,
                map: (window as any).INAVI.map,
                icon: partner.pinType === '10' ? bluePin : purplePin,
            });
            marker.on('touchstart', () => {
                const selectedMarkerInfo = (window as any).INAVI.selectedMarkerInfo;
                console.log(selectedMarkerInfo);
                if (selectedMarkerInfo && selectedMarkerInfo.id !== partner.ptnId) {
                    const icon = selectedMarkerInfo.iconType === '10' ? bluePin : purplePin;
                    (window as any).INAVI.selectedMarker.setIcon(icon);
                    // TODO: 현재 정보 창 닫기
                    console.log((window as any).INAVI.selectedInfoWindow);
                    (window as any).INAVI.selectedInfoWindow.setMap();
                }
                (window as any).INAVI.selectedMarker = marker;
                (window as any).INAVI.selectedMarkerInfo = {id: partner.ptnId, iconType: partner.pinType};
                marker.setIcon(partner.pinType === '10' ? selectedBluePin : selectedPurplePin);
                setSelectedPartner(partner);

                //  정보 창 생성
                console.log(marker.getPosition());
                const infoWindow = new (window as any).inavi.maps.CustomInfoWindow({
                    map: (window as any).INAVI.map,
                    position: {lat: marker.getPosition().lat + TILE_BANNER_HEIGHT, lng: marker.getPosition().lng},

                    content: [
                        ' <div style="padding: 6px 12px 7px; top: 40px; border: 1px solid #DCE0E5; border-radius: 15px; display: flex; flex-direction: column; background-color: #FFFFFF;">',
                        ` <span style="font-family:font_label_2Sb;">${partner.branchName}</span>`,
                        ' </div>',
                    ].join("")
                });
                (window as any).INAVI.selectedInfoWindow = infoWindow;
            });
        });
    }

    const removeMarkers = () => {
        (window as any).INAVI.selectedInfoWindow.setMap();
        (window as any).INAVI.selectedMarker.setMap();
        setSelectedPartner(null);

        (window as any).INAVI.selectedMarker = null;
        (window as any).INAVI.selectedMarkerInfo = null;
        (window as any).INAVI.selectedInfoWindow = null;
    }

    const handleClickRefresh = () => {
        removeMarkers();
        const center = (window as any).INAVI.map.getCenter();
        fetchData({lat: center.lat, lng: center.lng});
    }

    return (
        <>
            <div style={{display: 'grid', gridTemplateRows: 'auto 1fr', height: '100vh'}}>
                <CommonLeftIconHeader title={'내 주변 매장'}/>
                <Box
                    style={{
                        display: "flex",
                        gap: "12px",
                        justifyContent: "center",
                        flexDrection: "column",
                        alignItems: "center",
                        position: "relative",
                        padding: "0"
                    }}
                >
                    <div id="map" style={{width: '100%', height: '100%'}}></div>
                    <Box
                        style={{
                            padding: "6px 12px 7px",
                            position: "absolute",
                            top: "20px",
                            border: "1px solid #DCE0E5",
                            borderRadius: "35px",
                            display: "flex",
                            // justifyContent: "row",
                            gap: "4px",
                            backgroundColor: "#FFFFFF",
                        }}
                        onClick={handleClickRefresh}
                    >
                        <Image src={refeshIcon} alt="placeholder" width="20px" ratio="1:1"/>
                        <Text typo="font_label_2Sb" color="color_status_Info" style={{color: "#1662F8"}}>
                            현재 지도에서 검색
                        </Text>
                    </Box>

                    {selectedPartner && (
                        <Box
                            style={{
                                position: "absolute",
                                bottom: "40px",
                                display: "flex",
                                border: "1px solid #DCE0E5",
                                borderRadius: "15px",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                width: "80%",  // 상자 전체 너비 사용
                                backgroundColor: "#FFFFFF",
                                // padding: "6px 12px 7px",
                                gap: "4px",
                                padding: "16px",

                            }}
                        >
                            <Box
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    padding: "0",
                                }}
                            >
                                <TextSet
                                    subComponent='Heading_3'
                                    text={{
                                        title: selectedPartner.branchName,
                                    }}
                                    weight='bolder'
                                />
                                <TextSet
                                    subComponent='Body_1'
                                    text={{
                                        title: selectedPartner.address,
                                    }}
                                    weight='default'
                                />
                            </Box>

                            <Button
                                color='primary'
                                size='medium'
                                onClick={() => {
                                    console.log('click');
                                }}
                            >
                                혜택 확인
                            </Button>
                        </Box>
                    )}
                </Box>
            </div>
        </>
    )
}
export default PartnerMap;