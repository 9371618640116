export const formatCurrency = (value: string | undefined) => {
    if (!value) {
        return '0';
    }
    const numericValue = parseFloat(value.replace(/,/g, ''));
    if (isNaN(numericValue)) {
        // throw new Error('Invalid input: the value must be a valid number');
        return '0';
    }
    const formattedValue = numericValue.toLocaleString('ko-KR');
    return `${formattedValue}`;
};
