export const getGradeTag = (tagName: string, size = 'xSmall') => {
    if (!tagName) {
        return {};
    }
    console.log(tagName);

    switch (tagName) {
        case 'VVIP':
            return {
                label: 'VVIP',
                size: size,
                style: 'solid',
                colorToken: 'primary_low',
            };
        case 'VIP':
            return {
                label: 'VIP',
                size: size,
                style: 'solid',
                colorToken: 'purple_low',
            };

        case '우수':
            return {
                label: '우수',
                size: size,
                style: 'solid',
                colorToken: 'blue_low',
            };
        case '일반':
            return {
                label: '일반',
                size: size,
                style: 'solid',
                colorToken: 'default',
            };
        default:
            return {
                label: tagName,
                size: size,
                style: 'solid',
                colorToken: 'default',
            };
    }
};

export const getYouthTag = (youthName: string, size = 'xSmall') => {
    return {
        label: youthName,
        size: size,
        style: 'solid',
        colorToken: 'green_low',
    };
};

export const getLongTermTag = (tagName: string, size = 'xSmall') => {
    return {
        label: tagName,
        size: 'xSmall',
        style: 'solid',
        colorToken: 'blue_low',
    };
};
