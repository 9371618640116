import React from "react";
import { Text, Box, Button, Image } from "@lguuxe/2024_designsystem_uni";

interface MembershipBenefitSectionProps {
    title: string;
    target: string;
    benefits: string[];
    buttonText: string;
    imageSrc: string;
    onClick: () => void; // 버튼 클릭 시 호출될 함수의 타입을 정의
}

const BenefitGuideSection: React.FC<MembershipBenefitSectionProps> = ({ title, target, benefits, buttonText, imageSrc, onClick }) => {
    return (
        <Box style={{ display: "flex", flexDirection: "column", gap: "12px", padding: "24px 0 12px" }}>
            <div style={{ display: "flex", flexDirection: "column", gap: "12px", padding: "0" }}>
                <Text typo="font_heading_4B" color="color_text_neutral_6" style={{ fontSize: "18px", fontWeight: "800", padding: "0 20px" }}>
                    {title}
                </Text>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "8px", padding: "0 20px" }}>
                <Text typo="font_body_2M" color="color_text_neutral_5" style={{ fontSize: "16px", fontWeight: "600" }}>
                    대상 : {target}
                </Text>
                <ul style={{ padding: "0 0 0 25px", margin: "0", fontSize: "14px", color: "#66707A" }}>
                    {benefits.map((benefit, index) => (
                        <li key={index}>{benefit}</li>
                    ))}
                </ul>
            </div>
            <div style={{ display: "flex", flexDirection: "column", gap: "16px", padding: "4px 20px 12px" }}>
                <Image
                    src={imageSrc}
                    alt="placeholder"
                    width="100%"
                    ratio="4:3"
                />
                <Button
                    as="button"
                    color="secondary"
                    fill="solid"
                    size="medium"
                    state="default"
                    style={{ width: "100%" }}
                    onClick={onClick} // 클릭 시 props로 받은 onClick 함수 호출
                >
                    {buttonText}
                </Button>
            </div>
        </Box>
    );
};

export default BenefitGuideSection;
