// types.ts

import {encrypt} from "../../api/api";

export type User = {
    name: string;
    birth: string;
    gender: string;
    nationality: Nationality; // '내국인' or '외국인'
    carrier: string;
    phoneNumber: string;
    authCode: string;
    kmcInfo: {
        certNum: string;
        check1: string;
        check2: string;
        check3: string;
    },
};
export type Nationality = '내국인' | '외국인';

export type Carrier = {
    id: string;
    label: string;
    value: string;
};

export const getGender = (genderCode: string) => {
    if (parseInt(genderCode) % 2 === 0) {
        return 'F';
    } else {
        return 'M';
    }
}

export const getBirth8Digit = (birth: string, genderCode: string) => {
    if (genderCode === '1' || genderCode === '2' || genderCode === '5' || genderCode === '6') {
        return '19' + birth;
    } else if (genderCode === '3' || genderCode === '4' || genderCode === '7' || genderCode === '8') {
        return '20' + birth;
    } else {
        return '19' + birth;
    }
}

export const getRequestParam = async (user: User, mcpTrKey: string) => {
    const encryptName = await encrypt(mcpTrKey, user.name);
    return {
        phoneNo: user.phoneNumber,
        carrier: user.carrier,
        userName: encryptName,
        nation: user.nationality == '내국인' ? '0' : '1',
        userBirth: getBirth8Digit(user.birth, user.gender),
        gender: getGender(user.gender)
    }
}

export const getAuthRequestParam = async (user: User, mcpTrKey: string) => {
    const encryptName = await encrypt(mcpTrKey, user.name);

    return {
        phoneNo: user.phoneNumber,
        carrier: user.carrier,
        userName: encryptName,
        nation: user.nationality == '내국인' ? '0' : '1',
        userBirth: getBirth8Digit(user.birth, user.gender),
        gender: getGender(user.gender),
        smsNo: user.authCode,
        check1: user.kmcInfo.check1,
        check2: user.kmcInfo.check2,
        check3: user.kmcInfo.check3,
    }
}

export const getParentCheckParam = async (childCI: string, parentCI: string, mcpTrKey: string) => {
    const encryptChildCI = await encrypt(mcpTrKey, childCI);
    const encryptParentCI = await encrypt(mcpTrKey, parentCI);

    return {
        childCertID: encryptChildCI,
        parentCertID: encryptParentCI,
    }
}