// ****************************************************
// [배포] 4.마이 > 휴대폰결제 > 사용가능 금액 변경
// ****************************************************
import React, {Component, useEffect, useState} from 'react'
import CommonRightIconHeader2 from "../../components/Common/CommonRightIconHeader";
import SectionChange2 from "../../components/MobilePayment/SectionChange2";
import SectionCTABtn2 from "../../components/MobilePayment/SectionCTABtn2";
import {useNavigate} from "react-router-dom";
import {getItemByKey} from "../../utils/indexedDB";

const MobilePaymentLimit = () => {
    const navigate = useNavigate()
    const [memberStatus, setMemberStatus] = useState("")
    const [buttonDisable, setButtonDisable] = useState(false)
    const userStatus = getItemByKey("")
    useEffect(() => {
        const getStatus = async  () => {
            const status = await getItemByKey('memberStatus');
            setMemberStatus(status)
        }
        getStatus()
    }, []);

    const back = () => {
        navigate(-1)
    }
    return (
        <>
            <CommonRightIconHeader2 icon={"close_line"} title={"월 결제 한도 변경"} onClick={back}/>

            <SectionChange2 setButtonDisable={setButtonDisable}/>

            <SectionCTABtn2 status={memberStatus} buttonDisable={buttonDisable} />
        </>
    )
}

export default MobilePaymentLimit
