import React from 'react';
import { useLocation } from 'react-router-dom';
import CommonLeftIconHeader from '../../components/Common/CommonLeftIconHeader';
import SearchList from "../../components/Search/SearchList";
import CommonDivider from "../../components/Common/CommonDivider";

const ResultDetail: React.FC = () => {
    const location = useLocation();
    const { searchResults } = location.state || { searchResults: { sectionCount: "0", sectionList: [] } };

    const getTitleByCode = (code: string) => {
        switch (code) {
            case "1000":
                return '이벤트';
            case "1001":
                return '유플투쁠';
            case "1002":
                return '제휴사';
            case "1003":
                return '쿠폰';
            case "1004":
                return '공지사항';
            case "1005":
                return '자주하는 질문';
            default:
                return '';
        }
    };

    const getThresholdByTitle = (title: string) => {
        switch (title) {
            case '이벤트':
            case '제휴사':
                return 5;
            case '유플투쁠':
                return 3;
            case '쿠폰':
            case '공지사항':
            case '자주하는 질문':
                return 2;
            default:
                return 0; // 기본값을 설정 (필요한 경우)
        }
    };

    return (
        <div
            style={{
                width: '100%',
                height: '100vh',
            }}
        >
            <CommonLeftIconHeader title="검색 결과" />
            {searchResults.sectionList.map((section: any, index: number) => {
                const title = getTitleByCode(section.target); // 숫자 코드를 문자열로 변환
                return (
                    section.itemList && section.itemList.length > 0 && (
                        <div key={index}>
                            <SearchList title={title} items={section.itemList} threshold={getThresholdByTitle(title)}/>
                            <CommonDivider />
                        </div>
                    )
                )
            })}
        </div>
    );
};

export default ResultDetail;
