import React, {useEffect, useState} from 'react'
import {FAB, List, TextSet} from '@lguuxe/2024_designsystem_uni'
import CommonLeftIconHeader from "../../../components/Common/CommonLeftIconHeader";
import ChipButtonGroup from "../../../components/Entire/Help/ChipButtonGroup";
import {faqApi} from "../../../api/notiAndQna";
import {useNavigate} from "react-router-dom";

const chipButtons = [
    {id: 1, label: '전체', type: ''},
    {id: 2, label: '결제', type: '113001'},
    {id: 3, label: '혜택', type: '113003'},
    {id: 4, label: '기타', type: '113004'},
];

interface Faq {
    faqId: string;
    title: string;
    date: string;
}

const Faq: React.FC = () => {
    const navigate = useNavigate();
    const [activeChipId, setActiveChipId] = useState<number>(1);
    const [faqList, setFaqList] = useState<Faq[]>([]);
    const [page, setPage] = useState<number>(1);

    useEffect(() => {
        fetchData();
    }, [activeChipId]);

    const fetchData = async () => {
        try {
            const request = {
                orderType: '', // 필요없어보임
                faqType: chipButtons[activeChipId - 1].type,
                pageNo: page.toString(),
            }
            faqApi.getCategories(request, 'v1').then((res) => {
                console.log(res);
                if (res.header.resultCode === '0000' && res.body) {
                    setFaqList(res.body.faqList);
                    setPage(page + 1);
                }
            });

        } catch (error) {
            console.log('Failed to fetchData :', error);
        }
    }

    const handleChipClick = (id: number) => {
        console.log(`Chip with id ${id} clicked`);
        setActiveChipId(id);
        setPage(1);
    }

    const handleClick = (event: any) => {
        console.log(event.target.id);
        navigate(`/help/faq/${event.target.id}`);
    }

    const scrollToTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    return (
        <>
            <CommonLeftIconHeader title={'자주하는 질문'}/>

            <ChipButtonGroup chipButtons={chipButtons} activeChipId={activeChipId} onChipClick={handleChipClick}/>

            <List
                fill="none"
                type="view"
                size="large"
                items={faqList.map((item, index) => ({
                    id: item.faqId,
                    leftSlot: [
                        <TextSet
                            subComponent="Body_1"
                            text={{
                                title: item.title,
                            }}
                            weight="bolder"
                        />
                    ],
                    linkIconOnlyId: item.faqId,
                    onClick: handleClick,
                }))}
            />

            <div
                style={{
                    position: "fixed",
                    right: "24px",
                    bottom: "40px",
                }}
            >
                <FAB
                    icon="chevron_up_small_line"
                    onClick={scrollToTop}
                    size="small"
                />
            </div>
        </>
    )
}

export default Faq
