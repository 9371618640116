import React, {useEffect, useState} from "react";
import {Box, Button, CTA, TextField, TextSet} from "@lguuxe/2024_designsystem_uni";
import CommonLeftIconHeader from "../../../components/Common/CommonLeftIconHeader";
import {affiliateServiceApi} from "../../../api/partnerAndAffiliate-service";
import {joinRequestParam} from "./types";
import {useLocation, useNavigate} from "react-router-dom";
import {settingApi} from "../../../api/settingAndTerms";
import CommonPopup from "../../../components/Common/CommonPopup";
import AddressSection from "./daum";

interface UserInfo {
    address1: string;
    address2: string;
    email: string;
    zipCode: string;
}

const initialUserInfo: UserInfo = {
    address1: '',
    address2: '',
    email: '',
    zipCode: ''
}

const PopInput: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [supportText, setSupportText] = useState<any>([]);
    const [userInfo, setUserInfo] = useState<UserInfo>(initialUserInfo);
    const [activeNextButton, setActiveNextButton] = useState<boolean>(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState<boolean>(false);
    const [showFailPopup, setShowFailPopup] = useState<boolean>(false);
    const [showAddressSection, setShowAddressSection] = useState<boolean>(false);

    useEffect(() => {
        if (validateAddress(userInfo) && validateEmail(userInfo.email)) {
            setActiveNextButton(true);
        } else {
            setActiveNextButton(false);
        }
        console.log(userInfo);
    }, [userInfo]);

    const fetchJoin = async () => {
        try {
            const termList = location.state;
            console.log(termList);
            const requestParam = await joinRequestParam(termList);

            affiliateServiceApi.postJoin(requestParam, 'v1').then((response) => {
                console.log(response);
                switch (response.header.resultCode) {
                    case '0000':
                        setShowSuccessPopup(true);
                        // navigate('/pop/complete');
                        break;
                    case '1012': // 이메일 정보 없음
                    default:
                        break;
                }
            });
        } catch (error) {
            console.log('Failed to fetchJoin :', error);
        }
    }

    const fetchSettingInfo = async () => {
        try {
            settingApi.putMyInfo(userInfo, 'v1').then((response) => {
                console.log(response);
                switch (response.header.resultCode) {
                    case '0000':
                        fetchJoin();
                        break;
                    default:
                        setShowFailPopup(true);
                        break;
                }
            });
        } catch (error) {
            setShowFailPopup(true);
            console.log('Failed to fetchSettingInfo :', error);
        }
    }

    const handleEmailChange = (e: any) => {
        const email = e.value;
        setUserInfo(prevState => ({
            ...prevState,
            email: email
        }));
        if (validateEmail(email) || email.trim() === "") {
            setSupportText([]);
        } else {
            setSupportText([
                {
                    icon: 'error_circle_filled',
                    status: 'error',
                    text: '이메일 형식이 잘못되었습니다. 다시 확인해 주세요 .'
                }
            ]);
        }
    }

    const handleNextButton = () => {
        fetchSettingInfo();
    }

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateAddress = (userInfo: UserInfo): boolean => {
        if (userInfo.address1.trim() === "" || userInfo.address2.trim() === "" || userInfo.zipCode.trim() === "") {
            return false;
        }
        return true;
    }

    const handleAddressDetailChange = (e: any) => {
        const address2 = e.value;
        setUserInfo(prevState => ({
            ...prevState,
            address2: address2
        }));
    }

    const handleClick = () => {
        setShowAddressSection(true);
    }
    return (
        <>

            <CommonLeftIconHeader title={''}/>
            <Box
                type="2_trbl"
            >
                <TextSet
                    subComponent="Heading_2"
                    text={{
                        title: '추가 정보 입력',
                        description:
                            <span>POP서비스 이용을 위해<br/>주소와 이메일 주소를 입력해 주세요.</span>
                    }}
                    weight="bolder"
                />
            </Box>

            <Box
                type="2_trbl"
            >
                {!showAddressSection && <TextField
                    fill="single"
                    inputType="text"
                    isAutoTab
                    label="주소"
                    maxLength={10}
                    placeholder="주소를 검색해 주세요"
                    status="default"
                    value={userInfo.address1}
                    // disabled
                    rightAddons={[
                        // <AddressButton setAddress={setUserInfo}/>
                        <Button
                            size={"large"}
                            color="primary"
                            onClick={handleClick}
                        >
                            주소 검색
                        </Button>
                    ]}
                />}
                {showAddressSection &&
                    <AddressSection isOpen={showAddressSection} onClose={() => setShowAddressSection(false)}
                                    setAddress={setUserInfo}/>}

                <div style={{paddingTop: "12px"}}>
                    <TextField
                        fill="single"
                        inputType="text"
                        isAutoTab
                        placeholder="상세주소를 입력해 주세요"
                        status="default"
                        onChange={handleAddressDetailChange}
                    >
                    </TextField>
                </div>
            </Box>

            <div style={{paddingBottom: "120px"}}>
                {/* error 버전 */}
                <Box
                    type="2_trbl"
                >
                    <TextField
                        fill="single"
                        inputType="email"
                        isAutoTab
                        label="이메일"
                        placeholder="example@lguplus.co.kr"
                        status="default"
                        supportText={supportText}
                        onChange={handleEmailChange}
                    >
                        버튼
                    </TextField>
                </Box>
            </div>

            <Box className="fixedBtn" style={{padding: "0"}}>
                <CTA
                    layout="row"
                >
                    <Button color="neutral" onClick={() => {
                        navigate(-1)
                    }}>
                        취소
                    </Button>
                    <Button color="primary" disabled={!activeNextButton} onClick={handleNextButton}>
                        동의하기
                    </Button>
                </CTA>
            </Box>
            {showSuccessPopup && (
                <CommonPopup description={'POP서비스 가입했어.\nGS25에 휴대결제하고\n추가할인 받으세요.'} submitText={'확인'} onSubmit={() => {
                    navigate('/pop')
                }}/>)}
            {showFailPopup && (
                <CommonPopup description={'일시적인 오류로 POP서비스\n가입하지 못했어요.\n잠시 후 다시 시도해주세요.'} submitText={'확인'}
                             onSubmit={() => {
                                 navigate('/pop')
                             }}/>)}

            {/*<Postcode isOpen={showAddressPopup} onClose={() => setShowAddressPopup(false)}/>*/}
        </>
    )
}

export default PopInput