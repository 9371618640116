import React, {useEffect, useState} from 'react'
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import SectionInfoBox from "../../components/Setting/SectionInfoBox";
import {Box, Button, CTA, TextField} from '@lguuxe/2024_designsystem_uni';
import {settingApi} from "../../api/settingAndTerms";
import {initialUserInfo, UserInfo} from "./types";
import {getItemByKey} from "../../utils/indexedDB";
import {useNavigate} from "react-router-dom";
import AddressSection from "../Entire/pop/daum";
import CommonPopup from "../../components/Common/CommonPopup";
import { useSnackbar } from '../../provider/SnackbarProvider';

const UMembershipSettingMyinfo = () => {
    const [userInfo, setUserInfo] = useState<UserInfo>(initialUserInfo);
    const [activeSaveButton, setActiveSaveButton] = useState<boolean>(false);
    const [supportText, setSupportText] = useState<any>([]);
    const [userName, setUserName] = useState<string>("")
    const [showPopup, setShowPopup] = useState<boolean>(false)
    const { showSnackbar } = useSnackbar();
    const navigate = useNavigate()

    const [showAddressSection, setShowAddressSection] = useState<boolean>(false);
    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                const response = await settingApi.getMyInfo({}, 'v1');
                setUserInfo(response.body);
                console.log(response.body);
            } catch (error) {
                console.error("Error fetchAlarm detail:", error);
            }
        };
        const getUserName = async () => {
            const name = await getItemByKey("customerName")
            setUserName(name)
        }
        getUserName()
        // fetchUserInfo();
    }, []);

    useEffect(() => {
        if (validateAddress(userInfo) && validateEmail(userInfo.email)) {
            setActiveSaveButton(true);
        } else {
            setActiveSaveButton(false);
        }
        console.log(userInfo);
    }, [userInfo]);

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };
    const validateAddress = (userInfo: UserInfo): boolean => {
        return !(
            (userInfo.address1 == null || userInfo.address1.trim() === "") ||
            (userInfo.address2 == null || userInfo.address2.trim() === "") ||
            (userInfo.zipCode == null || userInfo.zipCode.trim() === "")
        );

    }
    const handleAddressDetailChange = (e: any) => {
        const address2 = e.value;
        setUserInfo(prevState => ({
            ...prevState,
            address2: address2
        }));
    }

    const handleEmailChange = (e: any) => {
        const email = e.value;
        setUserInfo(prevState => ({
            ...prevState,
            email: email
        }));
        if (validateEmail(email) || email.trim() === "") {
            setSupportText([]);
        } else {
            setSupportText([
                {
                    icon: 'error_circle_filled',
                    status: 'error',
                    text: '이메일 형식이 잘못되었습니다. 다시 확인해 주세요 .'
                }
            ]);
        }
    }

    const handleSave = async () => {
        try {
            const params = {
                email: userInfo.email,
                zipCode: userInfo.zipCode,
                address1: userInfo.address1,
                address2: userInfo.address2
            };
            const response = await settingApi.putMyInfo(params, 'v1');
            if (response.header.resultCode === "0000") {
                navigate('/setting');
                showSnackbar('내 정보를 수정했어요')
            }
        } catch (error) {
            console.error("정보 저장 중 오류가 발생했습니다:", error);
        }
    }

    return (
        <>
            <CommonLeftIconHeader title={"내 정보 수정"}/>
            <Box type="2_trbl">
                <TextField
                    fill="single"
                    inputType="text"
                    isAutoTab
                    label="이름"
                    maxLength={10}
                    disabled={true}
                    placeholder="홍길동"
                    status="default"
                    value={userName}
                >
                    버튼
                </TextField>
            </Box>
            <Box type="2_trbl">
                {!showAddressSection && <TextField
                    fill="single"
                    inputType="text"
                    isAutoTab
                    label="주소"
                    placeholder="주소를 검색해 주세요"
                    status="default"
                    value={userInfo.address1}
                    rightAddons={[
                        <Button
                            size='small'
                            color="primary"
                            onClick={() => setShowAddressSection(true)}
                        >
                            주소 검색
                        </Button>
                    ]}
                />}

                {showAddressSection &&
                    <AddressSection isOpen={showAddressSection} onClose={() => setShowAddressSection(false)}
                                    setAddress={setUserInfo}/>}

                <div style={{paddingTop: "12px"}}>
                    <TextField
                        fill="single"
                        inputType="text"
                        isAutoTab
                        placeholder="상세주소를 입력해 주세요"
                        status="default"
                        onChange={handleAddressDetailChange}
                    />
                </div>
            </Box>

            <div
                style={{
                    padding: "24px 20px 4px",
                }}
            >
                <TextField
                    fill="single"
                    inputType="email"
                    isAutoTab
                    label="이메일"
                    placeholder="example@lguplus.co.kr"
                    status="default"
                    supportText={supportText}
                    rightAddons={undefined}  // false는 의미가 없으므로 제거
                    onChange={handleEmailChange}
                >
                    버튼
                </TextField>
            </div>
            <div style={{
                padding: "28px 20px 0px 20px",
            }}>
                <SectionInfoBox/>
            </div>
            <div
                style={{
                    position: "fixed",
                    bottom: "0",
                    width: "100%"
                }}
            >
                <CTA
                    layout="row"
                >
                    <Button color="neutral" onClick={() => setShowPopup(true)}>
                        취소
                    </Button>
                    <Button color="primary" disabled={!activeSaveButton} onClick={handleSave}>
                        저장
                    </Button>
                </CTA>
                {showPopup && <CommonPopup description={"입력한 내용이 저장되지 않아요.\n수정하지 않고 나가시겠어요?"}
                                           submitText={"계속 수정"}
                                           onSubmit={() => setShowPopup(false)}
                                           cancelText={"나가기"}
                                           onClose={() => navigate(-1)}
                />}
            </div>
        </>
    )
}

export default UMembershipSettingMyinfo
