import {getItemByKey} from "../utils/indexedDB";

interface BridgeParameter<T = any> {
    type: string;
    param?: T;
}

declare global {
    interface Window {
        JSBridge: {
            sendEvent(parameter: string): string;
            sendEventReturn(parameter: string): BridgeParameter;
            onExecuteCallback(parameter: string): void;
        };
        webkit: {
            messageHandlers: {
                sendEvent: {
                    postMessage(parameter: string): string;
                };
                sendEventReturn: {
                    postMessage(parameter: string): BridgeParameter;
                };
                onExecuteCallback: {
                    postMessage(parameter: string): void;
                };
            };
        };
    }
}

export const sendEvent = async (key: string, param: any) => {
    const os = await getItemByKey('platform')
    if(os === "IOS") {
        console.log('call to IOS', key)
        sendEventToIOS(key, param)
    } else {
        console.log('call to Android', key)
        sendEventToAndroid(key, param)
    }
}

export const sendEventReturn = async (key: string, param: any) => {
    const os = await getItemByKey('platform')
    if(os === "IOS") {
        console.log('call to IOS', key)
        sendEventReturnToIOS(key, param)
    } else {
        console.log('call to Android', key)
        sendEventReturnToAndroid(key, param)
    }
}

export const sendEventToAndroid = (key: string, param: any) => {
    const parameter: BridgeParameter = {
        type: key,
        param: param,
    };
    const stringifiedObject = JSON.stringify(parameter);
    console.log(stringifiedObject);
    if (window.JSBridge && window.JSBridge.sendEvent) {
        window.JSBridge.sendEvent(stringifiedObject);
        return '(sendEventToAndroid) Android Bridge called!';
    } else {
        console.log('JSBridge is not ready!');
        return 'JSBridge is not ready!';
    }
};

export const sendEventReturnToAndroid = (key: string, param: any): any => {
    const parameter: BridgeParameter = {
        type: key,
        param: param,
    };
    if (window.JSBridge && window.JSBridge.sendEventReturn) {
        const stringifiedObject = JSON.stringify(parameter);
        console.log(stringifiedObject)
        window.JSBridge.sendEventReturn(stringifiedObject);
        return '(sendEventReturnToAndroid)Android Bridge called!';
    } else {
        console.log('JSBridge is not ready!');
        return null;
    }
};

export const sendEventToIOS = (key: string, param: any) => {
    const parameter: BridgeParameter = {
        type: key,
        param: param,
    };
    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.sendEvent) {
        const stringifiedObject = JSON.stringify(parameter);
        window.webkit.messageHandlers.sendEvent.postMessage(stringifiedObject);
        return 'iOS Bridge called!';
    } else {
        console.log('iOS Bridge is not ready');
        return 'iOS Bridge is not ready';
    }
};

export const sendEventReturnToIOS = (key: string, param: any): any => {
    const parameter: BridgeParameter = {
        type: key,
        param: param,
    };

    if (window.webkit && window.webkit.messageHandlers && window.webkit.messageHandlers.sendEventReturn) {
        const stringifiedObject = JSON.stringify(parameter);
        const res = window.webkit.messageHandlers.sendEventReturn.postMessage(stringifiedObject);
        console.log('iOS Bridge response:', res);
        return res.param;
    } else {
        console.log('iOS Bridge is not ready');
        return null;
    }
};
