import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Box, Button, CTA, Icon, Image, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import selfImg from '../../images/img/enter/authentication.svg';
import logoLGuplusImg from '../../images/main/benefit/logoLGuplus.svg';
import {sendEventReturn} from "../../api/bridgeApi";
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";

const STAGE_URL = 'https://dasteb.uplus.co.kr/dasweb/login/lgidLoginView.do?serviceCd=B09&returnUrl=das://callback';
const PROD_URL = 'https://das.lguplus.co.kr/dasweb/login/lgidLoginView.do?serviceCd=B09&returnUrl=das://callback'

const IdentityVerification: React.FC = () => {
    const navigate = useNavigate();
    const [showMyLgErrorPopup, setShowMyLgErrorPopup] = useState(false);

    // TODO: MyLgId 명의자와 휴대폰 명의자가 달라요 팝업
    // TODO: 단말 유심 통신사 불일치, 14세 미만
    // useEffect(() => {
    //     const fetchIndexedDb = async () => {
    //         const platform = await getItemByKey('platform');
    //         // if (platform === 'AOS') sendEventReturnToAndroid('GET_CTN_INFO', {});
    //     }
    //     fetchIndexedDb();
    //
    //
    // }, []);

    const handlePhoneVerification = () => {
        navigate('/phone-certification?target=web');
    };

    const handleMyLGIDLogin = () => {
        const params = {
            url: STAGE_URL,
        };
        sendEventReturn('CALL_LOGIN', params);
    }

    return (
        <>
            <CommonLeftIconHeader title={''}/>
            <Box
                type='1_trl'
                // style={{
                //     marginTop: '56px',
                // }}
            >
                <Image src={logoLGuplusImg} alt='placeholder' width='78px' height='22px'/>
            </Box>
            <Box type='3_trbl'>
                <TextSet
                    subComponent='Heading_2'
                    text={{
                        title: (
                            <>
                                <span>
                                    본인 인증 후 U+멤버십만의
                                    <br/>
                                    다양한 혜택을 즐겨보세요.
                                </span>
                            </>
                        ),
                    }}
                    weight='bolder'
                />
            </Box>
            <Box>
                <Image customClass='customClass' ratio='1.778:1' src={selfImg}/>
            </Box>
            <Box
                style={{
                    position: 'fixed',
                    bottom: '0',
                    width: '100%',
                    padding: '0 0 30px', // TODO: remove padding
                }}
            >
                <Box
                    style={{
                        backgroundColor: '#F9FAFB',
                        borderRadius: '12px',
                        padding: '16px 20px',
                        margin: '0 20px 4px',
                    }}
                >
                    <Box
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '6px',
                            padding: '0',
                        }}
                    >
                        <Icon color='#7f8a94' name='error_circle_filled' size='24px'/>
                        <TextSet
                            subComponent='Heading_6'
                            text={{
                                title: (
                                    <>
                                        <span style={{color: '#66707A'}}>꼭 확인하세요!</span>
                                    </>
                                ),
                            }}
                            weight='bolder'
                        />
                    </Box>
                    <ul
                        style={{
                            paddingLeft: '26px',
                            margin: '8px 0 0 0',
                            color: '#66707A',
                        }}
                    >
                        <li>
                            <Text typo='font_body_2M' color='color_text_neutral_3'>
                                만 14세 미만 개인 또는 법인 고객은 U+멤버십 휴대폰 인증을 선택해주세요.
                            </Text>
                        </li>
                        <li>
                            <Text typo='font_body_2M' color='color_text_neutral_3'>
                                My LG ID는 만 14 세 이상 개인 고객만 이용 가능합니다.
                            </Text>
                        </li>
                    </ul>
                </Box>
                <CTA layout='column'>
                    <Button color='primary' onClick={handlePhoneVerification}>
                        U+멤버십 휴대폰 인증하기
                    </Button>
                    <Button color='neutral' onClick={handleMyLGIDLogin}>MY LG ID 로그인하기</Button>
                </CTA>
            </Box>
            {/*{showMyLgErrorPopup && (*/}
            {/*    <CommonPopup description={'일시적으로 MY LG ID 인증을 할 수 없어요. \n휴대폰번호로 인증해 주세요.'} submitText={'휴대폰번호로 인증'}*/}
            {/*                 onSubmit={() => {*/}
            {/*                     navigate('/phone-certification')*/}
            {/*                 }} cancelText={'취소'} onClose={() => {*/}
            {/*        setShowMyLgErrorPopup(!showMyLgErrorPopup)*/}
            {/*    }}/>)}*/}
        </>
    );
};

export default IdentityVerification;
