import React from 'react';
import { Header, IconButton } from "@lguuxe/2024_designsystem_uni";

interface CommonHeaderProps {
    align: 'left' | 'center';
    icon: string;
    title?: string;
    divider?: boolean;
}

const CommonHeader: React.FC<CommonHeaderProps> = ({ align, icon, title, divider = false }) => {
    return (
        <>
            <Header
                align={align}
                divider={divider}
            >
                <Header.LeftSlot>
                    <IconButton
                        color="neutral"
                        fill="icon"
                        icon={icon}
                        noStates
                    />
                </Header.LeftSlot>
                {title}
            </Header>
        </>
    );
};

export default CommonHeader;