import React from 'react';
import {Header, IconButton, Text} from '@lguuxe/2024_designsystem_uni';
import {useNavigate} from 'react-router-dom';

interface CommonAllIconHeaderProps {
    title: string;
    isSearch?: boolean;
    isShare?: boolean;
}

const CommonAllIconHeader: React.FC<CommonAllIconHeaderProps> = ({title, isSearch = true, isShare = false}) => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    };

    const handleSearchClick = () => {
        navigate('/search');
    }

    const handleShareClick = () => {
        console.log('share');
    }

    return (
        <Header align='center' variant='heading'>
            <Header.LeftSlot>
                <IconButton color='neutral' fill='icon' icon='chevron_left_line' onClick={handleBackClick} noStates/>
            </Header.LeftSlot>
            <Header.CenterSlot>
                <Text typo='font_heading_4Sb'>{title}</Text>
            </Header.CenterSlot>
            <Header.RightSlot>
                {isSearch &&
                    <IconButton color='neutral' fill='icon' icon='search_line' onClick={handleSearchClick} noStates/>}
                {isShare &&
                    <IconButton color='neutral' fill='icon' icon='share_line' onClick={handleShareClick} noStates/>}
            </Header.RightSlot>
        </Header>
    );
};

export default CommonAllIconHeader;
