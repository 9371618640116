import React from "react";
import {Spinner, Text} from "@lguuxe/2024_designsystem_uni";

const BarcodeLoading = () => {
    return <>
        <div
            style={{
                position: 'absolute',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <div style={{zIndex: 2}}>
                <Spinner color="primary" fill="outline" size="medium"/>
            </div>
            <div style={{textAlign: 'center', zIndex: 2, marginTop: '1rem'}}>
                <Text typo='font_body_2M' color='#FFFFFF'>
                    불러오는 중..
                </Text>
            </div>
        </div>
    </>
};

export default BarcodeLoading;


