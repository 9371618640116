import {Button, Text} from "@lguuxe/2024_designsystem_uni";
import React, {useState} from "react";
import Snackbar from "../../Common/Snackbar";

interface NumberSectionProps {
    couponNumber: string;
}

const NumberSection: React.FC<NumberSectionProps> = ({couponNumber}) => {
    const [showSnackbar, setShowSnackbar] = useState(false);

    const handleClick = () => {
        navigator.clipboard
            .writeText(couponNumber)
            .then(() => {
                setShowSnackbar(true);
                setTimeout(() => {
                    setShowSnackbar(false);
                }, 1000);
            })
            .catch((err) => {
                console.error('복사 실패', err);
            });
    }

    return (<div
        style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "12px 20px 0",
            gap: "8px"
        }}
    >
        <div
            style={{
                borderRadius: "12px",
                width: "100%",
                boxSizing: "border-box",
                background: "#F3F5F6"
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "16px 20px"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column"
                    }}
                >
                    <Text typo="font_label_3Sb" color="color_text_neutral_3">
                        쿠폰번호
                    </Text>
                    <div style={{display: "flex", gap: "4px", alignItems: "center"}}>
                        <Text typo="font_body_1Sb" color="color_text_neutral_6">
                            {couponNumber}
                        </Text>
                        {/*<Text typo="font_body_1Sb" color="color_text_neutral_6">*/}
                        {/*    1234*/}
                        {/*</Text>*/}
                        {/*<Text typo="font_body_1Sb" color="color_text_neutral_6">*/}
                        {/*    5678*/}
                        {/*</Text>*/}
                        {/*<Text typo="font_body_1Sb" color="color_text_neutral_6">*/}
                        {/*    9012*/}
                        {/*</Text>*/}
                    </div>
                </div>
                <Button as="button" color="secondary" fill="solid" size="small" state="default" onClick={handleClick}>
                    복사
                </Button>
                {showSnackbar && <Snackbar text='바코드 번호가 복사되었습니다.'/>}
            </div>
        </div>
    </div>)
}

export default NumberSection