import React, {useEffect, useState} from "react";
import {TextSet} from "@lguuxe/2024_designsystem_uni";
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import HeadingCard from "../../components/Vipkok/HeadingCard";
import BoxHeadingUserGrade from "../../components/Vipkok/BoxHeadingUserGrade";
import BottomInfoBox from "../../components/Vipkok/BottomInfoBox";
import {History, VipStatus} from "./types";
import useLoading from "../../hooks/useLoading";
import {handlePromiseResult} from "../../api/api";
import {brazeEventApi} from "../../api/brazeBridge";
import {partnerApi} from "../../api/partnerAndAffiliate-service";
import {CategoryItem, TotalPartners} from "../Partner/types";
import {getItemByKey} from "../../utils/indexedDB";
import CategoryTabs from "../../components/Partner/CategoryTabs";
import PartnerList from "../../components/Partner/PartnerList";
import membershipsServiceApi from "../../api/memberships-service";


const initialHistory: History = {
    totalCnt: '',
    pointUseList: [],
};

const initPartners: TotalPartners = {
    totalCount: '0',
    partnerList: [],
};

const VipKok: React.FC = () => {
    const [history, setHistory] = useState<History>(initialHistory);
    const [categoryList, setCategoryList] = useState<CategoryItem[]>([]);
    const [partners, setPartners] = useState<TotalPartners>(initPartners);
    const [initialCategoryId, setInitialCategoryId] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [useVIPkok, setUseVIPkok] = useState<string>();
    const [cardLevel, setCardLevel] = useState<string>();
    const [userStatus, setUserStatus] = useState<string>();
    useEffect(() => {
        const fetchData = async () => {
            const currentYear = new Date().getFullYear();
            let list;
            try {
                setCardLevel(await getItemByKey('cardLevel'))
                setUseVIPkok(await getItemByKey('useVipkokYn'))
                setUserStatus(await getItemByKey('memberStatus'))
                const [history, categoryList, partners] = await Promise.allSettled([
                    membershipsServiceApi.vipKok.getUseHistory({year: currentYear}, 'v1'),
                    partnerApi.getCategories('v1'),
                    partnerApi.getPartners({}, 'v1'),
                ]);
                setHistory(handlePromiseResult(history));
                list = handlePromiseResult(categoryList).categoryList

                setCategoryList(list)
                setPartners(handlePromiseResult(partners))

            } catch (error) {
                console.error('An unexpected error occurred:', error);
            } finally {
                setIsLoading(false); // 로딩 상태 해제
                await handleCategoryChange(list[0].categoryId)
            }
        };
        fetchData();
        brazeEventApi.vipKok_info_enter();
    }, []);

    const getVipStatus = () => {
        switch (userStatus) {
            case "0":
                return VipStatus.NOT_LOGGED_IN
            case "1":
                return useVIPkok === "Y" ? VipStatus.VIP_USED : VipStatus.VIP_NOT_USED
            case "2":
                return VipStatus.EXCELLENT
            case "3":
                return useVIPkok === "Y" ? VipStatus.VIP_USED : VipStatus.VIP_NOT_USED
            case "4":
                return VipStatus.EXCELLENT
            case "5":
                return VipStatus.GENERAL
            default:
                return VipStatus.GENERAL
        }
    }
    const handleCategoryChange = async (categoryId: string) => {
        const response = await partnerApi.getPartners({cateDepth1: categoryId, vipKockYn: "Y"}, 'v1')
        setPartners(response.body)
    };

    return (
        <>
            {useLoading(isLoading)}
            <CommonLeftIconHeader title={"VIP콕"}/>

            <TextSet
                subComponent="Heading_2"
                weight="bolder"
                text={{
                    title: (
                        <>
                            <span className="qaz">
                                <span style={{color: "#E6007E"}}>VVIP/VIP</span> 고객을 위한 <br/>
                                <span style={{display: 'inline-block'}}>특별한 혜택!</span>
                            </span>
                        </>
                    ),
                    description: (
                        <span>
                            내가 선택한 제휴사에서 더 큰 할인 혜택을 받아보세요.<br/>매달 1회 혜택을 받을 수 있어요.
                        </span>
                    ),

                }}
                blockSpacing={{top: "24px", bottom: "12px"}}
                isIndent={true}
            />

            {/* 내 VIP콕 */}
            <TextSet
                subComponent="Heading_4"
                text={{
                    title: (
                        <>
                            <span className="qaz">내 VIP콕</span>
                        </>
                    ),
                }}
                blockSpacing={{top: "24px", bottom: "12px"}}
                isIndent={true}
            />
            <BoxHeadingUserGrade status={getVipStatus()}/>

            {/* 최근 사용 */}
            <HeadingCard history={history}/>

            {/* 4월의 VIP 콕 혜택 */}
            <CategoryTabs title='9월의 VIP콕' itemList={categoryList} onItemChange={handleCategoryChange}/>
            <PartnerList partners={partners} type={"VIP"}/>
            {/*하단 Infobox*/}
            <BottomInfoBox/>
        </>
    );
};

export default VipKok;
