import {Button, Checkbox, Icon, Text} from "@lguuxe/2024_designsystem_uni";
import BS_List from "../Common/BS_List";
import React, {useEffect, useState} from "react";
import {SortItem} from "../../pages/Partner/types";

interface SortFilterProps {
    totalCount: string;
    sortList: SortItem[];
    onSortChange: (sortTypeId: string, isUsable: boolean) => void;
    isUsableCheckBox?: boolean;
}

const SortFilter: React.FC<SortFilterProps> = ({totalCount, sortList, onSortChange, isUsableCheckBox = true}) => {
    const [selectedSort, setSelectedSort] = useState<SortItem>({id: '0', label: ''}); // '인기순'으로 초기화
    const [isUsable, setIsUsable] = useState<boolean>(false);
    const [showSortBS, setShowSortBS] = useState(false); // BS 리스트의 표시 여부

    useEffect(() => {
        console.log('SortFilter useEffect:', sortList);
        if (sortList.length > 0) setSelectedSort(sortList[0]);
    }, []);

    const handleSortChange = (selectedId: string) => {
        const selectedSortOption = sortList.find(item => item.id === selectedId);
        console.log('SortFilter handleSortChange:', selectedSortOption);
        if (selectedSortOption) {
            setSelectedSort(selectedSortOption); // 선택한 정렬 옵션으로 상태 업데이트
            onSortChange(selectedSortOption.id, isUsable);
        }
        setShowSortBS(false); // BS 리스트를 닫음
    };

    const handleUsableChange = () => {
        console.log('SortFilter handleUsableChange:', isUsable);
        setIsUsable(!isUsable);
        onSortChange(selectedSort.id, !isUsable);
    }
    return (
        <>
            <div style={{
                paddingTop: '12px',
                paddingLeft: '20px',
                paddingRight: '20px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}>
                <Text typo='font_heading_5B' color='color_text_neutral_6'>
                    총 {totalCount}개
                </Text>
                {isUsableCheckBox && (<div>
                    <Checkbox
                        falseValue='falseValue'
                        trueValue='trueValue'
                        label='내가 쓸 수 있는'
                        onChange={handleUsableChange}
                        size='medium'
                        value='value'
                        weight='default'
                        width='auto'
                    />
                </div>)}

                <div style={{width: 'auto'}}>
                    <Button fill="ghost" size="small" color="secondary" onClick={() => setShowSortBS(!showSortBS)}>
                        <Text as="span" textAlign="center">
                            {selectedSort.label}
                        </Text>
                        <Icon
                            name='chevron_down_small_line'
                            // size='24px'
                            color='#7f8a94'
                        />
                    </Button>
                </div>
            </div>
            {showSortBS && (
                <BS_List
                    title={'정렬'}
                    dataList={sortList}
                    onClose={() => setShowSortBS(false)}
                    onSelected={handleSortChange}
                    checkedItem={selectedSort.id}
                />
            )}
        </>
    );
}

export default SortFilter;