import React, {useEffect, useState} from "react";
import { Tab, Box } from "@lguuxe/2024_designsystem_uni";
import CommonLeftIconHeader from "../../components/Common/CommonLeftIconHeader";
import MembershipInfoTab from "../../components/BenefitGuide/Tab/MembershipInfoTab";
import MembershipUsageTab from "../../components/BenefitGuide/Tab/MembershipUsageTab";
import MobilePaymentTab from "../../components/BenefitGuide/Tab/MobilePatmentTab";
import MoneyMeTab from "../../components/BenefitGuide/Tab/MoneyMeTab";
import YouthTab from "../../components/BenefitGuide/Tab/YouthTab";
import LongTermCustomerTab from "../../components/BenefitGuide/Tab/LongTermCustomerTab";
import MembershipBenefitTab from "../../components/BenefitGuide/Tab/MemeberShipBenefitTab";
import MissionTab from "../../components/BenefitGuide/Tab/MissionTab";
import { useParams } from "react-router-dom";

const BenefitGuide: React.FC = () => {
    const { id } = useParams<{ id: string }>(); // id 가져오기
    const [mainTab, setMainTab] = useState<string>("1");
    const [subTab, setSubTab] = useState<string>("1");

    useEffect(() => {
        if (id) {
            const [main, sub] = id.split(""); // id 분할
            setMainTab(main);
            setSubTab(sub);
        }
    }, [id, mainTab, subTab]);

    const handleMainTabChange = (id: string) => {
        setMainTab(id);
        setSubTab("1");
    };

    const handleSubTabChange = (id: string) => {
        setSubTab(id);
    };

    return (
        <Box type="1_b" style={{ width: "100%" }}>
            <CommonLeftIconHeader title={"혜택 가이드"} />

            <Box type="1_b">
                <Tab
                    items={[
                        { id: "1", label: "U+멤버십 소개" },
                        { id: "2", label: "이용 방법" },
                    ]}
                    layout="fullWidth"
                    selectedItemId={mainTab}
                    onItemSelected={handleMainTabChange}
                    style={{ padding: "0 20px" }}
                />

                {mainTab === "1" && (
                    <Box type="4_tb" style={{ paddingTop: "16px", paddingBottom: "12px", display: "flex", gap: "6px" }}>
                        <Tab
                            fill="solid"
                            items={[
                                { id: "1", label: "등급 종류" },
                                { id: "2", label: "U+멤버십 혜택" },
                                { id: "3", label: "장기고객 혜택" },
                                { id: "4", label: "유쓰" },
                            ]}
                            layout="fitContent"
                            size="small"
                            selectedItemId={subTab}
                            onItemSelected={handleSubTabChange}
                        />
                    </Box>
                )}

                {mainTab === "2" && (
                    <Box type="4_tb" style={{ paddingTop: "16px", paddingBottom: "12px", display: "flex", gap: "6px" }}>
                        <Tab
                            fill="solid"
                            items={[
                                { id: "1", label: "U+멤버십" },
                                { id: "2", label: "휴대폰결제" },
                                { id: "3", label: "미션" },
                                { id: "4", label: "머니 Me" },
                            ]}
                            layout="fitContent"
                            size="small"
                            selectedItemId={subTab}
                            onItemSelected={handleSubTabChange}
                        />
                    </Box>
                )}

                {mainTab === "1" && subTab === "1" && <MembershipInfoTab />}
                {mainTab === "1" && subTab === "2" && <MembershipBenefitTab />}
                {mainTab === "1" && subTab === "3" && <LongTermCustomerTab />}
                {mainTab === "1" && subTab === "4" && <YouthTab />}
                {mainTab === "2" && subTab === "1" && <MembershipUsageTab />}
                {mainTab === "2" && subTab === "2" && <MobilePaymentTab />}
                {mainTab === "2" && subTab === "3" && <MissionTab />}
                {mainTab === "2" && subTab === "4" && <MoneyMeTab />}
            </Box>
        </Box>
    );
};

export default BenefitGuide;
