import React, { useEffect, useState } from 'react';
import { Box, Segment } from '@lguuxe/2024_designsystem_uni';
import { Nationality } from '../../pages/IdentityVerification/types';

interface DomesticForeignerButtonProps {
    onChange: (value: Nationality) => void;
    resetSignal: number;
}

const DomesticForeignerButton: React.FC<DomesticForeignerButtonProps> = ({ onChange, resetSignal }) => {
    const [nationality, setNationality] = useState<Nationality>('내국인'); // '내국인' or '외국인'

    useEffect(() => {
        onChange(nationality);
    }, [nationality]);

    useEffect(() => {
        setNationality('내국인');
    }, [resetSignal]);

    const handleSegmentClick = (event: any) => {
        const newNationality = event.index === 0 ? '내국인' : '외국인';
        setNationality(newNationality);
    };

    return (
        <Box type='2_trbl'>
            <Segment
                buttons={[
                    {
                        disabled: false,
                        label: '내국인',
                        selected: nationality === '내국인',
                    },
                    {
                        disabled: false,
                        label: '외국인',
                        selected: nationality === '외국인',
                    },
                ]}
                label='내국인/외국인'
                onClick={handleSegmentClick}
                size='medium'
                status='default'
            />
        </Box>
    );
};

export default DomesticForeignerButton;
