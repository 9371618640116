import {api, ApiEndpoints} from './api';
import {ApiResponse} from './types';

const authApi = {
    postAuthConfirm: (body: Record<string, any>, version: string, mcpTrKey: string): Promise<ApiResponse> =>
        api.post(ApiEndpoints.AUTH.SMS, body, version, mcpTrKey),
    // postSmsResend: (body: Record<string, any>, version: string): Promise<ApiResponse> =>
    //     api.post(ApiEndpoints.AUTH.SMS_RESEND, body, version),
    postPhoneConfirm: (body: Record<string, any>, version: string, mcpTrKey: string): Promise<ApiResponse> =>
        api.post(ApiEndpoints.AUTH.CONFIRM, body, version, mcpTrKey), //
    postParentCheck: (body: Record<string, any>, version: string, mcpTrKey: string): Promise<ApiResponse> =>
        api.post(ApiEndpoints.AUTH.PARENT_CHECK, body, version, mcpTrKey),
};

export {authApi};
