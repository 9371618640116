import React from "react";
import { Text, List, Box, IconButton } from "@lguuxe/2024_designsystem_uni";
import BoxHeaderInfo from "./BoxHeaderInfo";
import {sendEvent} from "../../api/bridgeApi";

type CallCenterProps = {
    phone: string;
    homepage: string;
    name: string;
};

const CallCenter: React.FC<CallCenterProps> = ({ phone, homepage , name}) => {
    return (
        <div>
            <BoxHeaderInfo title="문의" />
            <Box style={{ padding: "0 20px 48px" }} type="1_rbl">
                <div style={{ padding: "0", background: "#F3F5F6", borderRadius: "12px" }}>
                    <List
                        type="view"
                        fill="none"
                        items={[
                            {
                                onClick: () => {
                                    sendEvent('CALL_WEBVIEW', {
                                        url: homepage,
                                        title: name,
                                        showTitlebar: true
                                    })
                                },
                                leftSlot: (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Text typo="font_body_2M" color="color_text_neutral_6">
                                            홈페이지
                                        </Text>
                                    </div>
                                ),
                                rightSlot: (
                                    <>
                                        <IconButton
                                            color="neutral"
                                            fill="icon"
                                            icon="chevron_right_small_line"
                                            noStates
                                        />
                                        <Text typo="font_label_2Sb" color="color_text_cneutral_3">
                                            {homepage}
                                        </Text>
                                    </>
                                )
                            }
                        ]}
                    />
                    {/* 전화번호 표시 */}
                    <List
                        type="view"
                        fill="none"
                        items={[
                            {
                                onClick: () => {
                                    window.location.href = `tel:+82${phone}`;
                                },
                                leftSlot: (
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <Text typo="font_body_2M" color="color_text_neutral_6">
                                            고객센터
                                        </Text>
                                    </div>
                                ),
                                rightSlot: (
                                    <>

                                        <IconButton
                                            color="neutral"
                                            fill="icon"
                                            icon="chevron_right_small_line"
                                            noStates
                                        />
                                        <Text typo="font_label_2Sb" color="color_text_cneutral_6">
                                            {phone}
                                        </Text>
                                    </>
                                )
                            }
                        ]}
                    />
                </div>
            </Box>
        </div>
    );
};

export default CallCenter;
