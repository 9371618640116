import TagManager from 'react-gtm-module'
import {getItemByKey} from "../utils/indexedDB";

interface GtmArgs {
    clickLocation: string,
    clickText: string,
    isInner?: boolean,
    url?: string,
    eventId?: string,
    eventName?: string,
    eventPeriod?: string,
    ptnId?: string,
    ptnName?: string,
    ptnSector?: string,
}

export const gtmDataLayer = ({
                                 clickLocation,
                                 clickText,
                                 isInner = true,
                                 url,
                                 eventId,
                                 eventName,
                                 eventPeriod,
                                 ptnId,
                                 ptnName,
                                 ptnSector
                             }: GtmArgs) => {
    const tagManagerArgs = {
        dataLayer: {
            'data-gtm-event-name': 'all_click',
            'data-gtm-event-category': '클릭',
            'data-gtm-event-action': clickLocation + ' - 링크 클릭',
            'data-gtm-event-label': '컨텐츠 : ' + clickText,
            'data-gtm-click-url': url,
            'data-gtm-click-location': clickLocation,
            'data-gtm-module-name': clickLocation,
            'data-gtm-click-direction': isInner ? '내부' : '외부',
            'data-gtm-click-text': clickText,
            'data-gtm-mkt-event-id': eventId,
            'data-gtm-mkt-event-name': eventName,
            'data-gtm-mkt_event_period': eventPeriod,
            'data-gtm-affiliate-id': ptnId,
            'data-gtm-affiliate-name': ptnName,
            'data-gtm-affiliate-sector': ptnSector,
        }
    }

    TagManager.dataLayer(tagManagerArgs)
}


export const gtmPageLoad = ({contentGroup, memberStatus, moduleName, pageTitle}: any) => {
    const tagManagerArgs = {
        dataLayer: {
            event: 'spaPageLoaded',
            pageUrl: '',
            content_group: '',  // 14세이상_VIP | 14세미만_VIP | 14세미만_우수 ...
            module_name: '',
            page_title: '',
        }
    }

    TagManager.dataLayer(tagManagerArgs)
    console.log('gtmPageLoad : tagManagerArgs : ', tagManagerArgs)
}


export const gtmUserInfo = async () => {

    const getAgeGroup = (birthYear: number) => {
        const currentYear = new Date().getFullYear()
        const age = currentYear - birthYear

        if (age < 10) return 'Under 10';
        if (age >= 10 && age < 20) return '10s';
        if (age >= 20 && age < 30) return '20s';
        if (age >= 30 && age < 40) return '30s';
        if (age >= 40 && age < 50) return '40s';
        if (age >= 50 && age < 60) return '50s';
        if (age >= 60 && age < 70) return '60s';
        if (age >= 70 && age < 80) return '70s';
        if (age >= 80 && age < 90) return '80s';
        if (age >= 90 && age < 100) return '90s';
        return 'Over 100';
    }

    const getMemberType = (userGrade: string) => {
        // TODO: memberStatus에 따라서 return 값 변경
    }

    const tagManagerArgs = {
        digitalData: {
            userInfo: {
                user_id: await getItemByKey('memberId'),
                member_type: 'placeHolder',
                gender: await getItemByKey('gender'),
                age: getAgeGroup(Number(await getItemByKey('birthday'))),
                user_address: '',
                marketing_agreement: await getItemByKey('maketingInfoYn'),
                login_type: '',
                own_third_type: await getItemByKey('telcoCode') === 'L' ? '자사' : '타사',
                geo_service_agreement: 'placeHolder', //TODO: 인덱스 DB에 추가 필요
                ad_agreement_app: '',
                user_mykok_type: 'placeHolder', // 구독/라이프/영화... TODO: 인덱스 DB에 추가 필요
                entry_type: '', // 잠금화면/APPPush/앱테크
                member_loyalty: 'placeHolder', // TODO: 인덱스 DB에 추가 필요
                member_uth: 'placeHolder',
            },
        }
    }

    TagManager.dataLayer(tagManagerArgs)
}
