import {Button} from "@lguuxe/2024_designsystem_uni"

interface ButtonSectionProps {
    onClick: () => void
}

const ButtonSection: React.FC<ButtonSectionProps> = ({onClick}) => {
    return (
        <div
            style={{
                display: "flex",
                gap: "12px",
                flexDirection: "column",
                padding: "0",
                width: "100%"
            }}
            // type="1_trbl"
        >
            {/* <div
            style={{
              display: "flex",
              gap: "12px",
              justifyContent: "center",
              padding: "4px 20px 0",
              alignSelf: "stretch"
            }}
          >
            <InfoBox />
          </div> */}

            <div
                style={{
                    display: "flex",
                    gap: "12px",
                    justifyContent: "center",
                    padding: "4px 20px 0"
                }}
            >
                <Button
                    color="secondary"
                    fill="solid"
                    style={{
                        display: "flex",
                        flex: "1"
                    }}
                    onClick={onClick}
                >
                    직원 확인 비밀번호 입력
                </Button>
            </div>
        </div>
    )
}

export default ButtonSection