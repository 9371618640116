import React, {useState} from 'react';
import {List} from "@lguuxe/2024_designsystem_uni";
import {BenefitPtn, LongTerm, VipPtn} from "../../pages/UPTP/types";
import ExImg from "../../images/default/default thumbnail.svg";
import {useNavigate} from "react-router-dom";
import {sendEvent, sendEventReturn} from "../../api/bridgeApi"; // 기본 이미지

interface SectionHeadingTabListProps {
    items: Array<BenefitPtn | VipPtn | LongTerm>;
    selectedTab: string;
}

const SectionHeadingTabList: React.FC<SectionHeadingTabListProps> = ({items, selectedTab}) => {
    const [imageErrorIndexes, setImageErrorIndexes] = useState<Set<number>>(new Set());
    const navigate = useNavigate()
    console.log(items)
    console.log(selectedTab)
    const handleClick = (item: BenefitPtn | VipPtn | LongTerm) => {
        if (selectedTab === '1') {
            if ('ptnName' in item) {
                navigate(`/partners/${item.ptnId}`);
            }
        } else if (selectedTab === '2') {
            if ('ptnName' in item) {
                navigate(`/vip-kok-detail/${item.ptnId}`);
            }
        } else if (selectedTab === '3') {
            if ('serviceLink' in item && item.serviceLink) { // LongTerm 타입인지와 serviceLink 존재 여부 확인
                sendEvent('EXTERNAL_CALL', {
                    type:'1',
                    url: item.serviceLink
                });
            }
        }
    };

    return (
        <>
            <List
                fill="none"
                items={items.map((item, index) => ({
                    image: imageErrorIndexes.has(index) ? ExImg : ('ptnLogoImgUrl' in item ? item.ptnLogoImgUrl : item.serviceImgUrl),
                    text: {
                        eyebrow: 'ptnName' in item ? item.ptnName : item.serviceName,
                        title: item.benefitSummary,
                    },
                    linkIconOnlyId: "empty",
                    onClick: () => handleClick(item)
                }))}
                name="list_name"
                size="medium"
                type="view"
                weight="bolder"
            />
        </>
    );
}

export default SectionHeadingTabList;
