export type Company = {
    name: string;
    logo: string;
    summary: string;
};

export type category = {
    count: string,
    categoryId: string,
    categoryName: string
}

export type MyBadgeData = {
    badgeCount: string,
    sendableCount: string,
    categoryList: category[]
}

export const initialMyBadge: MyBadgeData = {
    badgeCount: '',
    sendableCount: '',
    categoryList: []
}