import React, { useState } from 'react';
import { List, Image, Button, TextSet } from "@lguuxe/2024_designsystem_uni";
import upltwoplIcon from "../../images/icon/1_grade/membership_48=vvip.svg";
import upltwoplIcon2 from "../../images/icon/1_grade/membership_48=vip.svg";
import upltwoplIcon3 from "../../images/icon/1_grade/membership_48=excellent.svg";
import upltwoplIcon4 from "../../images/icon/1_grade/membership_48=general.svg";
import Snackbar from "../Common/Snackbar";
import CouponItem from "./CouponItem";

// SectionListProps 인터페이스 추가
interface SectionListProps {
    cpnInfoList: {
        joinNoCode: string;
        cpnId: string;
        cpnName: string;
        expStartDate: string;
        expEndDate: string;
        memLevel: string;
        longMemLevel: string;
    }[];
}

// SectionList 컴포넌트 수정
const SectionList: React.FC<SectionListProps> = ({ cpnInfoList }) => {
    const [showSnackbar, setShowSnackbar] = useState(false);

    const handleCoupon = () => {
        setShowSnackbar(true);
        setTimeout(() => {
            setShowSnackbar(false);
        }, 1000);
    };

    // 등급에 따른 아이콘 매핑
    const getIconByMemLevel = (memLevel: string) => {
        switch (memLevel) {
            case '150001':
                return upltwoplIcon;
            case '150002':
                return upltwoplIcon2;
            case '150003':
                return upltwoplIcon3;
            default:
                return upltwoplIcon4;
        }
    };

    // cpnInfoList를 쿠폰 항목으로 변환
    const items = cpnInfoList.map((coupon, index) =>
        CouponItem({
            icon: getIconByMemLevel(coupon.memLevel.split(',')[0]), // 첫 번째 memLevel 기준으로 아이콘 결정
            eyebrow: coupon.cpnName,
            title: `쿠폰 ${index + 1}`,
            buttonText: "쿠폰받기",
            buttonOnClick: handleCoupon,
        })
    );

    return (
        <>
            {/*<List fill="none" items={items} type="view" />*/}
            {showSnackbar && (
                <Snackbar
                    text="쿠폰이 발급되었어요"
                    buttonText="쿠폰함 바로가기"
                    buttonLinkUrl="www.naver.com"
                />
            )}
        </>
    );
};

export default SectionList;
