import React, {useEffect, useState} from 'react';
import {BottomNavigation, Image, Text} from '@lguuxe/2024_designsystem_uni';
import {useNavigate} from 'react-router-dom';
import barcodeLineIcon from '../../images/main/benefit/barcode_line.svg';
import BS_Barcode from '../Barcode/BS_Barcode';
import {getItemByKey} from "../../utils/indexedDB";
import CommonPopup from "./CommonPopup";
import {brazeEventApi} from "../../api/brazeBridge";
import CommonLoginPopup from "./CommonLoginPopup";

interface NavigationBarProps {
    barcodeClose?: () => void;
    selectedItem?: 'home' | 'mission' | 'my' | 'entire';
}

const NavigationBar: React.FC<NavigationBarProps> = ({
                                                         barcodeClose,
                                                         selectedItem = 'home',
                                                     }) => {
    const [isBarcodeOpen, setIsBarcodeOpen] = useState(false);
    const [isUnder14PopupOpen, setIsUnder14PopupOpen] = useState(false);
    const [memberStatus, setMemberStatus] = useState('');
    const [memberId, setMemberId] = useState('');
    const [showLoginPopup, setShowLoginPopup] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            setMemberStatus(await getItemByKey('memberStatus'));
            setMemberId(await getItemByKey('memberId'));
        };

        fetchData();
    }, []);

    const handleIconClick = (id: string) => {
        switch (id) {
            case 'home':
                brazeEventApi.alltap_menu_click('홈');
                window.scrollTo(0, 0);
                navigate('/');
                break;
            case 'mission':
                brazeEventApi.alltap_menu_click('미션');
                if (!memberId) { //미로그인시 본인인증 팝업
                    setShowLoginPopup(true);
                    break;
                }
                if (memberStatus == '48') { //14세 미만 접근시 접근 불가 팝업
                    setIsUnder14PopupOpen(true);
                    break;
                }
                window.scrollTo(0, 0);
                navigate('/mission');

                break;
            case 'my':
                brazeEventApi.alltap_menu_click('마이');
                if (!memberId) { //미로그인시 본인인증 팝업
                    setShowLoginPopup(true);
                    break;
                }
                window.scrollTo(0, 0);
                navigate('/my');
                break;
            case 'entire':
                brazeEventApi.alltap_menu_click('전체');
                window.scrollTo(0, 0);
                navigate('/entire');
                break;
            default:
                break;
        }
    };
    const handleBarcodeClick = () => {
        console.log('handleBarcodeClick ------ : ', memberId);
        if (!memberId) { //미로그인시 본인인증 팝업
            setShowLoginPopup(true);
            return;
        }

        if (barcodeClose) {
            barcodeClose();
        } else {
            if (isBarcodeOpen) brazeEventApi.home_barcode_floating_close()
            setIsBarcodeOpen(!isBarcodeOpen);

        }
    };

    const handleBarcodeClose = () => {
        setIsBarcodeOpen(false);
        brazeEventApi.home_barcode_floating_close();
    };

    return (
        <>
            <BS_Barcode isOpen={isBarcodeOpen} onClose={handleBarcodeClose}/>
            <div
                style={{
                    position: 'fixed',
                    bottom: '0',
                    width: '100%',
                    zIndex: 1000,
                }}
            >

                <BottomNavigation
                    items={[
                        {
                            icon: 'home_line',
                            id: 'home',
                            label: '홈',
                        },
                        {
                            icon: 'flag_line',
                            id: 'mission',
                            label: '미션',
                        },
                        {
                            icon: 'person_filled',
                            id: 'my',
                            label: '마이',
                        },
                        {
                            icon: 'menu_line',
                            id: 'entire',
                            label: '전체',
                        },
                    ]}
                    selectedItemId={selectedItem}
                    onItemSelected={handleIconClick}
                    data-gtm-event-name={'all_click'}
                    data-gtm-event-category={'클릭'}
                    data-gtm-event-action={'GNB - 링크 클릭'}
                    data-gtm-event-label={'컨텐츠 : ' + (selectedItem === 'home' ? '홈' : selectedItem === 'mission' ? '미션' : selectedItem === 'my' ? '마이' : '전체')}
                    data-gtm-click-url={''}
                    data-gtm-click-location={'GNB'}
                    data-gtm-click-direction={'내부'}
                    data-gtm-click-text={selectedItem === 'home' ? '홈' : selectedItem === 'mission' ? '미션' : selectedItem === 'my' ? '마이' : '전체'}
                >
                    <BottomNavigation.centerSlot>
                        <div
                            style={{
                                alignItems: 'center',
                                bottom: '1rem',
                                display: 'flex',
                                justifyContent: 'center',
                                position: 'relative',
                                width: '100%',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '2px',
                                    width: '56px',
                                    height: '56px',
                                    borderRadius: '50%',
                                    border: '1px solid #F3F5F6',
                                    background: '#FFF',
                                }}
                                onClick={handleBarcodeClick}
                                data-gtm-event-name={'all_click'}
                                data-gtm-event-category={'클릭'}
                                data-gtm-event-action={'GNB - 링크 클릭'}
                                data-gtm-event-label={'컨텐츠 : 바코드'}
                                data-gtm-click-url={''}
                                data-gtm-click-location={'GNB'}
                                data-gtm-click-direction={'내부'}
                                data-gtm-click-text={'바코드'}
                            >
                                <Image src={barcodeLineIcon} alt='placeholder' width='24px' ratio='1:1'/>
                                <Text typo='font_label_4Sb' color='color_text_neutral_2'>
                                    바코드
                                </Text>
                            </div>
                            {/* <FAB icon="barcode_line" label="바코드" style={{ backgroundColor: "#fff" }} /> */}
                        </div>
                    </BottomNavigation.centerSlot>
                </BottomNavigation>
            </div>

            <CommonLoginPopup isOpen={showLoginPopup} onClose={() => setShowLoginPopup(false)}/>

            {isUnder14PopupOpen && (<CommonPopup submitText='확인' description='만 14세 이상 고객이 이용할 수 있어요.'
                                                 onSubmit={() => {
                                                     setIsUnder14PopupOpen(false)
                                                 }}/>)}
        </>
    );
};

export default NavigationBar;
