import React from 'react';
import { Toggle, Box, TextSet, List } from "@lguuxe/2024_designsystem_uni";

const SectionHeadingMembershipDiscount: React.FC = () => {
    return (
        <div
            style={{
                paddingBottom: "12px",
            }}
        >
            <Box type="2_trbl">
                <TextSet
                    subComponent="Heading_4"
                    text={{
                        title: 'U+멤버십 설정',
                    }}
                    weight="bolder"
                />
            </Box>
            <List
                fill="none"
                type="view"
                items={[
                    {
                        leftSlot: [
                            <TextSet
                                key="general-payment"
                                subComponent="Body_1"
                                text={{
                                    title: 'U+멤버십 할인 자동 적용',
                                    description: 'U+ 멤버십 제휴 매장에서 결제할 때 U+멤버십 카드를 보여주지 않아도 할인이 자동으로 적용돼요.'
                                }}
                            />,
                        ],
                        rightSlot: [
                            <Toggle
                                key="general-payment-toggle"
                                falseValue="falseValue"
                                onChange={() => {}}
                                size="medium"
                                trueValue="trueValue"
                                value="value"
                                weight="default"
                                width="auto"
                            />,
                        ],
                    },
                ]}
            />
        </div>
    );
}

export default SectionHeadingMembershipDiscount;
