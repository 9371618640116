import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header, IconButton } from '@lguuxe/2024_designsystem_uni';

const CommonHeaderNoTitle: FC = () => {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    };

    return (
        <Header align='left'>
            <Header.LeftSlot>
                <IconButton color='neutral' fill='icon' icon='chevron_left_line' noStates onClick={handleBackClick} />
            </Header.LeftSlot>
        </Header>
    );
};

export default CommonHeaderNoTitle;
