import React from 'react';
import {TextSet} from '@lguuxe/2024_designsystem_uni';
import CommonCarousel from '../Common/CommonCarousel';
import {RecommendPartner} from "../../pages/My/types";

interface RecommendPartnersProps {
    recommendList: RecommendPartner[];
}

const RecommendPartners: React.FC<RecommendPartnersProps> = ({recommendList}) => {
    return (
        <div
            style={{
                display: 'flex',
                gap: '0px',
                alignItems: 'center',
                flexDirection: 'column',
                padding: '0',
            }}
        >
            <div
                style={{
                    width: '360px',
                    backgroundColor: '#FFFFFF',
                }}
            >
                <TextSet
                    subComponent='Heading_4'
                    text={{
                        title: '[제휴사]에 관심있는 고객님을 위한 추천',
                        // description: "등급별 혜택정보 설명 설명 설명 설명 설명 설명 등급별 혜택정보 설명 설명 설명 설명 설명 설명"
                    }}
                    blockSpacing={{top: '24px', bottom: '12px'}}
                    isIndent={true}
                />
            </div>

            <CommonCarousel carouselItemList={recommendList.map(item => {
                return {id: item.ptnId, name: item.ptnName, imgUrl: item.ptnLogoImgUrl, content: item.benefitSummary}
            })}/>

            {/*<div*/}
            {/*    style={{*/}
            {/*        textAlign: 'center',*/}
            {/*        borderTop: '1px solid #E7EBEE',*/}
            {/*        marginTop: '12px',*/}
            {/*        width: '100%',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <Button*/}
            {/*        color='neutral'*/}
            {/*        rightIcon='chevron_right_small_line'*/}
            {/*        size='large'*/}
            {/*        fill='text'*/}
            {/*        style={{*/}
            {/*            padding: '14px 0',*/}
            {/*            width: '100%',*/}
            {/*        }}*/}
            {/*    >*/}
            {/*        더보기*/}
            {/*    </Button>*/}
            {/*</div>*/}
        </div>
    );
};

export default RecommendPartners;
