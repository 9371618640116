import React from 'react';
import { Image, Text, Icon } from '@lguuxe/2024_designsystem_uni';


interface GuideProps {
    title: string;
    icon: string;
    onClick: () => void;
}

const GuideSection:React.FC<GuideProps> = ({title, icon, onClick}) => {

    return (
        <div style={{ padding: '20px' }}>
            <div
                style={{
                    backgroundColor: '#FBDAED',
                    borderRadius: '12px',
                    padding: '12px 16px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                }}
                onClick={onClick}
            >
                <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Image ratio='1:1' src={icon} width='28' />
                    <Text typo='font_body_1Sb' color='color_custom_text_6'>
                        {title}
                    </Text>
                </div>
                <Icon name='chevron_right_small_line' size='24px' color='#E6007E' />
            </div>
        </div>
    );
};

export default GuideSection
;

