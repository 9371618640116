import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, CTA, Text, TextField} from '@lguuxe/2024_designsystem_uni';
import {authApi} from '../../api/auth';

import BS_Terms from './BS_Terms';
import ExceedPopup from './ExceedPopup';
import AuthPopup from './AuthPopup';
import CommonDivider from '../Common/CommonDivider';
import VerificationInfo from './VerificationInfo';
import {
    getAuthRequestParam,
    getBirth8Digit,
    getGender,
    getParentCheckParam,
    getRequestParam,
    User
} from '../../pages/IdentityVerification/types';
import {checkUnder14, validateUserInfo} from '../../pages/IdentityVerification/validators';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {encrypt, generateMCP_TR_KEY} from "../../api/api";
import CommonPopup from "../Common/CommonPopup";
import {getItemByKey} from "../../utils/indexedDB";
import {sendEvent, sendEventReturn} from "../../api/bridgeApi";

interface PhoneNumberInputProps {
    user: User;
    onChange: (user: User) => void;
    onReset: () => void;
}

const MAX_PHONE_ATTEMPTS = 4;
const MAX_AUTH_ATTEMPTS = 3;

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({user, onChange, onReset}) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [authCode, setAuthCode] = useState('');
    const [supportText, setSupportText] = useState<any[]>([]); // remove
    const [authSupportText, setAuthSupportText] = useState<any[]>([]);
    const [exceedPopupOpen, setExceedPopupOpen] = useState(false);
    const [authPopupOpen, setAuthPopupOpen] = useState(false);
    const [TermsBsOpen, setTermsBsOpen] = useState(false);
    const [phoneNumberButtonActive, setPhoneNumberButtonActive] = useState(false);
    const [isAndroid, setIsAndroid] = useState(false); // 안드로이드 앱에서만 사용
    const [isAuthButtonActive, setIsAuthButtonActive] = useState(false);
    const [showInfoMismatchPopup, setShowInfoMismatchPopup] = useState(false);
    const [showAuthRetryLimitPopup, setShowAuthRetryLimitPopup] = useState(false);
    const [showIDontKnowPopup, setShowIDontKnowPopup] = useState(false);
    const [representativeNoMatchPopup, setRepresentativeNoMatchPopup] = useState(false);
    const [showKmcPopup, setShowKmcPopup] = useState(false);

    const authCodeRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const location = useLocation();

    let isAgreeTerms = useRef(false);
    let phoneNumberCount = useRef(0);
    let authCount = useRef(0);

    const [searchParams] = useSearchParams();
    const target = searchParams.get('target');

    console.log('target : ', target);

    useEffect(() => {
        const fetchIndexedDb = async () => {
            const ctn = await getItemByKey('ctn');

            if (target !== 'representative' && ctn && ctn !== '000000000000' && ctn !== '00000000000') { // 이게 안드로이드임
                const fixedNumber = ctn.length === 12 ? ctn.slice(0, 3) + ctn.slice(4) : ctn;
                setPhoneNumber(fixedNumber);
                setIsAndroid(true);
            }
        }
        fetchIndexedDb();

    }, []);

    useEffect(() => {
        user = {...user, phoneNumber: phoneNumber, authCode: authCode};
        onChange(user);
    }, [phoneNumber, authCode]);

    useEffect(() => {
        validateUserInfo(user) ? setPhoneNumberButtonActive(true) : setPhoneNumberButtonActive(false);
    }, [user]);


    const handlePhoneNumberChange = (event: any) => {
        console.log(event);
        setPhoneNumber(event.value);
    };

    const handleAuthCodeChange = (event: any) => {
        setAuthCode(event.value);
    };

    const handleCertificationPopup = () => {
        setExceedPopupOpen(!exceedPopupOpen);
    };

    const fetchPhoneVerification = async () => {
        console.log('fetchPhoneVerification user : ', user);
        const mcpTrKey = generateMCP_TR_KEY();
        console.log('fetchPhoneVerification mcpTrKey : ', mcpTrKey);
        const request = await getRequestParam(user, mcpTrKey);
        console.log('fetchPhoneVerification request : ', request);
        try {
            const response = await authApi.postPhoneConfirm(request, 'v1', mcpTrKey);
            switch (response.header.resultCode) {
                case '0000':
                    onChange({...user, kmcInfo: response.body.kmcInfo});
                    setSupportText([
                        {
                            icon: 'error_circle_filled',
                            status: 'default',
                            text: `인증 번호를 발송하였습니다 (${phoneNumberCount.current + 1}/${MAX_PHONE_ATTEMPTS})`,
                        },
                    ]);
                    setIsAuthButtonActive(true);
                    phoneNumberCount.current++;
                    if (authCodeRef.current) {
                        authCodeRef.current.focus();
                    }
                    break;
                case '1001': // 입력한 정보 확인 불가
                    console.log(response.header.message);
                    setShowInfoMismatchPopup(true);
                    break;
                case '1002': // 입력한 정보 확인 불가
                    console.log(response.header.message);
                    setShowInfoMismatchPopup(true);
                    break;
                case '1004': // 연속 인증 요청 10건 초과
                    handleCertificationPopup();
                    setPhoneNumberButtonActive(false);
                    setTimeout(() => {
                        setPhoneNumberButtonActive(true);
                    }, 1);
                    break;
                case '1003':
                case '1005': // 당일 본인인증 오류 횟수 초과
                    console.log(response.header.message);
                    setShowAuthRetryLimitPopup(true);
                    break;
                case '6299':
                    setShowKmcPopup(true);
                    break;
                default:
                    setShowIDontKnowPopup(true);
                    break;
            }
            console.log('fetchPhoneVerification response : ', response);
        } catch (error) {
            console.log('fetchSms error : ', error);
        }
    };

    const fetchAuth = async () => {
        console.log('fetchAuth user : ', user);
        const mcpTrKey = generateMCP_TR_KEY();
        const request = await getAuthRequestParam(user, mcpTrKey);
        console.log('fetchAuth request : ', request);
        try {
            console.log('fetchAuth request : ', request);
            const response = await authApi.postAuthConfirm(request, 'v1', mcpTrKey);
            console.log('fetchAuth response : ', response.body);
            switch (response.header.resultCode) {
                case '0000':
                    if (target === 'app') {
                        const param = {
                            step: 2
                        }
                        sendEventReturn('CHANGE_PASSWORD', param);
                    }
                    if (target === 'web') {
                        const info = {
                            name: user.name,
                            birthday: getBirth8Digit(user.birth, user.gender),
                            gender: getGender(user.gender),
                            nation: user.nationality == '내국인' ? '0' : '1',
                            telcoCode: user.carrier,
                            phoneNo: user.phoneNumber,
                            certNum: response.body.kmcCheckInfo.certNum,
                            userCI: response.body.kmcCheckInfo.userCI,
                        }
                        console.log('info : -----------------------', info);
                        if (checkUnder14(getBirth8Digit(user.birth, user.gender))) {
                            navigate('/representative', {
                                state: {
                                    userInfo: info,
                                    childCI: response.body.kmcCheckInfo.userCI
                                }
                            });
                        } else {
                            navigate('/agreements/join', {state: info});
                        }
                    }
                    if (target === 'representative') {
                        fetchRepresentativeCheck(response.body.kmcCheckInfo.userCI);
                    }

                    break;
                case '1007': // 인증번호 입력 5회 초과
                    setAuthPopupOpen(true);
                    onReset();
                    setPhoneNumber('');
                    setAuthCode('');
                    setPhoneNumberButtonActive(false);
                    setIsAuthButtonActive(false);
                    authCount.current = 0;
                    setAuthSupportText([]);
                    setSupportText([]);
                    break;
                case '1008': // 인증번호 실패
                    setAuthSupportText([
                        {
                            icon: 'error_circle_filled',
                            status: 'error',
                            text: '인증번호를 다시 입력해 주세요.',
                        },
                    ]);
                    authCount.current++;
                    break;
                default:
                    setShowIDontKnowPopup(true);
                    break;

            }
        } catch (error) {
            console.error('fetchAuth error : ', error);
        }
    };

    const fetchRepresentativeCheck = async (parentCI: string) => {
        const mcpTrKey = generateMCP_TR_KEY();
        const childCI = location.state.childCI;
        const request = await getParentCheckParam(childCI, parentCI, mcpTrKey);
        try {
            const response = await authApi.postParentCheck(request, 'v1', mcpTrKey);
            switch (response.header.resultCode) {
                case '0000':
                    const userInfo = location.state.userInfo;
                    navigate('/agreements/join', {state: userInfo});
                    break;
                default:
                    setRepresentativeNoMatchPopup(true);
                    console.log('fetchRepresentativeCheck response : ', response);
                    break;
            }
        } catch (error) {
            console.log('fetchRepresentativeCheck error : ', error);
        }
    }

    const fetchIpin = async () => {
        // try {
        //     const mcpTrKey = generateMCP_TR_KEY();
        //     otherApi.postIpinRedirect({}, 'v1');
        // }
    }

    const handlePhoneButtonClick = () => {
        console.log('terms open : --------------', TermsBsOpen);
        if (isAgreeTerms.current === false) {
            setTermsBsOpen(true);
            return;
        }

        if (phoneNumberCount.current + 1 > MAX_PHONE_ATTEMPTS) {
            handleCertificationPopup();
            setPhoneNumberButtonActive(false);
            setTimeout(() => {
                setPhoneNumberButtonActive(true);
                phoneNumberCount.current = 0;
            }, 1);
            return;
        }

        fetchPhoneVerification();

    };

    const handleTermsAgree = () => {
        isAgreeTerms.current = true;
        handlePhoneButtonClick();
        if (authCodeRef.current) {
            authCodeRef.current.focus();
        }
    };

    const handleAuthButtonClick = () => {
        // if (target === 'representative') console.log('todo: fetchRepresentativeAuth()');
        // else fetchAuth();
        fetchAuth();
    };

    const handleToIpin = async () => {
        const mcpTrKey = generateMCP_TR_KEY();
        const param = {
            url: 'https://api-services.members.lgudevplatform.com/api-service/v1/ipin/redirect',
            isIPIN: true,
            MCP_TR_KEY: mcpTrKey,
            phoneNo: await encrypt(mcpTrKey, phoneNumber),
            showTitlebar: false,
        }
        sendEvent('CALL_WEBVIEW', param);
    }

    return (
        <>
            <Box type='2_trbl'>
                <TextField
                    fill='single'
                    inputType='number'
                    isAutoTab
                    label='휴대폰 번호'
                    maxLength={11}
                    placeholder='010 1234 5678'
                    status='default'
                    value={phoneNumber}
                    supportText={supportText}
                    disabled={isAndroid}
                    onChange={handlePhoneNumberChange}
                    rightAddons={[
                        <Button
                            state='default'
                            size='small'
                            color='primary'
                            fill='solid'
                            disabled={!phoneNumberButtonActive}
                            key='certify-button'
                            onClick={handlePhoneButtonClick}
                        >
                            {phoneNumberCount.current == 0 ? '인증번호 받기' : '다시 받기'}
                        </Button>,
                    ]}
                />
                {phoneNumberCount.current == 0 && (
                    <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                        <div style={{display: 'flex'}}>
                            <Text typo='font_detail_3Sb' color='color_text_neutral_2' style={{margin: '0 4px 0 2px'}}>
                                •
                            </Text>
                            <Text typo='font_detail_3Sb' color='color_text_neutral_2'>
                                본인 명의의 휴대폰만 인증 가능합니다.
                            </Text>
                        </div>
                        <div style={{display: 'flex', alignItems: 'flex-start'}}>
                            <Text typo='font_detail_3Sb' color='color_text_neutral_2' style={{margin: '0 4px 0 2px'}}>
                                •
                            </Text>
                            <Text typo='font_detail_3Sb' color='color_text_neutral_2'>
                                법인고객은 ‘입력 오류’ 팝업이 나타나면 ‘아이핀 인증’을 눌러 인증해 주시기 바랍니다.
                            </Text>
                        </div>
                    </div>
                )}
            </Box>

            <Box type='2_trbl'>
                <TextField
                    ref={authCodeRef}
                    fill='single'
                    inputType='number'
                    isAutoTab
                    label='인증번호'
                    maxLength={6}
                    placeholder='123456'
                    status='default'
                    value={authCode}
                    onChange={handleAuthCodeChange}
                    supportText={authSupportText}
                ></TextField>
            </Box>

            <Box style={{padding: '20px 0 8px'}}>
                <CommonDivider/>
            </Box>

            <VerificationInfo/>

            <Box
                style={{
                    width: '100%',
                    padding: '0',
                    marginTop: '26px',
                    paddingBottom: '20px',
                }}
            >
                <CTA layout='column'>
                    <Button color='primary' onClick={handleAuthButtonClick} disabled={!isAuthButtonActive}>
                        인증하기
                    </Button>
                </CTA>
            </Box>

            <ExceedPopup isOpen={exceedPopupOpen} onClose={handleCertificationPopup}/>
            <AuthPopup isOpen={authPopupOpen} onClose={() => {
                setAuthPopupOpen(!authPopupOpen)
            }}/>

            <BS_Terms onCloseClick={() => {
                setTermsBsOpen(false)
            }} onAgreeClick={handleTermsAgree} carrier={user.carrier} isOpen={TermsBsOpen}/>
            <CommonPopup isOpen={showInfoMismatchPopup}
                         description={'입력하신 정보를 확인할 수 없어요.\n인증 정보를 다시 입력해 주세요.\n※법인 고객은 아이핀 인증을 눌러 주세요.'}
                         submitText={'다시 입력하기'} onSubmit={() => {
                setShowInfoMismatchPopup(false)
            }} cancelText={'아이핀 인증'} onClose={handleToIpin}/>
            <CommonPopup isOpen={showAuthRetryLimitPopup}
                         description={'인증번호 오류 횟수가 초과 되었습니다.\n본인확인 차단을 해제하거나, \n내일 다시 시도해 주세요.'}
                         submitText={'확인'} onSubmit={() => {
                setShowAuthRetryLimitPopup(false);
            }}/>
            <CommonPopup isOpen={representativeNoMatchPopup}
                         title={'법정 대리인 불일치'}
                         description={'휴대폰을 개통할 때 등록한 법정대리인 정보를 입력해 주세요. \n\n법정대리인을 변경하려면 필요 서류를 챙겨 가까운 LG유플러스 매장에 방문해 주세요.'}
                         submitText={'확인'} onSubmit={() => {
                setRepresentativeNoMatchPopup(false)
            }}/>
            <CommonPopup isOpen={showIDontKnowPopup} description={'잠시만 기다려주세요.'} submitText={'확인'} onSubmit={() => {
                setShowIDontKnowPopup(false)
            }}/>
            <CommonPopup isOpen={showKmcPopup} description={'잠시만 기다려주세요. 본인인증 차단해제중입니다.'} submitText={'확인'}
                         onSubmit={() => {
                             setShowKmcPopup(false)
                         }}/>
        </>
    );
};

export default PhoneNumberInput;
