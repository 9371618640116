import React from 'react';
import { Box, TextSet } from '@lguuxe/2024_designsystem_uni';
import {Agreement, AgreementType} from '../../pages/Agreements/types';
import {SubType} from "../../pages/Agreements/types";
interface SectionHeadingProps {
    mainType: AgreementType;
    subType?: SubType;
}

const formatTextWithLineBreaks = (text: string) => {
    return text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
            {line}
            <br />
        </React.Fragment>
    ));
};

const SectionHeading: React.FC<SectionHeadingProps> = ({ mainType, subType }) => {
    let title = "서비스 이용 약관 및 \n개인정보 동의서에 동의해 주세요";
    let description: string | null = "";

    if (mainType === 'additional') {
        title = "개인정보 수집/이용 약관이 변경되어\n재동의가 필요합니다.";
        description = null;
    } else if (subType) {
        switch (subType) {
            case '자사':
                description = "U+멤버십에 가입하시면 U+멤버십, 휴대폰결제, 쿠폰 서비스 등을 편리하게 이용하실 수 있습니다.";
                break;
            case '타사':
                description = "U+멤버십에 가입하시면 U+멤버십 제휴사 혜택 및 쿠폰 서비스 등을 편리하게 이용하실 수 있습니다.\n(타사/알뜰폰/법인폰 휴대폰결제 서비스 이용 불가)";
                break;
            case '14세 미만':
                description = "U+멤버십에 가입하시면 U+멤버십 제휴사 혜택 및 쿠폰 서비스 등을 편리하게 이용하실 수 있습니다.\n(만 14세미만 휴대폰결제 서비스 이용 불가)";
                break;
            default:
                break;
        }
    }

    const formattedTitle = formatTextWithLineBreaks(title);
    const formattedDescription = description ? formatTextWithLineBreaks(description) : null;

    return (
        <Box type="2_trbl">
            <TextSet
                subComponent="Heading_2"
                text={{
                    title: <span style={{ paddingBottom: "6px", color: "#181A1B" }}>{formattedTitle}</span>,
                    description: formattedDescription ? <span>{formattedDescription}</span> : undefined
                }}
                weight="bolder"
            />
        </Box>
    );
};

export default SectionHeading;
