import React, { useState, useEffect } from 'react';
import CommonRightIconHeader from "../../components/Common/CommonRightIconHeader";
import CommonDivider from "../../components/Common/CommonDivider";
import SectionStepper from "../../components/Badge/SectionStepper";
import SectionCertified from "../../components/Badge/SectionCertified";
import SectionAccordion2 from "../../components/Badge/SectionAccordion2";
import { Text, CTA, Button } from '@lguuxe/2024_designsystem_uni';
import { useNavigate } from "react-router-dom";
import nft from "../../api/nft";
import nftApi from "../../api/nft";

// Badge 데이터 타입 정의
interface Badge {
    categoryName: '나들이킹' | '쇼핑킹' | '스터디킹' | '뷰티킹' | '컨텐츠킹' | '일상킹' | '푸드킹';
    count: number;
}

const GiftBadge: React.FC = () => {
    const navigate = useNavigate();
    const handleClose = () => {
        navigate(-1); // 이전 페이지로 이동
    };
    const [phoneNumber, setPhoneNumber] = useState('');
    const [maxCounts, setMaxCounts] = useState<Partial<Record<Badge['categoryName'], number>>>({});
    const [badgeData, setBadgeData] = useState<Badge[]>([]);
    const [supportText, setSupportText] = useState<any[]>([]);
    useEffect(() => {
        //TODO 추후 api수정되면 적용
        const fetchedMaxCounts = {
            '나들이킹': 5,
            '쇼핑킹': 3,
            '스터디킹': 6,
            '뷰티킹': 4,
            '컨텐츠킹': 2,
            '일상킹': 2,
        };

        setMaxCounts(fetchedMaxCounts);

        const initialBadgeData = Object.keys(fetchedMaxCounts).map(key => ({
            categoryName: key as Badge['categoryName'],
            count: 0,
        }));

        setBadgeData(initialBadgeData);
    }, []);

    const handleGift = async () => {
        const senderCTN = "SENDER_PHONE_NUMBER"; // 발신자 전화번호
        const reciverCTN = "RECEIVER_PHONE_NUMBER"; // 수신자 전화번호

        const badgeList = badgeData
            .map((badge, index) => ({
                categoryId: index.toString(), // categoryName에 맞는 categoryId 가져오기
                categoryName: badge.categoryName,
                badgeCount: badge.count
            }));

        const requestBody = {
            senderCTN,
            reciverCTN,
            badgeList,
        };

        console.log('보낼 데이터:', requestBody);

        try {
            const response = await nftApi.getCheckReceiver(requestBody, "v1"); // 실제 API 호출 부분
            console.log('API 응답:', response);
        } catch (error) {
            console.error('API 호출 오류:', error);
        }
    };

    const isGiftButtonEnabled = badgeData.some(badge => badge.count > 0);
    const handlePhoneNumberChange = (event: any) => {
        console.log(event);
        setPhoneNumber(event.value);
    };

    const handleCertification = async () => {
        console.log(phoneNumber)
        try {
            const response = await nftApi.getCheckReceiver({reciverCTN: phoneNumber}, "v1");
            const recivableYN = response.body.recivableYN
            const totalBadgeCount = badgeData.reduce((total, badge) => total + badge.count, 0);
            if(recivableYN === "Y") {
                const supportTextProps = [{
                    icon: 'check_circle_filled',
                    status : 'success',
                    text : "배지를 선물할 수 있어요."
                }]
                setSupportText(supportTextProps)
            } else if (recivableYN === "N") {
                const supportTextProps = [{
                    icon: 'error_circle_filled',
                    status : 'error',
                    text : "U+가입 번호로만 선물할 수 있습니다."
                }]
                setSupportText(supportTextProps)
            //TODO api가 받을수 있는 값을 리턴하게 수정요청후 반영
            } else if (totalBadgeCount > Number(response.body.recivableCount)) {
                const supportTextProps = [{
                    icon: 'error_circle_filled',
                    status : 'error',
                    text : `받는 분이 획득 가능한 배지보다 보내는 배지가 많습니다. 배지 개수를 확인해주세요. ${response.body.recivableCount}개 전송 가능`
                }]
                setSupportText(supportTextProps)
            } else if (response.body.recivableCount === "0") {
                const supportTextProps = [{
                    icon: 'error_circle_filled',
                    status : 'error',
                    text : `받는 분의 배지함이 가득 찼습니다.`
                }]
                setSupportText(supportTextProps)
            }
        } catch (e) {
            console.log(e)
        }

    };

    return (
        <>
            <CommonRightIconHeader icon={"close_line"} title={"배지 선물하기"} onClick={handleClose} />

            <div
                style={{
                    padding: "16px 20px",
                    backgroundColor: "#F3F5F6",
                }}
            >
                <Text typo="font_body_2M" color="color_text_neutral_3">
                    배지는 하루 한 번, U+ 고객에게만 보낼 수 있습니다.<br />
                    배지 개수는 여러 개를 한꺼번에 보낼 수 있습니다.
                </Text>
            </div>

            <SectionStepper
                maxCounts={maxCounts}
                badgeData={badgeData}
                setBadgeData={setBadgeData}
            />

            <SectionCertified onClick={handleCertification} supportText={supportText} onChange={handlePhoneNumberChange}/>

            <div style={{ margin: "16px 0 8px" }}>
                <CommonDivider />
            </div>

            <SectionAccordion2 />

            <CTA layout="column">
                <Button
                    color={isGiftButtonEnabled ? "primary" : "neutral"}
                    size="large"
                    disabled={!isGiftButtonEnabled} // badgeData의 count가 전부 0이면 disable 처리
                    style={{ marginTop: "12px" }}
                    onClick={handleGift}
                >
                    선물하기
                </Button>
            </CTA>
        </>
    );
}

export default GiftBadge;
