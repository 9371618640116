import React, { FC } from 'react';
import { Icon, Text } from "@lguuxe/2024_designsystem_uni";

const SectionInfoBox: FC = () => {
    return (
        <div
            style={{
                backgroundColor: "#F9FAFB",
                padding: "16px 20px",
                borderRadius: "10px",
                display: "flex",
                alignItems: "flex-start",
                flexDirection: "column"
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "row"
                }}>
                <Icon color='#7f8a94' name='error_circle_filled' size='20px' style={{ marginRight: "2px" }} />
                <Text typo="font_body_2M" color="color_text_neutral_3" style={{ marginBottom: "8px" }}>
                    개명한 경우, 앱을 재설치하거나 초기화한 후 다시 실행하면 자동으로 변경된 이름이 반영됩니다.
                </Text>
            </div>
            <div>
            <ul style={{ paddingLeft: "20px", color: "#7f8a94", margin: 0 }}>
                <li><Text typo="font_body_2M" color="color_text_neutral_3">아이폰: 앱을 삭제하고 다시 설치해 주세요.</Text></li>
                <li><Text typo="font_body_2M" color="color_text_neutral_3">안드로이드: 홈 화면에서 U+멤버십 앱을 1초 이상 누른 후 [애플리케이션 정보] → [저장공간] → [데이터 삭제]를 누르세요.</Text></li>
            </ul>
            </div>
        </div>
    );
}

export default SectionInfoBox;