// ****************************************************
// [배포] 4.마이 - 내 등급, 혜택 이력 - 내 이력 (UMembershipReceivedBenefitHistory)
// ****************************************************
import React from 'react';
import {Image, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import box1Img from '../../images/icon/4_my/point.svg';
import box2Img from '../../images/icon/4_my/moneyme_money.svg';
import {MissionReward, MoneyBenefit} from '../../pages/My/types';
import {formatCurrency} from '../../utils/currencyUtils';
import {useNavigate} from 'react-router-dom';
import {moneymeEnter} from "../../api/externalApi";

interface MissionRewordProps {
    missionReward: MissionReward;
    moneyBenefit: MoneyBenefit;
}

const
    MissionReword: React.FC<MissionRewordProps> = ({missionReward, moneyBenefit}) => {

        const navigate = useNavigate();

        // useEffect(() => {
        //     const fetchData = async () => {
        //         try {
        //             const [missionReward, moneyBenefit] = await Promise.allSettled([
        //                 otherApi.getMissionReward('v1'),
        //                 otherApi.getMoneyBenefit('v1'),
        //             ]);
        //             console.log('MissionReword : ', missionReward, moneyBenefit);
        //
        //             setMissionReward(handlePromiseResult(missionReward, initialMissionReward));
        //             setMoneyBenefit(handlePromiseResult(moneyBenefit, initialMoneyBenefit));
        //         } catch (e) {
        //             console.log(e);
        //         }
        //     };
        //     fetchData();
        // }, []);

        const handleRewordClick = () => {
            navigate('/mission');
        };

        const handleMoneyMeClick = () => {
            moneymeEnter();
        };

        // TODO: MoneyMe 연동이 됐는지 어떻게 알 수 있지 ??
        // 머니미 미연동시 ??? 문구 표기 + 자산 연결하기 버튼 추가

        return (
            <>
                <div style={{paddingBottom: '0'}}>
                    <TextSet
                        subComponent='Heading_4'
                        text={{
                            title: (
                                <>
                                    <span style={{color: '#181A1B'}}>나의 미션 리워드 & 머니</span>
                                </>
                            ),
                        }}
                        blockSpacing={{top: '24px', bottom: '12px'}}
                        isIndent={true}
                    />

                    <div
                        style={{
                            display: 'flex',
                            flexWrap: 'wrap',
                            gap: '8px',
                            padding: '0 20px 24px',
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: '#BEE4F1',
                                borderRadius: '12px',
                                width: 'calc(50% - 4px)',
                                boxSizing: 'border-box',
                                height: '168px',
                                position: 'relative',
                            }}
                            onClick={handleRewordClick}
                        >
                            <div style={{padding: '16px'}}>
                                <TextSet
                                    subComponent='Body_1'
                                    text={{
                                        eyebrow: '나의 미션 리워드',
                                        title: (
                                            <>
                                                <Text typo='font_body_1Sb' color='color_text_neutral_6'>
                                                    {formatCurrency(missionReward.rewardPoint)}P
                                                </Text>
                                            </>
                                        ),
                                    }}
                                    weight='bolder'
                                />
                                <div style={{position: 'absolute', right: '20px', bottom: '20px', padding: '0'}}>
                                    <Image customClass='customClass' ratio='1:1' src={box1Img} width='64'/>
                                </div>
                            </div>
                        </div>
                        <div
                            style={{
                                backgroundColor: '#FBDCD3',
                                borderRadius: '12px',
                                width: 'calc(50% - 4px)',
                                boxSizing: 'border-box',
                                height: '168px',
                                position: 'relative',
                            }}
                            onClick={handleMoneyMeClick}
                        >
                            <div style={{padding: '16px'}}>
                                <TextSet
                                    subComponent='Body_1'
                                    text={{
                                        eyebrow: '나의 놓친 혜택',
                                        title: (
                                            <>
                                                <Text typo='font_body_1Sb' color='color_text_neutral_6'>
                                                    {formatCurrency(moneyBenefit.moneyMePoint)}머니
                                                </Text>
                                            </>
                                        ),
                                    }}
                                    weight='bolder'
                                />
                                <div style={{position: 'absolute', right: '20px', bottom: '20px', padding: '0'}}>
                                    <Image customClass='customClass' ratio='1:1' src={box2Img} width='64'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    };

export default MissionReword;
