import React from 'react';
import { BottomNavigation, FAB } from '@lguuxe/2024_designsystem_uni';

interface BottomNavigationComponentProps {
    openBottomSheet: () => void;
}

const BottomNavigationComponent: React.FC<BottomNavigationComponentProps> = ({ openBottomSheet }) => {
    return (
        <BottomNavigation
            items={[
                {
                    icon: 'home_filled',
                    id: 'home',
                    label: '홈',
                },
                {
                    icon: 'flag_line',
                    id: 'misson',
                    label: '미션',
                },
                {
                    icon: 'person_line',
                    id: 'my',
                    label: '마이',
                },
                {
                    icon: 'person_filled',
                    id: 'profile',
                    label: '프로필',
                },
            ]}
            selectedItemId='home'
        >
            <BottomNavigation.centerSlot>
                <div
                    style={{
                        position: 'relative',
                        bottom: '1rem',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <FAB icon='close_line' label='닫기' onClick={openBottomSheet} />
                </div>
            </BottomNavigation.centerSlot>
        </BottomNavigation>
    );
};

export default BottomNavigationComponent;
