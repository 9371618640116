import React, { useState } from 'react';
import { Icon, Image, Text, TextSet } from '@lguuxe/2024_designsystem_uni';
import ExImg from "../../images/default/default thumbnail.svg";
import { CLICK_LOCATION, EVENT_ACTION } from "../../utils/const";
import { SwipeableHandlers, useSwipeable } from 'react-swipeable';
import useLandingPage from "../../hooks/useLandingPage";
interface BannerInfoProps {
    bannerList: BannerItem[];
}

interface BannerItem {
    bannerId: string;
    bannerTitle?: string;
    bannerImgUrl: string;
    targetInfo: {
        targetType: string;
        targetLink: string;
    }[];
}

const SectionBannerInfo: React.FC<BannerInfoProps> = ({ bannerList }) => {
    const [currentIndex, setCurrentIndex] = useState(0); // 현재 배너 인덱스를 추적하는 상태
    const {landingPage} = useLandingPage();
    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === bannerList.length - 1 ? prevIndex : prevIndex + 1
        );
    };

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? prevIndex : prevIndex - 1
        );
    };
    const handleClick = (item : BannerItem) => {
        landingPage(item.targetInfo[0].targetType, item.targetInfo[0].targetType, item.bannerTitle)
    }
    const handlers: SwipeableHandlers = useSwipeable({
        onSwipedLeft: handleNext,
        onSwipedRight: handlePrev,
        preventScrollOnSwipe: true,
        trackMouse: true,
    });

    return (
        <div {...handlers} style={{ position: 'relative', overflow: 'hidden', width: '100%' }}>
            <div style={{ width: '100%', textAlign: 'center', whiteSpace: 'nowrap' }}>
                <a
                    href={bannerList[currentIndex].targetInfo?.[0]?.targetLink || '#none'}
                    key={currentIndex}
                    data-gtm-event-name={'all_click'}
                    data-gtm-event-category={'클릭'}
                    data-gtm-event-action={`${CLICK_LOCATION.MIDDLE_BANNER} - ${EVENT_ACTION.LINK_CLICK}`}
                    data-gtm-event-label={`배너 ID: ${bannerList[currentIndex].bannerId} | 배너 타이틀: ${bannerList[currentIndex].bannerTitle}`}
                    data-gtm-click-url={bannerList[currentIndex].targetInfo?.[0]?.targetLink || ''}
                    data-gtm-click-location={`${CLICK_LOCATION.MIDDLE_BANNER}`}
                    data-gtm-click-direction={
                        bannerList[currentIndex].targetInfo?.[0]?.targetType === '117001' ? '외부' : '내부'
                    }
                    data-gtm-click-text={bannerList[currentIndex].bannerTitle || ''}
                    style={{
                        display: 'inline-block',
                        width: '100%',
                        verticalAlign: 'top',
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '20px',
                            backgroundColor: '#F3F5F6',
                            borderRadius: '12px',
                            marginBottom: '2px',
                        }}
                        onClick={() =>handleClick(bannerList[currentIndex])}
                    >
                        <div style={{ display: 'flex', gap: '8px' }}>
                            <Image
                                customClass="customClass"
                                ratio="1:1"
                                src={bannerList[currentIndex].bannerImgUrl || ExImg}
                                width="40"
                                rounded="8px"
                                srcFallback={ExImg}
                            />
                            <TextSet
                                subComponent="Body_1"
                                text={{
                                    title: (
                                        <>
                                            <Text typo="font_body_1B">{bannerList[currentIndex].bannerTitle || '제목 없음'}</Text>
                                        </>
                                    ),
                                }}
                            />
                        </div>
                        <div>
                            <Icon
                                color="#7F8A94"
                                name="chevron_right_small_line"
                                size="24px"
                            />
                        </div>
                    </div>
                </a>
            </div>

            <div style={{ textAlign: 'center'}}>
                <Text typo="font_label_3M" color="color_text_neutral_5" style={{ fontSize: "12px", fontWeight: "500" }}> {currentIndex + 1}  </Text>
                <Text typo="font_label_3M" color="color_text_neutral_1" style={{ fontSize: "12px", fontWeight: "500" }}>/{bannerList.length}</Text>
            </div>
        </div>
    );
}

export default SectionBannerInfo;
