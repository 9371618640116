import React from 'react';
import {List, TextSet} from "@lguuxe/2024_designsystem_uni";
import {useNavigate} from "react-router-dom";

interface QnAItemProps {
    itemList: QnAItem[]
    threshold?: number;
}

interface QnAItem {
    itemType: string;
    itemName: string;
    itemId: string;
    itemSummary: string;
}

const QnAItem: React.FC<QnAItemProps> = ({ itemList, threshold }) => {
    const itemsToRender = itemList.slice(0, threshold ?? itemList.length);
    const navigate = useNavigate()
    const onQnAClick = (item: QnAItem) => {
        navigate(`/help/announcement/${item.itemId}`)
    }

    return (
        <div>
            <List
                fill="none"
                name="list_name"
                size="large"
                type="view"
                weight="default"
                isSlotClickable={true}
                items={itemsToRender.map(item => ({
                    linkIconOnlyId: "empty",
                    onClick: () => onQnAClick(item),
                    leftSlot: [
                        <TextSet
                            subComponent="Body_1"
                            text={{
                                title:item.itemName,
                            }}
                        />
                    ],
                }))}
            />
        </div>
    );
}

export default QnAItem;