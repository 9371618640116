// PartnerList.tsx
import {IconButton, Image, List, Tag, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import React from 'react';
import {useNavigate} from 'react-router-dom';
import {PartnerItem, TotalPartners} from '../../pages/Partner/types';
import emptyPartnerImg from '../../images/img/empty/empty_partner.svg';
import defaultThumbnail from '../../images/default/default thumbnail.svg';
import {getTagInfoByBenefitCode} from "../../utils/helper";
import {partnerApi} from "../../api/partnerAndAffiliate-service";
import CommonPopup from "../Common/CommonPopup";

interface PartnerListProps {
    partners: TotalPartners;
    onClickLike?: (ptnId: string, isLike: boolean) => void;
    type? : string;
}

const PartnerList: React.FC<PartnerListProps> = ({partners, onClickLike, type}) => {
    const navigate = useNavigate();
    const [showLikeErrorPopup, setShowLikeErrorPopup] = React.useState(false);
    const [showDislikeErrorPopup, setShowDislikeErrorPopup] = React.useState(false);


    const fetchLike = async (ptnId: string, nowIsLike: boolean) => {
        try {
            const requestParam = {
                regYn: nowIsLike ? 'N' : 'Y',
            }
            partnerApi.putPartnersLikeById(ptnId, requestParam, 'v1').then((res) => {
                if (res.header.resultCode !== '0000') {
                    nowIsLike ? setShowDislikeErrorPopup(true) : setShowLikeErrorPopup(true)
                    onClickLike && onClickLike(ptnId, !nowIsLike);
                }
            });
        } catch (error) {
            console.error('Failed to fetch :', error);
        }
    }


    const handlePartnerClick = (partner: PartnerItem) => {
        if (type) {
            navigate(`/vip-kok-detail/${partner.ptnId}`);
        } else {
            navigate(`/partners/${partner.ptnId}`);
        }

    };

    const handleLike = async (ptnId: string, nowIsLike: boolean) => {
        // api와는 별개로 좋아요 누르면 화면이 변경되도록 한다.
        // api 오류시에는 오류 팝업을 띄워주고 다시 시도하도록 한다.
        onClickLike && await onClickLike(ptnId, nowIsLike);
        fetchLike(ptnId, nowIsLike);
    }

    const renderNoPartner = () => (
        <div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: '88px 0',
            }}>
                <Image src={emptyPartnerImg} alt='placeholder' width='120px' ratio='1:1'/>
                <div style={{
                    paddingTop: '20px',
                }}>
                    <Text typo='font_detail_1M' color='color_text_neutral_3' textAlign='center'>
                        제휴사가 없습니다.
                    </Text>
                </div>
            </div>
        </div>
    );

    const renderPartnerList = () => (
        <List
            type='view'
            fill='none'
            id='partnerList'
            isSlotClickable={true}
            items={partners.partnerList.map((partner) => ({
                id: `${partner.ptnId}`,
                leftSlot: [
                    <Image
                        src={partner.ptnLogoImgUrl}
                        ratio='1:1'
                        width='48px'
                        rounded={'50%'}
                        srcFallback={defaultThumbnail}
                        onClick={() => handlePartnerClick(partner)}
                    />,
                    <div style={{display: 'flex', flexDirection: 'column', gap: '0.5rem'}}
                         onClick={() => handlePartnerClick(partner)}>
                        <TextSet
                            subComponent='Body_1'
                            text={{
                                eyebrow: (
                                    <>
                                        <span>{partner.ptnName}</span>
                                        <span className='bar'></span>
                                        <span>텍스트</span>
                                    </>
                                ),
                                title: partner.benefitSummary,
                            }}
                        />
                        <Tag
                            item={partner.benefitTagList.map((benefit) => {
                                const tagInfo = getTagInfoByBenefitCode(benefit.benefitCode);
                                return {
                                    label: tagInfo.label,
                                    size: 'xSmall',
                                    style: 'solid',
                                    colorToken: tagInfo.colorToken,
                                };
                            })}
                        />
                    </div>,
                ],
                rightSlot: (
                    <IconButton
                        key={`icon-${partner.ptnId}`}
                        icon='favorite_line'
                        size='large'
                        fill='icon'
                        state='default'
                        isSelected={partner.isLike === 'Y'}
                        selectable
                        onClick={() => handleLike(partner.ptnId, partner.isLike === 'Y')}
                    />
                ),
            }))}
        />
    );

    return (
        <>
            {partners?.totalCount === '0' ? renderNoPartner() : renderPartnerList()}
            <CommonPopup submitText={'확인'} isOpen={showLikeErrorPopup} onSubmit={() => setShowLikeErrorPopup(false)}
                         description={'좋아요 등록에 실패했습니다. 다시 시도해주세요.'}/>
            <CommonPopup submitText={'확인'} isOpen={showDislikeErrorPopup}
                         onSubmit={() => setShowDislikeErrorPopup(false)}
                         description={'좋아요 취소에 실패했습니다. 다시 시도해주세요.'}/>
        </>
    );
};

export default PartnerList;
