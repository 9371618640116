import React from "react";
import {BottomSheet, Box, Button, CTA, Header, IconButton, Text} from "@lguuxe/2024_designsystem_uni";

interface BS_CouponEmployeeProps {
    isOpen: boolean;
    onClose: () => void;
    onAgree: () => void;
}

const BS_CouponEmployee: React.FC<BS_CouponEmployeeProps> = ({isOpen, onClose, onAgree}) => {
    return (
        <>
            <BottomSheet isOpen={isOpen} onClose={onClose}>
                <BottomSheet.TopSlot>
                    <Header align="left" variant="heading" style={{borderBottom: "1px solid #fff"}}>
                        직원 확인
                        <Header.RightSlot>
                            <IconButton color="neutral" fill="icon" icon="close_line" noStates
                                        onClick={onClose}/>
                        </Header.RightSlot>
                    </Header>
                </BottomSheet.TopSlot>

                <BottomSheet.CenterSlot>
                    <Box style={{padding: "0", height: "auto"}}>
                        {/* 직원 확인 정보 */}
                        <div style={{padding: "0 0 0"}}>
                            <Text typo="font_body_1M" color="color_text_neutral_3">
                                제휴사 직원이신가요? <br/>
                                [직원 확인]을 누르면 쿠폰이 사용 완료되어<br/>
                                더 이상 사용할 수 없어요.
                            </Text>
                        </div>
                    </Box>
                </BottomSheet.CenterSlot>

                <BottomSheet.BottomSlot>
                    <CTA gradation layout="row">
                        <Button color="primary" fill="solid" size="large" onClick={onAgree}>
                            직원 확인
                        </Button>
                    </CTA>
                </BottomSheet.BottomSlot>
            </BottomSheet>
        </>
    )
}

export default BS_CouponEmployee;