import React from 'react';
import { TextSet, Button, Text } from "@lguuxe/2024_designsystem_uni";
import CouponItem from "../ListItem/CouponItem";
import EventItem from "../ListItem/EventItem";
import NoticeItem from "../ListItem/NoticeItem";
import QnAItem from "../ListItem/QnAItem";
import StoreItem from "../ListItem/StoreItem";
import UPTPItem from "../ListItem/UPTPItem";
import { useNavigate } from 'react-router-dom';


interface SearchListProps {
    title: string;
    items: any[];
    threshold: number;
}

const SearchList: React.FC<SearchListProps> = ({ title, items, threshold }) => {
    const navigate = useNavigate();

    const handleMoreClick = () => {
        navigate('/result-more', { state: { title, items } });
    };
    const renderItems = (items:any[]) => {
        switch (title) {
            case '쿠폰':
                return <CouponItem itemList={items} threshold={threshold}/>;
            case '이벤트':
                return <EventItem itemList={items} threshold={threshold}/>;
            case '공지사항':
                return <NoticeItem itemList={items} threshold={threshold}/>;
            //TODO 리스트는 FAQ 등록일 기준 최근 2개까지의 검색결과만 표기
            case '자주하는 질문':
                return <QnAItem itemList={items} threshold={threshold}/>;
            case '제휴사':
                return <StoreItem itemList={items} threshold={threshold}/>;
            //TODO 진행 중 (어드민 설정 순) - 예정(시작일 순) - 종료(시작일 순) 순 최대 3개 표시
            case '유플투쁠':
                return <UPTPItem itemList={items} threshold={threshold}/>;
            default:
                return null;
        }
    };
    return (
        <div>
            <TextSet
                subComponent="Heading_4"
                text={{
                    title: (
                        <>
                            <Text typo="font_heading_4B">{title}<Text typo="font_heading_4B" color="color_text_primary">&nbsp;{items.length}</Text></Text>
                        </>
                    )
                }}
                blockSpacing={{ top: "24px", bottom: "12px" }}
                isIndent={true}
            />
            <div>
            {
                renderItems(items)
            }
            </div>
            {items.length > threshold && (
                <div
                    style={{
                        textAlign: "center",
                        borderTop: "1px solid #E7EBEE",
                        marginTop: "12px",
                    }}
                >
                    <Button
                        color="neutral"
                        rightIcon="chevron_right_small_line"
                        size="large"
                        fill="text"
                        style={{
                            padding: "14px 0",
                            width: "100%"
                        }}
                        onClick={handleMoreClick}
                    >
                        더보기
                    </Button>
                </div>
            )}
        </div>
    );
}

export default SearchList;
