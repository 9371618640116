import React from "react";
import { Box, Image, Text } from "@lguuxe/2024_designsystem_uni";

import gradeYouthIcon from "../../images/etc/grade_youth.svg";

const BoxTableBenefit3: React.FC = () => {
    return (
        <Box type="3_trbl">
            <div className="tbl" style={{ marginBottom: "0" }}>
                <table>
                    <caption>유쓰 등급 안내</caption>
                    <colgroup>
                        <col width="85px" />
                        <col />
                    </colgroup>
                    <tbody>
                    <tr>
                        <th>등급</th>
                        <th>등급 기준 안내</th>
                    </tr>
                    <tr>
                        <td>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <Image customClass="customClass" ratio="1:1" src={gradeYouthIcon} width="32" alt="유쓰 등급 아이콘" />
                                {/* <Image src={youth24Icon} alt="유쓰 24 아이콘" width="32" ratio="1:1" /> */}
                                {/* 실제 아이콘이 다르므로, 새로운 아이콘이 필요할 경우 다시 받아야 합니다 */}
                                <Text typo="font_body_1B" color="color_text_neutral_5" style={{ fontSize: "12px" }}>
                                    유쓰
                                </Text>
                            </div>
                        </td>
                        <td className="left">U+모바일을 이용하는 20대라면 누구나 유쓰 혜택을 받아요.</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </Box>
    );
};

export default BoxTableBenefit3;
