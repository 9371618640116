import React from 'react';
import {BottomSheet, Box, Button, CTA, Header, IconButton, Image, Text, TextSet} from "@lguuxe/2024_designsystem_uni";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {CLICK_LOCATION, EVENT_ACTION} from "../../utils/const";

interface BS_BadgeProps {
    imageSrc: string;
    name: string;
    count: number;
    onClose: () => void;
}

const BS_Badge: React.FC<BS_BadgeProps> = ({imageSrc, name, count, onClose}) => {
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };
    return (
        <BottomSheet isOpen onClose={onClose}>
            <BottomSheet.TopSlot>
                <Header
                    align="left"
                    variant="heading"
                    divider={false}
                    style={{
                        padding: "20px 20px 8px",
                        height: "auto"
                    }}
                >
                    <Header.CenterSlot>
                        <Text typo="font_heading_3B" style={{padding: "8px 0"}}>
                            {name}&nbsp;
                            <Text typo="font_heading_3B" style={{color: "#E6007E"}}>
                                {count}개
                            </Text>
                        </Text>
                    </Header.CenterSlot>
                    <Header.RightSlot>
                        <IconButton
                            color="neutral"
                            fill="icon"
                            icon="close_line"
                            noStates
                            onClick={onClose}
                        />
                    </Header.RightSlot>
                </Header>
            </BottomSheet.TopSlot>
            <BottomSheet.CenterSlot>
                <Slider {...settings}>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        <Box type="2_trbl">
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center"
                                }}
                            >
                                <Image
                                    customClass="customClass"
                                    ratio="1:1"
                                    src={imageSrc}
                                    width="160"
                                />
                            </div>
                        </Box>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center"
                            }}
                        >
                            <TextSet
                                blockSpacing={{
                                    bottom: '4px',
                                    top: '4px',
                                }}
                                subComponent="Heading_5"
                                text={{
                                    title: (
                                        <div
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center"
                                            }}
                                        >
                                            <span>해당하는 브랜드에서 혜택을</span>
                                            <span style={{color: "#E6007E"}}>3번 사용하면 발급돼요</span>
                                        </div>
                                    )
                                }}
                                weight="default"
                            />
                        </div>
                    </div>
                </Slider>
            </BottomSheet.CenterSlot>
            <BottomSheet.BottomSlot>
                <CTA gradation layout="column">
                    <Button color="primary" size="large"
                            data-gtm-event-name={'all_click'}
                            data-gtm-event-category={'클릭'}
                            data-gtm-event-action={`${CLICK_LOCATION.BADGE_DETAIL_POPUP} - ${EVENT_ACTION.LINK_CLICK}`}
                            data-gtm-event-label={`컨텐츠 : 브랜드 목록 보기`}
                            data-gtm-click-url={''}
                            data-gtm-click-location={`${CLICK_LOCATION.BADGE_DETAIL_POPUP}`}
                            data-gtm-click-direction={'내부'}
                            data-gtm-click-text={`브랜드 목록 보기`}>
                        브랜드 목록 보기
                    </Button>
                </CTA>
            </BottomSheet.BottomSlot>
        </BottomSheet>
    );
};

export default BS_Badge;
