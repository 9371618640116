import React, {useEffect, useState} from 'react';
import {Button, Image, Tab, Text, TextSet} from '@lguuxe/2024_designsystem_uni';
import {CategoryItem, MainRegion, Region} from '../../pages/Partner/types';
import arrowFlowIcon from "../../images/etc/arrow_flow.svg";
import BS_MainRegionBS from "./BS_MainRegionBS";
import {useNavigate} from "react-router-dom";
import {sendEventReturn} from "../../api/bridgeApi";
import BS_Location from "./BS_Location";
import {settingApi} from "../../api/settingAndTerms";
import {getItemByKey} from "../../utils/indexedDB";
import CommonPopup from "../Common/CommonPopup";
import {partnerApi} from "../../api/partnerAndAffiliate-service";
import BS_SubRegionBS from "./BS_SubRegionBS";

interface CategoryTabsProps {
    title: string;
    itemList: CategoryItem[];
    onItemChange: (itemId: string) => void;
    selectedItemId?: string;
}

const initMainRegion: MainRegion = {
    categoryId: '',
    categoryName: '',
    categoryType: '',
    subRegionList: [],
};

const initRegion: Region = {
    categoryId: '',
    categoryName: '',
    categoryType: '',
};

const CategoryTabs: React.FC<CategoryTabsProps> = ({title, itemList, onItemChange, selectedItemId}) => {
    const navigate = useNavigate();
    const [selectedCategory, setSelectedCategory] = useState<CategoryItem>();
    const [selectedMainRegion, setSelectedMainRegion] = useState<MainRegion>(initMainRegion);
    const [selectedSubRegion, setSelectedSubRegion] = useState<Region>(initRegion);
    const [subCategoryList, setSubCategoryList] = useState<MainRegion[]>([]);

    const [showMainRegionBS, setShowMainRegionBS] = useState(false);
    const [showSubRegionBS, setShowSubRegionBS] = useState(false);

    const [memberStatus, setMemberStatus] = useState('');
    const [show14BlockPopup, setShow14BlockPopup] = useState(false);
    const [showLocationTermBS, setShowLocationTermBS] = useState(false);

    useEffect(() => {
        const fetchIndexedDB = async () => {
            setMemberStatus(await getItemByKey('memberStatus'));
        }
        fetchIndexedDB();

    }, []);

    const fetchLocationAgree = async () => {
        try {
            const res = await settingApi.getTermsPersonalInfo({}, 'v1');
            if (res.header.resultCode !== '0000') {
                console.log('fetchLocationAgree error');
            }
            const term = res.body.termsList.find((item: any) => {
                return item.termsType === '125004';
            });

            if (term) {
                if (term.termYn === 'Y') {
                    sendEventReturn('GET_GRANT_LOCATION', {});
                } else {
                    setShowLocationTermBS(true);
                }
            }

        } catch (e) {
            console.log('fetchLocationAgree error');
        }
    }

    const fetchSubCategory = async (categoryId: string) => {
        try {
            const res = await partnerApi.getCategoriesSub(categoryId, {cateDepth: '2'}, 'v1');
            if (res.header.resultCode !== '0000') {
                console.log('fetchSubCategory error');
            }

            const mainRegionList = res.body.categoryList;
            mainRegionList.map((item: MainRegion) => {
                item.subRegionList = [];
                item.subRegionList.push({categoryId: '', categoryName: '전체', categoryType: ''});
                try {
                    partnerApi.getCategoriesSub(item.categoryId, {cateDepth: '3'}, 'v1').then((res) => {
                        if (res.header.resultCode !== '0000') {
                            console.log('fetchSubCategory error');
                        }
                        item.subRegionList = item.subRegionList.concat(res.body.categoryList);
                    });
                } catch (e) {
                    console.log('fetchSubCategory error');
                }
            });
            setSubCategoryList(mainRegionList);

        } catch (e) {
            console.log('fetchSubCategory error');
        }
    }

    const handleCategoryChange = (id: string) => {
        const selectedItem = itemList.find(item => item.categoryId === id);
        if (selectedItem) {
            setSelectedCategory(selectedItem);
            onItemChange(id);
            if (selectedItem.categoryName === '지역') {
                fetchSubCategory(id);
            }
        }
    };

    const handleMainRegionSelect = (mainRegion: MainRegion) => {
        setSelectedMainRegion(mainRegion);
        if (mainRegion.subRegionList.length > 1) {
            setShowSubRegionBS(true);
        }
    }

    const handleMapClick = () => {
        if (memberStatus === '3' || memberStatus === '4') {
            setShow14BlockPopup(true);
        }
        fetchLocationAgree();
        setShowLocationTermBS(true);
    }


    return (
        <>
            <TextSet
                subComponent='Heading_4'
                text={{title: <span>{title}</span>}}
                blockSpacing={{top: '24px', bottom: '12px'}}
                isIndent={true}
            />
            {itemList && itemList.length > 0 && (
                <Tab
                    items={itemList.map((item) => ({
                        id: item.categoryId,
                        label: item.categoryName,
                    }))}
                    layout="fitContent"
                    size="small"
                    selectedItemId={selectedItemId ? selectedItemId : itemList[0].categoryId}
                    onItemSelected={handleCategoryChange}
                />
            )}
            {selectedCategory?.categoryName == '지역' && (<div
                style={{
                    paddingTop: "12px",
                    paddingBottom: "12px",
                    paddingLeft: "20px",
                    paddingRight: "0px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    background: "#F3F5F6"
                }}
            >
                <Text as="span" textAlign="center" typo="font_heading_6B" color="color_text_neutral_3"
                      style={{display: "flex", gap: "6px"}}>
                    {selectedMainRegion.categoryName} <Image customClass="customClass" ratio="1:1" src={arrowFlowIcon}
                                                             width="8"/>
                </Text>
                <div
                    style={{
                        padding: "0px",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center"
                    }}
                >
                    <Button fill="ghost" size="small" color="secondary" onClick={() => {
                        setShowMainRegionBS(!showMainRegionBS)
                    }}>
                        <Text as="span" textAlign="center" typo="font_label_3Sb" color="color_text_neutral_3">
                            지역 선택
                        </Text>
                    </Button>
                    <span className="bar2" style={{
                        width: "1px",
                        height: "1rem",
                        backgroundColor: "#E7EBEE",
                        padding: "0",
                        margin: "0 6px"
                    }}></span>
                    <Button fill="ghost" size="small" color="secondary" onClick={handleMapClick}>
                        <Text as="span" textAlign="center" typo="font_label_3Sb" color="color_text_neutral_3">
                            지도 보기
                        </Text>
                    </Button>
                </div>
            </div>)}

            <BS_MainRegionBS regionList={subCategoryList} selectedRegionId={selectedMainRegion.categoryId}
                             isOpen={showMainRegionBS}
                             onClose={() => {
                                 setShowMainRegionBS(false)
                             }} onSelect={handleMainRegionSelect}/>
            <BS_SubRegionBS mainRegion={selectedMainRegion} isOpen={showSubRegionBS}
                            onClose={() => setShowSubRegionBS(false)} onSelect={() => {
            }}/>

            <CommonPopup submitText={'만 14세 이상 고객이 이용할 수 있어요.'} onSubmit={() => {
                setShow14BlockPopup(false)
            }} isOpen={show14BlockPopup}/>

            <BS_Location isOpen={showLocationTermBS} onClose={() => setShowLocationTermBS(false)}
                         onAgree={() => sendEventReturn('GET_GRANT_LOCATION', {})}/>

        </>
    );
};

export default CategoryTabs;
