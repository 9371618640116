import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from 'react';
import SectionHeader from '../../components/Common/CommonHeader';
import SectionHeading from '../../components/Agreements/SectionHeading';
import SectionChkList from '../../components/Agreements/SectionChkList';
import TermUnitedInfoBoxCTA from '../../components/Agreements/TermUnitedInfoBoxCTA';
import {Agreement, AgreementType, JoinRequestParams} from './types';
import useLoading from "../../hooks/useLoading";
import {termsApi} from "../../api/settingAndTerms";
import commonApi from "../../api/common";
import {affiliateServiceApi} from "../../api/partnerAndAffiliate-service";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {addItem, getItemByKey} from "../../utils/indexedDB";
import {decrypt, encrypt, generateMCP_TR_KEY} from "../../api/api";
import {sendEvent, sendEventReturn} from "../../api/bridgeApi";
import CommonPopup from "../../components/Common/CommonPopup";
import AgreementsDetail from "./AgreementsDetail";

export interface AgreementHandles {
    triggerHandleJoin: (fcmToken: string) => void;
}

const Agreements = forwardRef<AgreementHandles>((props, ref) => {
    const navigate = useNavigate();
    const {type} = useParams<{ type: AgreementType }>();
    const [agreementList, setAgreementList] = useState<Agreement[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [allCheck, setAllCheck] = useState(false);
    const [isButtonEnabled, setIsButtonEnabled] = useState(false);  // 버튼 활성화 상태
    const [isDetailOpen, setIsDetailOpen] = useState(false);  // Bottom Sheet의 상태 관리
    const [selectedTermId, setSelectedTermId] = useState<string | null>(null);
    const [openErrorPopup, setOpenErrorPopup] = useState(false);
    const [fcmToken, setFcmToken] = useState<string | null>(null);  // fcmToken 상태 추가
    const serviceId = "3";
    const location = useLocation();
    const info = location.state;

    useImperativeHandle(ref, () => ({
        async triggerHandleJoin(fcmToken: string) {
            console.log("handleJoin")
            await handleJoin(fcmToken);
        }
    }));

    const handleJoin = useCallback(async (fcmToken: string) => {
        if (fcmToken === '') {
            await addItem('pushRegId', fcmToken)
            await addItem('sendTokenYn', 'N')
        }
        setIsLoading(true);
        const MCP_TR_KEY = generateMCP_TR_KEY();
        const params: JoinRequestParams = {
            name: await encrypt(MCP_TR_KEY, info.name),
            phoneNo: info.phoneNo,
            birthday: info.birthday,
            gender: info.gender,
            nation: info.nation,
            telcoCode: info.telcoCode,
            pushRegId: fcmToken,
            modelName: await getItemByKey('model'),
            termsList: agreementList.map((agreement) => ({
                termId: agreement.termId,
                termYn: agreement.selected ? 'Y' : 'N',
                termsType: agreement.termsType,
                optTermCode: agreement.optTermCode || '',
            })),
            ...(info.certNum ? {certNum: info.certNum} : {}),
            ...(info.dasKey ? {dasKey: info.dasKey} : {}),
            ...(info.dasSsoKey ? {dasSsoKey: info.dasSsoKey} : {}),
            ...(info.ipinAuthId ? {ipinAuthId: info.ipinAuthId} : {}),
        };

        try {
            const response = await commonApi.join(params, 'v1', MCP_TR_KEY);
            const data = response.body;
            if (data === undefined || data === null) {
                setIsLoading(false);
                setOpenErrorPopup(true);
            } else {
                const memberId = await decrypt(MCP_TR_KEY, data.memberId);
                await sendEvent('SET_MEMBER_ID', {memberId});
                await addItem('memberId', memberId);

                const name = await decrypt(MCP_TR_KEY, data.customerName);
                await sendEvent('SET_USER_NAME', {userName: name});
                await addItem('customerName', name);

                const ctn = await decrypt(MCP_TR_KEY, data.ctn);
                await sendEvent('SET_CTN_INFO', {ctn_info: ctn});
                await addItem('ctn', ctn);

                const birthday = data.birthday;
                await sendEvent('SET_USER_BIRTH', {userBirth: birthday});
                await addItem('birthday', birthday);

                const gender = data.gender;
                await sendEvent('SET_USER_GENDER', {gender});
                await addItem('gender', gender);

                const telcoCode = data.telcoCode;
                await sendEvent('SET_CARRIER', {carrier: telcoCode});
                await addItem('telcoCode', telcoCode);

                await addItem('userStatus', data.memberStatus);

                const subNo = data.subNo;  // TODO 가번 추후 native 저장
                await addItem('subNo', subNo);

                const subCustNo = data.subCustNo;  // TODO 고객번호 추후 native 저장
                await addItem('subCustNo', subCustNo);

                await sendEventReturn('SET_PASSWORD', {});
                const adAgreement = agreementList.filter(agreement => agreement.termsType === '125003');
                if (adAgreement.length > 0) {
                    sendEvent('SET_AD_AGREE', {agree: adAgreement[0].selected});
                }
                await addItem('pushRegId', fcmToken)
                await addItem('sendTokenYn', 'Y')
                navigate('/?state=first', {replace: true});
            }
        } catch (error) {
            console.error('회원가입 실패:', error);
        } finally {
            setIsLoading(false);
        }
    }, [agreementList, info, navigate]);

    const handleCTAButtonClick = useCallback(() => {
        if (type === 'join') {
            handleJoin('');
            // sendEventReturn("FCM_TOKEN", {})
        } else if (type === 'additional') {
            console.log("추가 약관 동의 및 진행");
        } else if (type === 'partnership') {
            console.log("파트너십 약관 동의 및 진행");
        } else if (type === 'existing') {
            console.log("기존 약관 동의 및 진행");
        }
    }, [type, agreementList, info]);

    const goMain = () => {
        navigate('/');
    };

    const handleTermClick = (termId: string) => {
        setSelectedTermId(termId);
        setIsDetailOpen(true);  // Bottom Sheet 열기
        console.log("termId", termId)
        console.log("IsDetailOpen", isDetailOpen)
    };

    const handleDetailClose = () => {
        setIsDetailOpen(false);  // Bottom Sheet 닫기
    };

    const handleSubmit = (termId: string) => {
        // termId에 해당하는 약관의 체크 상태를 반대로 변경
        setAgreementList(prevList =>
            prevList.map(term =>
                term.termId === termId ? { ...term, selected: !term.selected } : term
            )
        );
        setIsDetailOpen(false); // 세부 항목 창 닫기
    };

    useEffect(() => {
        const fetchAgreements = async () => {
            try {
                let response;
                switch (type) {
                    case 'join':
                        response = await termsApi.getJoinAgreements({
                            birthday: info.birthday,
                            phoneNo: info.phoneNo
                        }, 'v1');
                        const reqTermsList = response.body.reqTermsList.map((term: any) => ({
                            termId: term.termId,
                            termName: term.termName,
                            termsType: term.termsType,
                            optTermCode: term.optTermCode,
                            selected: false,
                            isRequired: true,  // 필수 약관
                        }));

                        const optTermsList = response.body.optTermsList.map((term: any) => ({
                            termId: term.termId,
                            termName: term.termName,
                            termsType: term.termsType,
                            optTermCode: term.optTermCode,
                            selected: false,
                            isRequired: false,  // 선택 약관
                        }));

                        setAgreementList([...reqTermsList, ...optTermsList]);
                        break;
                    //TODO 추후수정
                    // case 'additional':
                    //     response = await commonApi.terms.getAgreements({ serviceYackReqStatus: session.serviceYackReqStatus }, 'v1');
                    //     break;
                    case 'partnership':
                        response = await affiliateServiceApi.getAgreements({serviceId}, 'v1');
                        break;
                    case 'existing':
                        response = await termsApi.getAgreements({}, 'v1');
                        break;
                    default:
                        throw new Error("Invalid agreement type");
                }
            } catch (error) {
                console.error("Error fetching agreements:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchAgreements();
    }, [type]);

    useEffect(() => {
        const allChecked = agreementList.every(term => term.selected);
        const allRequiredChecked = agreementList.every(term => term.isRequired ? term.selected : true);

        if (allCheck !== allChecked) {
            setAllCheck(allChecked);
        }
        setIsButtonEnabled(allRequiredChecked);  // 필수 약관이 모두 체크되었는지 확인하여 버튼 상태 설정
    }, [agreementList]);

    const handleAgreementChange = (termId: string, isChecked: boolean) => {
        setAgreementList(prevList =>
            prevList.map(term =>
                term.termId === termId ? {...term, selected: isChecked} : term
            )
        );
    };

    const handleAllAgreementsChange = (isChecked: boolean) => {
        setAgreementList(prevList =>
            prevList.map(term => ({...term, selected: isChecked}))
        );
        setAllCheck(isChecked);
    };

    return (
        <>
            {useLoading(isLoading)}
            {!isDetailOpen &&
                <>
                <SectionHeader align={"left"} icon={"chevron_left_line"}/>
                <SectionHeading mainType={type} subType={"자사"}/>
                <SectionChkList
                    agreementList={agreementList}
                    allCheck={allCheck}
                    onAgreementChange={handleAgreementChange}
                    onAllAgreementsChange={handleAllAgreementsChange}
                    type={type}
                    onTermClick={handleTermClick}
                />
                <TermUnitedInfoBoxCTA onClickCTA={handleCTAButtonClick} isButtonEnabled={isButtonEnabled}/>
                </>
            }

            {openErrorPopup && (
                <CommonPopup description={"일시적인 서비스 오류로 가입이 어려워요.\n다음에 이용부탁드려요."} submitText={"확인"} onSubmit={goMain}/>
            )}
            {isDetailOpen && <AgreementsDetail
                selected={agreementList.find(term => term.termId === selectedTermId)?.selected || false}
                type={type} termId={selectedTermId || ""}
                handleSubmit={() => handleSubmit(selectedTermId || '')}
                handleClose={() => setIsDetailOpen(false)}
            />}
        </>
    )
})

export default Agreements;
