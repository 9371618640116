import React from 'react';
import {useNavigate} from 'react-router-dom';
import {Badge, Image, Text} from '@lguuxe/2024_designsystem_uni';
import my1Icon from '../../images/icon/5_full menu/moneyme-1.svg';
import my2Icon from '../../images/icon/5_full menu/event-1.svg';
import my3Icon from '../../images/icon/5_full menu/chatbot_L.svg';
import {chatbot, moneymeEnter} from "../../api/externalApi";
import {getItemByKey} from "../../utils/indexedDB";

interface ShortcutButtonProps {
    onClick: () => void;
    icon: string;
    text: string;
    hasNew?: boolean;
}

const ShortcutButton: React.FC<ShortcutButtonProps> = ({onClick, icon, text, hasNew}) => {
    return (
        <div
            onClick={onClick}
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '33%',
                cursor: 'pointer',
            }}
        >
            {hasNew ? (
                <Badge text='N' style={{right: '12px', width: '10px', height: '18px'}}>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Image ratio='1:1' src={icon} width='72'/>
                    </div>
                </Badge>
            ) : (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Image ratio='1:1' src={icon} width='72'/>
                </div>
            )}
            <Text typo='font_heading_5Sb'>{text}</Text>
        </div>
    );
};

interface ShortcutSectionProps {
    handlePopup: (type: string) => void;
    memberStatus: string;
}

const ShortcutSection: React.FC<ShortcutSectionProps> = ({handlePopup, memberStatus}) => {
    const navigate = useNavigate();
    const MONEYME_DEV_URL = 'https://dev-mydata.lguplus.com:8080';

    const handleMoneyMeClick = async () => {
        const memberId = await getItemByKey('memberId');
        if (!memberId) {
            handlePopup('login');
            return;
        }
        moneymeEnter();
    }
    const handleEventClick = () => {
        navigate('/event');
    }
    const handleChatbotClick = async () => {
        const memberId = await getItemByKey('memberId');
        if (!memberId) {
            handlePopup('login');
            return;
        }
        chatbot();
    }
    return (
        <div
            style={{
                display: 'flex',
                gap: '8px',
                padding: '24px 20px 12px',
            }}
        >
            {(memberStatus === '1' || memberStatus === '2' || memberStatus === '5') && (
                <>
                    <ShortcutButton onClick={handleMoneyMeClick} icon={my1Icon} text='머니Me'/>
                    <ShortcutButton onClick={handleEventClick} icon={my2Icon} text='이벤트' hasNew/>
                </>
            )}
            <ShortcutButton onClick={handleChatbotClick} icon={my3Icon} text='챗봇'/>
        </div>
    );
};

export default ShortcutSection;
